import React from 'react';

import Btech from '../Btech';

export default function MBBS() {
  return (
    <div>
      <Btech
        title="MBBS"
        name="CaderaEdu Team"
        designation="Content Writer"
        writerInfo=" "
        contentAboutTitle="The high-quality delivery of medical education and training in the top MBBS colleges in India can equip students for expertise in fields in medicine. This institution offers a tough curriculum with immense involvement by both theoretical and practical aspects of medical science like Anatomy, Physiology, Biochemistry, Pathology, Pharmacology, and Clinical Medicine. An MBBS program is provided to equip the student with intense knowledge related to human biology, mechanisms of diseases, diagnostic techniques, and modes of treatments. This faculty of colleges consists of experienced doctors, researchers, and healthcare professionals bringing valuable insights and expertise into the classroom and enhancing the learning experience of the aspiring physicians.

One characteristic of first-rate MBBS colleges is that they lay much emphasis on practical training at clinics and exposure to real medical practices. The majority of institutions have teaching hospitals through which their students undergo practical learning in all kinds of medical facilities by being rotated to departments of medicine so they can put learning into practice."
      />
    </div>
  );
}
