import React from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import {useState,useEffect} from 'react';

function ExamSyllabusComponent(){


    const navigate = useNavigate();
    const { examName } = useParams();
  
    const [examData, setExamData] = useState(null);
  
    // Fetch exam data from the backend when the component mounts
    useEffect(() => {
      async function fetchExamData() {
        try {
          const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
          const data = await response.json();
          setExamData(data);
        } catch (error) {
          console.error("Error fetching exam data:", error);
        }
      }
  
      fetchExamData();
    }, [examName]);
  
    if (!examData) {
      return <div>Loading...</div>;
    }


    return (
        <>
         <h2 className="text-2xl font-bold mt-6">Syllabus</h2>
          {examData.syllabusSections.map((section, index) => (
          <div key={index} className="mb-4">
            <h3 className="font-semibold">{section.title}</h3>
            <ul className="list-disc ml-5">
              {section.topics.map((topic, i) => (
                <li key={i}>{topic}</li>
              ))}
            </ul>
          </div>
          ))}
        </>
    )
}

export default ExamSyllabusComponent;