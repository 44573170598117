import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStethoscope,
  faHeartbeat,
  faUserMd,
  faNotesMedical,
  faBookOpen,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { clat, ailet, slat, dullb } from "../../images";

const exams = [
  {
    id: 1,
    name: "GGSIPU LLB  (based on clat ug rank)",
    icon: "https://th.bing.com/th/id/OIP.5kAZnkhYvxW9kd4cU8aiiwAAAA?w=169&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
  },
  {
    id: 2,
    name: "GGSIPU LLM  (based on clat pg rank)",
    icon: "https://th.bing.com/th/id/OIP.5kAZnkhYvxW9kd4cU8aiiwAAAA?w=169&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
  },
   {
    id: 3,
    name: "NLU LLB Predictor(Based on CLAT UG Rank)",
    icon: clat,
  },
  { id: 4, name: "NLU LLM Predictor(Based on CLAT PG Rank)", icon: clat, },
 
  {
    id: 5,
    name: "AILET",
    icon: ailet,
  },
  {
    id: 6,
    name: "DU LLB Entrance Exam",
    icon: dullb,
  },
];

const LawPredictormobile = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedExams, setSelectedExams] = useState([]);
  const itemsPerPage = 6;
  const navigate = useNavigate();

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExams = exams.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(exams.length / itemsPerPage);

  const handleExamSelect = (exam) => {
    if (selectedExams.includes(exam.name)) {
      setSelectedExams(selectedExams.filter((name) => name !== exam.name));
    } else {
      setSelectedExams([...selectedExams, exam.name]);
    }
  };

  const handleNextClick = () => {
    if (selectedExams.length > 0) {
      const selectedExam = selectedExams[0];

      if (selectedExam === "GGSIPU LLB  (based on clat ug rank)") {
        navigate("/ggsipullbpredictor");
      } else if (selectedExam === "GGSIPU LLM  (based on clat pg rank)") {
        navigate("/ggsipullmpredictor");
      } else if (selectedExam === "NLU LLM Predictor(Based on CLAT PG Rank)") {
        navigate("/nlullmpredictor");
      } else if (selectedExam === "NLU LLB Predictor(Based on CLAT UG Rank)") {
        navigate("/nlullbpredictor");
      } else if (selectedExam === "AILET") {
        navigate("/");
      } else if (selectedExam === "DU LLB Entrance Exam") {
        navigate("/");
      } else {
        console.log("No valid exam selected for navigation");
      }
    }
  };

  return (
    <div className=" bg-gray-100 flex flex-col items-center p-4">
      <h1 className="text-3xl font-bold mb-4 text-center text-black">
        Law College Predictor 2024
      </h1>
      <p className="text-black-600 mb-6 text-center">
        Predict Colleges based on Law exams you have taken.
      </p>

      <div className="grid grid-cols-3 gap-6">
        {currentExams.map((exam) => (
          <div key={exam.id} className="flex flex-col items-center">
            <label
              key={exam.id}
              className="bg-white p-4 rounded-lg shadow-md text-center cursor-pointer transform hover:scale-105 transition-transform duration-200 ease-in-out relative" // Added relative positioning
              onClick={() => handleExamSelect(exam)}
            >
              <div className="w-16 h-16 mx-auto mb-2">
                <img
                  src={exam.icon}
                  alt={exam.name}
                  className="w-full h-full object-contain"
                />
              </div>
              <input
                type="checkbox"
                checked={selectedExams.includes(exam.name)}
                className="absolute inset-0 w-full h-full cursor-pointer opacity-0" // Hidden but occupies the box
              />
              {selectedExams.includes(exam.name) && (
                <div className="absolute top-2 right-2 text-green-500">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </label>
            <p className=" font-semibold text-gray-800 mt-1 text-center" style={{fontsize:'13px'}}>
              {exam.name}
            </p>{" "}
            {/* Name outside of the box */}
          </div>
        ))}
      </div>

      <div className="flex mt-6 space-x-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={handleNextClick}
          disabled={selectedExams.length === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 hover:bg-blue-600 transition-colors"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LawPredictormobile;
