[
  {
    "Rank": 1,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 5,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 9,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 10,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 12,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 13,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 14,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 16,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 17,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 18,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 19,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 20,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 21,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 22,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 24,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 25,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 26,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 27,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 28,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 29,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 30,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 31,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 32,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 33,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 34,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 35,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 37,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 39,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 41,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 42,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 43,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 44,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 45,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 46,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 47,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 48,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 49,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 50,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 51,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 52,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 53,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 54,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 55,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 57,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 58,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 59,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 60,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 61,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 62,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 63,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 64,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 65,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 66,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 67,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 68,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 71,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 72,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 74,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 75,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 76,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 77,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 78,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 79,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 80,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 81,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 82,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 83,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 85,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 86,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 87,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 88,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 89,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 90,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 91,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 92,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 93,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 94,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 95,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 96,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 97,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 98,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 99,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 100,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 101,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 102,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 107,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 116,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 122,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 129,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 131,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 137,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 149,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 151,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 154,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 165,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 169,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 172,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 176,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 192,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 193,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 198,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 217,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 219,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 224,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 225,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 236,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 239,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 240,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 250,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 258,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 266,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 267,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 270,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 280,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 288,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 291,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 292,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 294,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 296,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 297,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 299,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 301,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 303,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 309,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 315,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 316,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 326,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 333,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 336,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 344,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 348,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 356,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 358,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 361,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 364,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 366,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 372,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 378,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 381,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 382,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 425,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 431,
    "Category": "General",
    "SubCategory": "GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 432,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 433,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 436,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 442,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 446,
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 448,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 449,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 455,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 457,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 463,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 468,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 488,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 492,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 495,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 500,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 505,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 507,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 521,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 523,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 538,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 552,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 558,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 565,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 569,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 577,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 581,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 597,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 625,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 629,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 645,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 647,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 657,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 675,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 679,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 689,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 704,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 710,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 720,
    "Category": "General",
    "SubCategory": "PWD,W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 726,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 737,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 753,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 776,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 777,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 779,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 795,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 803,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 805,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 819,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 856,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 864,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 876,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 880,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 883,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 887,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 892,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 903,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 907,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 908,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 926,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 928,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 942,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 943,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 970,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 971,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 980,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 994,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1017,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1067,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1125,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1138,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1179,
    "Category": "SC",
    "SubCategory": "W,SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1210,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1217,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1416,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1682,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1693,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1705,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1732,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1758,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1759,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1769,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1793,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1811,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1838,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1869,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1955,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 1977,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2030,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2165,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2194,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2233,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2234,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2331,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2339,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2487,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2528,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2548,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2569,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2615,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2639,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2647,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2681,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2726,
    "Category": "EWS",
    "SubCategory": "EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2761,
    "Category": "SC",
    "SubCategory": "SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2809,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2823,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 2825,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3007,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3029,
    "Category": "EWS",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3033,
    "Category": "OBC",
    "SubCategory": "PWD,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3042,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3048,
    "Category": "SC",
    "SubCategory": "W,SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3083,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3096,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3151,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3193,
    "Category": "SC",
    "SubCategory": "SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3234,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3313,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3314,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3366,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3451,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3535,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3603,
    "Category": "EWS",
    "SubCategory": "W,EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3724,
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3729,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3758,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3775,
    "Category": "ST",
    "SubCategory": "ST_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 3801,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4014,
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4101,
    "Category": "EWS",
    "SubCategory": "W,EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4219,
    "Category": "EWS",
    "SubCategory": "EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4362,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4600,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4631,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4836,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4847,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4937,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 5109,
    "Category": "ST",
    "SubCategory": "W,ST_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 5141,
    "Category": "SC",
    "SubCategory": "SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 5279,
    "Category": "General",
    "SubCategory": "PWD,W,GC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 5362,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 5576,
    "Category": "EWS",
    "SubCategory": "W,EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 5837,
    "Category": "SC",
    "SubCategory": "SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 6303,
    "Category": "EWS",
    "SubCategory": "W,EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 6955,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 7487,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 7921,
    "Category": "SC",
    "SubCategory": "W,SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 8067,
    "Category": "SC",
    "SubCategory": "SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 8701,
    "Category": "ST",
    "SubCategory": "W,ST_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 9319,
    "Category": "EWS",
    "SubCategory": "EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 9724,
    "Category": "SC",
    "SubCategory": "W,SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 10201,
    "Category": "SC",
    "SubCategory": "W,SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 10681,
    "Category": "SC",
    "SubCategory": "SC_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 11845,
    "Category": "ST",
    "SubCategory": "W,ST_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 12319,
    "Category": "EWS",
    "SubCategory": "W,EWS_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 13860,
    "Category": "ST",
    "SubCategory": "W,ST_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 14349,
    "Category": "ST",
    "SubCategory": "ST_KA",
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Rank": 4,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 40,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 73,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 103,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 104,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 105,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 106,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 108,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 109,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 110,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 111,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 113,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 114,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 115,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 117,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 118,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 119,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 121,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 123,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 124,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 125,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 126,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 128,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 130,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 132,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 133,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 134,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 135,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 136,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 138,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 139,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 141,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 143,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 144,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 145,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 146,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 147,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 152,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 153,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 155,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 156,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 158,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 160,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 161,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 162,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 164,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 166,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 167,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 173,
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 175,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 189,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 272,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 285,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 307,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 319,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 332,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 367,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 391,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 401,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 427,
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 458,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 504,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 530,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 551,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 563,
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 564,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 583,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 613,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 621,
    "Category": "EWS",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 691,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 731,
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 756,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 780,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 783,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 822,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 859,
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 871,
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 912,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 917,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 930,
    "Category": "GC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 941,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 955,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1023,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1029,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1031,
    "Category": "OBC_D_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1037,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1039,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1048,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1076,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1079,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1087,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1112,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1116,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1604,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1697,
    "Category": "OBC_B_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 1795,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 2088,
    "Category": "OBC_B_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 2147,
    "Category": "OBC_B_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 2395,
    "Category": "OBC_D_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 2440,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3011,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3080,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3119,
    "Category": "EWS_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3157,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3163,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3278,
    "Category": "EWS_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3368,
    "Category": "OBC_E_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3375,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3400,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3427,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3465,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3496,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3555,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3705,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 3893,
    "Category": "SC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 4827,
    "Category": "OBC_A_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 5216,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 5548,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 5579,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 5595,
    "Category": "EWS_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6083,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6331,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6373,
    "Category": "OBC_A_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6410,
    "Category": "OBC_C_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6447,
    "Category": "SC_TL",
    "SubCategory": "Not Applicable",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6547,
    "Category": "SC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6666,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 6933,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 7248,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 7804,
    "Category": "SC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 7870,
    "Category": "SC_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 8075,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 8843,
    "Category": "ST_TL",
    "SubCategory": "W",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 9185,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Rank": 465,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 466,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 473,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 861,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1376,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 6066,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 8159,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 159,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 251,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 305,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 311,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 314,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 330,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 345,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 349,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 353,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 363,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 376,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 377,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 383,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 387,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 393,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 398,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 403,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 404,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 405,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 409,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 416,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 420,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 435,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 438,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 439,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 444,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 445,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 450,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 454,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 456,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 460,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 542,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 576,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 589,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 594,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 609,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 614,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 624,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 636,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 654,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 686,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 702,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 706,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 745,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 759,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 770,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 787,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 792,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 798,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 801,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 815,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 825,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 833,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 879,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 914,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 927,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 944,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 977,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1006,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1022,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1071,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1088,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1115,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1155,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1160,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1191,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1289,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1291,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1315,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1383,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1483,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1541,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1564,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1640,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 3161,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 4203,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 4612,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 4844,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 4859,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 5177,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 5178,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 5214,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 5566,
    "Category": "OBC",
    "SubCategory": "OBC_MP,PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 5577,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 5626,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 5664,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 6046,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 6188,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 6274,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 6872,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 7832,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 8929,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 9316,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 9473,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 9747,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 10023,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 10546,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 10791,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 10917,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 11498,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 11527,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 11689,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 12253,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 12340,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 13182,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 13336,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 13984,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 14049,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 14227,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 14598,
    "Category": "SC",
    "SubCategory": "SC_MP,W,PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 16025,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 17269,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 17865,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 18736,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.A.LL.B (Hons.)"
  },
  {
    "Rank": 1047,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1058,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1777,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1943,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1964,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 9440,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 30931,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 187,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 335,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 660,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 747,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 749,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 782,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 878,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 881,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 934,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1008,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1041,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1248,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1288,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1304,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1306,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1323,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1332,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1388,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1397,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1460,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1467,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1485,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1557,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1599,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1629,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1674,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1749,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1750,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1826,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1868,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1910,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 1959,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 3764,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 3897,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 4226,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 4545,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 5110,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 6909,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 7622,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 8322,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 9057,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 10337,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 10898,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 12365,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 13004,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 14341,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 14375,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 14754,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 15219,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 16201,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 21986,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 22572,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 23604,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 24819,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 30072,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 30118,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 30382,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "The National Law Institute University (NLIU), Bhopal _ B.Sc. LL.B. (Hons.) [Cyber Security]"
  },
  {
    "Rank": 278,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 279,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 140,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 163,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 168,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 171,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 178,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 180,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 181,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 182,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 183,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 186,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 191,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 194,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 195,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 196,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 197,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 200,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 201,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 202,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 204,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 205,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 209,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 211,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 212,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 213,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 218,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 227,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 228,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 229,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 230,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 232,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 233,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 237,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 238,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 241,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 242,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 247,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 248,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 249,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 253,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 255,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 260,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 262,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 264,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 265,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 274,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 275,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 277,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 313,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 351,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 359,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 362,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 407,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 447,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 452,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 461,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 467,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 472,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 516,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 532,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 575,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 744,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 804,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 817,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 847,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 888,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 965,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 988,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 2056,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 2610,
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 2856,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 3117,
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 3349,
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 3681,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 3752,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 3785,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 3922,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 3951,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 4196,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 4427,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 4464,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 4503,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 4841,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 5215,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 5408,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 5608,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 6694,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 7099,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 7177,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 7816,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 8615,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 8644,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 8716,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 8860,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 8918,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 9461,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 9708,
    "Category": "GC_WB",
    "SubCategory": "PWD_WB",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 9734,
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 9892,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 10135,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 10375,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 10703,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 11100,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 14436,
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 14456,
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 14509,
    "Category": "ST_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 15978,
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 17161,
    "Category": "ST_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 30484,
    "Category": "GC_WB",
    "SubCategory": "PWD_WB",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Rank": 2133,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 289,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 290,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 327,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 462,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 465,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 470,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 559,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 619,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 627,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 642,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 649,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 653,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 673,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 768,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 835,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 874,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 899,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 921,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 939,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 998,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1010,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1021,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1107,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1351,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1521,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1534,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1548,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 1827,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 2015,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 2058,
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 4663,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 5222,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 5813,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 7619,
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 8102,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 8958,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 9123,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 11157,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 11295,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 11405,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 11502,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 11542,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 11917,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 13097,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 13644,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 13948,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 14069,
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 25632,
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 26986,
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 30386,
    "Category": "ST_WB",
    "SubCategory": "Not Applicable",
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata BSc. LLB. (Hons.) [Criminology and Forensic]"
  },
  {
    "Rank": 375,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 379,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1424,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 5178,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 112,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 142,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 170,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 179,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 185,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 206,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 208,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 214,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 216,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 223,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 234,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 235,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 243,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 244,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 245,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 246,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 254,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 257,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 261,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 269,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 271,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 273,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 276,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 278,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 279,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 281,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 283,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 286,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 293,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 295,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 298,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 300,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 302,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 304,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 306,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 308,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 310,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 317,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 320,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 323,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 324,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 325,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 328,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 329,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 331,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 334,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 337,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 338,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 339,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 341,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 347,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 350,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 360,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 365,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 368,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 369,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 371,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 385,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 400,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 487,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 865,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 958,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1141,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1146,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1150,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1158,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1162,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1171,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1195,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1200,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1201,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1228,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1229,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1230,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1235,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1240,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1241,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1254,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1257,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1265,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1298,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1311,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1313,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 1742,
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 3574,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 3810,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 3845,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 3943,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4070,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4136,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4170,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4231,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4411,
    "Category": "ST",
    "SubCategory": "ST_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4417,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4457,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4536,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4549,
    "Category": "SC",
    "SubCategory": "SC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4573,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4835,
    "Category": "SC",
    "SubCategory": "SC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 4921,
    "Category": "SC",
    "SubCategory": "SC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 5122,
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 5148,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 5304,
    "Category": "SC",
    "SubCategory": "SC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 5760,
    "Category": "SC",
    "SubCategory": "SC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 7175,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 7213,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 7795,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 7877,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 8302,
    "Category": "ST",
    "SubCategory": "ST_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 8373,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 8403,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 8960,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 9025,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 9532,
    "Category": "ST",
    "SubCategory": "ST_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 9964,
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 11256,
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 13991,
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 14837,
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 16702,
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 21623,
    "Category": "General",
    "SubCategory": "GC_RJ",
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Rank": 789,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 791,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2069,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2103,
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 4349,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7751,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 8107,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 464,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 475,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 476,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 478,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 479,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 483,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 485,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 497,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 509,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 513,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 514,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 515,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 527,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 536,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 544,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 561,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 572,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 573,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 582,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 584,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 588,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 596,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 600,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 604,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 615,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 620,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 622,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 635,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 640,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 644,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 648,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 662,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 665,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 671,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 674,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 685,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 698,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 701,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 717,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 725,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 732,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 740,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 743,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 746,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 751,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 758,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 763,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 765,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 766,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 772,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 773,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 775,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 900,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 905,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 911,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 952,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1000,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1293,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1333,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1430,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1438,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1480,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1593,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1878,
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1905,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1922,
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1926,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1964,
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1965,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1972,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1981,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 1985,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2041,
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2068,
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2092,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2171,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2382,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2392,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2624,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2758,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2767,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2788,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2836,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2929,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 2980,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3022,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3091,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3092,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3143,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3367,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3410,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3469,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3534,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3632,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3853,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 3927,
    "Category": "GC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 4211,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 4297,
    "Category": "GC_CHT",
    "SubCategory": "W_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 5192,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 5394,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 5609,
    "Category": "OBC_CHT",
    "SubCategory": "W_OBC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 5729,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6220,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6419,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6602,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6653,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6745,
    "Category": "OBC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6765,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6900,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 6974,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7089,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7111,
    "Category": "OBC_CHT",
    "SubCategory": "W_OBC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7188,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7191,
    "Category": "OBC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7441,
    "Category": "SC",
    "SubCategory": "W_SC",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7671,
    "Category": "OBC_CHT",
    "SubCategory": "W_OBC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 7821,
    "Category": "OBC_CHT",
    "SubCategory": "W_OBC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 9218,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 9288,
    "Category": "OBC_CHT",
    "SubCategory": "W_OBC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 9296,
    "Category": "OBC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 9415,
    "Category": "OBC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 9563,
    "Category": "OBC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 10414,
    "Category": "ST",
    "SubCategory": "W_ST",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 10520,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 10910,
    "Category": "SC_CHT",
    "SubCategory": "W_SC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 11879,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 12009,
    "Category": "ST",
    "SubCategory": "W_ST",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 12035,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 12156,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 12296,
    "Category": "SC_CHT",
    "SubCategory": "W_SC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 12558,
    "Category": "SC_CHT",
    "SubCategory": "W_SC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 12593,
    "Category": "SC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 12990,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 14026,
    "Category": "SC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 14733,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 16063,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 16393,
    "Category": "SC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 16842,
    "Category": "SC_CHT",
    "SubCategory": "W_SC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 17112,
    "Category": "SC_CHT",
    "SubCategory": "W_SC_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 18160,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 18414,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 18577,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 19080,
    "Category": "SC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 19215,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 19467,
    "Category": "SC_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 19520,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 20832,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 21095,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 21343,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 22925,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 22956,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 23157,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 24773,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 26332,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 27468,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 28316,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 29051,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 29445,
    "Category": "OBC_CHT",
    "SubCategory": "PWD_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 29808,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 30339,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 31861,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 32312,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 32851,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 32988,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 33781,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 33985,
    "Category": "OBC_CHT",
    "SubCategory": "PWD_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 34413,
    "Category": "ST_CHT",
    "SubCategory": "W_ST_CHT",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 35510,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 36144,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 36231,
    "Category": "ST_CHT",
    "SubCategory": "Not Applicable",
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Rank": 444,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 445,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 456,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 464,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1248,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1910,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1922,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5729,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 8,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 174,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 210,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 226,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 287,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 312,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 318,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 321,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 343,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 354,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 373,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 375,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 379,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 380,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 384,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 386,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 388,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 389,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 390,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 392,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 394,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 396,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 397,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 402,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 411,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 412,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 415,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 418,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 421,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 423,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 426,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 429,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 430,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 434,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 440,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 443,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 541,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 546,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 603,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 606,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 628,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 652,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 663,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 672,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 690,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 693,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 712,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 722,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 727,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 750,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 771,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 810,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 812,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 852,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 920,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 938,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 989,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1001,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1135,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1219,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1227,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1244,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1284,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1286,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1325,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1339,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1349,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1359,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1377,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1394,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1424,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1442,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1452,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1461,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1472,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1498,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1502,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1504,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1508,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1517,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1524,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1542,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1551,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1570,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1572,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1588,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1591,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1592,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1611,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1612,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1616,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1642,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1690,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1694,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1696,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1721,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1725,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1762,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1764,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1770,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1771,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1787,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1796,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1798,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1804,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1809,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1815,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1822,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1834,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1836,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1841,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1848,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1849,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1852,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1858,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1875,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 1987,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2031,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2044,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2104,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2304,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2406,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2447,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2532,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2690,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2736,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2778,
    "Category": "General",
    "SubCategory": "GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2938,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 2996,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 3217,
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 4988,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5013,
    "Category": "SC",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5031,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5043,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5197,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5242,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5245,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5327,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5386,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5472,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5495,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5587,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5592,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5699,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5735,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5810,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5990,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 5997,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 6040,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 6066,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 6087,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 6175,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 7712,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 8283,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 9223,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 9235,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 9516,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 9732,
    "Category": "ST",
    "SubCategory": "W,GC_GJ",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 9806,
    "Category": "SC",
    "SubCategory": "PWD",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 9884,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 9965,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 10144,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 10164,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 10438,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 10501,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 10645,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 11580,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 11671,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 11832,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 12122,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 12468,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 13409,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Rank": 975,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 983,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 1060,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 1935,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2628,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 3056,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 10764,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 10991,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 26288,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 48532,
    "Category": "OBC",
    "SubCategory": "W,OBC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 52081,
    "Category": "SC",
    "SubCategory": "W,SC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 593,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 631,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 716,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 724,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 741,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 762,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 796,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 802,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 809,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 828,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 842,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 846,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 853,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 868,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 869,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 889,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 891,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 906,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 925,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 940,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 1376,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 1651,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 1799,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 1887,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 1933,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2103,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2131,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2141,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2225,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2262,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2337,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2390,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2429,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2484,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2504,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2563,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 2613,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 7751,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 8420,
    "Category": "General",
    "SubCategory": "GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 8474,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 8833,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 9478,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 9853,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 10674,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 10735,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 14865,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 15471,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 15524,
    "Category": "General",
    "SubCategory": "PWD,W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 16048,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 16161,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 21710,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 24292,
    "Category": "OBC",
    "SubCategory": "W,OBC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 33647,
    "Category": "EWS",
    "SubCategory": "EWS_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 38395,
    "Category": "General",
    "SubCategory": "GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 41160,
    "Category": "General",
    "SubCategory": "W,GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 41908,
    "Category": "General",
    "SubCategory": "W,GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 44312,
    "Category": "OBC",
    "SubCategory": "W,OBC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 45250,
    "Category": "General",
    "SubCategory": "W,GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 45501,
    "Category": "General",
    "SubCategory": "GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 45610,
    "Category": "General",
    "SubCategory": "W,GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 45896,
    "Category": "General",
    "SubCategory": "W,GC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 46810,
    "Category": "OBC",
    "SubCategory": "W,OBC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 47398,
    "Category": "OBC",
    "SubCategory": "W,OBC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 48324,
    "Category": "SC",
    "SubCategory": "W,SC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 48346,
    "Category": "ST",
    "SubCategory": "ST_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 50292,
    "Category": "SC",
    "SubCategory": "W,SC_DDN",
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Rank": 764,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 774,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 785,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 786,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 788,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3613,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 23185,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 25284,
    "Category": "OBC_UP",
    "SubCategory": "DFF_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 357,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 374,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 410,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 459,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 466,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 471,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 489,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 493,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 499,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 501,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 508,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 512,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 525,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 533,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 534,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 535,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 537,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 539,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 549,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 554,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 556,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 557,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 566,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 568,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 579,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 595,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 598,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 599,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 601,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 605,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 608,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 612,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 616,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 617,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 618,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 632,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 633,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 634,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 638,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 650,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 655,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 658,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 659,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 664,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 667,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 668,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 669,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 676,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 682,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 683,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 684,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 687,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 694,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 695,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 700,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 703,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 707,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 708,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 711,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 721,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 728,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 733,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 735,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 757,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 760,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1170,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1197,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1234,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1340,
    "Category": "General",
    "SubCategory": "CDP_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1398,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1405,
    "Category": "EWS_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1446,
    "Category": "EWS_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1520,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1560,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1624,
    "Category": "EWS_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1639,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1645,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1659,
    "Category": "EWS_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1820,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1954,
    "Category": "EWS_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1983,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1986,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2083,
    "Category": "General",
    "SubCategory": "W,CDP_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2095,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2119,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2146,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2154,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2167,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2214,
    "Category": "General",
    "SubCategory": "DFF_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2268,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2277,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2316,
    "Category": "EWS_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2318,
    "Category": "EWS_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2326,
    "Category": "EWS_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2344,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2482,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2566,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2627,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2699,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2730,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2787,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2834,
    "Category": "General",
    "SubCategory": "CDP_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2845,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2885,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2893,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 2974,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3016,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3031,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3044,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3140,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3179,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3251,
    "Category": "General",
    "SubCategory": "W,CDP_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3257,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3265,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3297,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3348,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3370,
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3411,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3426,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3434,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3448,
    "Category": "OBC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 3504,
    "Category": "General",
    "SubCategory": "W,CDP_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 4278,
    "Category": "General",
    "SubCategory": "W,CDP_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 4889,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 5230,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 5397,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 6859,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7164,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7205,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7503,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7621,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7659,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7812,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7852,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 7957,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8004,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8126,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8440,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8486,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8544,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8649,
    "Category": "OBC_UP",
    "SubCategory": "PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8781,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8783,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8814,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8825,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 8920,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 9052,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 9108,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 9206,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 9497,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 9616,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 9634,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 9729,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 10034,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 10322,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 10338,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 10340,
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 10518,
    "Category": "SC_UP",
    "SubCategory": "W",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 13555,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 13957,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 15966,
    "Category": "General",
    "SubCategory": "PWD,CDP_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 17023,
    "Category": "General",
    "SubCategory": "DFF_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 17545,
    "Category": "EWS_UP",
    "SubCategory": "W,PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 17937,
    "Category": "General",
    "SubCategory": "DFF_UP",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 18263,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 19949,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 20456,
    "Category": "ST_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 27682,
    "Category": "ST_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 34174,
    "Category": "ST_UP",
    "SubCategory": "Not Applicable",
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Rank": 1208,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1212,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1213,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1214,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1215,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1218,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1220,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1221,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1223,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1224,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1225,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1238,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1245,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1247,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1249,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1250,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 3900,
    "Category": "General",
    "SubCategory": "RES_PB,BC_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 10000,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 10083,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 10137,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 10253,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 16875,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 19949,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 220,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 502,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 578,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 585,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 646,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 651,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 688,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 699,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 709,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 718,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 729,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 736,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 738,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 764,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 769,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 785,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 788,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 789,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 799,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 826,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 829,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 831,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 837,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 839,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 843,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 845,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 848,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 849,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 850,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 854,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 870,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 875,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 882,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 884,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 886,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 893,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 897,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 901,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 904,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 910,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 913,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 923,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 924,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 933,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 936,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 947,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 948,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 950,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 951,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 956,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 959,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 963,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 964,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 968,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 974,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 975,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 976,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 979,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 982,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 984,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 985,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 987,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 991,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 999,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1007,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1012,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1014,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1018,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1020,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1033,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1036,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1042,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1046,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1050,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1054,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1070,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1074,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1075,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1082,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1083,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1085,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1089,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1093,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1094,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1108,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1118,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1127,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1128,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1129,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1134,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1137,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1145,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1148,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1152,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1153,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1154,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1156,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1161,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1163,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1165,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1169,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1172,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1173,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1175,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1176,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1177,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1180,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1181,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1182,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1183,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1184,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1185,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1187,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1189,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1190,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1192,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1193,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1198,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1199,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1204,
    "Category": "General",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1205,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1206,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 2350,
    "Category": "General",
    "SubCategory": "RES_PB,BC_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 3181,
    "Category": "General",
    "SubCategory": "RES_PB,BC_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 4551,
    "Category": "SC",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 6085,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 6236,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 6478,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7212,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7224,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7261,
    "Category": "SC",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7557,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7573,
    "Category": "SC",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7640,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7836,
    "Category": "SC",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 7934,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 8107,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 8397,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 8456,
    "Category": "SC",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 8600,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 8621,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 8822,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9084,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9179,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9274,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9440,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9505,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9562,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9749,
    "Category": "SC",
    "SubCategory": "RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9776,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9869,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 9963,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 11714,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 12264,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 12480,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 13030,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 13344,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 13581,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 13898,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 14636,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 14876,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 15548,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 16140,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 16386,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 16630,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 16777,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 21272,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 24262,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 26288,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 27089,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 28358,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 29970,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 30652,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 31402,
    "Category": "General",
    "SubCategory": "PWD,RES_PB",
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Rank": 1324,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1327,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1336,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1341,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1345,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1352,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1353,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1358,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2113,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2122,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 790,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 797,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1159,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1216,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1218,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1221,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1223,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1225,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1236,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1238,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1250,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1251,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1258,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1262,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1263,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1268,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1274,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1278,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1280,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1282,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1285,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1299,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1300,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1308,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1312,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1322,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1378,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1585,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1777,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1831,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1839,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2007,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2096,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2139,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2435,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2437,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2456,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2546,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2576,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2632,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2663,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2720,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2742,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2776,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3170,
    "Category": "W_BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3239,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4105,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4156,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4522,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4676,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4702,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 5011,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 5193,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 5278,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 5441,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 9413,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 9752,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 10783,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 11186,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 11203,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 12436,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 13121,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 13550,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 14091,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 14864,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 23802,
    "Category": "ST_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 29492,
    "Category": "W_BC_BR",
    "SubCategory": "PWD",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 30079,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 31040,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1486,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1505,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1513,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1531,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1544,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1545,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1552,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2703,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 16714,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 37886,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1051,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1097,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1203,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1247,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1255,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1270,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1341,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1358,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1374,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1379,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1380,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1385,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1389,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1391,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1399,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1401,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1426,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1429,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1440,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1441,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1456,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1462,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1476,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1477,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1478,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1571,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2113,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2306,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2369,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2497,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2550,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2560,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 2682,
    "Category": "EWS_BH",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3194,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3207,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3379,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3549,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3782,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 3909,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4147,
    "Category": "BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4379,
    "Category": "W_BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 4463,
    "Category": "W_BC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 5954,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 6125,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 6947,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 7146,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 7147,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 7481,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 7549,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 8044,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 8257,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 8444,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 8599,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 8985,
    "Category": "EBC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 12740,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 13339,
    "Category": "ST_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 14036,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 14477,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 14861,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 15070,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 15181,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 15383,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 16066,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 16099,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 16406,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 16432,
    "Category": "SC_BR",
    "SubCategory": "Not Applicable",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 32469,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 34721,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 37697,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Chanakya National Law University (CNLU), Patna _ B.B.A., LL.B. (Hons.)"
  },
  {
    "Rank": 1672,
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 231,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 399,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 481,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 526,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 641,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 713,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 715,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 730,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 761,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 844,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 898,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 946,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 949,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 957,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1024,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1025,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1045,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1052,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1069,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1073,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1081,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1086,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1090,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1110,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1111,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1117,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1136,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1144,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1174,
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1348,
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1481,
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1499,
    "Category": "JKM",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1515,
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1648,
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1661,
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 2100,
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 2341,
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 2385,
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 2524,
    "Category": "JKM",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 2658,
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 2800,
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 2916,
    "Category": "LA_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 3573,
    "Category": "DV_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 3580,
    "Category": "OBH_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 3795,
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 3831,
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 3886,
    "Category": "OBH_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 4173,
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 4868,
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 5376,
    "Category": "LA_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 5898,
    "Category": "VK_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 6867,
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 7381,
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 9009,
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 10276,
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 12306,
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 12443,
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 13117,
    "Category": "ST_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 13177,
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 13761,
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 14385,
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 14922,
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 15245,
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 18115,
    "Category": "OBX_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 25546,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 28315,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 32842,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 34640,
    "Category": "KUS_KL",
    "SubCategory": "Not Applicable",
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Rank": 1169,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1172,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1173,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1177,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1178,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1180,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1185,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1189,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1203,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1205,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3720,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 9869,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 34175,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 42224,
    "Category": "SC",
    "SubCategory": "PWD_SC",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 591,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 602,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 626,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 630,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 639,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 661,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 666,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 677,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 723,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 774,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 778,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 786,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 791,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 793,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 800,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 807,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 814,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 816,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 818,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 823,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 824,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 827,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 830,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 832,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 836,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 841,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 855,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 857,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 860,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 861,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 863,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 866,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 872,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 873,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 877,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 890,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 895,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 896,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 902,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 915,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 918,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 929,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 935,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 937,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 953,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 962,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 966,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 967,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 969,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 972,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 978,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 986,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 990,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 997,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1002,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1003,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1005,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1009,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1013,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1015,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1016,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1019,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1026,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1027,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1028,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1034,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1040,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1044,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1047,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1056,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1062,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1064,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1066,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1068,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1077,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1080,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1091,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1092,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1095,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1098,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1100,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1104,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1106,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1109,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1114,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1123,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1124,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1130,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1131,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1133,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1140,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1142,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1147,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1168,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1489,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1518,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1579,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1643,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1789,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1932,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1953,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2075,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2142,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2201,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2207,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2603,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2636,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2662,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2683,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2768,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2876,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 2923,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3060,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3365,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3398,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3521,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3522,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3551,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3563,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3578,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3602,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3608,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3622,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 3627,
    "Category": "General",
    "SubCategory": "DOM_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 7085,
    "Category": "SC",
    "SubCategory": "SC_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 7414,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 8124,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 8159,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 8222,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 8244,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 8489,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 8636,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 9152,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 9558,
    "Category": "SC",
    "SubCategory": "SC_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 12774,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 12809,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 12865,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 12954,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 13418,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 13900,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 13964,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 14825,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 14842,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 15355,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 15875,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 16075,
    "Category": "SC",
    "SubCategory": "SC_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 16482,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 16676,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 19137,
    "Category": "ST",
    "SubCategory": "ST_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 19394,
    "Category": "ST",
    "SubCategory": "ST_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 22675,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 23185,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 23452,
    "Category": "ST",
    "SubCategory": "ST_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 25317,
    "Category": "ST",
    "SubCategory": "ST_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 26103,
    "Category": "ST",
    "SubCategory": "ST_OD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 26234,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 29645,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 39060,
    "Category": "ST",
    "SubCategory": "PWD_ST",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 41240,
    "Category": "SC",
    "SubCategory": "PWD_SC",
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Rank": 1479,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1494,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1496,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1497,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1529,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1561,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1563,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1574,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1576,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1594,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2242,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2255,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2935,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2939,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2960,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2998,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3005,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 4257,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 6244,
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10847,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10861,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 11124,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 20725,
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 31673,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 33671,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 37923,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 637,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 973,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1103,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1178,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1239,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1259,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1271,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1279,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1294,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1295,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1301,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1324,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1327,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1336,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1337,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1344,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1345,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1352,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1353,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1370,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1373,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1395,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1409,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1411,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1419,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1432,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1433,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1455,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1618,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1620,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1678,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1727,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1765,
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1766,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1807,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1818,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1920,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1929,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1943,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1949,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2023,
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2038,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2052,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2129,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2195,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2211,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2226,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2259,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2335,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2355,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2370,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2399,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2488,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2491,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2492,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2505,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2511,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2518,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2575,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2628,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2643,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2676,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2677,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2717,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2732,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2782,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 2813,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3097,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3128,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3131,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3148,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3210,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3281,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3474,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3545,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3616,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3725,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 3741,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 4094,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 4117,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 4233,
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 4661,
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5100,
    "Category": "EBC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5108,
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5169,
    "Category": "EBC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5422,
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5654,
    "Category": "EBC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5710,
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5972,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 5993,
    "Category": "EBC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 6814,
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 8016,
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 8215,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 8788,
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 9058,
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10000,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10253,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10568,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10714,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10811,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10820,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 10844,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 16281,
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 16875,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 16910,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 17129,
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 17291,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 18130,
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 18912,
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 18972,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 18980,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 19592,
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 19635,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 19847,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 20178,
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 20315,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 22266,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 22453,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 23085,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 23147,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 23964,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 24550,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 24815,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 25256,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 25311,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 25754,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 25765,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 26792,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 27470,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 27732,
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 34175,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 35021,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 43393,
    "Category": "EBC_JD",
    "SubCategory": "PWD_JD",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 48856,
    "Category": "BC_JD",
    "SubCategory": "PWD_JD",
    "College Name": "National University of Study and Research in Law (NUSRL), Ranchi"
  },
  {
    "Rank": 1606,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1609,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1610,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1615,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1623,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1631,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1637,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1641,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1653,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1668,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1676,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1677,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1681,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1704,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 17168,
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 39188,
    "Category": "General",
    "SubCategory": "GC_AS,PWD",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1400,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1410,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1431,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1449,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1470,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1475,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1487,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1494,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1495,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1509,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1519,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1545,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1561,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1574,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1575,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1576,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1584,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1594,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1596,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1600,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1601,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1602,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1603,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1917,
    "Category": "General",
    "SubCategory": "GC_AS",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 2046,
    "Category": "General",
    "SubCategory": "GC_AS",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 2121,
    "Category": "General",
    "SubCategory": "GC_AS",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 2582,
    "Category": "General",
    "SubCategory": "GC_AS",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 2672,
    "Category": "General",
    "SubCategory": "GC_AS",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 8824,
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 8832,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 12155,
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 13146,
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 14361,
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 15860,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 16815,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 17046,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 17149,
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 17654,
    "Category": "STH_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 20708,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 22908,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 24983,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 27112,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 27796,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 27885,
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 29403,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 29648,
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 32580,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 33278,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 35236,
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 35647,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 36155,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 36216,
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 36813,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 36820,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 37320,
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 37438,
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 37923,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 38170,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 40362,
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Rank": 1362,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1366,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1370,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1371,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1375,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1390,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2158,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2180,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2195,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2782,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2940,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7553,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7964,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 8103,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10568,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10777,
    "Category": "BC_D_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 11620,
    "Category": "BC_D_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 12179,
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 15664,
    "Category": "BC_A_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 21521,
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 38496,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 39022,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 40585,
    "Category": "SC_AP",
    "SubCategory": "ESP_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 656,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1212,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1213,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1215,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1220,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1224,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1242,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1249,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1253,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1260,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1266,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1267,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1273,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1283,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1287,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1303,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1305,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1317,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1319,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1326,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1328,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1350,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1360,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1364,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1464,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1501,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1540,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1627,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1935,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1990,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2069,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2118,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2163,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2240,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2283,
    "Category": "GC_AP",
    "SubCategory": "CAP_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2328,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2329,
    "Category": "GC_AP",
    "SubCategory": "PWD",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2333,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2377,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2414,
    "Category": "GC_AP",
    "SubCategory": "CAP_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2441,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2444,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2448,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2458,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2459,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2464,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2540,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2733,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 2920,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 3287,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 3595,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 3643,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 3875,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 3913,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 4282,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 4299,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 4348,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 4351,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 4492,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 4723,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 5473,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 5616,
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 5866,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 6102,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 6642,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 6668,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7231,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7253,
    "Category": "GC_AP",
    "SubCategory": "NCC_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7271,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7379,
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7677,
    "Category": "BC_A_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 7770,
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 8057,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 8074,
    "Category": "SC",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 8707,
    "Category": "BC_B_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 8959,
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 9002,
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 9353,
    "Category": "BC_D_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 9361,
    "Category": "BC_B_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 9404,
    "Category": "BC_D_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 9411,
    "Category": "BC_B_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 9714,
    "Category": "BC_A_AP",
    "SubCategory": "NCC_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10083,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10161,
    "Category": "BC_D_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10229,
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10362,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10378,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10419,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10525,
    "Category": "SC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10790,
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 10840,
    "Category": "BC_C_AP",
    "SubCategory": "NCC_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 11995,
    "Category": "EWS_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 12162,
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 12314,
    "Category": "BC_B_AP",
    "SubCategory": "CAP_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 12541,
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 12758,
    "Category": "BC_B_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 13531,
    "Category": "BC_A_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 13810,
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 14018,
    "Category": "BC_E_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 14826,
    "Category": "SC_AP",
    "SubCategory": "NCC_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 15395,
    "Category": "BC_E_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 15435,
    "Category": "BC_A_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 15844,
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 16747,
    "Category": "BC_B_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 17227,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 17614,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 18342,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 19820,
    "Category": "SC_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 21269,
    "Category": "SC_AP",
    "SubCategory": "NCC_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 21306,
    "Category": "ST_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 21467,
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 31063,
    "Category": "BC_D_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 33942,
    "Category": "ST_AP",
    "SubCategory": "Not Applicable",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 34502,
    "Category": "ST_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 34733,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 35949,
    "Category": "ST_AP",
    "SubCategory": "W_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 36609,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 37611,
    "Category": "GC_AP",
    "SubCategory": "ESP_AP",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 37886,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 38137,
    "Category": "SC",
    "SubCategory": "PWD",
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Rank": 1512,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1595,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1607,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1621,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1628,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3024,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3038,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3070,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3135,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 4871,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 4873,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 5102,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 11163,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 11289,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 26767,
    "Category": "GC_TN",
    "SubCategory": "ESP_TN",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 34721,
    "Category": "SC",
    "SubCategory": "PWD",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 806,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1058,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1207,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1214,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1363,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1415,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1421,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1506,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1511,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1634,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1672,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1675,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2081,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2091,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2108,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2120,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2136,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2181,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2380,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2428,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2514,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2556,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2597,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2838,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2864,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2911,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2939,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2960,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2993,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 2995,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3068,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3183,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3215,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3397,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3536,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 3995,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 5288,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 5456,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 6850,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 6981,
    "Category": "WKM_KP_KHF",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 7299,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 10733,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 10830,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 10847,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 10861,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 11078,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 11465,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 12194,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 12755,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 12908,
    "Category": "GC_TN",
    "SubCategory": "XS_TN",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 13601,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 26925,
    "Category": "GSSQ_BCM_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 27763,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 32295,
    "Category": "SC",
    "SubCategory": "PWD",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 33750,
    "Category": "GSSQ_SCA_TN",
    "SubCategory": "PWD_TN",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 38376,
    "Category": "ST_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.A.LL.B. (Hons.)"
  },
  {
    "Rank": 1608,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1670,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1686,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1695,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1703,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1711,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3734,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3798,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3800,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 4759,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 6654,
    "Category": "BCM_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 11801,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 12418,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 15187,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 31655,
    "Category": "GSSQ_MBC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 821,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1272,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1505,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1555,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1567,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1578,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1587,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1595,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 1899,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 2178,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 2651,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 2739,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 2886,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3024,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3038,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3078,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3107,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3171,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3235,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3250,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3283,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3310,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3362,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3388,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3408,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3462,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3513,
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3568,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3588,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3613,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3666,
    "Category": "BCM_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 3762,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 4236,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 4566,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 4693,
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 5980,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 6827,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 7738,
    "Category": "WKM_KP_KHF",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 8119,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 8207,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 8265,
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 11240,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 11289,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 11346,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 11373,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 11386,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 12455,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 13766,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 14743,
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 21607,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 22808,
    "Category": "BCO_TN",
    "SubCategory": "XS_TN",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 23420,
    "Category": "SCA_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 25491,
    "Category": "BCO_TN",
    "SubCategory": "ESP_TN",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 28008,
    "Category": "GSSQ_MBC_TN",
    "SubCategory": "Not Applicable",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 29476,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 30707,
    "Category": "BCO_TN",
    "SubCategory": "PWD_TN",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 30795,
    "Category": "GSSQ_SCO_TN",
    "SubCategory": "PWD_TN",
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ B.Com. LL.B. (Hons.)"
  },
  {
    "Rank": 614,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 630,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 632,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 11623,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 34264,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 222,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 252,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 256,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 469,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 473,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 474,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 477,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 480,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 482,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 486,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 490,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 494,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 496,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 498,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 510,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 517,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 519,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 520,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 522,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 524,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 528,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 529,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 531,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 540,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 543,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 547,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 548,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 555,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 560,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 562,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 587,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 590,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 592,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 811,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 1004,
    "Category": "NTD_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 1867,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 2019,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 2123,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 2265,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 2317,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 2641,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 2921,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 3176,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 3280,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 3372,
    "Category": "EWS_MH",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 3533,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 3916,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 3932,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 4208,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 4431,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 4506,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 4589,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 4793,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 4972,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 5037,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 5187,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 5269,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 5691,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 5816,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 5903,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6248,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6337,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6358,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6468,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6526,
    "Category": "OBC_MH",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6635,
    "Category": "DTA_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6718,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 6916,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 7986,
    "Category": "SBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 8063,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 8153,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 9030,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 9117,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 9379,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 9859,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 10209,
    "Category": "NTB_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 10361,
    "Category": "SC_MH",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 10653,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 10865,
    "Category": "SBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 10866,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 11392,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 11642,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 11716,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 11790,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 12474,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 13476,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 13806,
    "Category": "NTB_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 13889,
    "Category": "DTA_MH",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 15108,
    "Category": "NTB_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 15431,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 15997,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 16878,
    "Category": "NTC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 17414,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 18376,
    "Category": "NTC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 21085,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 21138,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 24407,
    "Category": "DTA_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 25083,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 30958,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 32789,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Rank": 1510,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1514,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1526,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1537,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1547,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1565,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1567,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1575,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1578,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1582,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1586,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1587,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 6285,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 17245,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 23828,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 24395,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 24730,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 24735,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 25115,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 33758,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 34048,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 491,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 697,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 932,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 960,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1030,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1132,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1194,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1208,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1237,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1245,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1256,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1290,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1297,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1309,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1320,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1356,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1362,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1366,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1371,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1375,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1390,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1402,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1403,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1404,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1406,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1414,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1439,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1443,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1445,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1450,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1451,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1453,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1454,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1457,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1459,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1463,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1473,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1474,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1490,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1493,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1496,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 3547,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 3974,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 4085,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 4881,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 6641,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 6806,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 6870,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 7168,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 7169,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 7206,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 7395,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 7406,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 8873,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 8889,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 8994,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 9244,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 9269,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 9299,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 9601,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 9623,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 9891,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 10177,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 10268,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 10707,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 10895,
    "Category": "SBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 10927,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 11057,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 11623,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 11780,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 11815,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 12399,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 12470,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 12913,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 13320,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 13391,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 13568,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 13639,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 13733,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 13863,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 13904,
    "Category": "SBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 14423,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 14473,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 14628,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 14714,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 15052,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 16142,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 16190,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 16480,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 16635,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 16639,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 17803,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 18076,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 18459,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 19278,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 19297,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 20369,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 20501,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 21092,
    "Category": "OBC_MH",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 21628,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 21883,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 22068,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 22088,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 22742,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 27941,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 28924,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 29470,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 29705,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 31966,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 32559,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 33455,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 34264,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 34930,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 35161,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 36444,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 40206,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 40302,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 43568,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 44462,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 50326,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1555,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1600,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1603,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1627,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1646,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1647,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1683,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1685,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 16730,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 26855,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 28165,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 29474,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 35795,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 39065,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.A.LL.B.(Hons.)"
  },
  {
    "Rank": 1555,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1600,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1603,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1627,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1646,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1647,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1683,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1685,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 16730,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 26855,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 28165,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 29474,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 35795,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 39065,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 784,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 922,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1126,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1164,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1365,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1382,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1417,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1425,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1428,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1444,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1469,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1479,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1503,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1510,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1513,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1514,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1522,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1523,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1525,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1536,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1537,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1553,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 4790,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 6495,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 6830,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 7342,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 7700,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 7886,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 8449,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 8734,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 11089,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 12108,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 12561,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 12722,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 13705,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 13945,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 14258,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 14518,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 14814,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 14857,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 14872,
    "Category": "SBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 15152,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 15826,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 18817,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 20584,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 21344,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 22490,
    "Category": "EWS_MH",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 23828,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 24673,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 24735,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 25223,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 29656,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 29805,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 34910,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 35997,
    "Category": "DTA_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 36563,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 42768,
    "Category": "ST_MH",
    "SubCategory": "PWD",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 42769,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 43185,
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 46792,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Nagpur _ B.B.A.LL.B. (Honours)"
  },
  {
    "Rank": 1671,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1692,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1713,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1715,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1726,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1757,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1767,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1776,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1779,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1794,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1830,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1832,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1853,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1855,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1866,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1877,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2738,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2951,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17433,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18008,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18713,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 28599,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 28813,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 29520,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1211,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1357,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1491,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1512,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1538,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1547,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1556,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1563,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1582,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1586,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1597,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1607,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1608,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1615,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1622,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1623,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1630,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1631,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1635,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2276,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2668,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2708,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 4220,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 7457,
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 8023,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 8281,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 10564,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 13162,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 13413,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 13676,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 14311,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 15547,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16270,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16341,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16394,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16516,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16730,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16894,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17245,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17277,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17355,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17388,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17956,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18004,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 19970,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 20327,
    "Category": "SBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 21130,
    "Category": "NTB_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 24730,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 25086,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 25115,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 31776,
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 32420,
    "Category": "NTB_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 36139,
    "Category": "OBC_MH",
    "SubCategory": "PWD_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 38690,
    "Category": "DTA_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 46231,
    "Category": "General",
    "SubCategory": "PWD_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1806,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1880,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1882,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1895,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1913,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1921,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1956,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1963,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1984,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2070,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2080,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2097,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2107,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2109,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2111,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2115,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2956,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 3017,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18056,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 19003,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 19258,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 19348,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 29642,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 29674,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 29726,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 29975,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 681,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 931,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1252,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1613,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1625,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1646,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1647,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1654,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1668,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1677,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1684,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1703,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1704,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1711,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1713,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1715,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1726,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1735,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1757,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1779,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2506,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2738,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 2789,
    "Category": "General",
    "SubCategory": "XS_MH",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 7222,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 7755,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 9778,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 12485,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 14724,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 14939,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 15797,
    "Category": "NTD_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16741,
    "Category": "EWS_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 16899,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17128,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17433,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17556,
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17741,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 17955,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18471,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18665,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18713,
    "Category": "OBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 18962,
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 21210,
    "Category": "SBC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 22752,
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 23903,
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 24395,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 27190,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 27370,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 27741,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 27754,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 28165,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 28271,
    "Category": "SC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 30328,
    "Category": "ST_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 33863,
    "Category": "NTB_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 35552,
    "Category": "NTC_MH",
    "SubCategory": "W",
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad _ B.B.A. LL.B. (Hons.)"
  },
  {
    "Rank": 1914,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1934,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1937,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1945,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1951,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1961,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1962,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1971,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1973,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1992,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1993,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2001,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2026,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2027,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2039,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2060,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2061,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2062,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2063,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2064,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2072,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2076,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2078,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2084,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2085,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2089,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2101,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2102,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 12007,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 12105,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 12158,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 12193,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 13131,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 13590,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 13927,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 14051,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 14110,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 20412,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 885,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1186,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1310,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1354,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1413,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1436,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1486,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1529,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1544,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1550,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1554,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1558,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1606,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1609,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1610,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1632,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1633,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1641,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1653,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1656,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1665,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1669,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1671,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1681,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1686,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1689,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1692,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1702,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1709,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1714,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1724,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1737,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1739,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1748,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1767,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1776,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1784,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1794,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1801,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1805,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1808,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1814,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1825,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1830,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1840,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1846,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1853,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1856,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1863,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1866,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1870,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1872,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1877,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1882,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1883,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1885,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1890,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1894,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1895,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1897,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1898,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1902,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1903,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 2387,
    "Category": "SC",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 3214,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 3279,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 3325,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 3583,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 3993,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 4028,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 4638,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 5433,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 5912,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 6135,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 6563,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 6803,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 6868,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 6975,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 7091,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 8032,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 8084,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 8368,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 8736,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 9061,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 9162,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 9264,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10137,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10156,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10162,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10411,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10604,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10764,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10774,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 10846,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11006,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11087,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11165,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11324,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11393,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11541,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11575,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11663,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11741,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 11787,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 12423,
    "Category": "ST",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 13213,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 14247,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 16632,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 16818,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 17505,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 18918,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 18997,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 19367,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 20154,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 25417,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 27379,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 32396,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 33981,
    "Category": "SC",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 34211,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 35362,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.A. LL.B (Hons)"
  },
  {
    "Rank": 1989,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2014,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2086,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2093,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2116,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2125,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2126,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2128,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2134,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2137,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2140,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2143,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2145,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2148,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2150,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12621,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12650,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12681,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12699,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12720,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12740,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 15141,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 15492,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 15909,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 16248,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 16525,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 39699,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1059,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1539,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1728,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1729,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1733,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1763,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1806,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1810,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1819,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1850,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1860,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1862,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1884,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1913,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1925,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1928,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1930,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1934,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1936,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1941,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1946,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1948,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1956,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1960,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1963,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1969,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1971,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1973,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 2737,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 3138,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 8307,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 8855,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 9121,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 9789,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 10379,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12007,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12105,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12193,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12309,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12326,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12368,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12377,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12383,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12411,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12418,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12437,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 12953,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 13069,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 13131,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 13138,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 13193,
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 13416,
    "Category": "General",
    "SubCategory": "GC_HP",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 18341,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 21367,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 21426,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 22625,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 23188,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 34169,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 39073,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 39231,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla _ B.B.A. LL.B(Hons)"
  },
  {
    "Rank": 1861,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1864,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1870,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1876,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1881,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1885,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1912,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1930,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2160,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2176,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2219,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2222,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2237,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2241,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2694,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2702,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2705,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2769,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2821,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3526,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3634,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 4135,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12240,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12289,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12309,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12505,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12556,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 13720,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 23198,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 30382,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 30402,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 40103,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 40105,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 40159,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 40483,
    "Category": "OBC",
    "SubCategory": "W,PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1060,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1321,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1372,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1396,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1526,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1559,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1565,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1580,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1621,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1626,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1628,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1638,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1644,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1657,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1670,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1673,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1676,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1683,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1685,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1691,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1695,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1700,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1701,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1734,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1740,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1746,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1752,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1753,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1761,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1768,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1775,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1778,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1781,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1785,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1788,
    "Category": "General",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1790,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1791,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1797,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1824,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1847,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1854,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1889,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1901,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1938,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1940,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1968,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2004,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2009,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2077,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2082,
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2116,
    "Category": "General",
    "SubCategory": "GC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2122,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2158,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2180,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2242,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2255,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2307,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2340,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2371,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2393,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2530,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2557,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2596,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2655,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2657,
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2660,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 2935,
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3005,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3015,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3040,
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3070,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3178,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3208,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3229,
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3244,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3393,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3445,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3510,
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3525,
    "Category": "OBC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 3836,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 4899,
    "Category": "General",
    "SubCategory": "GC_MP,W,CDFP_\nMP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 5527,
    "Category": "General",
    "SubCategory": "GC_MP,W,CDFP_\nMP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 5538,
    "Category": "General",
    "SubCategory": "GC_MP,W,CDFP_\nMP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 5798,
    "Category": "General",
    "SubCategory": "GC_MP,W,CDFP_\nMP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 6108,
    "Category": "General",
    "SubCategory": "GC_MP,W,CDFP_\nMP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 6719,
    "Category": "General",
    "SubCategory": "GC_MP,W,CDFP_\nMP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 9927,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 10527,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 10991,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11124,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11163,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11414,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11590,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11739,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11754,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11801,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11824,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11844,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 11966,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12100,
    "Category": "SC",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12652,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 12887,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 13326,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 13443,
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 13453,
    "Category": "SC",
    "SubCategory": "SC_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 17247,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 18895,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 19499,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 19851,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 20412,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 20753,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 20765,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 21018,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 21021,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 21502,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 22149,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 22364,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 22407,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 22528,
    "Category": "ST",
    "SubCategory": "W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 22741,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 23145,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 23411,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 23836,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 25747,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 27255,
    "Category": "ST",
    "SubCategory": "ST_MP",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 27614,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 28117,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 28578,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 30101,
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 38688,
    "Category": "OBC",
    "SubCategory": "OBC_MP,PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 39022,
    "Category": "General",
    "SubCategory": "W,PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 39583,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 39699,
    "Category": "OBC",
    "SubCategory": "PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 39972,
    "Category": "SC",
    "SubCategory": "PWD",
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Rank": 1898,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1903,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1941,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1960,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1978,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1979,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2005,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2028,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2050,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2286,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2310,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2675,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2687,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3000,
    "Category": "EWS_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3649,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3650,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3672,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3750,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3804,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3809,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3861,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 6911,
    "Category": "BC_B_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 10408,
    "Category": "BC_A_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12383,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12447,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 13621,
    "Category": "SC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 33436,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 34993,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 909,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 983,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1393,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1458,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1465,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1484,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1497,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1527,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1531,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1552,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1568,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1569,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1637,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1658,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1662,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1708,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1716,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1717,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1718,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1723,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1743,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1754,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1774,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1780,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1783,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1813,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1828,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1829,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1832,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1835,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1843,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1855,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1857,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1861,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1864,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1876,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1880,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1893,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 1952,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2000,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2025,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2032,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2071,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2161,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2162,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2174,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2184,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2187,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2189,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2190,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2260,
    "Category": "GC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2273,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2292,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2555,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2583,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2638,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2640,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2674,
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2685,
    "Category": "EWS_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2750,
    "Category": "EWS_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2785,
    "Category": "EWS_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2826,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2940,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2973,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2998,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3056,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3099,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3135,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3185,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3262,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3355,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3403,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3460,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3478,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3481,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3526,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3542,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3571,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3582,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3587,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3604,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3611,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 3617,
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 4168,
    "Category": "BC_A_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 4404,
    "Category": "BC_B_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 6351,
    "Category": "BC_B_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 6379,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 6470,
    "Category": "BC_B_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 8187,
    "Category": "BC_A_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 8772,
    "Category": "BC_A_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 10545,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 10675,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 10717,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 11217,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 11301,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 11402,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 11567,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 11779,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 11927,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12098,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12158,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12240,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12289,
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12464,
    "Category": "SC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 12761,
    "Category": "SC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 14989,
    "Category": "DSC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 16162,
    "Category": "DSC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 17908,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 18905,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 20127,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 20671,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 21529,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 22328,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 22583,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 23000,
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 23930,
    "Category": "DSC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 27801,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 30517,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 32366,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 32428,
    "Category": "WLRGEC_HR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University (DBRANLU), Sonepat"
  },
  {
    "Rank": 2074,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2094,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2105,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2124,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2130,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2144,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2151,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2152,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2156,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2164,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2166,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2168,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2169,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2173,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2175,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2177,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2182,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2183,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2186,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2188,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2192,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2196,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2198,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2199,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2200,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2202,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2203,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2205,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2210,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2212,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2215,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2216,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2217,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2220,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2221,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2223,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2224,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2227,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2228,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2235,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2236,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2239,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2243,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 29782,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 32578,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 39972,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 40009,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1881,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1904,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1912,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1914,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1921,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1937,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1945,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1951,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1961,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1962,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1978,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1979,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1984,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1989,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1992,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 1993,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2001,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2002,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2005,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2010,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2012,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2014,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2017,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2018,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2020,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2024,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2026,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2027,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2028,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2029,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2035,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2037,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2039,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2040,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2042,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2045,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2049,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2050,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2051,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2054,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2055,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2060,
    "Category": "General",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 2728,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 16434,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 20009,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 20415,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 23458,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 24824,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 25784,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 26014,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 28786,
    "Category": "General",
    "SubCategory": "GC_TR",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 32531,
    "Category": "SC_TR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 35245,
    "Category": "SC_TR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 35377,
    "Category": "ST_TR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 37735,
    "Category": "ST_TR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 38496,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 39063,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 39188,
    "Category": "General",
    "SubCategory": "PWD",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 42872,
    "Category": "ST_TR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Rank": 50744,
    "Category": "ST_TR",
    "SubCategory": "Not Applicable",
    "College Name": "National Law University Tripura (NLUT), Agartala"
  }
]