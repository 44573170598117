[
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No.\nOCF, Sector-A-8, Narela, Delhi-110040",
    "Category": "OPNOHS",
    "Rank": 21375,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No.\nOCF, Sector-A-8, Narela, Delhi-110040",
    "Category": "OPDFOS",
    "Rank": 37207,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No.\nOCF, Sector-A-8, Narela, Delhi-110040",
    "Category": "OPNOOS",
    "Rank": 6222,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No.\nOCF, Sector-A-8, Narela, Delhi-110040",
    "Category": "OPNOHS",
    "Rank": 32798,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No.\nOCF, Sector-A-8, Narela, Delhi-110040",
    "Category": "OPDFOS",
    "Rank": 27060,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No.\nOCF, Sector-A-8, Narela, Delhi-110040",
    "Category": "OPNOOS",
    "Rank": 10226,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Institute Of Rural Development, Holambi Khurd, Delhi - 110082",
    "Category": "OPNOHS",
    "Rank": 43072,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Institute Of Rural Development, Holambi Khurd, Delhi - 110082",
    "Category": "OPNOOS",
    "Rank": 16267,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "NOKMAI",
    "Rank": 32575,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "OPNOHS",
    "Rank": 11179,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "OPDFOS",
    "Rank": 20780,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "OPNOOS",
    "Rank": 5991,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "SCNOOS",
    "Rank": 28144,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "OPNOHS",
    "Rank": 14502,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "OPDFOS",
    "Rank": 18974,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine Education & Development Society), B-12, Sector 62, Noida, U.P.",
    "Category": "OPNOOS",
    "Rank": 6258,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Dr. Akhilesh Das Gupta Institute of Professional Studies ( formerly Dr. Akhilesh Das Gupta Institute of Technology & Management), FC-26, Shastri Park, New Delhi - 110053",
    "Category": "OPNOHS",
    "Rank": 8610,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Dr. Akhilesh Das Gupta Institute of Professional Studies ( formerly Dr. Akhilesh Das Gupta Institute of Technology & Management), FC-26, Shastri Park, New Delhi - 110053",
    "Category": "OPNOOS",
    "Rank": 6781,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Dr. Akhilesh Das Gupta Institute of Professional Studies ( formerly Dr. Akhilesh Das Gupta Institute of Technology & Management), FC-26, Shastri Park, New Delhi - 110053",
    "Category": "SCNOOS",
    "Rank": 38095,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Dr. Akhilesh Das Gupta Institute of Professional Studies ( formerly Dr. Akhilesh Das Gupta Institute of Technology & Management), FC-26, Shastri Park, New Delhi - 110053",
    "Category": "OPNOHS",
    "Rank": 29458,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Dr. Akhilesh Das Gupta Institute of Professional Studies ( formerly Dr. Akhilesh Das Gupta Institute of Technology & Management), FC-26, Shastri Park, New Delhi - 110053",
    "Category": "OPNOOS",
    "Rank": 8650,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "OPNOHS",
    "Rank": 19256,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "OPDFOS",
    "Rank": 22357,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "OPNOOS",
    "Rank": 6539,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "SCNOOS",
    "Rank": 38728,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "STNOOS",
    "Rank": 38728,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "OPNOHS",
    "Rank": 16611,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "OPDFOS",
    "Rank": 29652,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "OPNOOS",
    "Rank": 7279,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-\n110037",
    "Category": "SCNOOS",
    "Rank": 40398,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "OPNOHS",
    "Rank": 13790,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "SCNOHS",
    "Rank": 44404,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "OPDFOS",
    "Rank": 18831,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "OPNOOS",
    "Rank": 5297,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "SCNOOS",
    "Rank": 26748,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "OPNOHS",
    "Rank": 11900,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "OPDFOS",
    "Rank": 16173,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Category": "OPNOOS",
    "Rank": 6119,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Ideal Institute of Management and Technology, 16 X Karkardooma, (Near Telephone Exchange), Vivek Vihar, Delhi 110092",
    "Category": "OPNOHS",
    "Rank": 10915,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Ideal Institute of Management and Technology, 16 X Karkardooma, (Near Telephone Exchange), Vivek Vihar, Delhi 110092",
    "Category": "OPNOOS",
    "Rank": 8488,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Ideal Institute of Management and Technology, 16 X Karkardooma, (Near Telephone Exchange), Vivek Vihar, Delhi 110092",
    "Category": "SCNOOS",
    "Rank": 37889,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "JIMS Engineering Management Technical Campus, 48/4, Knowledge Park - III Greater Noida",
    "Category": "OPNOHS",
    "Rank": 27892,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "JIMS Engineering Management Technical Campus, 48/4, Knowledge Park - III Greater Noida",
    "Category": "OPNOOS",
    "Rank": 10805,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "JIMS Engineering Management Technical Campus, 48/4, Knowledge Park - III Greater Noida",
    "Category": "SCNOOS",
    "Rank": 37642,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "JIMS Engineering Management Technical Campus, 48/4, Knowledge Park - III Greater Noida",
    "Category": "OPNOHS",
    "Rank": 26641,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "JIMS Engineering Management Technical Campus, 48/4, Knowledge Park - III Greater Noida",
    "Category": "OPNOOS",
    "Rank": 5777,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "KCC Institute of Legal & Higher Education 2B-2C, Knowledge Park III, Greater Noida, Gautam Budh, UP",
    "Category": "OPNOHS",
    "Rank": 16524,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "KCC Institute of Legal & Higher Education 2B-2C, Knowledge Park III, Greater Noida, Gautam Budh, UP",
    "Category": "OPDFOS",
    "Rank": 25774,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "KCC Institute of Legal & Higher Education 2B-2C, Knowledge Park III, Greater Noida, Gautam Budh, UP",
    "Category": "OPNOOS",
    "Rank": 7315,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "KCC Institute of Legal & Higher Education 2B-2C, Knowledge Park III, Greater Noida, Gautam Budh, UP",
    "Category": "OPNOHS",
    "Rank": 10554,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "KCC Institute of Legal & Higher Education 2B-2C, Knowledge Park III, Greater Noida, Gautam Budh, UP",
    "Category": "OPDFOS",
    "Rank": 31407,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "KCC Institute of Legal & Higher Education 2B-2C, Knowledge Park III, Greater Noida, Gautam Budh, UP",
    "Category": "OPNOOS",
    "Rank": 11893,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "OPDFHS",
    "Rank": 30913,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "OPNOHS",
    "Rank": 4151,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "SCNOHS",
    "Rank": 28155,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "STNOHS",
    "Rank": 40683,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "OPDFOS",
    "Rank": 10417,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "OPNOOS",
    "Rank": 3737,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "SCNOOS",
    "Rank": 19167,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "STNOOS",
    "Rank": 34345,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "OPNOHS",
    "Rank": 7389,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "SCNOHS",
    "Rank": 41845,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "OPDFOS",
    "Rank": 15880,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "OPNOOS",
    "Rank": 4813,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi- 110085",
    "Category": "SCNOOS",
    "Rank": 26496,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Surajmal Institute, C 4 Janak Puri, New Delhi 110058",
    "Category": "OPDFHS",
    "Rank": 33047,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Surajmal Institute, C 4 Janak Puri, New Delhi 110058",
    "Category": "OPNOHS",
    "Rank": 4320,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Surajmal Institute, C 4 Janak Puri, New Delhi 110058",
    "Category": "SCNOHS",
    "Rank": 21336,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Surajmal Institute, C 4 Janak Puri, New Delhi 110058",
    "Category": "OPNOOS",
    "Rank": 5312,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Maharaja Surajmal Institute, C 4 Janak Puri, New Delhi 110058",
    "Category": "SCNOOS",
    "Rank": 25143,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Trinity Institute of Innovations in Professional Studies, Plot no 2B/1, Knowledge Park - III, Greater Noida, Uttar Pradesh - 2011308",
    "Category": "OPNOHS",
    "Rank": 8660,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Trinity Institute of Innovations in Professional Studies, Plot no 2B/1, Knowledge Park - III, Greater Noida, Uttar Pradesh - 2011308",
    "Category": "OPNOOS",
    "Rank": 4455,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Trinity Institute of Professional Studies, Sector- 9, Dwarka (Adj. Pillar No. 1160), New Delhi-110075",
    "Category": "OPNOHS",
    "Rank": 12198,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Trinity Institute of Professional Studies, Sector- 9, Dwarka (Adj. Pillar No. 1160), New Delhi-110075",
    "Category": "SCNOHS",
    "Rank": 39066,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Trinity Institute of Professional Studies, Sector- 9, Dwarka (Adj. Pillar No. 1160), New Delhi-110075",
    "Category": "OPNOOS",
    "Rank": 6940,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Trinity Institute of Professional Studies, Sector- 9, Dwarka (Adj. Pillar No. 1160), New Delhi-110075",
    "Category": "SCNOOS",
    "Rank": 29974,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "BCNOHS",
    "Rank": 3509,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "EWNOHS",
    "Rank": 4806,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPDFHS",
    "Rank": 9609,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPNOHS",
    "Rank": 1336,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPPHHS",
    "Rank": 8075,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "SCNOHS",
    "Rank": 12193,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "STNOHS",
    "Rank": 36770,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "EWNOOS",
    "Rank": 3859,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPDFOS",
    "Rank": 18921,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPNOOS",
    "Rank": 1074,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPPHOS",
    "Rank": 41670,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "SCNOOS",
    "Rank": 15147,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "STNOOS",
    "Rank": 16161,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "NOKMAI",
    "Rank": 24084,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "BCNOHS",
    "Rank": 5790,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "EWNOHS",
    "Rank": 8552,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPDFHS",
    "Rank": 3882,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPNOHS",
    "Rank": 1563,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPPHHS",
    "Rank": 18263,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "SCNOHS",
    "Rank": 6490,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "STNOHS",
    "Rank": 26568,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "EWNOOS",
    "Rank": 1320,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "OPNOOS",
    "Rank": 1549,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "SCNOOS",
    "Rank": 13357,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Category": "STNOOS",
    "Rank": 26806,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPDFHS",
    "Rank": 19205,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPNOHS",
    "Rank": 1745,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "SCNOHS",
    "Rank": 15015,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "STNOHS",
    "Rank": 16630,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPDFOS",
    "Rank": 7893,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPNOOS",
    "Rank": 2202,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "SCDFOS",
    "Rank": 26584,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "SCNOOS",
    "Rank": 11240,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "STNOOS",
    "Rank": 28839,
    "Course": "INTEGRATED B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "NOKMAI",
    "Rank": 32643,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPDFHS",
    "Rank": 21255,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPNOHS",
    "Rank": 1437,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPPHHS",
    "Rank": 38170,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "SCNOHS",
    "Rank": 23789,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "STNOHS",
    "Rank": 23789,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPDFOS",
    "Rank": 12288,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "OPNOOS",
    "Rank": 2093,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New Delhi.",
    "Category": "SCNOOS",
    "Rank": 19601,
    "Course": "INTEGRATED B.B.A.-L.L.B."
  }
]