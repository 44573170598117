import React from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import {useState,useEffect} from 'react';


function ExamPatternComponent(){


    const navigate = useNavigate();
  const { examName } = useParams();
  const [examData, setExamData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchExamData();
  }, [examName]);

  if (!examData) return <div>Loading...</div>;

  // Dynamic data mappings based on fetched data
  const {
    year,
    admitCardName,
    admitCardDate,
    examPattern,
    faqs,
    tabs,
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
  } = examData;


    return(
        <>
        <h2 className="text-lg font-bold mb-2">JEE Mains Exam Pattern</h2>
          <table className="w-full bg-white shadow-lg border border-gray-300 text-sm">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-4 border border-gray-300 text-left text-gray-800 font-semibold">
                  Section
                </th>
                <th className="p-4 border border-gray-300 text-center text-gray-800 font-semibold">
                  Questions
                </th>
                <th className="p-4 border border-gray-300 text-center text-gray-800 font-semibold">
                  Duration
                </th>
              </tr>
            </thead>
            <tbody>
              {examPattern.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-4 border border-gray-300 text-gray-700 font-medium">
                    {item.section}
                  </td>
                  <td className="p-4 border border-gray-300 text-center text-gray-600">
                    {item.questions}
                  </td>
                  <td className="p-4 border border-gray-300 text-center text-gray-600">
                    {item.duration}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
    )
}

export default ExamPatternComponent;