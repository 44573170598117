import React from 'react';
import {useState,useEffect} from 'react';
import{useNavigate,useParams} from 'react-router-dom';

function ExamSchedule(){
   
    const navigate = useNavigate();
  const { examName } = useParams();
  const [examData,setExamData] = useState(null);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        const data = await response.json();
        setExamData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching exam data:", error);
        setLoading(false);
      }
    };

    fetchExamData();
  }, [examName]);

  if (loading) return <div>Loading...</div>;
  if (!examData) return <div>Exam not found.</div>;

  // Extract necessary data from fetched examData
  const {
    highlights = [],
    schedule = [],
    tabs=[],
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
    admitCardName,
    admitCardDate,
  } = examData;


    return (
        <>
        <h2 className="text-xl font-bold text-gray-800 mt-6 mb-2">Exam Schedule</h2>
        <table className="w-full text-left">
            <thead>
            <tr>
                <th className="p-4 border">Activity</th>
                <th className="p-4 border">Timeline</th>
            </tr>
            </thead>
            <tbody>
            {schedule.map((event, index) => (
                <tr key={index}>
                <td className="p-4 border">{event.activity}</td>
                <td className="p-4 border">{event.timeline}</td>
                </tr>
            ))}
            </tbody>
        </table>
        </>
    )
}

export default ExamSchedule;