[
  {
    "Sl.No.": 1,
    "Institute": "Army Institute of Management & Technology, Plot No. M-1, Block No. P-5, Sector-Pocket-5, Greater NOIDA-201306",
    "Course": "Master of Business Administration(General)",
    "Category": "NOAFAI",
    "Rank": 780
  },
  {
    "Sl.No.": 2,
    "Institute": "Army Institute of Management & Technology, Plot No. M-1, Block No. P-5, Sector-Pocket-5, Greater NOIDA-201306",
    "Course": "Master of Business Administration (Analytics)",
    "Category": "NOAFAI",
    "Rank": 774
  },
  {
    "Sl.No.": 3,
    "Institute": "Banarsidas Chandiwala Institute of Professional Studies, Plot No. 9 Sec 11, Phase I Dwarka, New Delhi 110075",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 773
  },
  {
    "Sl.No.": 3,
    "Institute": "Banarsidas Chandiwala Institute of Professional Studies, Plot No. 9 Sec 11, Phase I Dwarka, New Delhi 110075",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 559
  },
  {
    "Sl.No.": 4,
    "Institute": "Bhagwan Parshuram Institute of\nTechnology, P.S.P-4, Sector-17, Rohini, Delhi- 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 697
  },
  {
    "Sl.No.": 4,
    "Institute": "Bhagwan Parshuram Institute of\nTechnology, P.S.P-4, Sector-17, Rohini, Delhi- 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 582
  },
  {
    "Sl.No.": 5,
    "Institute": "C-DAC (formerly Electronics Research & Development Centre of India), Govt. of India, Anusandhan Bhawan, C-56/1, Institutional Area, Sector-62 Noida U.P.",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 781
  },
  {
    "Sl.No.": 5,
    "Institute": "C-DAC (formerly Electronics Research & Development Centre of India), Govt. of India, Anusandhan Bhawan, C-56/1, Institutional Area, Sector-62 Noida U.P.",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 542
  },
  {
    "Sl.No.": 6,
    "Institute": "Delhi Institute Of Advanced Studies, Plot No.6, Sector-25, Rohini, Delhi - 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 600
  },
  {
    "Sl.No.": 6,
    "Institute": "Delhi Institute Of Advanced Studies, Plot No.6, Sector-25, Rohini, Delhi - 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 572
  },
  {
    "Sl.No.": 7,
    "Institute": "Delhi Institute Of Advanced Studies, Plot No.6, Sector-25, Rohini, Delhi - 110085",
    "Course": "Master of Business\nAdministration (Financial Management)",
    "Category": "OPNOOS",
    "Rank": 742
  },
  {
    "Sl.No.": 8,
    "Institute": "Delhi Technical Campus, 28/1, Knowledge Park, III, Greater NOIDA, UP",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 584
  },
  {
    "Sl.No.": 8,
    "Institute": "Delhi Technical Campus, 28/1, Knowledge Park, III, Greater NOIDA, UP",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 711
  },
  {
    "Sl.No.": 9,
    "Institute": "Dr. Akhilesh Das Gupta Institute of Professional Studies ( formerly Dr. Akhilesh Das Gupta Institute of Technology & Management), FC-26, Shastri Park, New\nDelhi - 110053",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 747
  },
  {
    "Sl.No.": 9,
    "Institute": "Dr. Akhilesh Das Gupta Institute of Professional Studies ( formerly Dr. Akhilesh Das Gupta Institute of Technology & Management), FC-26, Shastri Park, New\nDelhi - 110053",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 565
  },
  {
    "Sl.No.": 10,
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex- II, Madhuban Chowk, Delhi",
    "Course": "Master of Business Administration (International Business)",
    "Category": "OPNOHS",
    "Rank": 769
  },
  {
    "Sl.No.": 10,
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex- II, Madhuban Chowk, Delhi",
    "Course": "Master of Business Administration (International Business)",
    "Category": "OPNOOS",
    "Rank": 585
  },
  {
    "Sl.No.": 11,
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex- II, Madhuban Chowk, Delhi",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 778
  },
  {
    "Sl.No.": 11,
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex- II, Madhuban Chowk, Delhi",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 706
  },
  {
    "Sl.No.": 12,
    "Institute": "Institute of Information Technology &\nManagement, D 29 Institutional Area, Janak Puri, New Delhi",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 683
  },
  {
    "Sl.No.": 12,
    "Institute": "Institute of Information Technology &\nManagement, D 29 Institutional Area, Janak Puri, New Delhi",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 721
  },
  {
    "Sl.No.": 13,
    "Institute": "Maharaja Agrasen Institute of Technology, Sector-22, Rohini, Delhi “ 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 768
  },
  {
    "Sl.No.": 13,
    "Institute": "Maharaja Agrasen Institute of Technology, Sector-22, Rohini, Delhi “ 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 673
  },
  {
    "Sl.No.": 14,
    "Institute": "Maharaja Surajmal Institute, C 4 Janak Puri, New Delhi 110058",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 779
  },
  {
    "Sl.No.": 14,
    "Institute": "Maharaja Surajmal Institute, C 4 Janak Puri, New Delhi 110058",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 765
  },
  {
    "Sl.No.": 15,
    "Institute": "Management Education & Research Institute, 53-54, Institutional Area, Janak\nPuri, New Delhi - 110058",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 745
  },
  {
    "Sl.No.": 16,
    "Institute": "Rukmini Devi Institute of Advanced Studies, 2A & 2B, Ph I Madhuban Chowk, Rohini,\nDelhi 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 772
  },
  {
    "Sl.No.": 16,
    "Institute": "Rukmini Devi Institute of Advanced Studies, 2A & 2B, Ph I Madhuban Chowk, Rohini,\nDelhi 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 591
  },
  {
    "Sl.No.": 17,
    "Institute": "Rukmini Devi Institute of Advanced Studies,\n2A & 2B, Ph I Madhuban Chowk, Rohini, Delhi 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 605
  },
  {
    "Sl.No.": 17,
    "Institute": "Rukmini Devi Institute of Advanced Studies,\n2A & 2B, Ph I Madhuban Chowk, Rohini, Delhi 110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 717
  },
  {
    "Sl.No.": 18,
    "Institute": "Tecnia Institute of Advanced Studies, Madhuban Chowk, Rohini, Delhi-110085",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 455
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "EWNOHS",
    "Rank": 646
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "OPDFHS",
    "Rank": 638
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOHS",
    "Rank": 356
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "SCNOHS",
    "Rank": 767
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "EWNOOS",
    "Rank": 342
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "OPDFOS",
    "Rank": 558
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "OPNOOS",
    "Rank": 141
  },
  {
    "Sl.No.": 19,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration(General)",
    "Category": "SCNOOS",
    "Rank": 573
  },
  {
    "Sl.No.": 20,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration (Analytics)",
    "Category": "OPDFHS",
    "Rank": 603
  },
  {
    "Sl.No.": 20,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration (Analytics)",
    "Category": "OPNOHS",
    "Rank": 574
  },
  {
    "Sl.No.": 20,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration (Analytics)",
    "Category": "OPNOOS",
    "Rank": 241
  },
  {
    "Sl.No.": 21,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration (Financial Analysis)",
    "Category": "OPNOHS",
    "Rank": 514
  },
  {
    "Sl.No.": 21,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration (Financial Analysis)",
    "Category": "SCNOHS",
    "Rank": 524
  },
  {
    "Sl.No.": 21,
    "Institute": "University School of Management Studies Sec 16 C Dwarka GGSIP Univeristy",
    "Course": "Master of Business Administration (Financial Analysis)",
    "Category": "OPNOOS",
    "Rank": 250
  }
]