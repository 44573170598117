import React from 'react';
import {useEffect,useState} from 'react';
import {useParams,useNavigate} from 'react-router-dom';
function MockTestStructure(){

    const { examName } = useParams();
    const [examData, setExamData] = useState(null);
    useEffect(() => {
        const fetchExamData = async () => {
          try {
            const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
            if (!response.ok) throw new Error("Failed to fetch data");
            const data = await response.json();
            setExamData(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchExamData();
      }, [examName]);
    
      if (!examData) return <p>Loading...</p>;

      const {
        admitCardDate,
        admitCardName,
        year,
        headerHeading,
        addHeading,
        heading1,
        heading1Description,
        mockDetails,
        tabs,
        faqs,
      } = examData;

    return (
        <>
         <div className="mb-6">
          <h2 className="text-lg font-semibold text-left">Mock Tests</h2>
          <table className="w-full mt-4 border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-200 p-2 text-left">
                  Mock Test
                </th>
                <th className="border border-gray-200 p-2 text-left">
                  Download Link
                </th>
              </tr>
            </thead>
            <tbody>
              {["Mock Test 1", "Solutions", "Mock Test 2", "Solutions"].map(
                (item, index) => (
                  <tr key={index}>
                    <td className="border border-gray-200 p-2 text-left">
                      {item}
                    </td>
                    <td className="border border-gray-200 p-2 text-blue-600 cursor-pointer text-left">
                      Click Here
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>

        {/* Test Structure Table */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-left">Test Structure</h2>
          <table className="w-full mt-4 border-collapse border border-gray-200">
            <tbody>
              {mockDetails.map((detail, index) => (
                <tr key={index}>
                  <td className="border border-gray-200 p-2 text-left">
                    {detail.label}
                  </td>
                  <td className="border border-gray-200 p-2 text-left">
                    {detail.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
    )
}

export default MockTestStructure;