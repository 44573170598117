// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

// const Overview = () => {
//   const navigate = useNavigate();

//   const faqs = [
//     {
//       question: "How much percentile is a good score in CAT exam?",
//       answer: "A score above 90 percentile is generally considered good.",
//     },
//     {
//       question: "What is the CAT 2024 admit card download date?",
//       answer: "November 5, 2024, from 4:00 pm onwards.",
//     },
//     {
//       question: "What is CAT exam pattern 2024?",
//       answer: "CAT 2024 will follow a pattern of 3 sections: VARC, DILR, and QA.",
//     },
//   ];

//   const iimCourses = [
//     { iim: "IIM Ahmedabad", courses: "PGP, PGP-FABM, ePGP, PhD" },
//     { iim: "IIM Bangalore", courses: "PGP, PGPEM, PGPBA, PhD" },
//     { iim: "IIM Calcutta", courses: "MBA, PhD" },
//     // Add more rows as necessary
//   ];

//   const tabs = [
//     { name: "Overview" },
//     { name: "Admit Card", path: "/CatExam/admitcard" },
//     { name: "Mock Test", path: "/CatExam/mocktest" },
//     // { name: "Question Papers", path: "/CatExam/questionpapers" },
//     // { name: "College Predictor", path: "/CatExam/collegepredictor" },
//     { name: "Dates", path: "/CatExam/dates" },
//     { name: "Syllabus", path: "/CatExam/examsyllabus" },
//     { name: "Pattern", path: "/CatExam/exampattern" },
//     { name: "Notification", path: "/CatExam/notification" },
//     { name: "Results", path: "/CatExam/results" },
//   ];

//   return (
//     <div className="bg-gray-100 p-4 md:p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white mb-6">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.CatExamHeaderHeading1} {props.CatExamYear} : {props.Mocktestaddwhichheading1}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.CatExamHeaderadmitcardname}: {props.CatExamHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.CatExamHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.CatExamHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => tab.path && navigate(tab.path)} // Ensure path exists
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       <div className="bg-white shadow p-4 rounded-lg mb-4">
//         <h1 className="text-xl md:text-2xl font-semibold">CAT 2024 Exam Information</h1>
//         <p className="text-gray-600 mt-2">
//           CAT 2024 exam date is  Candidates will be able to download their CAT 2024 admit card from
         
//         </p>
//       </div>

//       <div className="bg-white shadow p-4 rounded-lg mb-4">
//         <h2 className="text-lg font-semibold">Courses Offered Through CAT at IIMs</h2>
//         <p className="text-gray-600 mb-4">
//           Explore the list of courses offered by different IIMs through the CAT exam.
//         </p>
//         <div className="overflow-auto">
//           <table className="min-w-full bg-white text-left text-gray-600 border rounded-lg">
//             <thead>
//               <tr>
//                 <th className="px-4 py-2 border-b">IIM</th>
//                 <th className="px-4 py-2 border-b">Courses Offered Through CAT</th>
//               </tr>
//             </thead>
//             <tbody>
//               {iimCourses.map((row, index) => (
//                 <tr key={index}>
//                   <td className="px-4 py-2 border-b">{row.iim}</td>
//                   <td className="px-4 py-2 border-b">{row.courses}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>

//       <div className="bg-white shadow p-4 rounded-lg mb-4">
//         <h2 className="text-lg font-semibold">Frequently Asked Questions</h2>
//         {faqs.map((faq, index) => (
//           <FAQItem key={index} question={faq.question} answer={faq.answer} />
//         ))}
//       </div>
//     </div>
//   );
// };

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   return (
//     <div className="border-b border-gray-200 py-2">
//       <button onClick={() => setIsOpen(!isOpen)} className="text-left w-full flex justify-between items-center">
//         <span className="font-semibold">{question}</span>
//         <span>{isOpen ? "-" : "+"}</span>
//       </button>
//       {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
//     </div>
//   );
// };

// export default Overview;



// function Overview(){


//     return (
//         <>

        
           
//         </>
//     )
// }
// export default Overview;

