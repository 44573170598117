// import React from 'react';
// import { useNavigate, useParams } from 'react-router-dom'; // Ensure you import useNavigate if you're using it

// function Mocktest(props) {
//   const navigate = useNavigate(); // Using navigate for tab navigation
//   const { examName } = useParams();
//   const faqs = [
//     { question: "What makes CAT tougher than other MBA entrance exams?", answer: "Detailed answer here." },
//     { question: "What is the sectional time limit in CAT?", answer: "Detailed answer here." },
//     { question: "Is the structure of CAT mock test same as the actual exam?", answer: "Detailed answer here." },
//     { question: "How many CAT mock tests to practice to score 99 percentile?", answer: "Detailed answer here." },
//     { question: "Is CAT exam tough for an average student?", answer: "Detailed answer here." },
//     { question: "From when should I start solving CAT mock test series?", answer: "Detailed answer here." },
//     { question: "What is CAT exam pattern 2024?", answer: "Detailed answer here." },
//   ];

//   // const tabs = [
//   //   { name: "Overview", path: "/CatExam" },
//   //   { name: "Admit Card", path: "/CatExam/admitcard" },
//   //   { name: "Mock Test", path: "/CatExam/mocktest" },
//   //   // { name: "Question Papers", path: "/CatExam/questionpapers" },
//   //   // { name: "College Predictor", path: "/CatExam/collegepredictor" },
//   //   { name: "Dates", path: "/CatExam/dates" },
//   //   { name: "Syllabus", path: "/CatExam/examsyllabus" },
//   //   { name: "Pattern", path: "/CatExam/exampattern" },
//   //   { name: "Notification", path: "/CatExam/notification" },
//   //   { name: "Results", path: "/CatExam/results" }
//   // ];
//   const tabs = [
//     { name: "Overview", path: `/exam/${examName}` },
//     { name: "Admit Card", path: `/exam/${examName}/admitcard` },
//     { name: "Mock Test", path: `/exam/${examName}/mocktest` },
//     { name: "Dates", path: `/exam/${examName}/dates` },
//     { name: "Syllabus", path: `/exam/${examName}/syllabus` },
//     { name: "Pattern", path: `/exam/${examName}/pattern` },
//     { name: "Notification", path: `/exam/${examName}/notification` },
//     { name: "Results", path: `/exam/${examName}/results` },
//   ];
//   const mockDetails = [
//     { label: "Duration of CAT mocks", value: "120 minutes" },
//     { label: "Number of questions", value: "66" },
//     { label: "Total marks", value: "198" },
//     { label: "Language of free CAT mocks", value: "English" },
//     { label: "Number of answer choices", value: "4" },
//     { label: "Question type", value: "MCQ and non-MCQ" },
//     { label: "Onscreen timer", value: "Yes" },
//     { label: "Status of answers", value: "Yes" },
//     // Add more items if needed
//   ];

//   return (
//     <div className="bg-gray-100 p-8">
//       <div className="p-6 rounded-lg shadow-md bg-white">
//         <h1 className="text-2xl font-bold text-gray-800 text-left">
//         {props.MocktestHeaderHeading1} {props.MocktestYear}: {props.Mocktestaddwhichheading3}
//         </h1>
//         {/* <div className="flex items-center mt-2 text-gray-600 space-x-4">
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-eye text-gray-400"></i>
//             <span>605.7K Views</span>
//           </span>
//           <span className="flex items-center space-x-1">
//             <i className="fas fa-comments text-gray-400"></i>
//             <span>12.9K Comments</span>
//           </span>
//           <button className="text-blue-500 hover:underline">Share</button>
//         </div> */}
//         <div className="flex items-center space-x-2 mt-4">
//           <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
//           {props.MocktestHeaderadmitcardname}: {props.MocktestHeaderadmitcardDate}
//           </div>
//           <div className="ml-auto space-x-4">
//             <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
//             {props.MocktestHeaderDownloadGuidebutton}
//             </button>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             {props.MocktestHeaderSamplepaperbutton}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Navigation Section with Buttons */}
//       <div className="mt-6 bg-white p-4 rounded-md shadow-md">
//         <nav className="flex space-x-4 overflow-x-auto">
//           {tabs.map((tab) => (
//             <button
//               key={tab.name}
//               onClick={() => navigate(tab.path)}
//               className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
//             >
//               {tab.name}
//             </button>
//           ))}
//         </nav>
//       </div>

//       <div className="bg-gray-100 p-4 rounded-lg mb-6">
//         <h1 className="text-xl font-bold mb-4 text-left">{props.CatExamheading1}</h1>

//         {/* Mock Test Table */}
//         <div className="mb-6">
//         <p className="text-gray-600 mt-2">
//           {props.Mocktestheading1description1}
//                   </p>
//           <table className="w-full mt-4 border-collapse border border-gray-200">
//             <thead>
//               <tr className="bg-gray-100">
//                 <th className="border border-gray-200 p-2 text-left">{props.MocktesttableHeadername1}</th>
//                 <th className="border border-gray-200 p-2 text-left">{props.MocktesttableHeadernamedownloadlink1}</th>
//               </tr>
//             </thead>
//             <tbody>
//               {['Mock Test 1', 'Solutions', 'Mock Test 2', 'Solutions'].map((item, index) => (
//                 <tr key={index}>
//                   <td className="border border-gray-200 p-2 text-left">{item}</td>
//                   <td className="border border-gray-200 p-2 text-blue-600 cursor-pointer text-left">Click Here</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {/* FAQs */}
//         <div className="mb-6">
//           <h2 className="text-lg font-semibold text-left">Frequently Asked Questions</h2>
//           <ul className="mt-4 space-y-2">
//             {faqs.map((faq, index) => (
//               <li key={index} className="bg-gray-50 p-3 rounded-lg shadow-sm">
//                 <div className="font-medium text-gray-700">{faq.question}</div>
//                 <div className="text-gray-600">{faq.answer}</div>
//               </li>
//             ))}
//           </ul>
//         </div>

//         {/* Test Structure */}
//         <div className="mb-6">
//           <h2 className="text-lg font-semibold text-left">{props.mockteststructurequestion1}</h2>
//           <table className="w-full mt-4 border-collapse border border-gray-200">
//       <tbody>
//         {mockDetails.map((detail, index) => (
//           <tr key={index}>
//             <td className="border border-gray-200 p-2 text-left">{detail.label}</td>
//             <td className="border border-gray-200 p-2 text-left">{detail.value}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Mocktest;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MockTestStructure from "./MockTestStructure";


function Mocktest() {
  const navigate = useNavigate();
  const { examName } = useParams();
  const [examData, setExamData] = useState(null);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
        if (!response.ok) throw new Error("Failed to fetch data");
        const data = await response.json();
        setExamData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchExamData();
  }, [examName]);

  if (!examData) return <p>Loading...</p>;

  const {
    admitCardDate,
    admitCardName,
    year,
    headerHeading,
    addHeading,
    heading1,
    heading1Description,
    mockDetails,
    tabs,
    faqs,
  } = examData;

  return (
    <div className="bg-gray-100 p-8">
      {/* Header Section */}
      <div className="p-6 rounded-lg shadow-md bg-white">
        <h1 className="text-2xl font-bold text-gray-800 text-left">
          {headerHeading} {year}: {addHeading}
        </h1>
        <div className="flex items-center space-x-2 mt-4">
          <div className="bg-pink-100 text-pink-800 px-3 py-1 rounded-md text-sm font-medium">
            {admitCardName}: {new Date(admitCardDate).toLocaleDateString()}
          </div>
          <div className="ml-auto space-x-4">
            {/* <button className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-200">
              Download Guide
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
              Sample Paper
            </button> */}
          </div>
        </div>
      </div>

      {/* Navigation Section with Tabs */}
      <div className="mt-6 bg-white p-4 rounded-md shadow-md">
        <nav className="flex space-x-4 overflow-x-auto">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => navigate(tab.path)}
              className="py-2 px-4 text-sm font-semibold text-gray-600 hover:text-blue-500"
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Exam Details Section */}
      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        {/* Mock Test Table */}

       <MockTestStructure/>

        {/* FAQs Section */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-left">
            Frequently Asked Questions
          </h2>
          <ul className="mt-4 space-y-2">
            {faqs.map((faq, index) => (
              <li key={index} className="bg-gray-50 p-3 rounded-lg shadow-sm">
                <div className="font-medium text-gray-700">{faq.question}</div>
                <div className="text-gray-600">{faq.answer}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Mocktest;
