import React from "react";
import { Link } from "react-router-dom";

const CollegePredictors = ({
  isDropdownOpen,
  handleDropdownToggle,
  activeSubmenu,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const handleOptionSelect = () => {
    handleDropdownToggle(""); // Closing the dropdown by resetting the open state
  };

  return (
    <div className="relative text-xs">
      <button
        onClick={() => handleDropdownToggle("CollegePredictor")}
        className="text-white"
      >
        COLLEGE PREDICTOR {isDropdownOpen === "CollegePredictor" ? "▲" : "▼"}
      </button>
      {isDropdownOpen === "CollegePredictor" && (
        <div className="absolute top-full left-0 bg-white shadow-lg p-4 w-[600px] h-[300px] overflow-auto z-10 border border-gray-300 rounded-lg flex">
          <div className="w-[150px]">
            <ul className="space-y-2">
              <li
                onMouseEnter={() => handleMouseEnter("Medical")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Medical
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("B.Tech")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                B.Tech
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("LAW")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                LAW
              </li>
              <li
                onMouseEnter={() =>
                  handleMouseEnter("MANAGEMENT")
                }
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                MANAGEMENT
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("PredictorRankPredictors")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Rank Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("CollegePredictors")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                College Predictors
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("PredictorReviews")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Predictor Reviews
              </li>
              <li
                onMouseEnter={() => handleMouseEnter("PredictorResources")}
                onMouseLeave={handleMouseLeave}
                className="flex justify-between items-center text-xs py-2 hover:bg-gray-200 cursor-pointer transition"
              >
                Resources
              </li>
            </ul>
          </div>

          {/* Submenu for Top Ranked College Predictors */}
          {activeSubmenu === "Medical" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter("Medical")}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-4">
                <li className="text-xs">
                  <Link to="/NeetUGAllIndiaPredictor" onClick={handleOptionSelect}>
                    Neet UG All India
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/NeetPGPredictor" onClick={handleOptionSelect}>
                    NEET PG All India
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/UPPgPredictor" onClick={handleOptionSelect}>
                    UP PG Predictor
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {/* Submenu for LAW */}
          {activeSubmenu === "LAW" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter("LAW")}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-4">
                <li className="text-xs">
                  <Link to="/ggsipullbpredictor" onClick={handleOptionSelect}>
                    GGSIPU LLB Predictor
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/ggsipullmpredictor" onClick={handleOptionSelect}>
                    GGSIPU LLM Predictor
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/nlullbpredictor" onClick={handleOptionSelect}>
                    NLU LLB PREDICTOR
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/nlullmpredictor" onClick={handleOptionSelect}>
                    NLU LLM Predictor
                  </Link>
                </li>
              </ul>
            </div>
          )}
 {activeSubmenu === "MANAGEMENT" && (
  <div
    className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
    onMouseEnter={() => handleMouseEnter("MANAGEMENT")}
    onMouseLeave={handleMouseLeave}
  >
    <ul className="space-y-4">
      <li className="text-xs">
        <Link to="/ggsipumbapredictor" onClick={handleOptionSelect}>
          GGSIPU MBA Predictor
        </Link>
      </li>
      <li className="text-xs">
        <Link to="/catpredictor" onClick={handleOptionSelect}>
          CAT Predictor
        </Link>
      </li>
      <li className="text-xs">
        <Link to="/xatpredictor" onClick={handleOptionSelect}>
          XAT Predictor
        </Link>
      </li>
      <li className="text-xs">
        <Link to="/matpredictor" onClick={handleOptionSelect}>
          MAT Predictor
        </Link>
      </li>
    </ul>
  </div>
)}


          {/* Submenu for B.Tech */}
          {activeSubmenu === "B.Tech" && (
            <div
              className="absolute top-0 left-[150px] w-[200px] bg-white shadow-lg p-4"
              onMouseEnter={() => handleMouseEnter("B.Tech")}
              onMouseLeave={handleMouseLeave}
            >
              <ul className="space-y-4">
                <li className="text-xs">
                  <Link to="/jacdelhipredictor" onClick={handleOptionSelect}>
                    JAC DELHI Predictor
                  </Link>
                </li>
                <li className="text-xs">
                  <Link to="/JOSAAPredictor" onClick={handleOptionSelect}>
                    JoSAA Predictor
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollegePredictors;
