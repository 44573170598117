import React from 'react';
import {useState,useEffect} from 'react';
import {useNavigate,useParams} from 'react-router-dom';

function ExamNotificationComponent(){

    const [examData, setExamData] = useState(null);
    const [openFaq, setOpenFaq] = useState(null);
    const { examName } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchExamData = async () => {
        try {
          const response = await fetch(`https://caderaedu.com/api/exam/${examName}`);
          if (!response.ok) throw new Error("Failed to fetch data");
          const data = await response.json();
          setExamData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchExamData();
    }, [examName]);
  
    const toggleFaq = (index) => {
      setOpenFaq(openFaq === index ? null : index);
    };
  
    if (!examData) return <div className="text-center py-6">Loading...</div>;
  
    const {
      year,
      admitCardName,
      admitCardDate,
      tabs,
      schedule,
      pastSchedule,
      faqs,
      headerHeading,
      addHeading,
      heading1,
      heading1Description,
    } = examData;

    return (
        <>
         <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-6">
          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
            Upcoming Exam Schedule
          </h3>
          <table className="min-w-full bg-white border border-gray-200 mb-6">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Date
                </th>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Event
                </th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2 text-gray-700">{item.timeline}</td>
                  <td className="px-4 py-2 text-gray-600">{item.activity}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
            Past Schedule
          </h3>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Date
                </th>
                <th className="px-4 py-2 text-left bg-gray-100 border-b font-semibold">
                  Event
                </th>
              </tr>
            </thead>
            <tbody>
              {pastSchedule.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2 text-gray-700">{item.date}</td>
                  <td className="px-4 py-2 text-gray-600">{item.event}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
    )
}
export default ExamNotificationComponent;