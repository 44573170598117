import React from "react";
import Sidebar from "./sidebar";

const Cutoff = (props) => {
  const isDataAvailable = props.courses;
  const managementTable = (
    <div className="overflow-x-auto">
      <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
        {props.ManagementCourseName}
      </h2>
      {isDataAvailable ? (
        <>
          <div className="overflow-x-auto">
            <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
              {props.ManagementCourseName}
            </h2>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto bg-white shadow-lg rounded-lg mb-8">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2 md:p-4 text-left">Course Name</th>
                    <th className="p-2 md:p-4 text-left">Quota</th>
                    <th className="p-2 md:p-4 text-left">Cutoff</th>
                  </tr>
                </thead>
                <tbody>
                  {props.courses
                    .filter((course) => course.name) // Filter out courses without a name
                    .map((course, index) =>
                      course.quotas.map((quota, quotaIndex) => (
                        <tr key={`${index}-${quotaIndex}`} className="border-b">
                          <td className="p-2 md:p-4">{course.name}</td>
                          <td className="p-2 md:p-4">{quota.quotaType}</td>
                          <td className="p-2 md:p-4">{quota.closingRank}</td>
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-gray-100 p-4 rounded-lg mb-6 text-center">
          <p className="text-sm md:text-base text-gray-600">
            No cutoff data available.
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto p-6 flex flex-col lg:flex-row">
      <div className="lg:w-3/4 w-full lg:pr-6">
        <div className="mb-6">
          <p className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800">
            {props.CutoffParagraph1}
          </p>
        </div>

        {/* Table of Contents */}
        <div className="bg-gray-100 p-4 rounded-lg mb-6">
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            {props.CutoffTopic}
          </h2>
          <p className="text-sm md:text-base">{props.CutoffAboutInfo}</p>
        </div>

        {managementTable}
      </div>

      {/* Sidebar */}
      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
};

export default Cutoff;
