import React from "react";

const CourseandFee = ({ CollegeName, courses }) => {
  return (
    <div className="overflow-x-auto mt-4 bg-gray-200">
      {/* Table Section */}
      <div className="w-2/10 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-xl font-semibold mb-4">
          {CollegeName
            ? `${CollegeName} Fees & Eligibility`
            : "College Fees & Eligibility"}
        </h2>

        <div className="overflow-x-auto">
          {courses && courses.length > 0 ? (
            <table className="min-w-full table-auto text-left border-collapse border border-gray-300">
              <thead>
                <tr className="bg-blue-100">
                  <th className="border px-4 py-2">Course</th>
                  <th className="border px-4 py-2">Indian Fees</th>
                  <th className="border px-4 py-2">NRI Fees</th>
                  <th className="border px-4 py-2">Eligibility</th>
                  <th className="border px-4 py-2">Application Date</th>
                </tr>
              </thead>
              <tbody>
                {courses
                  .filter((course) => course.name) // Exclude rows without a course name
                  .map((course, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">{course.name}</td>
                      <td className="border px-4 py-2">{course.fee}</td>
                      <td className="border px-4 py-2">{course.nriFee || "-"}</td>
                      <td className="border px-4 py-2">{course.eligibility}</td>
                      <td className="border px-4 py-2">
                        {course.applicationDeadline}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-600">
              No course fee data is available.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseandFee;
