import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Home,
  About,
  Courses,
  Exams,
  Colleges,
  CollegePredictor,
  Counselling,
  NotFound,
  Header,
  Footer,
  Timesignup,
  NewsletterSubscription,
  CookiesConsent,
  LoginPage,
  ForgotPasswordPage,
  Signup,
  LawPredictormobile,
  HospitalityPredictormobile,
  SciencePredictormobile,
} from "./components"; // Ensure you have these components
import ClickCollege from "./components/Page/ClickCollege";
import LatestNotification from "./components/LatestNotifications";
import MCCAllIndiaPredictor from "./components/NeetUGAllIndiaPredictor"; // Adjust this import if needed
import NeetPG from "./components/NEETPGPredictor/NeetPg"; // Adjust this import if needed
import JosaaPre from "./components/JOSAA_Predictor/JOSAAPredictor";
import Btechcolleges from "./components/Page/Btechcollegesdummy/Btechcolleges";
import TopEngineeringclgs from "./components/Page/Btechcollegesdummy/TopEngineeringclgs";
import TopPrivateEngclg from "./components/Page/Btechcollegesdummy/TopPrivateEngclg";
import TopIITsinIndia from "./components/Page/Btechcollegesdummy/TopIITsinIndia";
import TopNITsinIndia from "./components/Page/Btechcollegesdummy/TopNITsinIndia";
import TopEngineeringKarnataka from "./components/Page/Btechcollegesdummy/TopEngineeringKarnataka";
import TopEngiCollegesinPune from "./components/Page/Btechcollegesdummy/TopEngiCollegesinPune";
import TopEngiCollegesinHyderabad from "./components/Page/Btechcollegesdummy/TopEngiCollegesinHyderabad";
import IIT_Jammu from "./components/Page/CollegesPages/IIT_Jammu";
import LatestNotificationPage from "./components/LatestNotificationPage";
import Medicalcolleges from "./components/Page/Medicalcollegesdummy/Medicalcolleges";
import TopGovernmentMedical from "./components/Page/Medicalcollegesdummy/TopGovernmentMedical";
import TopMedicalIndia from "./components/Page/Medicalcollegesdummy/TopMedicalIndia";
import TopPrivateMedical from "./components/Page/Medicalcollegesdummy/TopPrivateMedical";
import TopBangaloreMedical from "./components/Page/Medicalcollegesdummy/TopBangaloreMedical";
import TopMumbaiMedical from "./components/Page/Medicalcollegesdummy/TopMumbaiMedical";
import UpPg from "./components/UPPGPredictor/UPPg";
import MTech from "./components/Page/StudyGoals/MTech";
import DiplomainEngineering from "./components/Page/StudyGoals/DiplomainEngineering";
import MBAPGDM from "./components/Page/StudyGoals/MBAPGDM";
import BBABMS from "./components/Page/StudyGoals/BBABMS";
import ExecutiveMBA from "./components/Page/StudyGoals/ExecutiveMBA";
import Bcom from "./components/Page/StudyGoals/Bcom";
import Mcom from "./components/Page/StudyGoals/Mcom";
import BA from "./components/Page/StudyGoals/BA";
import MA from "./components/Page/StudyGoals/MA";
import BFA from "./components/Page/StudyGoals/BFA";
import BSW from "./components/Page/StudyGoals/BSW";
import BSc from "./components/Page/StudyGoals/BSc";
import MSc from "./components/Page/StudyGoals/MSc";
import PhD from "./components/Page/StudyGoals/PhD";
import MBBS from "./components/Page/StudyGoals/MBBS";
import PGMedical from "./components/Page/StudyGoals/PGMedical";
import LLbpredictor from "./components/predictors/Llbpredictor";
import NLUPredictor from "./components/predictors/NLUpredictor";
import TopManagementCollegesinIndia from "./components/Page/MangementCollegedummy/TopManagementCollegesinIndia";
import TopIIMsinIndia from "./components/Page/MangementCollegedummy/TopIIMsinIndia";
import TopManagementCollegesinBangalore from "./components/Page/MangementCollegedummy/TopManagementCollegesinBangalore";
import TopMBACollegesinPune from "./components/Page/MangementCollegedummy/TopMBACollegesinPune";
import TopPrivateManagementColleges from "./components/Page/MangementCollegedummy/TopPrivateManagementColleges";
import TopLawCollegesinDelhi from "./components/Page/lawcollegesdummy/TopLawCollegesinDelhi";
import TopLawCollegesinIndia from "./components/Page/lawcollegesdummy/TopLawCollegesinIndia";
import TopLawCollegesinMumbai from "./components/Page/lawcollegesdummy/TopLawCollegesinMumbai";
import TopNationalLawUniversities from "./components/Page/lawcollegesdummy/TopNationalLawUniversities";
import TopPrivateLawColleges from "./components/Page/lawcollegesdummy/TopPrivateLawColleges";
import TopPrivatePharmacyColleges from "./components/Page/pharmacycollegesdummy/TopPrivatePharmacyColleges";
import TopPharmacyCollegesinDelhi from "./components/Page/pharmacycollegesdummy/TopPharmacyCollegesinDelhi";
import TopPharmacyCollegesinIndia from "./components/Page/pharmacycollegesdummy/TopPharmacyCollegesinIndia";
import TopPharmacyCollegesinMumbai from "./components/Page/pharmacycollegesdummy/TopPharmacyCollegesinMumbai";
import TopPharmacyCollegesinBanglore from "./components/Page/pharmacycollegesdummy/TopPharmacyCollegesinBangalore";
import LLMpredictor from "./components/predictors/LLMpredictor";
import TopArchitectureCollegesinindia from "./components/Page/Architecturecollegesdummy/TopArchitectureCollegesinIndia";
import TopPrivateArchitectureColleges from "./components/Page/Architecturecollegesdummy/TopPrivateArchitectureColleges";
import TopArchitectureCollegesinDelhi from "./components/Page/Architecturecollegesdummy/TopArchitectureCollegesinDelhi";
import TopArchitectureCollegesinMumbai from "./components/Page/Architecturecollegesdummy/TopArchitectureCollegesinMumbai";
import TopArchitectureCollegesinBangalore from "./components/Page/Architecturecollegesdummy/TopArchitectureCollegesinBangalore";
import TopArtsCollegesinIndia from "./components/Page/ArtsandCommercecollegedummy/TopArtsCollegesinIndia";
import TopCommerceCollegesinIndia from "./components/Page/ArtsandCommercecollegedummy/TopCommerceCollegesinIndia";
import TopPrivateArtsColleges from "./components/Page/ArtsandCommercecollegedummy/TopPrivateArtsColleges";
import TopArtsCollegesinDelhi from "./components/Page/ArtsandCommercecollegedummy/TopArtsCollegesinDelhi";
import TopCommerceCollegesinMumbai from "./components/Page/ArtsandCommercecollegedummy/TopCommerceCollegesinMumbai";
import MobileHeader from "./components/MobileHeader";
import Profile from "./components/Profile";
import DesignPredictormobile from "./components/Designpredictormobile";
import NLULLMPredictor from './components/predictors/NLULLMpredictor';
import MedicalPredictormobileview from "./components/MedicalPredictormobileview";
import BtechPredictormobileview from "./components/BtechPredeictermobileview";
import MBAPredictormobileview from "./components/MBAPredictermobileview";
import MTechPredictormobileview from "./components/MTechPredictormobileview";

import SearchPage from "./components/SearchPage";

import CatExam from "./components/ExamSection/CatExam";
import Dates from "./components/ExamSection/Dates";
import AdmitCard from "./components/ExamSection/Admitcard";
import ExamSyllabus from "./components/ExamSection/ExamSyllabus";
import ExamPattern from "./components/ExamSection/ExamPattern";
import Mocktest from "./components/ExamSection/Mocktest";
import Overview from "./components/ExamSection/Overview";
import ExamResult from "./components/ExamSection/ExamResults";
import ExamNotification from "./components/ExamSection/ExamNotification";
import ManagementExams from "./components/ExamSection/ManagementExams";
import MedicalExams from "./components/ExamSection/MedicalExams";
import EngineeringExams from "./components/ExamSection/EngineeringExams";
import LawExams from "./components/ExamSection/LawExams";
import MassMediaExams from "./components/ExamSection/MassMediaExams";
import HumanitiesExams from "./components/ExamSection/HumanitiesExams";
import ScienceExams from "./components/ExamSection/ScienceExams";
import ArchitectureExams from "./components/ExamSection/ArchitectureExams";
import AccountingExams from "./components/ExamSection/AccountingExams";
import JACDelhi from "./components/JACDELHIPredictor/jacdelhi";
import GGSIPUMBA from "./components/predictors/ggsipumba";
const App = () => {
  const [redirections, setRedirections] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 716);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 716);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch redirection data from the backend
  useEffect(() => {
    fetch("https://caderaedu.com/api/get/redirection") // Adjust the port if necessary
      .then((response) => response.json())
      .then((data) => setRedirections(data))
      .catch((error) =>
        console.error("Error fetching redirection data:", error)
      );
  }, []);

  // Check for cookie consent popup
  useEffect(() => {
    const isConsentGiven = sessionStorage.getItem("cookieConsent");
    if (!isConsentGiven) {
      setShowPopup(true);
    }
  }, []);

  return (
    <>
      {isMobile ? <MobileHeader /> : <Header />} {/* Conditional Header */}{" "}
      {/* Include Header on all pages */}
      <Routes>
        {/* Dynamic Redirection based on fetched data */}
        {redirections.map(
          (redirect) => (
            console.log(redirect),
            (
              <Route
                key={redirect._id} // Use the unique _id from the redirection entry
                path={redirect.sourceURL}
                element={<Navigate to={redirect.targetURL} replace />}
              />
            )
          )
        )}
        {/* Define other routes */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/exams" element={<Exams />} />
        <Route path="/colleges" element={<Colleges />} />
        <Route path="/college-predictor" element={<CollegePredictor />} />
        <Route path="/counselling" element={<Counselling />} />
        <Route path="/UPPgPredictor" element={<UpPg />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotpasswordpage" element={<ForgotPasswordPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/nlullbpredictor" element={<NLUPredictor />} />
        <Route path="/nlullmpredictor" element={<NLULLMPredictor />} />
        <Route
          path="/NeetUGAllIndiaPredictor"
          element={<MCCAllIndiaPredictor />}
        />
        <Route
          path="/LatestNotificationpage"
          element={<LatestNotificationPage />}
        />
        <Route path="/" exact component={LatestNotification} />
        <Route
          path="/notifications/:slug"
          element={<LatestNotificationPage />}
        />
        <Route path="/Btech" element={<Btechcolleges />} />
        <Route path="/TopEngineering" element={<TopEngineeringclgs />} />
        <Route path="/TopPrivate" element={<TopPrivateEngclg />} />
        <Route path="/TopIIT" element={<TopIITsinIndia />} />
        <Route path="/TopNIT" element={<TopNITsinIndia />} />
        <Route path="/TopKarnataka" element={<TopEngineeringKarnataka />} />
        <Route path="/TopPune" element={<TopEngiCollegesinPune />} />
        <Route path="/TopHyderabad" element={<TopEngiCollegesinHyderabad />} />
        <Route path="/Medical" element={<Medicalcolleges />} />
        <Route path="/TopMedicalIndia" element={<TopMedicalIndia />} />
        <Route
          path="/TopGovernmentMedical"
          element={<TopGovernmentMedical />}
        />
        <Route path="/JACDELHIPredictor" element={<JACDelhi />} />
        <Route path="/TopPrivateMedical" element={<TopPrivateMedical />} />
        <Route path="/TopBangaloreMedical" element={<TopBangaloreMedical />} />
        <Route path="/TopMumbaiMedical" element={<TopMumbaiMedical />} />
        {/* Dynamic Route for College Pages */}
        <Route path="/college/:slug" element={<ClickCollege />} />
        <Route path="/MTech" element={<MTech />} />
        <Route
          path="/DiplomainEngineering"
          element={<DiplomainEngineering />}
        />
        <Route
          path="/TopManagementCollegesinIndia"
          element={<TopManagementCollegesinIndia />}
        />
        <Route path="/TopIIMsinIndia" element={<TopIIMsinIndia />} />
        <Route
          path="/TopManagementCollegesinBangalore"
          element={<TopManagementCollegesinBangalore />}
        />
        <Route
          path="/TopMBACollegesinPune"
          element={<TopMBACollegesinPune />}
        />
        <Route
          path="/TopPrivateManagementColleges"
          element={<TopPrivateManagementColleges />}
        />
        <Route
          path="/TopLawCollegesinDelhi"
          element={<TopLawCollegesinDelhi />}
        />
         <Route
          path="/ggsipullmpredictor"
          element={<LLMpredictor/>}
        />
        <Route
          path="/TopLawCollegesinIndia"
          element={<TopLawCollegesinIndia />}
        />
        <Route
          path="/TopLawCollegesinMumbai"
          element={<TopLawCollegesinMumbai />}
        />
        <Route
          path="/TopNationalLawUniversities"
          element={<TopNationalLawUniversities />}
        />
        <Route
          path="/TopPrivateLawColleges"
          element={<TopPrivateLawColleges />}
        />
        <Route
          path="/TopPrivatePharmacyColleges"
          element={<TopPrivatePharmacyColleges />}
        />
        <Route
          path="/TopPharmacyCollegesinDelhi"
          element={<TopPharmacyCollegesinDelhi />}
        />
        <Route
          path="/TopPharmacyCollegesinIndia"
          element={<TopPharmacyCollegesinIndia />}
        />
        <Route
          path="/TopPharmacyCollegesinMumbai"
          element={<TopPharmacyCollegesinMumbai />}
        />
        <Route
          path="/TopPharmacyCollegesinBanglore"
          element={<TopPharmacyCollegesinBanglore />}
        />
        {/*  Architecture Colleges */}
        <Route
          path="/TopArchitectureCollegesinindia"
          element={<TopArchitectureCollegesinindia />}
        />
        <Route
          path="/TopPrivateArchitectureColleges"
          element={<TopPrivateArchitectureColleges />}
        />
        <Route
          path="/TopArchitectureCollegesinDelhi"
          element={<TopArchitectureCollegesinDelhi />}
        />
        <Route
          path="/TopArchitectureCollegesinMumbai"
          element={<TopArchitectureCollegesinMumbai />}
        />
        <Route
          path="/TopArchitectureCollegesinBangalore"
          element={<TopArchitectureCollegesinBangalore />}
        />
        {/* Art & Commerce */}
        <Route
          path="/TopArtsCollegesinIndia"
          element={<TopArtsCollegesinIndia />}
        />
        <Route
          path="/TopCommerceCollegesinIndia"
          element={<TopCommerceCollegesinIndia />}
        />
        <Route
          path="/TopPrivateArtsColleges"
          element={<TopPrivateArtsColleges />}
        />
           <Route path="/ggsipullbpredictor" element={<LLbpredictor/>} />
           <Route path="/ggsipumbapredictor" element={<GGSIPUMBA/>} />
        <Route
          path="/TopArtsCollegesinDelhi"
          element={<TopArtsCollegesinDelhi />}
        />
        <Route
          path="TopCommerceCollegesinMumbai"
          element={<TopCommerceCollegesinMumbai />}
        />
        <Route path="/MBA/PGDM" element={<MBAPGDM />} />
        <Route path="/BBA/BMS" element={<BBABMS />} />
        <Route path="/ExecutiveMBAData" element={<ExecutiveMBA />} />
        <Route path="/Bcom" element={<Bcom />} />
        <Route path="/Mcom" element={<Mcom />} />
        <Route path="/BA" element={<BA />} />
        <Route path="/MA" element={<MA />} />
        <Route path="/BFA" element={<BFA />} />
        <Route path="/BSW" element={<BSW />} />
        <Route path="/BSc" element={<BSc />} />
        <Route path="/MSc" element={<MSc />} />
        <Route path="/PhD" element={<PhD />} />
        <Route path="/MBBS" element={<MBBS />} />
        <Route path="/PGMedical" element={<PGMedical />} />
        <Route path="/NeetPGPredictor" element={<NeetPG />} />
        <Route path="/JOSAAPredictor" element={<JosaaPre />} />
        <Route
          path="/MedicalPredictormobileview"
          element={<MedicalPredictormobileview />}
        />
        <Route
          path="/BtechPredictormobileview"
          element={<BtechPredictormobileview />}
        />
        <Route
          path="/MBAPredictormobileview"
          element={<MBAPredictormobileview />}
        />
        <Route
          path="/MTechPredictormobileview"
          element={<MTechPredictormobileview />}
        />
        <Route
          path="/DesignPredictorMobile"
          element={<DesignPredictormobile />}
        />
        <Route path="/LawPredictormobile" element={<LawPredictormobile />} />
        <Route
          path="/hospitalitypredictor"
          element={<HospitalityPredictormobile />}
        />
        <Route path="/sciencepredictor" element={<SciencePredictormobile />} />
        <Route path="/search" element={<SearchPage />} />{" "}
        {/* Add the search route */}
        <Route path="*" element={<NotFound />} /> {/* Handle 404 pages */}
        <Route path="/ManagementExams" element={<ManagementExams />} />
        <Route path="/MedicalExams" element={<MedicalExams />} />
        <Route path="/EngineeringExams" element={<EngineeringExams />} />
        <Route path="/Lawexams" element={<LawExams />} />
        <Route path="/MassMediaExams" element={<MassMediaExams />} />
        <Route path="/HumanitiesExams" element={<HumanitiesExams />} />
        <Route path="/ScienceExams" element={<ScienceExams />} />
        <Route path="/ArchitectureExams" elements={<ArchitectureExams />} />
        <Route path="/AccountingExams" element={<AccountingExams />} />
        <Route path="/exam/:examName" element={<CatExam />} />
        <Route path="/exam/:examName/dates" element={<Dates />} />
        <Route path="/exam/:examName/admitcard" element={<AdmitCard />} />
        <Route path="/exam/:examName/syllabus" element={<ExamSyllabus />} />
        <Route path="/exam/:examName/pattern" element={<ExamPattern />} />
        <Route path="/exam/:examName/mocktest" element={<Mocktest />} />
        <Route path="/exam/:examName/overview" element={<Overview />} />
        <Route path="/exam/:examName/results" element={<ExamResult />} />
        <Route
          path="/exam/:examName/notification"
          element={<ExamNotification />}
        />
      </Routes>
      <NewsletterSubscription />
      {showPopup && <CookiesConsent />} {/* Cookie consent popup */}
      <Footer />
      <Timesignup />
    </>
  );
};

export default App;
