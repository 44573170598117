[
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9106,
    "Closing Rank": 14782
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18286,
    "Closing Rank": 23024
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2062,
    "Closing Rank": 2267
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3638,
    "Closing Rank": 4265
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4115,
    "Closing Rank": 5231
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7450,
    "Closing Rank": 8720
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2381,
    "Closing Rank": 2587
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4031,
    "Closing Rank": 4293
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 753,
    "Closing Rank": 977
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1718,
    "Closing Rank": 1750
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2389,
    "Closing Rank": 3685
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4833,
    "Closing Rank": 7557
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 197,
    "Closing Rank": 197
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 400,
    "Closing Rank": 466
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1025,
    "Closing Rank": 1025
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 774,
    "Closing Rank": 1237
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2107,
    "Closing Rank": 3291
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31,
    "Closing Rank": 31
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 382,
    "Closing Rank": 596
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1279,
    "Closing Rank": 1313
  },

  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 241,
    "Closing Rank": 356
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 546,
    "Closing Rank": 649
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5381,
    "Closing Rank": 7661
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12140,
    "Closing Rank": 14183
  },

  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1013,
    "Closing Rank": 1261
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2194,
    "Closing Rank": 2249
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2167,
    "Closing Rank": 2876
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5899,
    "Closing Rank": 6611
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1105,
    "Closing Rank": 1762
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2081,
    "Closing Rank": 2081
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 667,
    "Closing Rank": 792
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1179,
    "Closing Rank": 1179
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3944,
    "Closing Rank": 5157
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8798,
    "Closing Rank": 10904
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59,
    "Closing Rank": 59
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 601,
    "Closing Rank": 738
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1941,
    "Closing Rank": 1941
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1423,
    "Closing Rank": 2026
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3327,
    "Closing Rank": 3729
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 914,
    "Closing Rank": 1239
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1773,
    "Closing Rank": 1915
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 384,
    "Closing Rank": 538
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 910,
    "Closing Rank": 910
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10756,
    "Closing Rank": 11307
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18825,
    "Closing Rank": 18825
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1846,
    "Closing Rank": 1978
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3136,
    "Closing Rank": 4378
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9267,
    "Closing Rank": 9267
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2045,
    "Closing Rank": 2226
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4555,
    "Closing Rank": 4555
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1333,
    "Closing Rank": 1333
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7881,
    "Closing Rank": 10233
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16104,
    "Closing Rank": 19505
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 172,
    "Closing Rank": 172
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1400,
    "Closing Rank": 1639
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3122,
    "Closing Rank": 3236
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3078,
    "Closing Rank": 3885
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7053,
    "Closing Rank": 7801
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1907,
    "Closing Rank": 2293
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2465,
    "Closing Rank": 3941
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 918,
    "Closing Rank": 1041
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1735,
    "Closing Rank": 1735
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11823,
    "Closing Rank": 14898
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20901,
    "Closing Rank": 21693
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2301,
    "Closing Rank": 2373
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4185,
    "Closing Rank": 4185
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4730,
    "Closing Rank": 5608
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9228,
    "Closing Rank": 9228
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2332,
    "Closing Rank": 2774
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4396,
    "Closing Rank": 4396
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1102,
    "Closing Rank": 1223
  },
  {
    "Institute": "Indian Institute of Technology Bhubaneswar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "330P",
    "Closing Rank": "602P"
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 817,
    "Closing Rank": 2394
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2494,
    "Closing Rank": 6360
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44,
    "Closing Rank": 44
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 327,
    "Closing Rank": 618
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1050,
    "Closing Rank": 1409
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 447,
    "Closing Rank": 1577
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1935,
    "Closing Rank": 3516
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62,
    "Closing Rank": 62
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 136,
    "Closing Rank": 678
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 803,
    "Closing Rank": 1493
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 184,
    "Closing Rank": 501
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 424,
    "Closing Rank": 424
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 631,
    "Closing Rank": 1191
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4101,
    "Closing Rank": 4101
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 139,
    "Closing Rank": 139
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 360,
    "Closing Rank": 360
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1490,
    "Closing Rank": 1490
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1106,
    "Closing Rank": 1291
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3479,
    "Closing Rank": 3479
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 521,
    "Closing Rank": 545
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1438,
    "Closing Rank": 1438
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "BS in Mathematics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 247,
    "Closing Rank": 247
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 650,
    "Closing Rank": 2545
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4559,
    "Closing Rank": 6650
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 130,
    "Closing Rank": 153
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 390,
    "Closing Rank": 485
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 927,
    "Closing Rank": 1438
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 953,
    "Closing Rank": 1338
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2481,
    "Closing Rank": 3197
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64,
    "Closing Rank": 64
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 492,
    "Closing Rank": 783
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1123,
    "Closing Rank": 1498
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 301,
    "Closing Rank": 413
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 344,
    "Closing Rank": 742
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5629,
    "Closing Rank": 7430
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9692,
    "Closing Rank": 11262
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 193,
    "Closing Rank": 193
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1175,
    "Closing Rank": 1338
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2637,
    "Closing Rank": 2637
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2780,
    "Closing Rank": 3506
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7579,
    "Closing Rank": 7754
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1688,
    "Closing Rank": 2025
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3101,
    "Closing Rank": 3101
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1007,
    "Closing Rank": 1036
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1498,
    "Closing Rank": 1498
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2244,
    "Closing Rank": 4046
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6334,
    "Closing Rank": 8833
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 142,
    "Closing Rank": 151
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 559,
    "Closing Rank": 769
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1725,
    "Closing Rank": 2029
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1076,
    "Closing Rank": 1979
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3419,
    "Closing Rank": 4709
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 580,
    "Closing Rank": 1002
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 770,
    "Closing Rank": 1986
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41,
    "Closing Rank": 331
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 601,
    "Closing Rank": 623
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 68
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7,
    "Closing Rank": 421
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 3
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7,
    "Closing Rank": 7
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7,
    "Closing Rank": 23
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43,
    "Closing Rank": 79
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3,
    "Closing Rank": 3
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5,
    "Closing Rank": 50
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 66,
    "Closing Rank": 274
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 2
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20,
    "Closing Rank": 20
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 32
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10,
    "Closing Rank": 71
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 1
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 13
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16,
    "Closing Rank": 40
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 1
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1562,
    "Closing Rank": 2408
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3878,
    "Closing Rank": 4408
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 144,
    "Closing Rank": 144
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 398,
    "Closing Rank": 525
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 920,
    "Closing Rank": 920
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 618,
    "Closing Rank": 1255
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1491,
    "Closing Rank": 2705
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 280,
    "Closing Rank": 722
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 373,
    "Closing Rank": 373
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 130,
    "Closing Rank": 222
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 714,
    "Closing Rank": 714
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15,
    "Closing Rank": 496
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 642,
    "Closing Rank": 1339
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14,
    "Closing Rank": 30
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 77,
    "Closing Rank": 77
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57,
    "Closing Rank": 116
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 208,
    "Closing Rank": 455
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21,
    "Closing Rank": 21
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 131,
    "Closing Rank": 325
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 608,
    "Closing Rank": 830
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23,
    "Closing Rank": 23
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34,
    "Closing Rank": 171
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 196,
    "Closing Rank": 368
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36,
    "Closing Rank": 79
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 84,
    "Closing Rank": 176
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 536,
    "Closing Rank": 973
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1731,
    "Closing Rank": 2424
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52,
    "Closing Rank": 52
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 183,
    "Closing Rank": 199
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 558,
    "Closing Rank": 753
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 379,
    "Closing Rank": 571
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1006,
    "Closing Rank": 1877
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42,
    "Closing Rank": 42
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 197,
    "Closing Rank": 274
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 584,
    "Closing Rank": 1054
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 131,
    "Closing Rank": 221
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 363,
    "Closing Rank": 363
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1905,
    "Closing Rank": 2689
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3767,
    "Closing Rank": 5537
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 184,
    "Closing Rank": 184
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 490,
    "Closing Rank": 568
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1691,
    "Closing Rank": 1691
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1300,
    "Closing Rank": 1650
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3388,
    "Closing Rank": 3824
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 706,
    "Closing Rank": 990
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1673,
    "Closing Rank": 1673
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 552,
    "Closing Rank": 669
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1473,
    "Closing Rank": 1473
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 197,
    "Closing Rank": 1719
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1680,
    "Closing Rank": 3629
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 156,
    "Closing Rank": 156
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 442,
    "Closing Rank": 567
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1410,
    "Closing Rank": 1410
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 970,
    "Closing Rank": 1578
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2329,
    "Closing Rank": 2854
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 217,
    "Closing Rank": 779
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1772,
    "Closing Rank": 2347
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15,
    "Closing Rank": 682
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1047,
    "Closing Rank": 1047
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3424,
    "Closing Rank": 4718
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8821,
    "Closing Rank": 9292
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 935,
    "Closing Rank": 1060
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2019,
    "Closing Rank": 2019
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1601,
    "Closing Rank": 2716
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5701,
    "Closing Rank": 5973
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 622,
    "Closing Rank": 1267
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1874,
    "Closing Rank": 1874
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 680,
    "Closing Rank": 830
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Environmental Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1587,
    "Closing Rank": 1587
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1042,
    "Closing Rank": 1726
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3258,
    "Closing Rank": 4762
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63,
    "Closing Rank": 63
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 279,
    "Closing Rank": 303
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 706,
    "Closing Rank": 706
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 401,
    "Closing Rank": 1196
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2881,
    "Closing Rank": 3817
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 588,
    "Closing Rank": 999
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1052,
    "Closing Rank": 1052
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 323,
    "Closing Rank": 351
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Industrial Engineering and Operations Research (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1404,
    "Closing Rank": 1404
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 666,
    "Closing Rank": 1685
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1662,
    "Closing Rank": 4444
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 78,
    "Closing Rank": 152
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 164,
    "Closing Rank": 367
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 968,
    "Closing Rank": 1319
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 462,
    "Closing Rank": 958
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1265,
    "Closing Rank": 2722
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 191,
    "Closing Rank": 476
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 142,
    "Closing Rank": 1066
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 61,
    "Closing Rank": 309
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 466,
    "Closing Rank": 516
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2690,
    "Closing Rank": 4193
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6870,
    "Closing Rank": 8491
  },

  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 624,
    "Closing Rank": 907
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1585,
    "Closing Rank": 1723
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1439,
    "Closing Rank": 2207
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3446,
    "Closing Rank": 5594
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 901,
    "Closing Rank": 1369
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1500,
    "Closing Rank": 2276
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 514,
    "Closing Rank": 641
  },
  {
    "Institute": "Indian Institute of Technology Bombay",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1154,
    "Closing Rank": 1251
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7482,
    "Closing Rank": 10842
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14742,
    "Closing Rank": 18004
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1590,
    "Closing Rank": 1854
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3230,
    "Closing Rank": 3230
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3195,
    "Closing Rank": 4693
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7596,
    "Closing Rank": 8700
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2375,
    "Closing Rank": 2569
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4116,
    "Closing Rank": 4116
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in General Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1394,
    "Closing Rank": 1400
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12859,
    "Closing Rank": 14440
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21058,
    "Closing Rank": 23252
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2113,
    "Closing Rank": 2174
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3969,
    "Closing Rank": 3969
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5151,
    "Closing Rank": 5543
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8998,
    "Closing Rank": 8998
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2787,
    "Closing Rank": 3050
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4427,
    "Closing Rank": 4427
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1416,
    "Closing Rank": 1453
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3634,
    "Closing Rank": 4089
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7453,
    "Closing Rank": 7886
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 671,
    "Closing Rank": 679
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1517,
    "Closing Rank": 1517
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1441,
    "Closing Rank": 1767
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3788,
    "Closing Rank": 4641
  },

  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 904,
    "Closing Rank": 1086
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1940,
    "Closing Rank": 1940
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 621,
    "Closing Rank": 678
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4694,
    "Closing Rank": 6643
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11035,
    "Closing Rank": 12194
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 969,
    "Closing Rank": 1093
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2281,
    "Closing Rank": 2281
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2377,
    "Closing Rank": 2943
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6343,
    "Closing Rank": 6353
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1531,
    "Closing Rank": 1751
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3907,
    "Closing Rank": 3907
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "B.Tech in Microelectronics & VLSI (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1084,
    "Closing Rank": 1096
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11233,
    "Closing Rank": 14239
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20007,
    "Closing Rank": 21077
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2124,
    "Closing Rank": 2215
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4493,
    "Closing Rank": 4493
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5184,
    "Closing Rank": 5698
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2614,
    "Closing Rank": 3144
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4590,
    "Closing Rank": 4590
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1331,
    "Closing Rank": 1502
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12900,
    "Closing Rank": 16430
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23662,
    "Closing Rank": 23698
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2353,
    "Closing Rank": 2487
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4093,
    "Closing Rank": 4093
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5803,
    "Closing Rank": 6034
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3170,
    "Closing Rank": 3198
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4482,
    "Closing Rank": 4482
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "BS in Chemical Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1493,
    "Closing Rank": 1529
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9968,
    "Closing Rank": 12562
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20688,
    "Closing Rank": 22150
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1796,
    "Closing Rank": 1953
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3738,
    "Closing Rank": 3738
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4238,
    "Closing Rank": 4726
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8450,
    "Closing Rank": 9050
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2311,
    "Closing Rank": 2546
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4406,
    "Closing Rank": 4406
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 717,
    "Closing Rank": 795
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1526,
    "Closing Rank": 1526
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1803,
    "Closing Rank": 3118
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6049,
    "Closing Rank": 6725
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 178,
    "Closing Rank": 178
  },

  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 330,
    "Closing Rank": 489
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 825,
    "Closing Rank": 1058
  },

  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 765,
    "Closing Rank": 1274
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2698,
    "Closing Rank": 3578
  },

  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 542,
    "Closing Rank": 761
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1061,
    "Closing Rank": 1732
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 293,
    "Closing Rank": 371
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 870,
    "Closing Rank": 870
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3222,
    "Closing Rank": 3918
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7110,
    "Closing Rank": 9188
  },

  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 529,
    "Closing Rank": 595
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1401,
    "Closing Rank": 1401
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1284,
    "Closing Rank": 1679
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3683,
    "Closing Rank": 4638
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 731,
    "Closing Rank": 977
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1897,
    "Closing Rank": 1897
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 378,
    "Closing Rank": 615
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 983,
    "Closing Rank": 983
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4772,
    "Closing Rank": 6909
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11796,
    "Closing Rank": 12842
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 855,
    "Closing Rank": 1188
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2285,
    "Closing Rank": 2285
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2320,
    "Closing Rank": 2932
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6754,
    "Closing Rank": 7019
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1216,
    "Closing Rank": 1650
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2943,
    "Closing Rank": 3151
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 772,
    "Closing Rank": 833
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1632,
    "Closing Rank": 1632
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9089,
    "Closing Rank": 10475
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19986,
    "Closing Rank": 20153
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1885,
    "Closing Rank": 1958
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3601,
    "Closing Rank": 3601
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4320,
    "Closing Rank": 5006
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8869,
    "Closing Rank": 8869
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2298,
    "Closing Rank": 2631
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4734,
    "Closing Rank": 4734
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1037,
    "Closing Rank": 1342
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6947,
    "Closing Rank": 9734
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18047,
    "Closing Rank": 19060
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1470,
    "Closing Rank": 1676
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3723,
    "Closing Rank": 3723
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3063,
    "Closing Rank": 4112
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8440,
    "Closing Rank": 8967
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1831,
    "Closing Rank": 2133
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3471,
    "Closing Rank": 3923
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 677,
    "Closing Rank": 974
  },
  {
    "Institute": "Indian Institute of Technology Mandi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "397P",
    "Closing Rank": "397P"
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3677,
    "Closing Rank": 4849
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8216,
    "Closing Rank": 9733
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 961,
    "Closing Rank": 1040
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2000,
    "Closing Rank": 2341
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1986,
    "Closing Rank": 2749
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5747,
    "Closing Rank": 7010
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1100,
    "Closing Rank": 1548
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2663,
    "Closing Rank": 2673
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 496,
    "Closing Rank": 819
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1310,
    "Closing Rank": 1310
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1689,
    "Closing Rank": 2221
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3550,
    "Closing Rank": 5060
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 140,
    "Closing Rank": 140
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 283,
    "Closing Rank": 474
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1308,
    "Closing Rank": 1358
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 960,
    "Closing Rank": 1303
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2912,
    "Closing Rank": 3412
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 126,
    "Closing Rank": 655
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 974,
    "Closing Rank": 1348
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 423,
    "Closing Rank": 481
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 771,
    "Closing Rank": 771
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2629,
    "Closing Rank": 3646
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6656,
    "Closing Rank": 6847
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 494,
    "Closing Rank": 594
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2232,
    "Closing Rank": 2232
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1424,
    "Closing Rank": 2054
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3654,
    "Closing Rank": 4525
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60,
    "Closing Rank": 60
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 764,
    "Closing Rank": 887
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1576,
    "Closing Rank": 2263
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 233,
    "Closing Rank": 572
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 604,
    "Closing Rank": 604
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2666,
    "Closing Rank": 4169
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6862,
    "Closing Rank": 9424
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 159,
    "Closing Rank": 159
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 580,
    "Closing Rank": 778
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1688,
    "Closing Rank": 1972
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1056,
    "Closing Rank": 2063
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4144,
    "Closing Rank": 5280
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 396,
    "Closing Rank": 852
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1095,
    "Closing Rank": 1937
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18,
    "Closing Rank": 188
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 235,
    "Closing Rank": 312
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27,
    "Closing Rank": 116
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39,
    "Closing Rank": 556
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5,
    "Closing Rank": 5
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 68,
    "Closing Rank": 68
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24,
    "Closing Rank": 35
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 108,
    "Closing Rank": 145
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39,
    "Closing Rank": 89
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 319,
    "Closing Rank": 394
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3,
    "Closing Rank": 3
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23,
    "Closing Rank": 50
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 76,
    "Closing Rank": 102
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2,
    "Closing Rank": 2
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14,
    "Closing Rank": 23
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51,
    "Closing Rank": 52
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3,
    "Closing Rank": 3
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 128,
    "Closing Rank": 204
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 958,
    "Closing Rank": 1061
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10,
    "Closing Rank": 10
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40,
    "Closing Rank": 54
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 238,
    "Closing Rank": 238
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 120,
    "Closing Rank": 169
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 417,
    "Closing Rank": 587
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46,
    "Closing Rank": 80
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 169,
    "Closing Rank": 169
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25,
    "Closing Rank": 42
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 171,
    "Closing Rank": 171
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 382,
    "Closing Rank": 625
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1411,
    "Closing Rank": 1826
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34,
    "Closing Rank": 54
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 118,
    "Closing Rank": 156
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 478,
    "Closing Rank": 554
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27,
    "Closing Rank": 27
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 237,
    "Closing Rank": 422
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 966,
    "Closing Rank": 1549
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18,
    "Closing Rank": 18
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56,
    "Closing Rank": 230
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 240,
    "Closing Rank": 547
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24,
    "Closing Rank": 95
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 190,
    "Closing Rank": 205
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 646,
    "Closing Rank": 810
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2019,
    "Closing Rank": 2462
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70,
    "Closing Rank": 70
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 162,
    "Closing Rank": 162
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 162,
    "Closing Rank": 214
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 700,
    "Closing Rank": 700
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 428,
    "Closing Rank": 510
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1595,
    "Closing Rank": 1837
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28,
    "Closing Rank": 28
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 249,
    "Closing Rank": 288
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 739,
    "Closing Rank": 829
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 99,
    "Closing Rank": 159
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (Power and Automation) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 345,
    "Closing Rank": 345
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1898,
    "Closing Rank": 2707
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5632,
    "Closing Rank": 6523
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 503,
    "Closing Rank": 565
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1137,
    "Closing Rank": 1137
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1362,
    "Closing Rank": 1957
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4393,
    "Closing Rank": 5132
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 875,
    "Closing Rank": 1092
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2231,
    "Closing Rank": 2231
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 586,
    "Closing Rank": 645
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1481,
    "Closing Rank": 1481
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 890,
    "Closing Rank": 1194
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2128,
    "Closing Rank": 3381
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 260,
    "Closing Rank": 307
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1000,
    "Closing Rank": 1000
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 190,
    "Closing Rank": 640
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1854,
    "Closing Rank": 2151
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43,
    "Closing Rank": 43
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 256,
    "Closing Rank": 407
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 840,
    "Closing Rank": 840
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 214,
    "Closing Rank": 245
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 562,
    "Closing Rank": 562
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1845,
    "Closing Rank": 2560
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4087,
    "Closing Rank": 7030
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 600,
    "Closing Rank": 715
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2137,
    "Closing Rank": 2137
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1411,
    "Closing Rank": 1917
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3523,
    "Closing Rank": 4329
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 749,
    "Closing Rank": 1117
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2413,
    "Closing Rank": 2766
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 535,
    "Closing Rank": 746
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1016,
    "Closing Rank": 1016
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2422,
    "Closing Rank": 3797
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8280,
    "Closing Rank": 9743
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 598,
    "Closing Rank": 928
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2233,
    "Closing Rank": 2233
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2190,
    "Closing Rank": 2504
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5821,
    "Closing Rank": 6222
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1345,
    "Closing Rank": 1408
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2545,
    "Closing Rank": 2545
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 797,
    "Closing Rank": 813
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1341,
    "Closing Rank": 1341
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 118,
    "Closing Rank": 332
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 824,
    "Closing Rank": 1240
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25,
    "Closing Rank": 25
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 90,
    "Closing Rank": 90
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38,
    "Closing Rank": 74
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 374,
    "Closing Rank": 417
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12,
    "Closing Rank": 12
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93,
    "Closing Rank": 218
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 589,
    "Closing Rank": 757
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11,
    "Closing Rank": 11
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85,
    "Closing Rank": 164
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 214,
    "Closing Rank": 470
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3,
    "Closing Rank": 3
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50,
    "Closing Rank": 97
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55,
    "Closing Rank": 55
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 339,
    "Closing Rank": 417
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1433,
    "Closing Rank": 1644
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 78,
    "Closing Rank": 84
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 645,
    "Closing Rank": 645
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 250,
    "Closing Rank": 298
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 779,
    "Closing Rank": 1071
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 165,
    "Closing Rank": 185
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 667,
    "Closing Rank": 667
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 117,
    "Closing Rank": 170
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1225,
    "Closing Rank": 1782
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3748,
    "Closing Rank": 5487
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35,
    "Closing Rank": 35
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 316,
    "Closing Rank": 420
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 985,
    "Closing Rank": 1114
  },

  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 597,
    "Closing Rank": 1010
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2267,
    "Closing Rank": 3475
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41,
    "Closing Rank": 41
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 116,
    "Closing Rank": 500
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1140,
    "Closing Rank": 1329
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 208,
    "Closing Rank": 310
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 408,
    "Closing Rank": 408
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2452,
    "Closing Rank": 3089
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7335,
    "Closing Rank": 9018
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 537,
    "Closing Rank": 722
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1596,
    "Closing Rank": 1734
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 879,
    "Closing Rank": 2191
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5326,
    "Closing Rank": 6191
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 825,
    "Closing Rank": 1335
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1875,
    "Closing Rank": 2391
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 688,
    "Closing Rank": 810
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1159,
    "Closing Rank": 1159
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4352,
    "Closing Rank": 6102
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9820,
    "Closing Rank": 12551
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 956,
    "Closing Rank": 1215
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2604,
    "Closing Rank": 2605
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2665,
    "Closing Rank": 3185
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5730,
    "Closing Rank": 7737
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1579,
    "Closing Rank": 1780
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2677,
    "Closing Rank": 2889
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 836,
    "Closing Rank": 991
  },
  {
    "Institute": "Indian Institute of Technology Delhi",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1386,
    "Closing Rank": 1538
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7109,
    "Closing Rank": 7913
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15679,
    "Closing Rank": 16378
  },

  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1326,
    "Closing Rank": 1402
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2385,
    "Closing Rank": 2385
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3015,
    "Closing Rank": 3568
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6714,
    "Closing Rank": 6965
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1513,
    "Closing Rank": 1913
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2786,
    "Closing Rank": 2786
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 848,
    "Closing Rank": 922
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1642,
    "Closing Rank": 1642
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8296,
    "Closing Rank": 9773
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16460,
    "Closing Rank": 18217
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1299,
    "Closing Rank": 1474
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2775,
    "Closing Rank": 2775
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3390,
    "Closing Rank": 3974
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6590,
    "Closing Rank": 7849
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1600,
    "Closing Rank": 1881
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3425,
    "Closing Rank": 3731
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 566,
    "Closing Rank": 631
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1363,
    "Closing Rank": 1363
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 823,
    "Closing Rank": 1389
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3499,
    "Closing Rank": 4698
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47,
    "Closing Rank": 57
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 163,
    "Closing Rank": 218
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 716,
    "Closing Rank": 749
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14,
    "Closing Rank": 22
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 301,
    "Closing Rank": 649
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1053,
    "Closing Rank": 1468
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16,
    "Closing Rank": 16
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 245,
    "Closing Rank": 329
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 604,
    "Closing Rank": 728
  },

  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 113,
    "Closing Rank": 168
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 154,
    "Closing Rank": 154
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2359,
    "Closing Rank": 3818
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5167,
    "Closing Rank": 8297
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "53P",
    "Closing Rank": "270P"
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 451,
    "Closing Rank": 744
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1460,
    "Closing Rank": 1579
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "38P",
    "Closing Rank": "38P"
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1193,
    "Closing Rank": 1648
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2688,
    "Closing Rank": 4056
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 660,
    "Closing Rank": 997
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1470,
    "Closing Rank": 1704
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 350,
    "Closing Rank": 467
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 656,
    "Closing Rank": 656
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5765,
    "Closing Rank": 7612
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13790,
    "Closing Rank": 15115
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1302,
    "Closing Rank": 1511
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3128,
    "Closing Rank": 3618
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8144,
    "Closing Rank": 8144
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2066,
    "Closing Rank": 2104
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4096,
    "Closing Rank": 4096
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1173,
    "Closing Rank": 1173
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1591,
    "Closing Rank": 2180
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4905,
    "Closing Rank": 5064
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 83,
    "Closing Rank": 83
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 232,
    "Closing Rank": 261
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 901,
    "Closing Rank": 901
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 635,
    "Closing Rank": 1064
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1807,
    "Closing Rank": 2373
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "121P",
    "Closing Rank": "121P"
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 575,
    "Closing Rank": 719
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1041,
    "Closing Rank": 1041
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 260,
    "Closing Rank": 287
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 563,
    "Closing Rank": 563
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4946,
    "Closing Rank": 6812
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12137,
    "Closing Rank": 14123
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 824,
    "Closing Rank": 1173
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2103,
    "Closing Rank": 2509
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2198,
    "Closing Rank": 2993
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5542,
    "Closing Rank": 6402
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1223,
    "Closing Rank": 1702
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3160,
    "Closing Rank": 3569
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 573,
    "Closing Rank": 747
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1636,
    "Closing Rank": 1636
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8299,
    "Closing Rank": 10894
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18413,
    "Closing Rank": 19770
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1577,
    "Closing Rank": 1821
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2989,
    "Closing Rank": 2989
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3582,
    "Closing Rank": 4524
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8322,
    "Closing Rank": 8653
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2163,
    "Closing Rank": 2597
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3223,
    "Closing Rank": 4011
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1175,
    "Closing Rank": 1225
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Metallurgical Engineering and Materials Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1734,
    "Closing Rank": 1734
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5666,
    "Closing Rank": 6640
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10683,
    "Closing Rank": 10970
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1077,
    "Closing Rank": 1307
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1916,
    "Closing Rank": 3159
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5795,
    "Closing Rank": 5795
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1425,
    "Closing Rank": 1668
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1731,
    "Closing Rank": 1731
  },
  {
    "Institute": "Indian Institute of Technology Indore",
    "Academic Program Name": "Space Sciences and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 719,
    "Closing Rank": 719
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2716,
    "Closing Rank": 4745
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10505,
    "Closing Rank": 11444
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "269P",
    "Closing Rank": "269P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 628,
    "Closing Rank": 989
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2021,
    "Closing Rank": 2021
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1741,
    "Closing Rank": 2345
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4427,
    "Closing Rank": 5661
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 502,
    "Closing Rank": 1302
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2141,
    "Closing Rank": 2606
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 557,
    "Closing Rank": 799
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1219,
    "Closing Rank": 1219
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5515,
    "Closing Rank": 10770
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15669,
    "Closing Rank": 19903
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1529,
    "Closing Rank": 1744
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3247,
    "Closing Rank": 3462
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3191,
    "Closing Rank": 4488
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8281,
    "Closing Rank": 8557
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2067,
    "Closing Rank": 2523
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3377,
    "Closing Rank": 4242
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1053,
    "Closing Rank": 1129
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Agricultural and Food Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "343P",
    "Closing Rank": "343P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10069,
    "Closing Rank": 12893
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20374,
    "Closing Rank": 22024
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1913,
    "Closing Rank": 2100
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4291,
    "Closing Rank": 4291
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4616,
    "Closing Rank": 5493
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2982,
    "Closing Rank": 3092
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4560,
    "Closing Rank": 4575
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1387,
    "Closing Rank": 1462
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Applied Geology (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "768P",
    "Closing Rank": "768P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16634,
    "Closing Rank": 20427
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20942,
    "Closing Rank": 24653
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3043,
    "Closing Rank": 3311
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4976,
    "Closing Rank": 4976
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6513,
    "Closing Rank": 7772
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3797,
    "Closing Rank": 4204
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4054,
    "Closing Rank": 4054
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1094,
    "Closing Rank": 1755
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "1656P",
    "Closing Rank": "1656P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 467,
    "Closing Rank": 898
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2604,
    "Closing Rank": 2630
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 196,
    "Closing Rank": "104P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 120,
    "Closing Rank": 138
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 355,
    "Closing Rank": 355
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 308,
    "Closing Rank": 412
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 924,
    "Closing Rank": 1109
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10,
    "Closing Rank": 10
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 166,
    "Closing Rank": 243
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 780,
    "Closing Rank": 782
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 123,
    "Closing Rank": 135
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 256,
    "Closing Rank": 256
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5505,
    "Closing Rank": 7972
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14147,
    "Closing Rank": 15493
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1371,
    "Closing Rank": 1415
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3189,
    "Closing Rank": 3189
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3326,
    "Closing Rank": 3797
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6514,
    "Closing Rank": 7866
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2034,
    "Closing Rank": 2294
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1404,
    "Closing Rank": 2475
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 806,
    "Closing Rank": 1002
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1731,
    "Closing Rank": 1731
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3905,
    "Closing Rank": 4848
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8646,
    "Closing Rank": 11646
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "247P",
    "Closing Rank": "247P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 710,
    "Closing Rank": 911
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1858,
    "Closing Rank": 2117
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1779,
    "Closing Rank": 2388
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4348,
    "Closing Rank": 5232
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 497,
    "Closing Rank": 1297
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1292,
    "Closing Rank": 2540
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 683,
    "Closing Rank": 740
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 375,
    "Closing Rank": 938
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7869,
    "Closing Rank": 12488
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18426,
    "Closing Rank": 19077
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1742,
    "Closing Rank": 1934
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4190,
    "Closing Rank": 4190
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4714,
    "Closing Rank": 5375
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8798,
    "Closing Rank": 9099
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1740,
    "Closing Rank": 3023
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3919,
    "Closing Rank": 3919
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1413,
    "Closing Rank": 1496
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "576P",
    "Closing Rank": "576P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4902,
    "Closing Rank": 6992
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12769,
    "Closing Rank": 15388
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 149,
    "Closing Rank": 149
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1032,
    "Closing Rank": 1214
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2357,
    "Closing Rank": 2478
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2031,
    "Closing Rank": 2991
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5456,
    "Closing Rank": 6337
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1232,
    "Closing Rank": 1578
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1170,
    "Closing Rank": 1474
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 405,
    "Closing Rank": 541
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 960,
    "Closing Rank": 1158
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 226,
    "Closing Rank": 415
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1172,
    "Closing Rank": 1661
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20,
    "Closing Rank": 22
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 66,
    "Closing Rank": 66
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53,
    "Closing Rank": 67
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 171,
    "Closing Rank": 306
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7,
    "Closing Rank": 9
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 91,
    "Closing Rank": 209
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 396,
    "Closing Rank": 601
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7,
    "Closing Rank": 7
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17,
    "Closing Rank": 137
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 151,
    "Closing Rank": 414
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6,
    "Closing Rank": 6
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33,
    "Closing Rank": 67
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 183,
    "Closing Rank": 206
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "5P",
    "Closing Rank": "5P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3176,
    "Closing Rank": 5263
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6763,
    "Closing Rank": 10746
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "315P",
    "Closing Rank": "315P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 698,
    "Closing Rank": 986
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1971,
    "Closing Rank": 1971
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1941,
    "Closing Rank": 2736
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5105,
    "Closing Rank": 7383
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1632,
    "Closing Rank": 2027
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3690,
    "Closing Rank": 3724
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 637,
    "Closing Rank": 818
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1444,
    "Closing Rank": 1444
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1460,
    "Closing Rank": 2016
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4312,
    "Closing Rank": 5599
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 112,
    "Closing Rank": "19P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 268,
    "Closing Rank": 349
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 847,
    "Closing Rank": 974
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 719,
    "Closing Rank": 944
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1657,
    "Closing Rank": 2057
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "29P",
    "Closing Rank": "29P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 415,
    "Closing Rank": 544
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1244,
    "Closing Rank": 1307
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 225,
    "Closing Rank": 257
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 551,
    "Closing Rank": 618
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 516,
    "Closing Rank": 1448
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1815,
    "Closing Rank": 3816
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21,
    "Closing Rank": 72
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "297P",
    "Closing Rank": "297P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 185,
    "Closing Rank": 228
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 519,
    "Closing Rank": 802
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30,
    "Closing Rank": 30
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 234,
    "Closing Rank": 687
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 977,
    "Closing Rank": 1672
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32,
    "Closing Rank": "25P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 157,
    "Closing Rank": 390
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 664,
    "Closing Rank": 1135
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9,
    "Closing Rank": 9
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 110,
    "Closing Rank": 219
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Electronics and Electrical Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 372,
    "Closing Rank": 448
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9996,
    "Closing Rank": 12413
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19316,
    "Closing Rank": 20680
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1877,
    "Closing Rank": 2058
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4289,
    "Closing Rank": 4289
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4299,
    "Closing Rank": 5104
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9049,
    "Closing Rank": 9049
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2708,
    "Closing Rank": 3004
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4168,
    "Closing Rank": 4168
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1483,
    "Closing Rank": 1497
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Exploration Geophysics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "457P",
    "Closing Rank": "457P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2915,
    "Closing Rank": 4364
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7214,
    "Closing Rank": 10726
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 155,
    "Closing Rank": 155
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 518,
    "Closing Rank": 772
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1665,
    "Closing Rank": 1665
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1112,
    "Closing Rank": 2307
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3669,
    "Closing Rank": 5032
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1171,
    "Closing Rank": 1653
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2378,
    "Closing Rank": 2552
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 567,
    "Closing Rank": 840
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Industrial and Systems Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1200,
    "Closing Rank": 1200
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2022,
    "Closing Rank": 2481
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6373,
    "Closing Rank": 6582
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 167,
    "Closing Rank": 167
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 403,
    "Closing Rank": 510
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1663,
    "Closing Rank": 1663
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1103,
    "Closing Rank": 1302
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3385,
    "Closing Rank": 3448
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 609,
    "Closing Rank": 895
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1880,
    "Closing Rank": 1880
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 505,
    "Closing Rank": 583
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1540,
    "Closing Rank": 1540
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4649,
    "Closing Rank": 6067
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12350,
    "Closing Rank": 12933
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 934,
    "Closing Rank": 1328
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2407,
    "Closing Rank": 2407
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2322,
    "Closing Rank": 3292
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5944,
    "Closing Rank": 6497
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1820,
    "Closing Rank": 2076
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3147,
    "Closing Rank": 3665
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 952,
    "Closing Rank": 1034
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Manufacturing Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1677,
    "Closing Rank": 1677
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 971,
    "Closing Rank": 1329
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3514,
    "Closing Rank": 3957
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53,
    "Closing Rank": 53
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 151,
    "Closing Rank": 254
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 757,
    "Closing Rank": 757
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 504,
    "Closing Rank": 740
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1478,
    "Closing Rank": 1883
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "4P",
    "Closing Rank": "4P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 229,
    "Closing Rank": 509
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1149,
    "Closing Rank": 1405
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 161,
    "Closing Rank": 267
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 415,
    "Closing Rank": 415
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2160,
    "Closing Rank": 3613
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6783,
    "Closing Rank": 10732
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "188P",
    "Closing Rank": "356P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 584,
    "Closing Rank": 703
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1435,
    "Closing Rank": 1864
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1137,
    "Closing Rank": 1721
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3638,
    "Closing Rank": 4493
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 607,
    "Closing Rank": 966
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1567,
    "Closing Rank": 2369
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 381,
    "Closing Rank": 540
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 708,
    "Closing Rank": 888
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5472,
    "Closing Rank": 7104
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12914,
    "Closing Rank": 15082
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "388P",
    "Closing Rank": "388P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1325,
    "Closing Rank": 1359
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2697,
    "Closing Rank": 2801
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2394,
    "Closing Rank": 3378
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6844,
    "Closing Rank": 7423
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1523,
    "Closing Rank": 2109
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2762,
    "Closing Rank": 2994
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 839,
    "Closing Rank": 930
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1622,
    "Closing Rank": 1622
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7431,
    "Closing Rank": 10218
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16126,
    "Closing Rank": 19143
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1420,
    "Closing Rank": 1572
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3206,
    "Closing Rank": 3429
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3031,
    "Closing Rank": 4015
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8100,
    "Closing Rank": 8519
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1678,
    "Closing Rank": 2285
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3003,
    "Closing Rank": 4060
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 933,
    "Closing Rank": 1085
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "69P",
    "Closing Rank": "184P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7876,
    "Closing Rank": 9098
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16598,
    "Closing Rank": 17790
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1441,
    "Closing Rank": 1695
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3390,
    "Closing Rank": 3741
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3313,
    "Closing Rank": 4208
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7362,
    "Closing Rank": 8540
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2174,
    "Closing Rank": 2357
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3618,
    "Closing Rank": 3976
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1128,
    "Closing Rank": 1192
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Ocean Engineering and Naval Architecture (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "294P",
    "Closing Rank": "294P"
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2957,
    "Closing Rank": 9672
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14639,
    "Closing Rank": 17600
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1478,
    "Closing Rank": 1701
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3668,
    "Closing Rank": 3668
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3604,
    "Closing Rank": 4608
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7850,
    "Closing Rank": 8776
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2394,
    "Closing Rank": 2664
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3740,
    "Closing Rank": 3740
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1126,
    "Closing Rank": 1336
  },
  {
    "Institute": "Indian Institute of Technology Kharagpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "641P",
    "Closing Rank": "641P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 685,
    "Closing Rank": 875
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2214,
    "Closing Rank": 2840
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 114,
    "Closing Rank": 127
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 372,
    "Closing Rank": 372
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23,
    "Closing Rank": 23
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 295,
    "Closing Rank": 399
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 562,
    "Closing Rank": 798
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19,
    "Closing Rank": 19
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20,
    "Closing Rank": 251
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 334,
    "Closing Rank": 334
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 88,
    "Closing Rank": 89
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6579,
    "Closing Rank": 8493
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15656,
    "Closing Rank": 15954
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1502,
    "Closing Rank": 1636
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3613,
    "Closing Rank": 4087
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4554,
    "Closing Rank": 5782
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1969,
    "Closing Rank": 2563
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3820,
    "Closing Rank": 3820
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1040,
    "Closing Rank": 1197
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6015,
    "Closing Rank": 8524
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14833,
    "Closing Rank": 15214
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1551,
    "Closing Rank": 1632
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2904,
    "Closing Rank": 2904
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3056,
    "Closing Rank": 4058
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6673,
    "Closing Rank": 6673
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2300,
    "Closing Rank": 2324
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3479,
    "Closing Rank": 3479
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Biotechnology and Bioinformatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1074,
    "Closing Rank": 1099
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4229,
    "Closing Rank": 5757
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10591,
    "Closing Rank": 11897
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "200P",
    "Closing Rank": "361P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 889,
    "Closing Rank": 1021
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2086,
    "Closing Rank": 2086
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2152,
    "Closing Rank": 2755
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4306,
    "Closing Rank": 4578
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1331,
    "Closing Rank": 1588
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2215,
    "Closing Rank": 2215
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 646,
    "Closing Rank": 783
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1328,
    "Closing Rank": 1328
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7053,
    "Closing Rank": 7898
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12882,
    "Closing Rank": 16049
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "75P",
    "Closing Rank": "75P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1044,
    "Closing Rank": 1331
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2260,
    "Closing Rank": 2260
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2830,
    "Closing Rank": 3334
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5762,
    "Closing Rank": 6223
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1229,
    "Closing Rank": 1789
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1301,
    "Closing Rank": 2852
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 640,
    "Closing Rank": 697
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1302,
    "Closing Rank": 1302
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1046,
    "Closing Rank": 1790
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4462,
    "Closing Rank": 5158
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 272,
    "Closing Rank": 272
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "26P",
    "Closing Rank": "26P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 638,
    "Closing Rank": 991
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1547,
    "Closing Rank": 1547
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 529,
    "Closing Rank": 657
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 878,
    "Closing Rank": 878
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 305,
    "Closing Rank": 441
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computational Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 348,
    "Closing Rank": 348
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 431,
    "Closing Rank": 656
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1709,
    "Closing Rank": 1809
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32,
    "Closing Rank": 32
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48,
    "Closing Rank": 48
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 91,
    "Closing Rank": 112
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 356,
    "Closing Rank": 356
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 123,
    "Closing Rank": 302
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 605,
    "Closing Rank": 865
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93,
    "Closing Rank": 182
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 363,
    "Closing Rank": 386
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5,
    "Closing Rank": 5
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53,
    "Closing Rank": 82
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 107,
    "Closing Rank": 107
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1022,
    "Closing Rank": 2105
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3084,
    "Closing Rank": 4344
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 133,
    "Closing Rank": 133
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 290,
    "Closing Rank": 387
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 723,
    "Closing Rank": 723
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 606,
    "Closing Rank": 1021
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1260,
    "Closing Rank": 2009
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58,
    "Closing Rank": 58
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 464,
    "Closing Rank": 614
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 873,
    "Closing Rank": 1218
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 276,
    "Closing Rank": 292
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 545,
    "Closing Rank": 545
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1570,
    "Closing Rank": 2147
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4056,
    "Closing Rank": 4434
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 321,
    "Closing Rank": 350
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 868,
    "Closing Rank": 868
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 557,
    "Closing Rank": 920
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2076,
    "Closing Rank": 2076
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 401,
    "Closing Rank": 463
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 528,
    "Closing Rank": 528
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Electrical Engineering (IC Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 277,
    "Closing Rank": 373
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3442,
    "Closing Rank": 4932
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11250,
    "Closing Rank": 12055
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "91P",
    "Closing Rank": "91P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1004,
    "Closing Rank": 1180
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1997,
    "Closing Rank": 1997
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2435,
    "Closing Rank": 2856
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3086,
    "Closing Rank": 6654
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1690,
    "Closing Rank": 1818
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3009,
    "Closing Rank": 3009
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1024,
    "Closing Rank": 1066
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1822,
    "Closing Rank": 3835
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4470,
    "Closing Rank": 5748
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 536,
    "Closing Rank": 654
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1830,
    "Closing Rank": 1830
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1166,
    "Closing Rank": 1984
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2543,
    "Closing Rank": 4046
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 863,
    "Closing Rank": 1125
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1512,
    "Closing Rank": 1512
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 498,
    "Closing Rank": 592
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Engineering Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1028,
    "Closing Rank": 1028
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8178,
    "Closing Rank": 9551
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15890,
    "Closing Rank": 16446
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1755,
    "Closing Rank": 1799
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3018,
    "Closing Rank": 3018
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2923,
    "Closing Rank": 4097
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7981,
    "Closing Rank": 7981
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2199,
    "Closing Rank": 2370
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3889,
    "Closing Rank": 3889
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1177,
    "Closing Rank": 1240
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5105,
    "Closing Rank": 8197
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13015,
    "Closing Rank": 15999
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "186P",
    "Closing Rank": "186P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1344,
    "Closing Rank": 1422
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2499,
    "Closing Rank": 2499
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2847,
    "Closing Rank": 3481
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7549,
    "Closing Rank": 7564
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1841,
    "Closing Rank": 2196
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3360,
    "Closing Rank": 3360
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 817,
    "Closing Rank": 945
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 660,
    "Closing Rank": 982
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2885,
    "Closing Rank": 3625
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 147,
    "Closing Rank": 158
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 502,
    "Closing Rank": 502
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 419,
    "Closing Rank": 480
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 918,
    "Closing Rank": 918
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22,
    "Closing Rank": 22
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 238,
    "Closing Rank": 301
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 519,
    "Closing Rank": 519
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 178,
    "Closing Rank": 178
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3751,
    "Closing Rank": 4469
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7527,
    "Closing Rank": 10758
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "142P",
    "Closing Rank": "142P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 689,
    "Closing Rank": 786
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1868,
    "Closing Rank": 1868
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1765,
    "Closing Rank": 2121
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2745,
    "Closing Rank": 4746
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "132P",
    "Closing Rank": "132P"
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 836,
    "Closing Rank": 1116
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2205,
    "Closing Rank": 2270
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 387,
    "Closing Rank": 570
  },
  {
    "Institute": "Indian Institute of Technology Hyderabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 881,
    "Closing Rank": 881
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3048,
    "Closing Rank": 4182
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7749,
    "Closing Rank": 8194
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "111P",
    "Closing Rank": "111P"
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 509,
    "Closing Rank": 555
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1537,
    "Closing Rank": 1587
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1341,
    "Closing Rank": 1722
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4169,
    "Closing Rank": 4502
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 797,
    "Closing Rank": 1081
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1528,
    "Closing Rank": 2082
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 450,
    "Closing Rank": 589
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 863,
    "Closing Rank": 863
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12293,
    "Closing Rank": 14666
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18682,
    "Closing Rank": 20850
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2098,
    "Closing Rank": 2295
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3966,
    "Closing Rank": 4419
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4754,
    "Closing Rank": 5752
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9141,
    "Closing Rank": 9141
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3063,
    "Closing Rank": 3167
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4617,
    "Closing Rank": 4686
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1250,
    "Closing Rank": 1452
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Bio Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "1049P",
    "Closing Rank": "1049P"
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8295,
    "Closing Rank": 11036
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15996,
    "Closing Rank": 19661
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1625,
    "Closing Rank": 1782
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3246,
    "Closing Rank": 3246
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4259,
    "Closing Rank": 4613
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8291,
    "Closing Rank": 8539
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2292,
    "Closing Rank": 2688
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4029,
    "Closing Rank": 4029
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 995,
    "Closing Rank": 1120
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "707P",
    "Closing Rank": "707P"
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12654,
    "Closing Rank": 16381
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22452,
    "Closing Rank": 23748
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2437,
    "Closing Rank": 2491
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5713,
    "Closing Rank": 6013
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3184,
    "Closing Rank": 3228
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4853,
    "Closing Rank": 4853
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1553,
    "Closing Rank": 1553
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Chemistry with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "1059P",
    "Closing Rank": "1059P"
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11244,
    "Closing Rank": 13316
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19028,
    "Closing Rank": 21003
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1905,
    "Closing Rank": 2031
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4153,
    "Closing Rank": 4153
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4108,
    "Closing Rank": 4937
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9273,
    "Closing Rank": 9273
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2495,
    "Closing Rank": 2619
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4172,
    "Closing Rank": 4201
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 901,
    "Closing Rank": 980
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1799,
    "Closing Rank": 1799
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2062,
    "Closing Rank": 3061
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5655,
    "Closing Rank": 7457
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 76,
    "Closing Rank": 76
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 348,
    "Closing Rank": 416
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1117,
    "Closing Rank": 1150
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29,
    "Closing Rank": 29
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 735,
    "Closing Rank": 1404
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2023,
    "Closing Rank": 3361
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 503,
    "Closing Rank": 789
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1247,
    "Closing Rank": 1421
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 244,
    "Closing Rank": 396
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 327,
    "Closing Rank": 327
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4693,
    "Closing Rank": 6346
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9003,
    "Closing Rank": 11925
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "360P",
    "Closing Rank": "360P"
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 869,
    "Closing Rank": 1149
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1718,
    "Closing Rank": 1718
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2316,
    "Closing Rank": 2972
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6084,
    "Closing Rank": 6785
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1379,
    "Closing Rank": 1835
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2929,
    "Closing Rank": 3114
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 791,
    "Closing Rank": 875
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1505,
    "Closing Rank": 1560
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11381,
    "Closing Rank": 13339
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19921,
    "Closing Rank": 22782
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1954,
    "Closing Rank": 2211
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4299,
    "Closing Rank": 4299
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4948,
    "Closing Rank": 5688
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8892,
    "Closing Rank": 9279
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2790,
    "Closing Rank": 2950
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4235,
    "Closing Rank": 4235
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1346,
    "Closing Rank": 1419
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "1026P",
    "Closing Rank": "1026P"
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6984,
    "Closing Rank": 9488
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14797,
    "Closing Rank": 18249
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1355,
    "Closing Rank": 1616
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3141,
    "Closing Rank": 3315
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3320,
    "Closing Rank": 4105
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8042,
    "Closing Rank": 8755
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2006,
    "Closing Rank": 2330
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3733,
    "Closing Rank": 4132
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 968,
    "Closing Rank": 1071
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "198P",
    "Closing Rank": "332P"
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10714,
    "Closing Rank": 14641
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23402,
    "Closing Rank": 23673
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2395,
    "Closing Rank": 2420
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4654,
    "Closing Rank": 5792
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2817,
    "Closing Rank": 3182
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4697,
    "Closing Rank": 4697
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1510,
    "Closing Rank": 1510
  },
  {
    "Institute": "Indian Institute of Technology Jodhpur",
    "Academic Program Name": "Physics with Specialization (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "1032P",
    "Closing Rank": "1032P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2713,
    "Closing Rank": 3638
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6950,
    "Closing Rank": 10186
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "157P",
    "Closing Rank": "157P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "345P",
    "Closing Rank": "345P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 629,
    "Closing Rank": 875
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1917,
    "Closing Rank": 1917
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 863,
    "Closing Rank": 1980
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3457,
    "Closing Rank": 4326
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 705,
    "Closing Rank": 987
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1805,
    "Closing Rank": 2655
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 146,
    "Closing Rank": 681
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 768,
    "Closing Rank": 768
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5259,
    "Closing Rank": 6952
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10725,
    "Closing Rank": 12032
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1273,
    "Closing Rank": 1341
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2519,
    "Closing Rank": 2519
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2995,
    "Closing Rank": 3476
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6561,
    "Closing Rank": 7371
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1623,
    "Closing Rank": 2071
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3355,
    "Closing Rank": 3462
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 829,
    "Closing Rank": 1168
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Biological Sciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1790,
    "Closing Rank": 1790
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2227,
    "Closing Rank": 4015
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7129,
    "Closing Rank": 9528
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "29P",
    "Closing Rank": "199P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 543,
    "Closing Rank": 707
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1482,
    "Closing Rank": 1661
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1402,
    "Closing Rank": 2058
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3295,
    "Closing Rank": 4628
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 855,
    "Closing Rank": 1185
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1635,
    "Closing Rank": 2062
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 547,
    "Closing Rank": 684
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1043,
    "Closing Rank": 1062
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7317,
    "Closing Rank": 10498
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15079,
    "Closing Rank": 16196
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1472,
    "Closing Rank": 1650
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3744,
    "Closing Rank": 3744
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3173,
    "Closing Rank": 4766
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7860,
    "Closing Rank": 8578
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2460,
    "Closing Rank": 2667
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4596,
    "Closing Rank": 4614
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1348,
    "Closing Rank": 1434
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Chemistry (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "250P",
    "Closing Rank": "250P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4213,
    "Closing Rank": 5948
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4232,
    "Closing Rank": 13819
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "391P",
    "Closing Rank": "395P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 806,
    "Closing Rank": 1020
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2054,
    "Closing Rank": 2293
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2168,
    "Closing Rank": 2764
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5332,
    "Closing Rank": 6704
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 982,
    "Closing Rank": 1509
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2680,
    "Closing Rank": 2969
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 290,
    "Closing Rank": 482
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 668,
    "Closing Rank": 837
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 120,
    "Closing Rank": 252
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 781,
    "Closing Rank": 1117
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6,
    "Closing Rank": 11
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 88,
    "Closing Rank": 117
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28,
    "Closing Rank": 51
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 150,
    "Closing Rank": 294
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4,
    "Closing Rank": 4
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 61,
    "Closing Rank": 132
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 410,
    "Closing Rank": 538
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6,
    "Closing Rank": 6
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55,
    "Closing Rank": 96
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 133,
    "Closing Rank": 397
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4,
    "Closing Rank": 4
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8,
    "Closing Rank": 8
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30,
    "Closing Rank": 54
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 127,
    "Closing Rank": 141
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7210,
    "Closing Rank": 10058
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16346,
    "Closing Rank": 17618
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1534,
    "Closing Rank": 1627
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2942,
    "Closing Rank": 2942
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3667,
    "Closing Rank": 4562
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7778,
    "Closing Rank": 8477
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1636,
    "Closing Rank": 2567
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4511,
    "Closing Rank": 4511
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1280,
    "Closing Rank": 1369
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Earth Sciences (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "702P",
    "Closing Rank": "702P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2137,
    "Closing Rank": 3106
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5790,
    "Closing Rank": 6326
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 169,
    "Closing Rank": 169
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 569,
    "Closing Rank": 690
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1548,
    "Closing Rank": 1548
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1258,
    "Closing Rank": 2051
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3436,
    "Closing Rank": 4211
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 806,
    "Closing Rank": 1430
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2897,
    "Closing Rank": 3401
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 521,
    "Closing Rank": 725
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1645,
    "Closing Rank": 1645
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 743,
    "Closing Rank": 1290
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2616,
    "Closing Rank": 3166
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 107,
    "Closing Rank": 128
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "190P",
    "Closing Rank": "190P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 193,
    "Closing Rank": 259
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 702,
    "Closing Rank": 859
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "3P",
    "Closing Rank": "3P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 432,
    "Closing Rank": 743
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1397,
    "Closing Rank": 2426
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53,
    "Closing Rank": 61
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 281,
    "Closing Rank": 452
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 769,
    "Closing Rank": 1152
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 98,
    "Closing Rank": 203
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 414,
    "Closing Rank": 518
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4831,
    "Closing Rank": 6008
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11438,
    "Closing Rank": 13464
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 955,
    "Closing Rank": 1258
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2264,
    "Closing Rank": 2558
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2603,
    "Closing Rank": 3126
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6390,
    "Closing Rank": 7187
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1544,
    "Closing Rank": 1853
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3140,
    "Closing Rank": 3639
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 859,
    "Closing Rank": 1080
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1412,
    "Closing Rank": 1412
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 756,
    "Closing Rank": 990
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2288,
    "Closing Rank": 3445
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 100,
    "Closing Rank": 100
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "12P",
    "Closing Rank": "12P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 111,
    "Closing Rank": 209
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 607,
    "Closing Rank": 607
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "1P",
    "Closing Rank": "1P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 352,
    "Closing Rank": 673
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1295,
    "Closing Rank": 2234
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 212,
    "Closing Rank": 372
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1148,
    "Closing Rank": 1341
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 182,
    "Closing Rank": 343
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 632,
    "Closing Rank": 632
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1889,
    "Closing Rank": 2776
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6470,
    "Closing Rank": 8298
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 186,
    "Closing Rank": "34P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 386,
    "Closing Rank": 506
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1499,
    "Closing Rank": 1686
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1046,
    "Closing Rank": 1392
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3631,
    "Closing Rank": 4202
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "135P",
    "Closing Rank": "135P"
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 586,
    "Closing Rank": 834
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1541,
    "Closing Rank": 2026
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 426,
    "Closing Rank": 509
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 582,
    "Closing Rank": 776
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3377,
    "Closing Rank": 5508
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7470,
    "Closing Rank": 16084
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 95,
    "Closing Rank": 95
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1135,
    "Closing Rank": 1436
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2897,
    "Closing Rank": 2897
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2664,
    "Closing Rank": 3602
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6209,
    "Closing Rank": 7080
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1980,
    "Closing Rank": 2363
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3812,
    "Closing Rank": 3812
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1176,
    "Closing Rank": 1277
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Physics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1765,
    "Closing Rank": 1765
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 699,
    "Closing Rank": 1030
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1938,
    "Closing Rank": 3674
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 105,
    "Closing Rank": 105
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 160,
    "Closing Rank": 207
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 800,
    "Closing Rank": 800
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 486,
    "Closing Rank": 714
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1611,
    "Closing Rank": 1999
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 402,
    "Closing Rank": 534
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1035,
    "Closing Rank": 1035
  },
  {
    "Institute": "Indian Institute of Technology Kanpur",
    "Academic Program Name": "Statistics and Data Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 220,
    "Closing Rank": 224
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1469,
    "Closing Rank": 2815
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5475,
    "Closing Rank": 6845
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "119P",
    "Closing Rank": "119P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 760,
    "Closing Rank": 837
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1232,
    "Closing Rank": 1232
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 832,
    "Closing Rank": 1655
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1452,
    "Closing Rank": 3432
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "63P",
    "Closing Rank": "63P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 299,
    "Closing Rank": 900
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1522,
    "Closing Rank": 1699
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 173,
    "Closing Rank": 478
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 661,
    "Closing Rank": 661
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2199,
    "Closing Rank": 2984
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2184,
    "Closing Rank": 2184
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 883,
    "Closing Rank": 883
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1395,
    "Closing Rank": 1455
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3953,
    "Closing Rank": 3953
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 995,
    "Closing Rank": 995
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Aerospace Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 503,
    "Closing Rank": 503
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 132,
    "Closing Rank": 419
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1079,
    "Closing Rank": 1379
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43,
    "Closing Rank": 43
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 80,
    "Closing Rank": 104
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 298,
    "Closing Rank": 298
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 137,
    "Closing Rank": 207
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 306,
    "Closing Rank": 503
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30,
    "Closing Rank": 30
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 119,
    "Closing Rank": 198
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 237,
    "Closing Rank": 237
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38,
    "Closing Rank": 83
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Artificial Intelligence and Data Analytics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 218,
    "Closing Rank": 218
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3135,
    "Closing Rank": 5755
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5326,
    "Closing Rank": 9871
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1142,
    "Closing Rank": 1384
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2205,
    "Closing Rank": 2205
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2456,
    "Closing Rank": 3364
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5526,
    "Closing Rank": 6929
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1727,
    "Closing Rank": 2266
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2942,
    "Closing Rank": 2942
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1057,
    "Closing Rank": 1090
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1566,
    "Closing Rank": 1566
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8849,
    "Closing Rank": 10682
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12473,
    "Closing Rank": 14739
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1561,
    "Closing Rank": 1743
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2996,
    "Closing Rank": 2996
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4045,
    "Closing Rank": 4682
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6950,
    "Closing Rank": 7692
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2517,
    "Closing Rank": 2778
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4048,
    "Closing Rank": 4048
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1325,
    "Closing Rank": 1359
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Biological Science (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1691,
    "Closing Rank": 1691
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2024,
    "Closing Rank": 3972
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6727,
    "Closing Rank": 9068
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "274P",
    "Closing Rank": "383P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 643,
    "Closing Rank": 784
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1306,
    "Closing Rank": 1843
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 820,
    "Closing Rank": 2042
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2636,
    "Closing Rank": 3772
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 724,
    "Closing Rank": 1237
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1434,
    "Closing Rank": 2010
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 291,
    "Closing Rank": 617
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 851,
    "Closing Rank": 895
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3305,
    "Closing Rank": 5688
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7794,
    "Closing Rank": 12586
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 147,
    "Closing Rank": "282P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 951,
    "Closing Rank": 1111
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1884,
    "Closing Rank": 1945
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1586,
    "Closing Rank": 2592
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3477,
    "Closing Rank": 5366
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1044,
    "Closing Rank": 1324
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1802,
    "Closing Rank": 2445
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 401,
    "Closing Rank": 522
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 648,
    "Closing Rank": 652
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 76,
    "Closing Rank": 159
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 558,
    "Closing Rank": 757
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4,
    "Closing Rank": 4
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17,
    "Closing Rank": 42
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 109,
    "Closing Rank": 129
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18,
    "Closing Rank": 82
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 288,
    "Closing Rank": 344
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4,
    "Closing Rank": 4
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36,
    "Closing Rank": 72
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12,
    "Closing Rank": 106
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "8P",
    "Closing Rank": "8P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5,
    "Closing Rank": 29
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 68,
    "Closing Rank": 68
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 299,
    "Closing Rank": 838
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1304,
    "Closing Rank": 2677
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 87,
    "Closing Rank": 94
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "69P",
    "Closing Rank": "69P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 105,
    "Closing Rank": 178
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 468,
    "Closing Rank": 539
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "14P",
    "Closing Rank": "14P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 122,
    "Closing Rank": 407
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 641,
    "Closing Rank": 1063
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48,
    "Closing Rank": 51
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 114,
    "Closing Rank": 378
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 121,
    "Closing Rank": 563
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39,
    "Closing Rank": 149
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 297,
    "Closing Rank": 336
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1828,
    "Closing Rank": 3656
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6825,
    "Closing Rank": 8939
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "359P",
    "Closing Rank": "359P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 790,
    "Closing Rank": 1235
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2744,
    "Closing Rank": 2744
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 933,
    "Closing Rank": 1772
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2860,
    "Closing Rank": 3896
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 778,
    "Closing Rank": 1571
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2110,
    "Closing Rank": 2280
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 998,
    "Closing Rank": 1133
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 979,
    "Closing Rank": 979
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 931,
    "Closing Rank": 1858
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3376,
    "Closing Rank": 4636
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "370P",
    "Closing Rank": "370P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 342,
    "Closing Rank": 803
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2082,
    "Closing Rank": 2082
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 385,
    "Closing Rank": 1192
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2319,
    "Closing Rank": 3046
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1003,
    "Closing Rank": 1326
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 685,
    "Closing Rank": 685
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 908,
    "Closing Rank": 1022
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1429,
    "Closing Rank": 1429
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 458,
    "Closing Rank": 2310
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2868,
    "Closing Rank": 6678
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 120,
    "Closing Rank": "156P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 359,
    "Closing Rank": 577
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 845,
    "Closing Rank": 1385
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 509,
    "Closing Rank": 1199
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2292,
    "Closing Rank": 3442
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "93P",
    "Closing Rank": "93P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 375,
    "Closing Rank": 792
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 647,
    "Closing Rank": 1467
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 150,
    "Closing Rank": 369
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 458,
    "Closing Rank": 715
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3770,
    "Closing Rank": 4748
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9735,
    "Closing Rank": 10699
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "340P",
    "Closing Rank": "340P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1019,
    "Closing Rank": 1263
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2109,
    "Closing Rank": 2109
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2010,
    "Closing Rank": 2463
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4498,
    "Closing Rank": 5297
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1328,
    "Closing Rank": 1679
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1564,
    "Closing Rank": 2722
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 629,
    "Closing Rank": 784
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1339,
    "Closing Rank": 1339
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4274,
    "Closing Rank": 7404
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12471,
    "Closing Rank": 13618
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "272P",
    "Closing Rank": "272P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1084,
    "Closing Rank": 1568
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2710,
    "Closing Rank": 2807
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2615,
    "Closing Rank": 3210
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5654,
    "Closing Rank": 6778
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "133P",
    "Closing Rank": "133P"
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1355,
    "Closing Rank": 2224
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2720,
    "Closing Rank": 3007
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 770,
    "Closing Rank": 1056
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Naval Architecture and Ocean Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 139,
    "Closing Rank": 139
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1292,
    "Closing Rank": 2742
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7069,
    "Closing Rank": 7069
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 942,
    "Closing Rank": 942
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1804,
    "Closing Rank": 2369
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5459,
    "Closing Rank": 5459
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1784,
    "Closing Rank": 1784
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3152,
    "Closing Rank": 3152
  },
  {
    "Institute": "Indian Institute of Technology Madras",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1149,
    "Closing Rank": 1149
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1804,
    "Closing Rank": 2309
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4703,
    "Closing Rank": 4824
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 108,
    "Closing Rank": 108
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 305,
    "Closing Rank": 361
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1182,
    "Closing Rank": 1182
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 908,
    "Closing Rank": 1115
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2317,
    "Closing Rank": 2534
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 512,
    "Closing Rank": 649
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 989,
    "Closing Rank": 989
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 125,
    "Closing Rank": 249
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6353,
    "Closing Rank": 8269
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14225,
    "Closing Rank": 15791
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1281,
    "Closing Rank": 1553
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3039,
    "Closing Rank": 3039
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3134,
    "Closing Rank": 3800
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6706,
    "Closing Rank": 7934
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1460,
    "Closing Rank": 2125
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3126,
    "Closing Rank": 3126
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1075,
    "Closing Rank": 1146
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "159P",
    "Closing Rank": "159P"
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8338,
    "Closing Rank": 10234
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16078,
    "Closing Rank": 17839
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1563,
    "Closing Rank": 1761
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2965,
    "Closing Rank": 2965
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3093,
    "Closing Rank": 4148
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8117,
    "Closing Rank": 8173
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2065,
    "Closing Rank": 2356
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3416,
    "Closing Rank": 3416
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 485,
    "Closing Rank": 651
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1199,
    "Closing Rank": 1199
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 811,
    "Closing Rank": 1708
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2427,
    "Closing Rank": 4592
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 71,
    "Closing Rank": 71
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 222,
    "Closing Rank": 264
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 770,
    "Closing Rank": 770
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 411,
    "Closing Rank": 750
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1602,
    "Closing Rank": 1714
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56,
    "Closing Rank": 56
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 259,
    "Closing Rank": 338
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 828,
    "Closing Rank": 828
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "21P",
    "Closing Rank": "21P"
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 155,
    "Closing Rank": 207
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 374,
    "Closing Rank": 374
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1836,
    "Closing Rank": 2234
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5172,
    "Closing Rank": 5172
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 394,
    "Closing Rank": 394
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1163,
    "Closing Rank": 1163
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 923,
    "Closing Rank": 1048
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2950,
    "Closing Rank": 2950
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "105P",
    "Closing Rank": "105P"
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 418,
    "Closing Rank": 490
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 367,
    "Closing Rank": 367
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 644,
    "Closing Rank": 644
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2835,
    "Closing Rank": 4055
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6395,
    "Closing Rank": 8494
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 767,
    "Closing Rank": 842
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1697,
    "Closing Rank": 1697
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1415,
    "Closing Rank": 1930
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4489,
    "Closing Rank": 4896
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1028,
    "Closing Rank": 1354
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1984,
    "Closing Rank": 1984
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 500,
    "Closing Rank": 534
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 955,
    "Closing Rank": 955
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3932,
    "Closing Rank": 4588
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8611,
    "Closing Rank": 8611
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 904,
    "Closing Rank": 904
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1791,
    "Closing Rank": 1791
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1806,
    "Closing Rank": 2274
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5783,
    "Closing Rank": 5783
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1468,
    "Closing Rank": 1633
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 710,
    "Closing Rank": 710
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1021,
    "Closing Rank": 1021
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2458,
    "Closing Rank": 4349
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7247,
    "Closing Rank": 7281
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 527,
    "Closing Rank": 747
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1282,
    "Closing Rank": 1282
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1347,
    "Closing Rank": 2660
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4751,
    "Closing Rank": 5545
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1015,
    "Closing Rank": 1558
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2918,
    "Closing Rank": 2918
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Integrated Circuit Design & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1014,
    "Closing Rank": 1069
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8632,
    "Closing Rank": 11298
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16409,
    "Closing Rank": 17233
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1825,
    "Closing Rank": 1891
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3708,
    "Closing Rank": 3708
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4192,
    "Closing Rank": 4653
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8171,
    "Closing Rank": 8171
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2374,
    "Closing Rank": 2741
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2821,
    "Closing Rank": 2821
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1227,
    "Closing Rank": 1269
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "806P",
    "Closing Rank": "806P"
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4859,
    "Closing Rank": 6344
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10210,
    "Closing Rank": 13488
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 953,
    "Closing Rank": 1217
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2705,
    "Closing Rank": 2705
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2646,
    "Closing Rank": 3075
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6809,
    "Closing Rank": 7095
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 666,
    "Closing Rank": 1725
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3892,
    "Closing Rank": 3892
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 759,
    "Closing Rank": 780
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1407,
    "Closing Rank": 1407
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6581,
    "Closing Rank": 7422
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13636,
    "Closing Rank": 14114
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1350,
    "Closing Rank": 1489
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2795,
    "Closing Rank": 2795
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3201,
    "Closing Rank": 3498
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7139,
    "Closing Rank": 7139
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1998,
    "Closing Rank": 2060
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3637,
    "Closing Rank": 3637
  },
  {
    "Institute": "Indian Institute of Technology Gandhinagar",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 944,
    "Closing Rank": 944
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3557,
    "Closing Rank": 4346
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6711,
    "Closing Rank": 9272
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 538,
    "Closing Rank": 611
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1322,
    "Closing Rank": 1322
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1209,
    "Closing Rank": 1661
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3196,
    "Closing Rank": 4132
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "44P",
    "Closing Rank": "44P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 773,
    "Closing Rank": 1012
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1056,
    "Closing Rank": 1056
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 469,
    "Closing Rank": 575
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1125,
    "Closing Rank": 1125
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Geotechnical Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14906,
    "Closing Rank": 15274
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Geotechnical Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24612,
    "Closing Rank": 24612
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Geotechnical Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2382,
    "Closing Rank": 2382
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Geotechnical Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5378,
    "Closing Rank": 5517
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Geotechnical Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2753,
    "Closing Rank": 2753
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Geotechnical Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1188,
    "Closing Rank": 1188
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Structural Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13323,
    "Closing Rank": 14055
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Structural Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18498,
    "Closing Rank": 18498
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Structural Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2375,
    "Closing Rank": 2375
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Structural Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4591,
    "Closing Rank": 5199
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech in CE. - M. Tech. in Structural Engineering (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2526,
    "Closing Rank": 2613
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (CSE) and M.Tech in CSE (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3466,
    "Closing Rank": 4429
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (CSE) and M.Tech in CSE (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9776,
    "Closing Rank": 9776
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (CSE) and M.Tech in CSE (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 667,
    "Closing Rank": 667
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (CSE) and M.Tech in CSE (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1277,
    "Closing Rank": 1541
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (CSE) and M.Tech in CSE (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 921,
    "Closing Rank": 921
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (CSE) and M.Tech in CSE (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1917,
    "Closing Rank": 1917
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (CSE) and M.Tech in CSE (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 729,
    "Closing Rank": 729
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ECE) -M. Tech. in VLSI (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6068,
    "Closing Rank": 6253
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ECE) -M. Tech. in VLSI (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14138,
    "Closing Rank": 14138
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ECE) -M. Tech. in VLSI (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 991,
    "Closing Rank": 991
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ECE) -M. Tech. in VLSI (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2211,
    "Closing Rank": 2215
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ECE) -M. Tech. in VLSI (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6902,
    "Closing Rank": 6902
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ECE) -M. Tech. in VLSI (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1281,
    "Closing Rank": 1281
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ECE) -M. Tech. in VLSI (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1005,
    "Closing Rank": 1005
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (EEE)-M. Tech. in (Power &. Control) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7254,
    "Closing Rank": 7605
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (EEE)-M. Tech. in (Power &. Control) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16498,
    "Closing Rank": 16498
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (EEE)-M. Tech. in (Power &. Control) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1192,
    "Closing Rank": 1192
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (EEE)-M. Tech. in (Power &. Control) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3036,
    "Closing Rank": 3084
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (EEE)-M. Tech. in (Power &. Control) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7167,
    "Closing Rank": 7167
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (EEE)-M. Tech. in (Power &. Control) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1705,
    "Closing Rank": 1890
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (Mathematics & Computing) M. Tech. in (Mathematics & Computing) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5147,
    "Closing Rank": 5683
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (Mathematics & Computing) M. Tech. in (Mathematics & Computing) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11004,
    "Closing Rank": 11004
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (Mathematics & Computing) M. Tech. in (Mathematics & Computing) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 714,
    "Closing Rank": 714
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (Mathematics & Computing) M. Tech. in (Mathematics & Computing) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2014,
    "Closing Rank": 2200
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (Mathematics & Computing) M. Tech. in (Mathematics & Computing) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4930,
    "Closing Rank": 4930
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (Mathematics & Computing) M. Tech. in (Mathematics & Computing) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1303,
    "Closing Rank": 1303
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (Mathematics & Computing) M. Tech. in (Mathematics & Computing) (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 721,
    "Closing Rank": 721
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ME) - M. Tech. in Mechatronics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8327,
    "Closing Rank": 9835
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ME) - M. Tech. in Mechatronics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18529,
    "Closing Rank": 18529
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ME) - M. Tech. in Mechatronics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1907,
    "Closing Rank": 1907
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ME) - M. Tech. in Mechatronics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3449,
    "Closing Rank": 3916
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ME) - M. Tech. in Mechatronics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8810,
    "Closing Rank": 8810
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ME) - M. Tech. in Mechatronics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2303,
    "Closing Rank": 2303
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B. Tech. (ME) - M. Tech. in Mechatronics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1244,
    "Closing Rank": 1244
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4371,
    "Closing Rank": 4864
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1961,
    "Closing Rank": 1961
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3612,
    "Closing Rank": 3612
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Artificial Intelligence and Data Science) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1160,
    "Closing Rank": 1160
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Chemical Engineering) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12577,
    "Closing Rank": 13111
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Chemical Engineering) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4544,
    "Closing Rank": 4544
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Chemical Engineering) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2697,
    "Closing Rank": 2697
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12162,
    "Closing Rank": 14176
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20511,
    "Closing Rank": 20511
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2461,
    "Closing Rank": 2461
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Chemical Science and Technology) - MBA in Hospital and Health Care Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5396,
    "Closing Rank": 5396
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14220,
    "Closing Rank": 15129
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2292,
    "Closing Rank": 2292
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4971,
    "Closing Rank": 4971
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Civil Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2668,
    "Closing Rank": 2668
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3427,
    "Closing Rank": 3427
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11166,
    "Closing Rank": 11166
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1183,
    "Closing Rank": 1183
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 941,
    "Closing Rank": 941
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Computer Science and Engineering) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 581,
    "Closing Rank": 581
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5875,
    "Closing Rank": 7575
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16808,
    "Closing Rank": 16808
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1337,
    "Closing Rank": 1337
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2622,
    "Closing Rank": 2622
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electrical and Electronics Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1826,
    "Closing Rank": 1826
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6895,
    "Closing Rank": 7070
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18135,
    "Closing Rank": 18135
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1186,
    "Closing Rank": 1186
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Electronics and Communication Engineering) - MBA in Hospital and Healthcare Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3094,
    "Closing Rank": 3094
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10491,
    "Closing Rank": 12375
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2199,
    "Closing Rank": 2199
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4878,
    "Closing Rank": 4878
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Engineering Physics) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2684,
    "Closing Rank": 2684
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4990,
    "Closing Rank": 5445
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2229,
    "Closing Rank": 2229
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6341,
    "Closing Rank": 6341
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mathematics and Computing) - MBA in Digital Business Management (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1309,
    "Closing Rank": 1309
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7840,
    "Closing Rank": 9445
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14756,
    "Closing Rank": 19507
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1595,
    "Closing Rank": 1703
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2889,
    "Closing Rank": 3250
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7457,
    "Closing Rank": 7457
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2122,
    "Closing Rank": 2351
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Mechanical Engineering) - MBA (IIM Mumbai) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1194,
    "Closing Rank": 1194
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15433,
    "Closing Rank": 15438
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22211,
    "Closing Rank": 22211
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4728,
    "Closing Rank": 4728
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2341,
    "Closing Rank": 2341
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech (Metallurgical and Materials Engineering) - MBA (IIM Bodh Gaya) (5 Years, Bachelor of Technology and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1273,
    "Closing Rank": 1273
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6287,
    "Closing Rank": 6660
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12637,
    "Closing Rank": 12637
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 926,
    "Closing Rank": 926
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2372,
    "Closing Rank": 2383
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1670,
    "Closing Rank": 1670
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2945,
    "Closing Rank": 2945
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 882,
    "Closing Rank": 882
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "BS in Economics with MBA (IIM Bodh Gaya) (5 Years, Bachelor of Science and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6885,
    "Closing Rank": 9961
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "BS in Economics with MBA (IIM Bodh Gaya) (5 Years, Bachelor of Science and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3944,
    "Closing Rank": 3944
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "BS in Economics with MBA (IIM Bodh Gaya) (5 Years, Bachelor of Science and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3890,
    "Closing Rank": 3890
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "BS in Economics with MBA (IIM Bodh Gaya) (5 Years, Bachelor of Science and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2678,
    "Closing Rank": 2678
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "BS in Economics with MBA (IIM Bodh Gaya) (5 Years, Bachelor of Science and MBA (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1329,
    "Closing Rank": 1329
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10776,
    "Closing Rank": 12519
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20166,
    "Closing Rank": 21996
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1726,
    "Closing Rank": 1940
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3395,
    "Closing Rank": 3395
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4236,
    "Closing Rank": 4836
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8339,
    "Closing Rank": 8805
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2195,
    "Closing Rank": 2592
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4041,
    "Closing Rank": 4097
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1135,
    "Closing Rank": 1245
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1722,
    "Closing Rank": 1722
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11793,
    "Closing Rank": 14062
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22292,
    "Closing Rank": 22410
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1985,
    "Closing Rank": 2201
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3779,
    "Closing Rank": 3779
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5025,
    "Closing Rank": 5437
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9036,
    "Closing Rank": 9036
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2713,
    "Closing Rank": 3075
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3964,
    "Closing Rank": 3964
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1239,
    "Closing Rank": 1252
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "1016P",
    "Closing Rank": "1016P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12287,
    "Closing Rank": 14579
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22663,
    "Closing Rank": 23883
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2040,
    "Closing Rank": 2127
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3967,
    "Closing Rank": 3967
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4184,
    "Closing Rank": 5194
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8731,
    "Closing Rank": 9291
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2430,
    "Closing Rank": 2648
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3991,
    "Closing Rank": 4292
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 989,
    "Closing Rank": 1110
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "141P",
    "Closing Rank": "141P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1760,
    "Closing Rank": 3144
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4695,
    "Closing Rank": 7192
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 173,
    "Closing Rank": "36P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 124,
    "Closing Rank": 124
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 380,
    "Closing Rank": 445
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 994,
    "Closing Rank": 1301
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 770,
    "Closing Rank": 1135
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2522,
    "Closing Rank": 2975
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "100P",
    "Closing Rank": "100P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 499,
    "Closing Rank": 681
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 837,
    "Closing Rank": 1887
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 306,
    "Closing Rank": 404
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 444,
    "Closing Rank": 444
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8762,
    "Closing Rank": 11437
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18633,
    "Closing Rank": 21392
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2055,
    "Closing Rank": 2083
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4441,
    "Closing Rank": 4441
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4744,
    "Closing Rank": 5527
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2219,
    "Closing Rank": 2770
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3751,
    "Closing Rank": 3751
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Economics (4 Years, Bachelor of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1489,
    "Closing Rank": 1489
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5868,
    "Closing Rank": 6873
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12395,
    "Closing Rank": 14153
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "291P",
    "Closing Rank": "291P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 998,
    "Closing Rank": 1132
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2304,
    "Closing Rank": 2304
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2086,
    "Closing Rank": 2695
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5822,
    "Closing Rank": 6649
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1417,
    "Closing Rank": 1689
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2763,
    "Closing Rank": 2763
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 805,
    "Closing Rank": 876
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1446,
    "Closing Rank": 1446
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4119,
    "Closing Rank": 5732
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11192,
    "Closing Rank": 12036
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "290P",
    "Closing Rank": "290P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 725,
    "Closing Rank": 910
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2145,
    "Closing Rank": 2145
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1895,
    "Closing Rank": 2197
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4835,
    "Closing Rank": 5603
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1209,
    "Closing Rank": 1312
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2041,
    "Closing Rank": 2041
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 672,
    "Closing Rank": 803
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1372,
    "Closing Rank": 1372
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10781,
    "Closing Rank": 12031
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19654,
    "Closing Rank": 21964
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2030,
    "Closing Rank": 2195
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3768,
    "Closing Rank": 3768
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4351,
    "Closing Rank": 5206
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9150,
    "Closing Rank": 9150
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2433,
    "Closing Rank": 2806
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4290,
    "Closing Rank": 4290
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1248,
    "Closing Rank": 1482
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "824P",
    "Closing Rank": "824P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3252,
    "Closing Rank": 4952
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9830,
    "Closing Rank": 10651
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 180,
    "Closing Rank": 180
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 630,
    "Closing Rank": 697
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1874,
    "Closing Rank": 1874
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1136,
    "Closing Rank": 1939
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3657,
    "Closing Rank": 4568
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 805,
    "Closing Rank": 1187
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2161,
    "Closing Rank": 2161
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 625,
    "Closing Rank": 849
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1361,
    "Closing Rank": 1361
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7745,
    "Closing Rank": 10647
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15112,
    "Closing Rank": 21121
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "378P",
    "Closing Rank": "378P"
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1592,
    "Closing Rank": 1700
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3362,
    "Closing Rank": 3386
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3217,
    "Closing Rank": 4099
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6356,
    "Closing Rank": 8469
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1994,
    "Closing Rank": 2380
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3713,
    "Closing Rank": 3905
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 953,
    "Closing Rank": 1114
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1770,
    "Closing Rank": 1770
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11553,
    "Closing Rank": 15323
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23452,
    "Closing Rank": 24442
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2146,
    "Closing Rank": 2216
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4022,
    "Closing Rank": 4022
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5451,
    "Closing Rank": 5706
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2848,
    "Closing Rank": 3071
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4504,
    "Closing Rank": 4504
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1287,
    "Closing Rank": 1324
  },
  {
    "Institute": "Indian Institute of Technology Patna",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "953P",
    "Closing Rank": "953P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3105,
    "Closing Rank": 19885
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17302,
    "Closing Rank": 19354
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2916,
    "Closing Rank": 2926
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4599,
    "Closing Rank": 4599
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5379,
    "Closing Rank": 6973
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3473,
    "Closing Rank": 3792
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1739,
    "Closing Rank": 1739
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": "76P",
    "Closing Rank": "315P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5871,
    "Closing Rank": 7286
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13107,
    "Closing Rank": 14009
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1389,
    "Closing Rank": 1398
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2617,
    "Closing Rank": 2617
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3266,
    "Closing Rank": 3549
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7685,
    "Closing Rank": 8022
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1876,
    "Closing Rank": 2148
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3393,
    "Closing Rank": 3393
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1184,
    "Closing Rank": 1229
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "144P",
    "Closing Rank": "144P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3531,
    "Closing Rank": 4689
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6799,
    "Closing Rank": 10941
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 199,
    "Closing Rank": 199
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "327P",
    "Closing Rank": "327P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 701,
    "Closing Rank": 836
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1588,
    "Closing Rank": 2059
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1745,
    "Closing Rank": 2386
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5014,
    "Closing Rank": 5629
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 902,
    "Closing Rank": 1264
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1630,
    "Closing Rank": 2665
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 594,
    "Closing Rank": 748
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1253,
    "Closing Rank": 1532
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8062,
    "Closing Rank": 11647
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11950,
    "Closing Rank": 18537
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1647,
    "Closing Rank": 1840
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3547,
    "Closing Rank": 3547
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3438,
    "Closing Rank": 4818
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8781,
    "Closing Rank": 8990
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2042,
    "Closing Rank": 2581
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4418,
    "Closing Rank": 4418
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1003,
    "Closing Rank": 1137
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4202,
    "Closing Rank": 6293
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10785,
    "Closing Rank": 14749
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "338P",
    "Closing Rank": "338P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "314P",
    "Closing Rank": "314P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 863,
    "Closing Rank": 1154
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2362,
    "Closing Rank": 2537
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2210,
    "Closing Rank": 2899
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6018,
    "Closing Rank": 7570
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 374,
    "Closing Rank": 1478
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2007,
    "Closing Rank": 2823
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 358,
    "Closing Rank": 457
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1029,
    "Closing Rank": 1122
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 277,
    "Closing Rank": 481
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1270,
    "Closing Rank": 1870
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13,
    "Closing Rank": 18
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 93,
    "Closing Rank": 93
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56,
    "Closing Rank": 90
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 339,
    "Closing Rank": 395
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 165,
    "Closing Rank": 236
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 556,
    "Closing Rank": 783
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8,
    "Closing Rank": 8
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41,
    "Closing Rank": 144
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 460,
    "Closing Rank": 474
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10,
    "Closing Rank": 10
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56,
    "Closing Rank": 75
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 192,
    "Closing Rank": 229
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 503,
    "Closing Rank": 680
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2000,
    "Closing Rank": 2114
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49,
    "Closing Rank": 49
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 99,
    "Closing Rank": 132
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 477,
    "Closing Rank": 477
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 238,
    "Closing Rank": 421
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 936,
    "Closing Rank": 1105
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 195,
    "Closing Rank": 226
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 694,
    "Closing Rank": 694
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 101,
    "Closing Rank": 103
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 326,
    "Closing Rank": 326
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3233,
    "Closing Rank": 5312
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10154,
    "Closing Rank": 10847
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 877,
    "Closing Rank": 931
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2084,
    "Closing Rank": 2084
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1747,
    "Closing Rank": 2552
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4637,
    "Closing Rank": 4949
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1268,
    "Closing Rank": 1879
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3203,
    "Closing Rank": 3203
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 992,
    "Closing Rank": 1009
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1495,
    "Closing Rank": 2033
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3631,
    "Closing Rank": 6111
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 96,
    "Closing Rank": 148
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 191,
    "Closing Rank": 191
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 287,
    "Closing Rank": 385
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 788,
    "Closing Rank": 1042
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "15P",
    "Closing Rank": "15P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 768,
    "Closing Rank": 1012
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2182,
    "Closing Rank": 3072
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "84P",
    "Closing Rank": "84P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 358,
    "Closing Rank": 560
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1079,
    "Closing Rank": 1402
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 124,
    "Closing Rank": 298
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 446,
    "Closing Rank": 654
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "7P",
    "Closing Rank": "7P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 987,
    "Closing Rank": 1424
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2653,
    "Closing Rank": 3590
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64,
    "Closing Rank": 69
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 181,
    "Closing Rank": 265
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 617,
    "Closing Rank": 781
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 514,
    "Closing Rank": 732
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1247,
    "Closing Rank": 1709
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29,
    "Closing Rank": 29
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 174,
    "Closing Rank": 398
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 784,
    "Closing Rank": 932
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "17P",
    "Closing Rank": "17P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 109,
    "Closing Rank": 217
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 474,
    "Closing Rank": 484
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3834,
    "Closing Rank": 4818
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11253,
    "Closing Rank": 11253
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 909,
    "Closing Rank": 1061
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2302,
    "Closing Rank": 2302
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2018,
    "Closing Rank": 2521
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6329,
    "Closing Rank": 6329
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1242,
    "Closing Rank": 1416
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2831,
    "Closing Rank": 2831
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 835,
    "Closing Rank": 835
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2456,
    "Closing Rank": 3951
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6739,
    "Closing Rank": 10019
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "280P",
    "Closing Rank": "280P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 762,
    "Closing Rank": 1208
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2298,
    "Closing Rank": 2298
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1908,
    "Closing Rank": 2690
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6528,
    "Closing Rank": 6911
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1122,
    "Closing Rank": 1642
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3074,
    "Closing Rank": 3315
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1025,
    "Closing Rank": 1118
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7985,
    "Closing Rank": 9977
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15494,
    "Closing Rank": 17322
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1505,
    "Closing Rank": 1530
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3367,
    "Closing Rank": 3367
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3931,
    "Closing Rank": 4209
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8427,
    "Closing Rank": 8470
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1082,
    "Closing Rank": 2491
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3707,
    "Closing Rank": 3707
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1233,
    "Closing Rank": 1332
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geological Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "699P",
    "Closing Rank": "699P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6436,
    "Closing Rank": 9210
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16073,
    "Closing Rank": 18299
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1233,
    "Closing Rank": 1496
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3489,
    "Closing Rank": 3489
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3487,
    "Closing Rank": 3834
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8044,
    "Closing Rank": 8416
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2254,
    "Closing Rank": 2505
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3833,
    "Closing Rank": 3833
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1162,
    "Closing Rank": 1371
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Geophysical Technology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "928P",
    "Closing Rank": "928P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1089,
    "Closing Rank": 1512
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4010,
    "Closing Rank": 4814
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 137,
    "Closing Rank": 137
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 255,
    "Closing Rank": 270
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 876,
    "Closing Rank": 876
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 511,
    "Closing Rank": 857
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2178,
    "Closing Rank": 2529
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "70P",
    "Closing Rank": "70P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 266,
    "Closing Rank": 611
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 912,
    "Closing Rank": 912
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 261,
    "Closing Rank": 338
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 823,
    "Closing Rank": 823
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2526,
    "Closing Rank": 3672
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7668,
    "Closing Rank": 10517
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "366P",
    "Closing Rank": "366P"
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 513,
    "Closing Rank": 686
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1879,
    "Closing Rank": 2091
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1097,
    "Closing Rank": 1784
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3784,
    "Closing Rank": 5050
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 813,
    "Closing Rank": 1010
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1604,
    "Closing Rank": 2287
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 397,
    "Closing Rank": 515
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 659,
    "Closing Rank": 860
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6173,
    "Closing Rank": 7051
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13244,
    "Closing Rank": 15488
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1185,
    "Closing Rank": 1290
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2711,
    "Closing Rank": 2838
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2849,
    "Closing Rank": 3349
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7420,
    "Closing Rank": 7844
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1569,
    "Closing Rank": 2001
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2927,
    "Closing Rank": 3119
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 885,
    "Closing Rank": 958
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1609,
    "Closing Rank": 1609
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6021,
    "Closing Rank": 9079
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12854,
    "Closing Rank": 15922
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1575,
    "Closing Rank": 1619
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3729,
    "Closing Rank": 3729
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2560,
    "Closing Rank": 4243
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8317,
    "Closing Rank": 8466
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1898,
    "Closing Rank": 2327
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3836,
    "Closing Rank": 3836
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1426,
    "Closing Rank": 1426
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4457,
    "Closing Rank": 5365
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11018,
    "Closing Rank": 11912
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 891,
    "Closing Rank": 1048
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2505,
    "Closing Rank": 2505
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2540,
    "Closing Rank": 2936
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5993,
    "Closing Rank": 6543
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1568,
    "Closing Rank": 1749
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3168,
    "Closing Rank": 3317
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 864,
    "Closing Rank": 904
  },
  {
    "Institute": "Indian Institute of Technology Roorkee",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1588,
    "Closing Rank": 1588
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13369,
    "Closing Rank": 16011
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24275,
    "Closing Rank": 24275
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2434,
    "Closing Rank": 2459
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4733,
    "Closing Rank": 4733
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3996,
    "Closing Rank": 5756
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3132,
    "Closing Rank": 3162
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4671,
    "Closing Rank": 4671
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geology (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1458,
    "Closing Rank": 1458
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15220,
    "Closing Rank": 15591
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25416,
    "Closing Rank": 25416
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2392,
    "Closing Rank": 2502
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4591,
    "Closing Rank": 4591
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5779,
    "Closing Rank": 5890
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2794,
    "Closing Rank": 2996
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4789,
    "Closing Rank": 4789
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Applied Geophysics (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1451,
    "Closing Rank": 1451
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7882,
    "Closing Rank": 11398
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16313,
    "Closing Rank": 19432
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1515,
    "Closing Rank": 1674
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3300,
    "Closing Rank": 3300
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3961,
    "Closing Rank": 4316
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7539,
    "Closing Rank": 8205
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2118,
    "Closing Rank": 2384
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3770,
    "Closing Rank": 3770
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1081,
    "Closing Rank": 1152
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1513,
    "Closing Rank": 1513
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9898,
    "Closing Rank": 12996
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20786,
    "Closing Rank": 23485
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1797,
    "Closing Rank": 1950
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4263,
    "Closing Rank": 4263
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3690,
    "Closing Rank": 4547
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7843,
    "Closing Rank": 8938
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2030,
    "Closing Rank": 2412
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2857,
    "Closing Rank": 4115
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 765,
    "Closing Rank": 912
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1548,
    "Closing Rank": 1548
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2167,
    "Closing Rank": 3846
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5560,
    "Closing Rank": 8682
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85,
    "Closing Rank": 125
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "349P",
    "Closing Rank": "349P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 407,
    "Closing Rank": 528
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1172,
    "Closing Rank": 1361
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "13P",
    "Closing Rank": "13P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 758,
    "Closing Rank": 1273
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1892,
    "Closing Rank": 3220
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47,
    "Closing Rank": "39P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 427,
    "Closing Rank": 699
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 754,
    "Closing Rank": 1552
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 197,
    "Closing Rank": 304
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 394,
    "Closing Rank": 439
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4868,
    "Closing Rank": 7567
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13093,
    "Closing Rank": 14711
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 970,
    "Closing Rank": 1121
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2276,
    "Closing Rank": 2283
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2127,
    "Closing Rank": 2752
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4661,
    "Closing Rank": 6161
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1062,
    "Closing Rank": 1595
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2366,
    "Closing Rank": 2870
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 550,
    "Closing Rank": 801
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1308,
    "Closing Rank": 1459
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4743,
    "Closing Rank": 5982
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9327,
    "Closing Rank": 12788
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "77P",
    "Closing Rank": "323P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 759,
    "Closing Rank": 880
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1855,
    "Closing Rank": 2003
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1257,
    "Closing Rank": 2102
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3161,
    "Closing Rank": 4377
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 707,
    "Closing Rank": 1214
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2091,
    "Closing Rank": 2421
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 486,
    "Closing Rank": 673
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 758,
    "Closing Rank": 1020
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8392,
    "Closing Rank": 10778
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16923,
    "Closing Rank": 17730
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1518,
    "Closing Rank": 1557
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3510,
    "Closing Rank": 3510
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4049,
    "Closing Rank": 4206
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8343,
    "Closing Rank": 9217
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2077,
    "Closing Rank": 2547
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4420,
    "Closing Rank": 4420
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1270,
    "Closing Rank": 1360
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9606,
    "Closing Rank": 15291
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21683,
    "Closing Rank": 23835
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "352P",
    "Closing Rank": "352P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2184,
    "Closing Rank": 2355
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3917,
    "Closing Rank": 3917
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3755,
    "Closing Rank": 5681
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2925,
    "Closing Rank": 3113
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4628,
    "Closing Rank": 4628
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1351,
    "Closing Rank": 1441
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "390P",
    "Closing Rank": "390P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4100,
    "Closing Rank": 4956
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9026,
    "Closing Rank": 10248
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "73P",
    "Closing Rank": "73P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 560,
    "Closing Rank": 677
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2016,
    "Closing Rank": 2016
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1280,
    "Closing Rank": 1663
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3565,
    "Closing Rank": 4170
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "153P",
    "Closing Rank": "153P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 759,
    "Closing Rank": 1000
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1999,
    "Closing Rank": 1999
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 340,
    "Closing Rank": 603
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 642,
    "Closing Rank": 642
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7675,
    "Closing Rank": 9875
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16262,
    "Closing Rank": 19099
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1354,
    "Closing Rank": 1462
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2977,
    "Closing Rank": 3036
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3021,
    "Closing Rank": 3698
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6948,
    "Closing Rank": 7762
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1711,
    "Closing Rank": 2117
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3410,
    "Closing Rank": 3695
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 674,
    "Closing Rank": 1064
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1322,
    "Closing Rank": 1639
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12446,
    "Closing Rank": 13599
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21216,
    "Closing Rank": 23512
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1992,
    "Closing Rank": 2186
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3961,
    "Closing Rank": 3961
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4736,
    "Closing Rank": 5445
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2466,
    "Closing Rank": 2960
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4110,
    "Closing Rank": 4110
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1243,
    "Closing Rank": 1340
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mineral and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "489P",
    "Closing Rank": "489P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8439,
    "Closing Rank": 14762
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19181,
    "Closing Rank": 25824
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2094,
    "Closing Rank": 2282
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4285,
    "Closing Rank": 4393
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4352,
    "Closing Rank": 5446
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8429,
    "Closing Rank": 9262
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1787,
    "Closing Rank": 2977
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3779,
    "Closing Rank": 4552
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1082,
    "Closing Rank": 1171
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "552P",
    "Closing Rank": "687P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13690,
    "Closing Rank": 15321
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21592,
    "Closing Rank": 21592
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2177,
    "Closing Rank": 2368
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4598,
    "Closing Rank": 4598
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4644,
    "Closing Rank": 5717
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2534,
    "Closing Rank": 3102
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4730,
    "Closing Rank": 4792
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1174,
    "Closing Rank": 1338
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Mining Machinery Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "540P",
    "Closing Rank": "540P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7795,
    "Closing Rank": 12310
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16543,
    "Closing Rank": 20817
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "107P",
    "Closing Rank": "107P"
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1721,
    "Closing Rank": 1919
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3342,
    "Closing Rank": 3763
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3586,
    "Closing Rank": 4582
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7219,
    "Closing Rank": 8736
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1976,
    "Closing Rank": 2559
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4001,
    "Closing Rank": 4266
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 716,
    "Closing Rank": 1112
  },
  {
    "Institute": "Indian Institute of Technology (ISM) Dhanbad",
    "Academic Program Name": "Petroleum Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1543,
    "Closing Rank": 1543
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2254,
    "Closing Rank": 2656
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7074,
    "Closing Rank": 8412
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 122,
    "Closing Rank": 122
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 499,
    "Closing Rank": 563
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1116,
    "Closing Rank": 1308
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3785,
    "Closing Rank": 3785
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 738,
    "Closing Rank": 781
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1888,
    "Closing Rank": 1888
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 440,
    "Closing Rank": 449
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8597,
    "Closing Rank": 10097
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14701,
    "Closing Rank": 15740
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1527,
    "Closing Rank": 1631
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2873,
    "Closing Rank": 2873
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3836,
    "Closing Rank": 4203
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8316,
    "Closing Rank": 8382
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1955,
    "Closing Rank": 2227
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3910,
    "Closing Rank": 3910
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1127,
    "Closing Rank": 1238
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1703,
    "Closing Rank": 1703
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10126,
    "Closing Rank": 11365
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17886,
    "Closing Rank": 18966
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1902,
    "Closing Rank": 1949
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3714,
    "Closing Rank": 3714
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4186,
    "Closing Rank": 4371
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8232,
    "Closing Rank": 8904
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2309,
    "Closing Rank": 2419
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3778,
    "Closing Rank": 3778
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 706,
    "Closing Rank": 744
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1699,
    "Closing Rank": 1699
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1158,
    "Closing Rank": 2379
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3461,
    "Closing Rank": 6044
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 126,
    "Closing Rank": 136
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 234,
    "Closing Rank": 291
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 818,
    "Closing Rank": 1108
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "43P",
    "Closing Rank": "43P"
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 450,
    "Closing Rank": 1084
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1492,
    "Closing Rank": 2227
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63,
    "Closing Rank": 63
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 388,
    "Closing Rank": 548
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1389,
    "Closing Rank": 1641
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 242,
    "Closing Rank": 419
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 773,
    "Closing Rank": 773
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3406,
    "Closing Rank": 5528
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6205,
    "Closing Rank": 12038
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 795,
    "Closing Rank": 925
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2150,
    "Closing Rank": 2438
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1936,
    "Closing Rank": 2590
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4632,
    "Closing Rank": 6437
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1099,
    "Closing Rank": 1593
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2172,
    "Closing Rank": 2382
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 554,
    "Closing Rank": 764
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1427,
    "Closing Rank": 1427
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8158,
    "Closing Rank": 9142
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16348,
    "Closing Rank": 18747
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1543,
    "Closing Rank": 1790
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3886,
    "Closing Rank": 4310
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9270,
    "Closing Rank": 9270
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2143,
    "Closing Rank": 2573
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4477,
    "Closing Rank": 4477
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1368,
    "Closing Rank": 1391
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1755,
    "Closing Rank": 3518
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8734,
    "Closing Rank": 9494
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 411,
    "Closing Rank": 552
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1912,
    "Closing Rank": 1912
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1267,
    "Closing Rank": 1724
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2514,
    "Closing Rank": 2514
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 807,
    "Closing Rank": 930
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1451,
    "Closing Rank": 1451
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 599,
    "Closing Rank": 620
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1316,
    "Closing Rank": 1316
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6919,
    "Closing Rank": 8636
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15057,
    "Closing Rank": 17603
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1241,
    "Closing Rank": 1494
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3095,
    "Closing Rank": 3095
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3113,
    "Closing Rank": 3810
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7008,
    "Closing Rank": 8395
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1825,
    "Closing Rank": 2128
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3154,
    "Closing Rank": 3800
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 782,
    "Closing Rank": 935
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "71P",
    "Closing Rank": "71P"
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11211,
    "Closing Rank": 13009
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20595,
    "Closing Rank": 21582
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2134,
    "Closing Rank": 2208
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4611,
    "Closing Rank": 4968
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8970,
    "Closing Rank": 9018
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2652,
    "Closing Rank": 2662
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4385,
    "Closing Rank": 4385
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1366,
    "Closing Rank": 1366
  },
  {
    "Institute": "Indian Institute of Technology Ropar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "936P",
    "Closing Rank": "936P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20507,
    "Closing Rank": 22390
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25278,
    "Closing Rank": 25278
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3439,
    "Closing Rank": 3467
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7644,
    "Closing Rank": 8177
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3540,
    "Closing Rank": 4321
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4991,
    "Closing Rank": 4991
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": "616P",
    "Closing Rank": "764P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10717,
    "Closing Rank": 12761
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20653,
    "Closing Rank": 20966
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1976,
    "Closing Rank": 2090
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5377,
    "Closing Rank": 5680
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2686,
    "Closing Rank": 2954
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4522,
    "Closing Rank": 4522
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Biochemical Engineering with M.Tech. in Biochemical Engineering and Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1350,
    "Closing Rank": 1465
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11279,
    "Closing Rank": 14001
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17189,
    "Closing Rank": 18700
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2061,
    "Closing Rank": 2081
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4763,
    "Closing Rank": 5361
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8573,
    "Closing Rank": 8573
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2635,
    "Closing Rank": 3125
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4763,
    "Closing Rank": 4763
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Bioengineering with M.Tech in Biomedical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1461,
    "Closing Rank": 1461
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10504,
    "Closing Rank": 12154
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18654,
    "Closing Rank": 22716
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1838,
    "Closing Rank": 2044
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3629,
    "Closing Rank": 3629
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3543,
    "Closing Rank": 4895
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2245,
    "Closing Rank": 2976
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4619,
    "Closing Rank": 4675
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1297,
    "Closing Rank": 1430
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "788P",
    "Closing Rank": "788P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12260,
    "Closing Rank": 13931
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24628,
    "Closing Rank": 24753
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2222,
    "Closing Rank": 2254
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4991,
    "Closing Rank": 5501
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3005,
    "Closing Rank": 3020
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4860,
    "Closing Rank": 4860
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Ceramic Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1456,
    "Closing Rank": 1456
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5715,
    "Closing Rank": 7757
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12307,
    "Closing Rank": 16188
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1078,
    "Closing Rank": 1314
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2608,
    "Closing Rank": 2615
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2957,
    "Closing Rank": 3404
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6033,
    "Closing Rank": 7414
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1637,
    "Closing Rank": 2078
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3129,
    "Closing Rank": 3940
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 828,
    "Closing Rank": 973
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1802,
    "Closing Rank": "44P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7809,
    "Closing Rank": 9339
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15552,
    "Closing Rank": 18970
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1327,
    "Closing Rank": 1426
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2829,
    "Closing Rank": 2866
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3186,
    "Closing Rank": 3819
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7465,
    "Closing Rank": 8011
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1733,
    "Closing Rank": 2064
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3128,
    "Closing Rank": 3721
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 491,
    "Closing Rank": 633
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1355,
    "Closing Rank": 1472
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8533,
    "Closing Rank": 10583
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18871,
    "Closing Rank": 19360
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1646,
    "Closing Rank": 1683
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3587,
    "Closing Rank": 3587
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3545,
    "Closing Rank": 4123
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7141,
    "Closing Rank": 8759
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1956,
    "Closing Rank": 2144
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3888,
    "Closing Rank": 3888
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 712,
    "Closing Rank": 800
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 667,
    "Closing Rank": 1071
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2562,
    "Closing Rank": 4130
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27,
    "Closing Rank": 40
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 142,
    "Closing Rank": 187
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 507,
    "Closing Rank": 517
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 226,
    "Closing Rank": 524
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 880,
    "Closing Rank": 1592
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14,
    "Closing Rank": 14
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 192,
    "Closing Rank": 262
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 527,
    "Closing Rank": 851
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "19P",
    "Closing Rank": "19P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 77,
    "Closing Rank": 163
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 254,
    "Closing Rank": 254
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1120,
    "Closing Rank": 1510
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4165,
    "Closing Rank": 4593
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 246,
    "Closing Rank": 247
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1073,
    "Closing Rank": 1073
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 553,
    "Closing Rank": 727
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2055,
    "Closing Rank": 2237
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45,
    "Closing Rank": 45
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 300,
    "Closing Rank": 340
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1453,
    "Closing Rank": 1453
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 210,
    "Closing Rank": 279
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 278,
    "Closing Rank": 278
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2852,
    "Closing Rank": 3608
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6391,
    "Closing Rank": 10177
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "121P",
    "Closing Rank": "256P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 505,
    "Closing Rank": 604
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1411,
    "Closing Rank": 1658
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1107,
    "Closing Rank": 1544
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3285,
    "Closing Rank": 4181
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "123P",
    "Closing Rank": "149P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 642,
    "Closing Rank": 916
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1013,
    "Closing Rank": 2086
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 353,
    "Closing Rank": 497
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 754,
    "Closing Rank": 920
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3826,
    "Closing Rank": 4619
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10337,
    "Closing Rank": 10830
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 637,
    "Closing Rank": 675
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2153,
    "Closing Rank": 2153
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1607,
    "Closing Rank": 1896
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4355,
    "Closing Rank": 4433
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1157,
    "Closing Rank": 1350
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2830,
    "Closing Rank": 2830
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 468,
    "Closing Rank": 468
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electrical Engineering with M.Tech. in Power Electronics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1307,
    "Closing Rank": 1307
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2110,
    "Closing Rank": 2826
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5767,
    "Closing Rank": 6986
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 150,
    "Closing Rank": 163
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 179,
    "Closing Rank": 179
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 401,
    "Closing Rank": 492
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1119,
    "Closing Rank": 1262
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "18P",
    "Closing Rank": "18P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1027,
    "Closing Rank": 1351
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2395,
    "Closing Rank": 3268
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 524,
    "Closing Rank": 808
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1683,
    "Closing Rank": 2050
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 324,
    "Closing Rank": 421
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 917,
    "Closing Rank": 981
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6196,
    "Closing Rank": 7764
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16241,
    "Closing Rank": 16996
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1692,
    "Closing Rank": 1777
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4076,
    "Closing Rank": 4076
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3447,
    "Closing Rank": 3874
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8040,
    "Closing Rank": 8040
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2087,
    "Closing Rank": 2255
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4467,
    "Closing Rank": 4467
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1256,
    "Closing Rank": 1271
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10813,
    "Closing Rank": 12317
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21633,
    "Closing Rank": 23182
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2077,
    "Closing Rank": 2300
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4645,
    "Closing Rank": 5474
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8455,
    "Closing Rank": 8455
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2983,
    "Closing Rank": 3046
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4487,
    "Closing Rank": 4487
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Industrial Chemistry (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1380,
    "Closing Rank": 1476
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8540,
    "Closing Rank": 12524
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19448,
    "Closing Rank": 19700
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1827,
    "Closing Rank": 1889
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3310,
    "Closing Rank": 3310
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4849,
    "Closing Rank": 5324
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2637,
    "Closing Rank": 2797
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4376,
    "Closing Rank": 4439
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Materials Science and Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1448,
    "Closing Rank": 1448
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1538,
    "Closing Rank": 2154
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4954,
    "Closing Rank": 6186
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 121,
    "Closing Rank": 121
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 310,
    "Closing Rank": 346
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1105,
    "Closing Rank": 1105
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 780,
    "Closing Rank": 1028
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2578,
    "Closing Rank": 2726
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 501,
    "Closing Rank": 672
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 675,
    "Closing Rank": 675
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 316,
    "Closing Rank": 385
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 831,
    "Closing Rank": 831
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4029,
    "Closing Rank": 6596
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11998,
    "Closing Rank": 15157
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 865,
    "Closing Rank": 1152
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2275,
    "Closing Rank": 2594
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2060,
    "Closing Rank": 2829
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5585,
    "Closing Rank": 6731
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1141,
    "Closing Rank": 1654
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2993,
    "Closing Rank": 3610
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 600,
    "Closing Rank": 763
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1409,
    "Closing Rank": 1585
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6689,
    "Closing Rank": 7497
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14319,
    "Closing Rank": 16564
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1244,
    "Closing Rank": 1248
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3151,
    "Closing Rank": 3151
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2746,
    "Closing Rank": 3138
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6603,
    "Closing Rank": 6772
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1671,
    "Closing Rank": 1790
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 970,
    "Closing Rank": 1006
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "267P",
    "Closing Rank": "267P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7717,
    "Closing Rank": 10740
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16644,
    "Closing Rank": 19931
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1473,
    "Closing Rank": 1714
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3003,
    "Closing Rank": 3491
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3739,
    "Closing Rank": 4494
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8302,
    "Closing Rank": 8551
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2177,
    "Closing Rank": 2477
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3929,
    "Closing Rank": 4295
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1115,
    "Closing Rank": 1296
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "358P",
    "Closing Rank": "386P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10791,
    "Closing Rank": 11530
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16837,
    "Closing Rank": 22750
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1888,
    "Closing Rank": 1947
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4080,
    "Closing Rank": 4080
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4471,
    "Closing Rank": 4945
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9190,
    "Closing Rank": 9190
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2721,
    "Closing Rank": 2744
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4086,
    "Closing Rank": 4086
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Metallurgical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1352,
    "Closing Rank": 1356
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10803,
    "Closing Rank": 12876
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20895,
    "Closing Rank": 24616
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1814,
    "Closing Rank": 2014
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3647,
    "Closing Rank": 3873
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4081,
    "Closing Rank": 4990
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8101,
    "Closing Rank": 9144
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2435,
    "Closing Rank": 2924
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4563,
    "Closing Rank": 4767
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1186,
    "Closing Rank": 1300
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "496P",
    "Closing Rank": "728P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12958,
    "Closing Rank": 14557
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24523,
    "Closing Rank": 25614
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2257,
    "Closing Rank": 2351
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5004,
    "Closing Rank": 5454
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2850,
    "Closing Rank": 3054
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4884,
    "Closing Rank": 4884
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1140,
    "Closing Rank": 1140
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "905P",
    "Closing Rank": "905P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11311,
    "Closing Rank": 13562
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20701,
    "Closing Rank": 22879
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1900,
    "Closing Rank": 2139
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3700,
    "Closing Rank": 3994
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4175,
    "Closing Rank": 5464
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8658,
    "Closing Rank": 9257
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2748,
    "Closing Rank": 3118
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4816,
    "Closing Rank": 4827
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1241,
    "Closing Rank": 1449
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "500P",
    "Closing Rank": "500P"
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13669,
    "Closing Rank": 14463
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21830,
    "Closing Rank": 24870
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2229,
    "Closing Rank": 2363
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5488,
    "Closing Rank": 5622
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3135,
    "Closing Rank": 3141
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4524,
    "Closing Rank": 4524
  },
  {
    "Institute": "Indian Institute of Technology (BHU) Varanasi",
    "Academic Program Name": "Pharmaceutical Engineering & Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1478,
    "Closing Rank": 1515
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7410,
    "Closing Rank": 9178
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15505,
    "Closing Rank": 17156
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1224,
    "Closing Rank": 1522
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3055,
    "Closing Rank": 3292
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3576,
    "Closing Rank": 4051
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7828,
    "Closing Rank": 8486
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2139,
    "Closing Rank": 2616
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3345,
    "Closing Rank": 4053
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1198,
    "Closing Rank": 1318
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Biosciences and Bioengineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1766,
    "Closing Rank": 1766
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4618,
    "Closing Rank": 5363
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11635,
    "Closing Rank": 12557
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 867,
    "Closing Rank": 1046
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2203,
    "Closing Rank": 2270
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2413,
    "Closing Rank": 2630
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5621,
    "Closing Rank": 6011
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1224,
    "Closing Rank": 1442
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2450,
    "Closing Rank": 2736
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 743,
    "Closing Rank": 811
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1317,
    "Closing Rank": 1398
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5443,
    "Closing Rank": 6834
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11731,
    "Closing Rank": 14720
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1082,
    "Closing Rank": 1198
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2349,
    "Closing Rank": 2349
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2271,
    "Closing Rank": 3307
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6121,
    "Closing Rank": 6918
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1554,
    "Closing Rank": 1868
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3483,
    "Closing Rank": 3516
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 869,
    "Closing Rank": 993
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Chemical Science and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 916,
    "Closing Rank": 916
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5869,
    "Closing Rank": 7670
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15033,
    "Closing Rank": 16581
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1178,
    "Closing Rank": 1300
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2545,
    "Closing Rank": 2598
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2946,
    "Closing Rank": 3258
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6204,
    "Closing Rank": 7488
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1577,
    "Closing Rank": 1761
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3091,
    "Closing Rank": 3290
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 361,
    "Closing Rank": 624
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 670,
    "Closing Rank": 1246
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 466,
    "Closing Rank": 623
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1788,
    "Closing Rank": 2332
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26,
    "Closing Rank": 26
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 119,
    "Closing Rank": 119
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 87,
    "Closing Rank": 128
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 419,
    "Closing Rank": 419
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6,
    "Closing Rank": 6
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31,
    "Closing Rank": 31
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 212,
    "Closing Rank": 364
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 862,
    "Closing Rank": 950
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13,
    "Closing Rank": 13
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 134,
    "Closing Rank": 181
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 481,
    "Closing Rank": 677
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "4P",
    "Closing Rank": "4P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 74,
    "Closing Rank": 100
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 231,
    "Closing Rank": 263
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 829,
    "Closing Rank": 995
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2228,
    "Closing Rank": 2748
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 134,
    "Closing Rank": 157
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 493,
    "Closing Rank": 493
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 360,
    "Closing Rank": 479
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1151,
    "Closing Rank": 1186
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 228,
    "Closing Rank": 293
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 847,
    "Closing Rank": 847
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 140,
    "Closing Rank": 160
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 379,
    "Closing Rank": 379
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1462,
    "Closing Rank": 1658
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3688,
    "Closing Rank": 4679
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 74,
    "Closing Rank": 92
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "283P",
    "Closing Rank": "283P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 267,
    "Closing Rank": 347
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 915,
    "Closing Rank": 990
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "6P",
    "Closing Rank": "6P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 682,
    "Closing Rank": 869
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2114,
    "Closing Rank": 2462
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "22P",
    "Closing Rank": "22P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 351,
    "Closing Rank": 485
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1230,
    "Closing Rank": 1422
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "13P",
    "Closing Rank": "13P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 204,
    "Closing Rank": 282
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 556,
    "Closing Rank": 556
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1686,
    "Closing Rank": 2217
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5193,
    "Closing Rank": 6141
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 157,
    "Closing Rank": 157
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 368,
    "Closing Rank": 412
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1131,
    "Closing Rank": 1131
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 926,
    "Closing Rank": 1040
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2364,
    "Closing Rank": 3045
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "81P",
    "Closing Rank": "81P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 556,
    "Closing Rank": 585
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1423,
    "Closing Rank": 1429
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 108,
    "Closing Rank": 328
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Electronics and Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 663,
    "Closing Rank": 663
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4960,
    "Closing Rank": 5920
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9754,
    "Closing Rank": 11615
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1076,
    "Closing Rank": 1109
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2328,
    "Closing Rank": 2802
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6588,
    "Closing Rank": 6588
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1495,
    "Closing Rank": 1682
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2698,
    "Closing Rank": 2698
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 720,
    "Closing Rank": 720
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4302,
    "Closing Rank": 5686
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10217,
    "Closing Rank": 14111
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1007,
    "Closing Rank": 1287
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2192,
    "Closing Rank": 2192
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2648,
    "Closing Rank": 3222
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4962,
    "Closing Rank": 7221
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1839,
    "Closing Rank": 2061
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3264,
    "Closing Rank": 3913
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1130,
    "Closing Rank": 1216
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1719,
    "Closing Rank": 1719
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 722,
    "Closing Rank": 1021
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2578,
    "Closing Rank": 3350
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 67,
    "Closing Rank": 67
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 161,
    "Closing Rank": 180
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 649,
    "Closing Rank": 743
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 372,
    "Closing Rank": 614
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1179,
    "Closing Rank": 1649
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "49P",
    "Closing Rank": "49P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 272,
    "Closing Rank": 393
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 952,
    "Closing Rank": 1164
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 195,
    "Closing Rank": 227
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 533,
    "Closing Rank": 533
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3130,
    "Closing Rank": 4523
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7018,
    "Closing Rank": 12046
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "261P",
    "Closing Rank": "261P"
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 639,
    "Closing Rank": 793
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1794,
    "Closing Rank": 2141
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1778,
    "Closing Rank": 2139
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4122,
    "Closing Rank": 5376
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 613,
    "Closing Rank": 1126
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2687,
    "Closing Rank": 2978
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 453,
    "Closing Rank": 579
  },
  {
    "Institute": "Indian Institute of Technology Guwahati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 947,
    "Closing Rank": 1436
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3471,
    "Closing Rank": 6516
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10240,
    "Closing Rank": 11544
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "124P",
    "Closing Rank": "124P"
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 914,
    "Closing Rank": 1018
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2023,
    "Closing Rank": 2023
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2173,
    "Closing Rank": 2470
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3453,
    "Closing Rank": 5333
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 879,
    "Closing Rank": 1021
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2083,
    "Closing Rank": 2194
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 209,
    "Closing Rank": 612
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6043,
    "Closing Rank": 7570
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12695,
    "Closing Rank": 14348
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "126P",
    "Closing Rank": "126P"
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1086,
    "Closing Rank": 1201
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2172,
    "Closing Rank": 2172
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2482,
    "Closing Rank": 2811
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5797,
    "Closing Rank": 5994
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1191,
    "Closing Rank": 1332
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2443,
    "Closing Rank": 2443
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 613,
    "Closing Rank": 687
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7987,
    "Closing Rank": 10709
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16917,
    "Closing Rank": 17772
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1516,
    "Closing Rank": 1648
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2940,
    "Closing Rank": 2940
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3003,
    "Closing Rank": 3999
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7109,
    "Closing Rank": 8020
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1803,
    "Closing Rank": 2207
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3134,
    "Closing Rank": 3408
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 826,
    "Closing Rank": 1068
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1521,
    "Closing Rank": 1521
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8375,
    "Closing Rank": 9472
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15017,
    "Closing Rank": 15657
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "343P",
    "Closing Rank": "343P"
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1265,
    "Closing Rank": 1286
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2335,
    "Closing Rank": 2335
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2982,
    "Closing Rank": 3539
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6070,
    "Closing Rank": 7148
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1814,
    "Closing Rank": 1904
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2952,
    "Closing Rank": 2952
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 777,
    "Closing Rank": 902
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1506,
    "Closing Rank": 1506
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15066,
    "Closing Rank": 15871
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25106,
    "Closing Rank": 25107
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2338,
    "Closing Rank": 2378
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5647,
    "Closing Rank": 5810
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3057,
    "Closing Rank": 3089
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4676,
    "Closing Rank": 4676
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1379,
    "Closing Rank": 1379
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11065,
    "Closing Rank": 13137
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21738,
    "Closing Rank": 23440
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1787,
    "Closing Rank": 2017
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3827,
    "Closing Rank": 3827
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4449,
    "Closing Rank": 4803
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8617,
    "Closing Rank": 8930
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2360,
    "Closing Rank": 2609
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4142,
    "Closing Rank": 4142
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1169,
    "Closing Rank": 1230
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "306P",
    "Closing Rank": "306P"
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8535,
    "Closing Rank": 10843
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15399,
    "Closing Rank": 15399
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1739,
    "Closing Rank": 1739
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3412,
    "Closing Rank": 3412
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3016,
    "Closing Rank": 4511
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7479,
    "Closing Rank": 7479
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1872,
    "Closing Rank": 2189
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1204,
    "Closing Rank": 1327
  },
  {
    "Institute": "Indian Institute of Technology Bhilai",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "622P",
    "Closing Rank": "622P"
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4070,
    "Closing Rank": 5371
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10256,
    "Closing Rank": 11151
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 647,
    "Closing Rank": 776
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2155,
    "Closing Rank": 2155
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1756,
    "Closing Rank": 2002
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4318,
    "Closing Rank": 5336
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "120P",
    "Closing Rank": "120P"
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 732,
    "Closing Rank": 1024
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 968,
    "Closing Rank": 968
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 399,
    "Closing Rank": 519
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1272,
    "Closing Rank": 1272
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7765,
    "Closing Rank": 9603
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15164,
    "Closing Rank": 16560
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1366,
    "Closing Rank": 1634
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2597,
    "Closing Rank": 2597
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2992,
    "Closing Rank": 3573
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7365,
    "Closing Rank": 7435
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1771,
    "Closing Rank": 1889
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2935,
    "Closing Rank": 2935
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 926,
    "Closing Rank": 961
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1660,
    "Closing Rank": 1660
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4928,
    "Closing Rank": 6685
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12515,
    "Closing Rank": 13381
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 950,
    "Closing Rank": 1138
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2103,
    "Closing Rank": 2593
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5327,
    "Closing Rank": 5977
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1352,
    "Closing Rank": 1613
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 787,
    "Closing Rank": 814
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10277,
    "Closing Rank": 12066
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19611,
    "Closing Rank": 20054
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1682,
    "Closing Rank": 1781
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3178,
    "Closing Rank": 3178
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4133,
    "Closing Rank": 4696
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9232,
    "Closing Rank": 9256
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2175,
    "Closing Rank": 2427
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4019,
    "Closing Rank": 4169
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1065,
    "Closing Rank": 1151
  },
  {
    "Institute": "Indian Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "544P",
    "Closing Rank": "544P"
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12874,
    "Closing Rank": 15812
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19444,
    "Closing Rank": 23384
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2242,
    "Closing Rank": 2408
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4351,
    "Closing Rank": 4351
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4276,
    "Closing Rank": 5565
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9281,
    "Closing Rank": 9281
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2633,
    "Closing Rank": 2845
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4304,
    "Closing Rank": 4304
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1153,
    "Closing Rank": 1153
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "275P",
    "Closing Rank": "275P"
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4837,
    "Closing Rank": 6199
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8303,
    "Closing Rank": 9127
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "371P",
    "Closing Rank": "371P"
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 794,
    "Closing Rank": 819
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1841,
    "Closing Rank": 1841
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1567,
    "Closing Rank": 2069
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3591,
    "Closing Rank": 3962
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "41P",
    "Closing Rank": "41P"
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 475,
    "Closing Rank": 1097
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1821,
    "Closing Rank": 1821
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 537,
    "Closing Rank": 564
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 834,
    "Closing Rank": 834
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6267,
    "Closing Rank": 8084
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12138,
    "Closing Rank": 13283
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "214P",
    "Closing Rank": "214P"
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1054,
    "Closing Rank": 1176
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2033,
    "Closing Rank": 2033
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2412,
    "Closing Rank": 2788
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4822,
    "Closing Rank": 5639
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1371,
    "Closing Rank": 1602
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2742,
    "Closing Rank": 2742
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 718,
    "Closing Rank": 722
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1275,
    "Closing Rank": 1275
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8381,
    "Closing Rank": 9625
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12222,
    "Closing Rank": 15014
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1268,
    "Closing Rank": 1498
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2247,
    "Closing Rank": 2247
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3180,
    "Closing Rank": 3764
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6969,
    "Closing Rank": 7181
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1886,
    "Closing Rank": 2040
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2488,
    "Closing Rank": 2488
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 896,
    "Closing Rank": 965
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1552,
    "Closing Rank": 1552
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10901,
    "Closing Rank": 12540
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15990,
    "Closing Rank": 21578
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1710,
    "Closing Rank": 1897
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3701,
    "Closing Rank": 3701
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3458,
    "Closing Rank": 4625
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7669,
    "Closing Rank": 8130
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2018,
    "Closing Rank": 2440
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3546,
    "Closing Rank": 3546
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1119,
    "Closing Rank": 1187
  },
  {
    "Institute": "Indian Institute of Technology Palakkad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1786,
    "Closing Rank": 1786
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12624,
    "Closing Rank": 14341
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16379,
    "Closing Rank": 21110
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2041,
    "Closing Rank": 2324
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3426,
    "Closing Rank": 3426
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4996,
    "Closing Rank": 5277
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6944,
    "Closing Rank": 8251
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2406,
    "Closing Rank": 2979
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4007,
    "Closing Rank": 4007
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1263,
    "Closing Rank": 1291
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1789,
    "Closing Rank": 1789
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11383,
    "Closing Rank": 15429
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21139,
    "Closing Rank": 23179
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2271,
    "Closing Rank": 2374
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3528,
    "Closing Rank": 3528
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4477,
    "Closing Rank": 5432
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7602,
    "Closing Rank": 8332
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2565,
    "Closing Rank": 2754
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4324,
    "Closing Rank": 4324
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 994,
    "Closing Rank": 994
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "236P",
    "Closing Rank": "236P"
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1915,
    "Closing Rank": 5024
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5737,
    "Closing Rank": 6324
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "14P",
    "Closing Rank": "37P"
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 589,
    "Closing Rank": 727
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 913,
    "Closing Rank": 913
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1242,
    "Closing Rank": 1727
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2907,
    "Closing Rank": 3342
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "51P",
    "Closing Rank": "51P"
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 343,
    "Closing Rank": 1057
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1419,
    "Closing Rank": 1419
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "24P",
    "Closing Rank": "24P"
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 129,
    "Closing Rank": 492
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 750,
    "Closing Rank": 750
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6236,
    "Closing Rank": 8816
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7711,
    "Closing Rank": 12402
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1271,
    "Closing Rank": 1406
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2441,
    "Closing Rank": 2441
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2177,
    "Closing Rank": 3389
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4823,
    "Closing Rank": 6912
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1508,
    "Closing Rank": 1851
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2499,
    "Closing Rank": 2809
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 845,
    "Closing Rank": 951
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1364,
    "Closing Rank": 1364
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9589,
    "Closing Rank": 12605
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17187,
    "Closing Rank": 17187
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2123,
    "Closing Rank": 2123
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4535,
    "Closing Rank": 4808
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9033,
    "Closing Rank": 9033
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3026,
    "Closing Rank": 3026
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1190,
    "Closing Rank": 1190
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9340,
    "Closing Rank": 11509
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14969,
    "Closing Rank": 20361
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1677,
    "Closing Rank": 1758
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3045,
    "Closing Rank": 3045
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3807,
    "Closing Rank": 4295
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7373,
    "Closing Rank": 7833
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2322,
    "Closing Rank": 2558
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2923,
    "Closing Rank": 3875
  },
  {
    "Institute": "Indian Institute of Technology Tirupati",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1000,
    "Closing Rank": 1117
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12943,
    "Closing Rank": 15048
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18930,
    "Closing Rank": 21759
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2104,
    "Closing Rank": 2376
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3588,
    "Closing Rank": 3588
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5259,
    "Closing Rank": 5802
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2939,
    "Closing Rank": 3043
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4151,
    "Closing Rank": 4151
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1257,
    "Closing Rank": 1330
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "787P",
    "Closing Rank": "787P"
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11454,
    "Closing Rank": 15583
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23902,
    "Closing Rank": 25172
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2391,
    "Closing Rank": 2428
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4404,
    "Closing Rank": 4404
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5386,
    "Closing Rank": 5807
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2843,
    "Closing Rank": 3015
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4510,
    "Closing Rank": 4510
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 914,
    "Closing Rank": 1073
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "480P",
    "Closing Rank": "480P"
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3412,
    "Closing Rank": 6310
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8353,
    "Closing Rank": 12108
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 831,
    "Closing Rank": 945
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2119,
    "Closing Rank": 2119
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1584,
    "Closing Rank": 2341
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5389,
    "Closing Rank": 6685
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 923,
    "Closing Rank": 1093
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2147,
    "Closing Rank": 2147
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 525,
    "Closing Rank": 635
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7727,
    "Closing Rank": 10005
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15039,
    "Closing Rank": 18177
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1510,
    "Closing Rank": 1745
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2830,
    "Closing Rank": 2830
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3333,
    "Closing Rank": 4237
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7429,
    "Closing Rank": 7775
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1824,
    "Closing Rank": 2208
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3716,
    "Closing Rank": 3716
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1039,
    "Closing Rank": 1155
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14903,
    "Closing Rank": 16439
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24005,
    "Closing Rank": 25778
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2443,
    "Closing Rank": 2472
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4659,
    "Closing Rank": 4659
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5856,
    "Closing Rank": 6032
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2674,
    "Closing Rank": 3164
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4851,
    "Closing Rank": 4851
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1390,
    "Closing Rank": 1512
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "941P",
    "Closing Rank": "941P"
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6555,
    "Closing Rank": 7956
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12130,
    "Closing Rank": 14105
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "17P",
    "Closing Rank": "17P"
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1095,
    "Closing Rank": 1191
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2610,
    "Closing Rank": 3141
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7179,
    "Closing Rank": 7240
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1273,
    "Closing Rank": 1566
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2239,
    "Closing Rank": 2750
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 892,
    "Closing Rank": 1087
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10912,
    "Closing Rank": 12483
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21564,
    "Closing Rank": 23887
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1925,
    "Closing Rank": 1967
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3921,
    "Closing Rank": 3921
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4518,
    "Closing Rank": 5048
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2470,
    "Closing Rank": 2650
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3839,
    "Closing Rank": 3839
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1208,
    "Closing Rank": 1236
  },
  {
    "Institute": "Indian Institute of Technology Jammu",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "733P",
    "Closing Rank": "733P"
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14948,
    "Closing Rank": 15739
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24344,
    "Closing Rank": 24506
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2306,
    "Closing Rank": 2463
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4558,
    "Closing Rank": 4558
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5243,
    "Closing Rank": 5830
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3058,
    "Closing Rank": 3121
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4059,
    "Closing Rank": 4059
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Chemical and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1301,
    "Closing Rank": 1337
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14375,
    "Closing Rank": 15945
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24560,
    "Closing Rank": 25079
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2417,
    "Closing Rank": 2422
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3922,
    "Closing Rank": 3922
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5268,
    "Closing Rank": 5549
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2627,
    "Closing Rank": 2856
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4513,
    "Closing Rank": 4513
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Civil and Infrastructure Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1017,
    "Closing Rank": 1147
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3482,
    "Closing Rank": 6375
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4989,
    "Closing Rank": 11138
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "66P",
    "Closing Rank": "66P"
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 771,
    "Closing Rank": 906
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1652,
    "Closing Rank": 1652
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1732,
    "Closing Rank": 2225
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4785,
    "Closing Rank": 5409
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "148P",
    "Closing Rank": "148P"
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 975,
    "Closing Rank": 1295
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1716,
    "Closing Rank": 2129
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 422,
    "Closing Rank": 607
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1044,
    "Closing Rank": 1044
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7796,
    "Closing Rank": 9778
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15007,
    "Closing Rank": 15299
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1464,
    "Closing Rank": 1567
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2840,
    "Closing Rank": 2840
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3470,
    "Closing Rank": 3823
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7268,
    "Closing Rank": 7297
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1828,
    "Closing Rank": 2080
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2964,
    "Closing Rank": 2964
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 872,
    "Closing Rank": 906
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4752,
    "Closing Rank": 8772
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10983,
    "Closing Rank": 14717
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": "106P",
    "Closing Rank": "106P"
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1336,
    "Closing Rank": 1458
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2122,
    "Closing Rank": 2122
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2762,
    "Closing Rank": 3368
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5820,
    "Closing Rank": 6982
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1443,
    "Closing Rank": 1712
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2734,
    "Closing Rank": 2734
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 732,
    "Closing Rank": 850
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1347,
    "Closing Rank": 1347
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10655,
    "Closing Rank": 12851
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20242,
    "Closing Rank": 20440
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2265,
    "Closing Rank": 2339
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3749,
    "Closing Rank": 3749
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5100,
    "Closing Rank": 5258
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2948,
    "Closing Rank": 3133
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4066,
    "Closing Rank": 4066
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1405,
    "Closing Rank": 1442
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9258,
    "Closing Rank": 16968
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23467,
    "Closing Rank": 25762
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2473,
    "Closing Rank": 2524
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4489,
    "Closing Rank": 4489
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5925,
    "Closing Rank": 6185
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3183,
    "Closing Rank": 3302
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4768,
    "Closing Rank": 4859
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1503,
    "Closing Rank": 1549
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Interdisciplinary Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": "1036P",
    "Closing Rank": "1036P"
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4677,
    "Closing Rank": 7381
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11879,
    "Closing Rank": 12205
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 885,
    "Closing Rank": 1167
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2046,
    "Closing Rank": 2046
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2613,
    "Closing Rank": 2819
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6616,
    "Closing Rank": 6867
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1090,
    "Closing Rank": 1479
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2799,
    "Closing Rank": 2799
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 596,
    "Closing Rank": 788
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10317,
    "Closing Rank": 13435
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19074,
    "Closing Rank": 21591
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2024,
    "Closing Rank": 2196
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3563,
    "Closing Rank": 3563
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4218,
    "Closing Rank": 5082
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7628,
    "Closing Rank": 9014
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1327,
    "Closing Rank": 2629
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4302,
    "Closing Rank": 4303
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 664,
    "Closing Rank": 1213
  },
  {
    "Institute": "Indian Institute of Technology Dharwad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1687,
    "Closing Rank": 1687
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56402,
    "Closing Rank": 67216
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52325,
    "Closing Rank": 52325
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13529,
    "Closing Rank": 14091
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13481,
    "Closing Rank": 13481
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52003,
    "Closing Rank": 81089
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41323,
    "Closing Rank": 41323
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11387,
    "Closing Rank": 12966
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12329,
    "Closing Rank": 12329
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39435,
    "Closing Rank": 47967
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 54448,
    "Closing Rank": 57854
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7793,
    "Closing Rank": 7839
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15038,
    "Closing Rank": 16245
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18751,
    "Closing Rank": 18751
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7472,
    "Closing Rank": 7723
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9772,
    "Closing Rank": 9772
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2675,
    "Closing Rank": 2875
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36773,
    "Closing Rank": 56585
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38712,
    "Closing Rank": 51020
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 979,
    "Closing Rank": 979
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10871,
    "Closing Rank": 11340
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10402,
    "Closing Rank": 10402
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48968,
    "Closing Rank": 64555
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44955,
    "Closing Rank": 62792
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7565,
    "Closing Rank": 10304
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10115,
    "Closing Rank": 10115
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31059,
    "Closing Rank": 37272
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41493,
    "Closing Rank": 42080
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2013,
    "Closing Rank": 2013
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5981,
    "Closing Rank": 5981
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8350,
    "Closing Rank": 8350
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10761,
    "Closing Rank": 12648
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15841,
    "Closing Rank": 16448
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5270,
    "Closing Rank": 6743
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7303,
    "Closing Rank": 7303
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2359,
    "Closing Rank": 2493
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3849,
    "Closing Rank": 3849
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55135,
    "Closing Rank": 66693
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55142,
    "Closing Rank": 67182
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10955,
    "Closing Rank": 12304
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11219,
    "Closing Rank": 11219
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40504,
    "Closing Rank": 62329
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52917,
    "Closing Rank": 58441
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8777,
    "Closing Rank": 11809
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22842,
    "Closing Rank": 22842
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37743,
    "Closing Rank": 44274
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50776,
    "Closing Rank": 54742
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7142,
    "Closing Rank": 7181
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9339,
    "Closing Rank": 9339
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13014,
    "Closing Rank": 14293
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19773,
    "Closing Rank": 20397
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4999,
    "Closing Rank": 6228
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7576,
    "Closing Rank": 7576
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1297,
    "Closing Rank": 1307
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2735,
    "Closing Rank": 2735
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10292,
    "Closing Rank": 16940
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14911,
    "Closing Rank": 20475
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 657,
    "Closing Rank": 657
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2938,
    "Closing Rank": 4051
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3239,
    "Closing Rank": 3239
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 464,
    "Closing Rank": 464
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5814,
    "Closing Rank": 12760
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6128,
    "Closing Rank": 15646
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2346,
    "Closing Rank": 4220
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4131,
    "Closing Rank": 5763
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 198,
    "Closing Rank": 198
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2480,
    "Closing Rank": 6283
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12636,
    "Closing Rank": 12636
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6330,
    "Closing Rank": 10957
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14367,
    "Closing Rank": 17115
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 360,
    "Closing Rank": 360
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 271,
    "Closing Rank": 271
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1576,
    "Closing Rank": 1854
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2658,
    "Closing Rank": 2658
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2752,
    "Closing Rank": 3834
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6499,
    "Closing Rank": 7675
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 198,
    "Closing Rank": 198
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1461,
    "Closing Rank": 2109
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2606,
    "Closing Rank": 3012
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 462,
    "Closing Rank": 812
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1485,
    "Closing Rank": 1485
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37,
    "Closing Rank": 37
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16983,
    "Closing Rank": 20116
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21470,
    "Closing Rank": 21470
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4823,
    "Closing Rank": 4903
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12902,
    "Closing Rank": 21147
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31609,
    "Closing Rank": 40718
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4622,
    "Closing Rank": 5140
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6741,
    "Closing Rank": 6741
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6872,
    "Closing Rank": 6872
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11121,
    "Closing Rank": 14016
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20893,
    "Closing Rank": 22062
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 575,
    "Closing Rank": 575
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2129,
    "Closing Rank": 2239
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5049,
    "Closing Rank": 5293
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7871,
    "Closing Rank": 7871
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2501,
    "Closing Rank": 2827
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4676,
    "Closing Rank": 4676
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1140,
    "Closing Rank": 1140
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Data Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45,
    "Closing Rank": 45
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28005,
    "Closing Rank": 33719
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34911,
    "Closing Rank": 35126
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6845,
    "Closing Rank": 7109
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8759,
    "Closing Rank": 8759
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17886,
    "Closing Rank": 38019
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41167,
    "Closing Rank": 47050
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6375,
    "Closing Rank": 7741
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8258,
    "Closing Rank": 8258
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20623,
    "Closing Rank": 20623
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11802,
    "Closing Rank": 21545
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27336,
    "Closing Rank": 30087
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1193,
    "Closing Rank": 1193
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3422,
    "Closing Rank": 3474
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5200,
    "Closing Rank": 5200
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6597,
    "Closing Rank": 7231
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10974,
    "Closing Rank": 10974
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3909,
    "Closing Rank": 4170
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5036,
    "Closing Rank": 5036
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1336,
    "Closing Rank": 1355
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2598,
    "Closing Rank": 2598
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14933,
    "Closing Rank": 26835
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28204,
    "Closing Rank": 32778
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2409,
    "Closing Rank": 2409
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4315,
    "Closing Rank": 6149
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7307,
    "Closing Rank": 7307
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13511,
    "Closing Rank": 33738
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21898,
    "Closing Rank": 40885
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4550,
    "Closing Rank": 6638
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7387,
    "Closing Rank": 7387
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6588,
    "Closing Rank": 9295
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19836,
    "Closing Rank": 19836
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11652,
    "Closing Rank": 15270
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22206,
    "Closing Rank": 23262
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 848,
    "Closing Rank": 941
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2388,
    "Closing Rank": 2412
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3811,
    "Closing Rank": 3811
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 152,
    "Closing Rank": 152
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5133,
    "Closing Rank": 5653
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8211,
    "Closing Rank": 8408
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3120,
    "Closing Rank": 3552
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4615,
    "Closing Rank": 4615
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1101,
    "Closing Rank": 1167
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1569,
    "Closing Rank": 1569
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26375,
    "Closing Rank": 28308
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33893,
    "Closing Rank": 33893
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6966,
    "Closing Rank": 6966
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31100,
    "Closing Rank": 35696
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41484,
    "Closing Rank": 41484
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6134,
    "Closing Rank": 6917
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8161,
    "Closing Rank": 8161
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29341,
    "Closing Rank": 29341
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15751,
    "Closing Rank": 16973
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25894,
    "Closing Rank": 25894
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1184,
    "Closing Rank": 1184
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2595,
    "Closing Rank": 2636
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5925,
    "Closing Rank": 6347
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10301,
    "Closing Rank": 10301
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3928,
    "Closing Rank": 3928
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5579,
    "Closing Rank": 5579
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1884,
    "Closing Rank": 1884
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51820,
    "Closing Rank": 67982
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36750,
    "Closing Rank": 63028
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12054,
    "Closing Rank": 13177
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13447,
    "Closing Rank": 13447
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60779,
    "Closing Rank": 74512
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56040,
    "Closing Rank": 80485
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9709,
    "Closing Rank": 14319
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14955,
    "Closing Rank": 14955
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37487,
    "Closing Rank": 46905
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50826,
    "Closing Rank": 55941
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3491,
    "Closing Rank": 3491
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7709,
    "Closing Rank": 7763
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13878,
    "Closing Rank": 16102
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16630,
    "Closing Rank": 16630
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6956,
    "Closing Rank": 7383
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10263,
    "Closing Rank": 10263
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2470,
    "Closing Rank": 2929
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2420,
    "Closing Rank": 2420
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17521,
    "Closing Rank": 26077
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21818,
    "Closing Rank": 25376
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4504,
    "Closing Rank": 5397
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4297,
    "Closing Rank": 4297
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12957,
    "Closing Rank": 20809
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23552,
    "Closing Rank": 30554
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4090,
    "Closing Rank": 6508
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6389,
    "Closing Rank": 7016
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6338,
    "Closing Rank": 19832
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10765,
    "Closing Rank": 14120
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16421,
    "Closing Rank": 21173
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 573,
    "Closing Rank": 573
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2033,
    "Closing Rank": 2033
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2110,
    "Closing Rank": 2220
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3841,
    "Closing Rank": 3841
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3941,
    "Closing Rank": 4985
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7827,
    "Closing Rank": 8296
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 340,
    "Closing Rank": 340
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2805,
    "Closing Rank": 3107
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3427,
    "Closing Rank": 3427
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 978,
    "Closing Rank": 1173
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35090,
    "Closing Rank": 48219
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36352,
    "Closing Rank": 42712
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8030,
    "Closing Rank": 10288
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8794,
    "Closing Rank": 8794
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36232,
    "Closing Rank": 48231
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48210,
    "Closing Rank": 53998
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7747,
    "Closing Rank": 9775
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9647,
    "Closing Rank": 9647
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23302,
    "Closing Rank": 30740
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34421,
    "Closing Rank": 39256
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2481,
    "Closing Rank": 2481
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5449,
    "Closing Rank": 5542
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6363,
    "Closing Rank": 6363
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8431,
    "Closing Rank": 11353
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14216,
    "Closing Rank": 17410
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1486,
    "Closing Rank": 1486
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4708,
    "Closing Rank": 6410
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5683,
    "Closing Rank": 5683
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2605,
    "Closing Rank": 2869
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3248,
    "Closing Rank": 3248
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21758,
    "Closing Rank": 26506
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27011,
    "Closing Rank": 27011
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4979,
    "Closing Rank": 5775
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15075,
    "Closing Rank": 31705
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30730,
    "Closing Rank": 30730
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7257,
    "Closing Rank": 7725
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7233,
    "Closing Rank": 7233
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6525,
    "Closing Rank": 6525
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11793,
    "Closing Rank": 14559
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22482,
    "Closing Rank": 23411
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1541,
    "Closing Rank": 1541
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2270,
    "Closing Rank": 2365
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4690,
    "Closing Rank": 5314
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8651,
    "Closing Rank": 8651
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2982,
    "Closing Rank": 3466
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4855,
    "Closing Rank": 4855
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1088,
    "Closing Rank": 1088
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29363,
    "Closing Rank": 48460
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45677,
    "Closing Rank": 49462
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8135,
    "Closing Rank": 10528
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25038,
    "Closing Rank": 47293
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41011,
    "Closing Rank": 56439
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8442,
    "Closing Rank": 10075
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12658,
    "Closing Rank": 13333
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23454,
    "Closing Rank": 30878
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41224,
    "Closing Rank": 43899
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1513,
    "Closing Rank": 1513
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4953,
    "Closing Rank": 5067
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7808,
    "Closing Rank": 7808
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9064,
    "Closing Rank": 10702
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16501,
    "Closing Rank": 17625
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1751,
    "Closing Rank": 1751
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5017,
    "Closing Rank": 5733
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8464,
    "Closing Rank": 8626
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2143,
    "Closing Rank": 2293
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3699,
    "Closing Rank": 3699
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 67538,
    "Closing Rank": 80933
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 68128,
    "Closing Rank": 76737
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14138,
    "Closing Rank": 14966
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14493,
    "Closing Rank": 14493
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66171,
    "Closing Rank": 81118
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 78061,
    "Closing Rank": 83770
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12817,
    "Closing Rank": 15261
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15499,
    "Closing Rank": 15499
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46988,
    "Closing Rank": 50863
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56931,
    "Closing Rank": 58773
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8611,
    "Closing Rank": 8679
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9710,
    "Closing Rank": 9710
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16322,
    "Closing Rank": 17382
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21107,
    "Closing Rank": 21114
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7929,
    "Closing Rank": 8911
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10819,
    "Closing Rank": 10831
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3388,
    "Closing Rank": 3462
  },
  {
    "Institute": "Dr. B R Ambedkar National Institute of Technology, Jalandhar",
    "Academic Program Name": "Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3592,
    "Closing Rank": 3592
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 757,
    "Closing Rank": 2524
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 712,
    "Closing Rank": 1296
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 314,
    "Closing Rank": 751
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1134,
    "Closing Rank": 2298
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1476,
    "Closing Rank": 1493
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 424,
    "Closing Rank": 1669
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 368,
    "Closing Rank": 368
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 433,
    "Closing Rank": 650
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1545,
    "Closing Rank": 1545
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 497,
    "Closing Rank": 926
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 316,
    "Closing Rank": 631
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 270,
    "Closing Rank": 417
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 165,
    "Closing Rank": 165
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 348,
    "Closing Rank": 700
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 703,
    "Closing Rank": 722
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 159,
    "Closing Rank": 321
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 143,
    "Closing Rank": 143
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 78,
    "Closing Rank": 192
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 65,
    "Closing Rank": 65
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5228,
    "Closing Rank": 6022
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1009,
    "Closing Rank": 1009
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1865,
    "Closing Rank": 1865
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2333,
    "Closing Rank": 2804
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2209,
    "Closing Rank": 2209
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1261,
    "Closing Rank": 1968
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3147,
    "Closing Rank": 3147
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 430,
    "Closing Rank": 430
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1168,
    "Closing Rank": 1168
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4505,
    "Closing Rank": 5514
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7880,
    "Closing Rank": 7880
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 937,
    "Closing Rank": 951
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1598,
    "Closing Rank": 1598
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1831,
    "Closing Rank": 2173
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4883,
    "Closing Rank": 4883
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1275,
    "Closing Rank": 1349
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3122,
    "Closing Rank": 3122
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 521,
    "Closing Rank": 521
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19731,
    "Closing Rank": 27394
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17887,
    "Closing Rank": 31162
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1615,
    "Closing Rank": 1615
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4427,
    "Closing Rank": 4727
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6051,
    "Closing Rank": 6051
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7598,
    "Closing Rank": 9223
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10549,
    "Closing Rank": 17034
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1795,
    "Closing Rank": 5265
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7515,
    "Closing Rank": 7563
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 586,
    "Closing Rank": 1729
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3006,
    "Closing Rank": 3006
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21226,
    "Closing Rank": 25049
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26719,
    "Closing Rank": 32066
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2039,
    "Closing Rank": 2039
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3958,
    "Closing Rank": 4344
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6406,
    "Closing Rank": 6406
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8299,
    "Closing Rank": 9283
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13155,
    "Closing Rank": 15790
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 905,
    "Closing Rank": 905
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4408,
    "Closing Rank": 5396
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4796,
    "Closing Rank": 7023
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2007,
    "Closing Rank": 2399
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3379,
    "Closing Rank": 3379
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16373,
    "Closing Rank": 30509
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25667,
    "Closing Rank": 35785
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2078,
    "Closing Rank": 3096
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4842,
    "Closing Rank": 5361
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6104,
    "Closing Rank": 6104
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8841,
    "Closing Rank": 10919
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10828,
    "Closing Rank": 17360
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2660,
    "Closing Rank": 4735
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6036,
    "Closing Rank": 6609
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 209,
    "Closing Rank": 261
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 453,
    "Closing Rank": 453
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15273,
    "Closing Rank": 32608
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27994,
    "Closing Rank": 44371
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 958,
    "Closing Rank": 958
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5307,
    "Closing Rank": 5733
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7448,
    "Closing Rank": 7448
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 447,
    "Closing Rank": 447
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9542,
    "Closing Rank": 11936
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14832,
    "Closing Rank": 16471
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1196,
    "Closing Rank": 5549
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6992,
    "Closing Rank": 7033
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 862,
    "Closing Rank": 1480
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2202,
    "Closing Rank": 2202
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3099,
    "Closing Rank": 5435
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5725,
    "Closing Rank": 8836
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 68,
    "Closing Rank": 68
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 785,
    "Closing Rank": 957
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1718,
    "Closing Rank": 1718
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 117,
    "Closing Rank": 117
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1669,
    "Closing Rank": 2184
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2908,
    "Closing Rank": 3086
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 97,
    "Closing Rank": 97
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 604,
    "Closing Rank": 1182
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1697,
    "Closing Rank": 2821
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 170,
    "Closing Rank": 203
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 864,
    "Closing Rank": 864
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1501,
    "Closing Rank": 4711
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5097,
    "Closing Rank": 7220
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 179,
    "Closing Rank": 179
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 345,
    "Closing Rank": 345
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 644,
    "Closing Rank": 736
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1374,
    "Closing Rank": 1374
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1023,
    "Closing Rank": 1781
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2576,
    "Closing Rank": 4063
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 121,
    "Closing Rank": 121
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 557,
    "Closing Rank": 1015
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1315,
    "Closing Rank": 1315
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30,
    "Closing Rank": 30
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 253,
    "Closing Rank": 433
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 708,
    "Closing Rank": 708
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11057,
    "Closing Rank": 14001
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17031,
    "Closing Rank": 21644
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2014,
    "Closing Rank": 2014
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2186,
    "Closing Rank": 2322
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3638,
    "Closing Rank": 3638
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4062,
    "Closing Rank": 5155
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8380,
    "Closing Rank": 8807
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 181,
    "Closing Rank": 181
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 718,
    "Closing Rank": 2981
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4446,
    "Closing Rank": 4446
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 234,
    "Closing Rank": 674
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 824,
    "Closing Rank": 824
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9332,
    "Closing Rank": 13170
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15637,
    "Closing Rank": 19755
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 787,
    "Closing Rank": 787
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2058,
    "Closing Rank": 2190
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3441,
    "Closing Rank": 3441
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4480,
    "Closing Rank": 5425
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8772,
    "Closing Rank": 9480
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 400,
    "Closing Rank": 400
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2527,
    "Closing Rank": 3053
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4310,
    "Closing Rank": 4824
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1006,
    "Closing Rank": 1125
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 716,
    "Closing Rank": 716
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6149,
    "Closing Rank": 10321
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10829,
    "Closing Rank": 15405
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2118,
    "Closing Rank": 2118
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1473,
    "Closing Rank": 1700
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2941,
    "Closing Rank": 2941
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2892,
    "Closing Rank": 3768
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5587,
    "Closing Rank": 7318
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 331,
    "Closing Rank": 331
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2016,
    "Closing Rank": 2472
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4307,
    "Closing Rank": 4307
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 352,
    "Closing Rank": 546
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1675,
    "Closing Rank": 1675
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6929,
    "Closing Rank": 8437
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5851,
    "Closing Rank": 15765
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 858,
    "Closing Rank": 858
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1514,
    "Closing Rank": 1578
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2489,
    "Closing Rank": 2489
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75,
    "Closing Rank": 75
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2471,
    "Closing Rank": 3879
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5381,
    "Closing Rank": 6307
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 270,
    "Closing Rank": 270
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2071,
    "Closing Rank": 2297
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3182,
    "Closing Rank": 3182
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 685,
    "Closing Rank": 925
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1422,
    "Closing Rank": 1422
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11382,
    "Closing Rank": 20618
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24624,
    "Closing Rank": 31631
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 999,
    "Closing Rank": 999
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3246,
    "Closing Rank": 3472
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5312,
    "Closing Rank": 5312
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5289,
    "Closing Rank": 7466
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10823,
    "Closing Rank": 11963
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3339,
    "Closing Rank": 4206
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4548,
    "Closing Rank": 4552
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 744,
    "Closing Rank": 1105
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1718,
    "Closing Rank": 1718
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5685,
    "Closing Rank": 20436
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30023,
    "Closing Rank": 33594
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1308,
    "Closing Rank": 1308
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3237,
    "Closing Rank": 3611
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5976,
    "Closing Rank": 5976
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 259,
    "Closing Rank": 259
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6792,
    "Closing Rank": 8361
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13799,
    "Closing Rank": 15498
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3432,
    "Closing Rank": 4770
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6372,
    "Closing Rank": 6372
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1205,
    "Closing Rank": 1756
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2452,
    "Closing Rank": 2452
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28556,
    "Closing Rank": 36368
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37292,
    "Closing Rank": 44634
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1846,
    "Closing Rank": 1846
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5925,
    "Closing Rank": 5993
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7335,
    "Closing Rank": 7335
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11468,
    "Closing Rank": 13199
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18962,
    "Closing Rank": 19523
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5297,
    "Closing Rank": 6530
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1938,
    "Closing Rank": 1938
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3329,
    "Closing Rank": 3329
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32851,
    "Closing Rank": 36965
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39308,
    "Closing Rank": 48668
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2574,
    "Closing Rank": 2574
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6076,
    "Closing Rank": 6267
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9625,
    "Closing Rank": 9625
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11740,
    "Closing Rank": 13480
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17202,
    "Closing Rank": 19038
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1647,
    "Closing Rank": 1647
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6206,
    "Closing Rank": 6813
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8536,
    "Closing Rank": 8536
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2571,
    "Closing Rank": 2607
  },
  {
    "Institute": "Malaviya National Institute of Technology Jaipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4286,
    "Closing Rank": 4286
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 796,
    "Closing Rank": 2945
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 843,
    "Closing Rank": 1269
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 419,
    "Closing Rank": 949
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 456,
    "Closing Rank": 456
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1416,
    "Closing Rank": 2919
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 949,
    "Closing Rank": 1724
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 462,
    "Closing Rank": 1024
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 746,
    "Closing Rank": 746
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 290,
    "Closing Rank": 437
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 226,
    "Closing Rank": 226
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 947,
    "Closing Rank": 1469
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 849,
    "Closing Rank": 1080
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 264,
    "Closing Rank": 616
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 326,
    "Closing Rank": 326
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 735,
    "Closing Rank": 863
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 709,
    "Closing Rank": 716
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 123,
    "Closing Rank": 414
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 378,
    "Closing Rank": 394
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 163,
    "Closing Rank": 211
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 130,
    "Closing Rank": 130
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27303,
    "Closing Rank": 38413
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25924,
    "Closing Rank": 37635
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6229,
    "Closing Rank": 6432
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6607,
    "Closing Rank": 6607
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11056,
    "Closing Rank": 13782
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17944,
    "Closing Rank": 18673
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4393,
    "Closing Rank": 6650
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10066,
    "Closing Rank": 10066
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3508,
    "Closing Rank": 4113
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4783,
    "Closing Rank": 4783
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30761,
    "Closing Rank": 34554
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39181,
    "Closing Rank": 41894
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2400,
    "Closing Rank": 2718
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5629,
    "Closing Rank": 5705
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7483,
    "Closing Rank": 7483
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10697,
    "Closing Rank": 12168
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18460,
    "Closing Rank": 18558
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1403,
    "Closing Rank": 1403
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6121,
    "Closing Rank": 6723
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8374,
    "Closing Rank": 8374
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2441,
    "Closing Rank": 2455
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3802,
    "Closing Rank": 3802
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29755,
    "Closing Rank": 42663
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47043,
    "Closing Rank": 56610
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 907,
    "Closing Rank": 907
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6941,
    "Closing Rank": 7386
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9439,
    "Closing Rank": 9439
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13635,
    "Closing Rank": 15351
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19990,
    "Closing Rank": 22491
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4326,
    "Closing Rank": 7405
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9142,
    "Closing Rank": 9524
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2315,
    "Closing Rank": 3300
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3840,
    "Closing Rank": 3840
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24066,
    "Closing Rank": 39973
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41996,
    "Closing Rank": 51012
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1606,
    "Closing Rank": 1606
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6765,
    "Closing Rank": 6908
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8760,
    "Closing Rank": 8760
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 455,
    "Closing Rank": 455
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11465,
    "Closing Rank": 13911
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19837,
    "Closing Rank": 20228
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1282,
    "Closing Rank": 1282
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5301,
    "Closing Rank": 6915
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9368,
    "Closing Rank": 9394
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1344,
    "Closing Rank": 1693
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2745,
    "Closing Rank": 2745
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6025,
    "Closing Rank": 10832
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7156,
    "Closing Rank": 15768
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 582,
    "Closing Rank": 692
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1686,
    "Closing Rank": 2088
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2603,
    "Closing Rank": 2652
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2698,
    "Closing Rank": 4066
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4903,
    "Closing Rank": 9576
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 293,
    "Closing Rank": 301
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 992,
    "Closing Rank": 2294
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1403,
    "Closing Rank": 2884
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 330,
    "Closing Rank": 330
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 367,
    "Closing Rank": 1710
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2617,
    "Closing Rank": 2623
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6217,
    "Closing Rank": 8830
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9487,
    "Closing Rank": 14804
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 311,
    "Closing Rank": 313
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1310,
    "Closing Rank": 1440
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2378,
    "Closing Rank": 2485
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93,
    "Closing Rank": 93
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2535,
    "Closing Rank": 3521
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4222,
    "Closing Rank": 6632
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 162,
    "Closing Rank": 162
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1244,
    "Closing Rank": 1993
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2737,
    "Closing Rank": 3262
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52,
    "Closing Rank": 52
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 635,
    "Closing Rank": 738
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1161,
    "Closing Rank": 1328
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39,
    "Closing Rank": 39
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11659,
    "Closing Rank": 21323
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25923,
    "Closing Rank": 30690
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 515,
    "Closing Rank": 2476
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3343,
    "Closing Rank": 3635
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5313,
    "Closing Rank": 5313
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5738,
    "Closing Rank": 8153
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11703,
    "Closing Rank": 13895
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3881,
    "Closing Rank": 5006
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6364,
    "Closing Rank": 7121
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2074,
    "Closing Rank": 2661
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4312,
    "Closing Rank": 4312
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15886,
    "Closing Rank": 20334
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24069,
    "Closing Rank": 28138
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1452,
    "Closing Rank": 1452
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3240,
    "Closing Rank": 3513
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4671,
    "Closing Rank": 4671
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5919,
    "Closing Rank": 6910
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10361,
    "Closing Rank": 12303
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 645,
    "Closing Rank": 645
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2961,
    "Closing Rank": 4425
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4619,
    "Closing Rank": 6905
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1153,
    "Closing Rank": 1561
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2345,
    "Closing Rank": 2345
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11814,
    "Closing Rank": 17869
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18855,
    "Closing Rank": 25392
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2080,
    "Closing Rank": 2201
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2736,
    "Closing Rank": 2916
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4001,
    "Closing Rank": 4362
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4547,
    "Closing Rank": 6801
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11564,
    "Closing Rank": 13773
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2299,
    "Closing Rank": 3825
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3785,
    "Closing Rank": 5867
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1311,
    "Closing Rank": 2318
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3085,
    "Closing Rank": 3085
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11457,
    "Closing Rank": 14955
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19586,
    "Closing Rank": 22084
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 495,
    "Closing Rank": 551
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2349,
    "Closing Rank": 2517
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3530,
    "Closing Rank": 4068
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4277,
    "Closing Rank": 5524
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8158,
    "Closing Rank": 9991
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 378,
    "Closing Rank": 378
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2535,
    "Closing Rank": 3260
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4070,
    "Closing Rank": 5350
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 898,
    "Closing Rank": 1293
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1606,
    "Closing Rank": 1606
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49,
    "Closing Rank": 49
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43281,
    "Closing Rank": 47044
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51659,
    "Closing Rank": 53087
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7658,
    "Closing Rank": 8077
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10880,
    "Closing Rank": 10880
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14506,
    "Closing Rank": 17158
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22862,
    "Closing Rank": 23582
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7227,
    "Closing Rank": 8410
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11362,
    "Closing Rank": 11362
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4503,
    "Closing Rank": 4645
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3850,
    "Closing Rank": 3850
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35097,
    "Closing Rank": 44668
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52836,
    "Closing Rank": 53135
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7235,
    "Closing Rank": 7347
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8858,
    "Closing Rank": 8858
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14640,
    "Closing Rank": 15097
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19139,
    "Closing Rank": 19678
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1240,
    "Closing Rank": 1240
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7000,
    "Closing Rank": 7626
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8278,
    "Closing Rank": 8278
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2880,
    "Closing Rank": 2966
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Materials Science and Metallurgical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4432,
    "Closing Rank": 4432
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11498,
    "Closing Rank": 16047
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21932,
    "Closing Rank": 21932
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2646,
    "Closing Rank": 2646
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4444,
    "Closing Rank": 4444
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5141,
    "Closing Rank": 6033
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11562,
    "Closing Rank": 11562
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4311,
    "Closing Rank": 5514
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1899,
    "Closing Rank": 1899
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12973,
    "Closing Rank": 14740
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21302,
    "Closing Rank": 21302
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2268,
    "Closing Rank": 2268
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3728,
    "Closing Rank": 5360
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10583,
    "Closing Rank": 10583
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3521,
    "Closing Rank": 3655
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5794,
    "Closing Rank": 5794
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mathematics and Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1120,
    "Closing Rank": 1120
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22235,
    "Closing Rank": 34634
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41538,
    "Closing Rank": 49166
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3330,
    "Closing Rank": 3919
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5561,
    "Closing Rank": 6141
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8032,
    "Closing Rank": 8159
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9694,
    "Closing Rank": 11952
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17659,
    "Closing Rank": 19844
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5074,
    "Closing Rank": 6634
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7952,
    "Closing Rank": 9483
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1833,
    "Closing Rank": 3335
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5168,
    "Closing Rank": 5168
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22699,
    "Closing Rank": 29993
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36154,
    "Closing Rank": 42003
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 949,
    "Closing Rank": 2499
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2018,
    "Closing Rank": 2018
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4897,
    "Closing Rank": 5280
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7353,
    "Closing Rank": 7837
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 446,
    "Closing Rank": 446
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9182,
    "Closing Rank": 10546
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16582,
    "Closing Rank": 18396
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1392,
    "Closing Rank": 1392
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4906,
    "Closing Rank": 6108
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7422,
    "Closing Rank": 8986
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1728,
    "Closing Rank": 2147
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2730,
    "Closing Rank": 2730
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 726,
    "Closing Rank": 3194
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 540,
    "Closing Rank": 696
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 678,
    "Closing Rank": 1316
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2443,
    "Closing Rank": 3671
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1658,
    "Closing Rank": 1658
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 381,
    "Closing Rank": 457
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 221,
    "Closing Rank": 221
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 357,
    "Closing Rank": 515
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 339,
    "Closing Rank": 781
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 555,
    "Closing Rank": 640
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 141,
    "Closing Rank": 181
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 281,
    "Closing Rank": 391
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 318,
    "Closing Rank": 340
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 94,
    "Closing Rank": 111
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 280,
    "Closing Rank": 280
  },
  {
    "Institute": "Maulana Azad National Institute of Technology Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52,
    "Closing Rank": 54
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27136,
    "Closing Rank": 34919
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39598,
    "Closing Rank": 42103
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5746,
    "Closing Rank": 5760
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9332,
    "Closing Rank": 9332
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10858,
    "Closing Rank": 14261
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5208,
    "Closing Rank": 6025
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7368,
    "Closing Rank": 7368
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 258,
    "Closing Rank": 258
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2979,
    "Closing Rank": 5286
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32231,
    "Closing Rank": 35422
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34506,
    "Closing Rank": 38879
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6178,
    "Closing Rank": 6220
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8121,
    "Closing Rank": 8121
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11784,
    "Closing Rank": 13918
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17845,
    "Closing Rank": 17845
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1487,
    "Closing Rank": 1487
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7112,
    "Closing Rank": 7750
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8654,
    "Closing Rank": 8654
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2616,
    "Closing Rank": 2672
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3734,
    "Closing Rank": 3734
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20435,
    "Closing Rank": 22101
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31499,
    "Closing Rank": 32646
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1450,
    "Closing Rank": 1450
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3629,
    "Closing Rank": 3652
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6114,
    "Closing Rank": 6114
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6240,
    "Closing Rank": 7973
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12241,
    "Closing Rank": 12241
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3305,
    "Closing Rank": 3750
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5740,
    "Closing Rank": 6579
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2951,
    "Closing Rank": 3724
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18237,
    "Closing Rank": 21061
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20419,
    "Closing Rank": 34050
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2273,
    "Closing Rank": 2273
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3752,
    "Closing Rank": 3752
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5973,
    "Closing Rank": 5973
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7491,
    "Closing Rank": 8348
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14313,
    "Closing Rank": 14313
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4254,
    "Closing Rank": 4922
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4654,
    "Closing Rank": 4654
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1799,
    "Closing Rank": 1839
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18477,
    "Closing Rank": 26362
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41257,
    "Closing Rank": 42910
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 696,
    "Closing Rank": 696
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4163,
    "Closing Rank": 4528
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7060,
    "Closing Rank": 7060
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7788,
    "Closing Rank": 9043
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14771,
    "Closing Rank": 17586
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 600,
    "Closing Rank": 600
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2337,
    "Closing Rank": 4509
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4816,
    "Closing Rank": 4816
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3273,
    "Closing Rank": 3551
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2207,
    "Closing Rank": 2207
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15324,
    "Closing Rank": 29422
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29464,
    "Closing Rank": 41013
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 668,
    "Closing Rank": 668
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2191,
    "Closing Rank": 2191
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4853,
    "Closing Rank": 5018
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7244,
    "Closing Rank": 7244
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 167,
    "Closing Rank": 167
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8930,
    "Closing Rank": 10495
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12963,
    "Closing Rank": 15463
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4590,
    "Closing Rank": 5314
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6075,
    "Closing Rank": 6828
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 460,
    "Closing Rank": 1232
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2082,
    "Closing Rank": 2082
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2401,
    "Closing Rank": 5943
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4497,
    "Closing Rank": 9834
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 74,
    "Closing Rank": 117
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 267,
    "Closing Rank": 267
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 857,
    "Closing Rank": 1044
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1685,
    "Closing Rank": 1770
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1312,
    "Closing Rank": 2802
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2775,
    "Closing Rank": 5075
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75,
    "Closing Rank": 80
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 481,
    "Closing Rank": 481
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 213,
    "Closing Rank": 1136
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 890,
    "Closing Rank": 1286
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16,
    "Closing Rank": 16
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 155,
    "Closing Rank": 927
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2425,
    "Closing Rank": 3286
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26,
    "Closing Rank": 26
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2417,
    "Closing Rank": 4191
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5177,
    "Closing Rank": 8838
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59,
    "Closing Rank": 134
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 584,
    "Closing Rank": 709
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1372,
    "Closing Rank": 1373
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 157,
    "Closing Rank": 157
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 982,
    "Closing Rank": 1605
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2836,
    "Closing Rank": 4113
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 91,
    "Closing Rank": 120
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 173,
    "Closing Rank": 1072
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 942,
    "Closing Rank": 1873
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22,
    "Closing Rank": 22
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 84,
    "Closing Rank": 84
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 256,
    "Closing Rank": 428
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 614,
    "Closing Rank": 657
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15,
    "Closing Rank": 15
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9340,
    "Closing Rank": 11921
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18796,
    "Closing Rank": 23004
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 476,
    "Closing Rank": 476
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1799,
    "Closing Rank": 1894
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3944,
    "Closing Rank": 3944
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3867,
    "Closing Rank": 4534
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8604,
    "Closing Rank": 8929
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 262,
    "Closing Rank": 262
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1542,
    "Closing Rank": 2528
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4107,
    "Closing Rank": 4290
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1554,
    "Closing Rank": 1785
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3324,
    "Closing Rank": 3324
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9008,
    "Closing Rank": 10928
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15078,
    "Closing Rank": 16066
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 148,
    "Closing Rank": 148
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2103,
    "Closing Rank": 2103
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1651,
    "Closing Rank": 1904
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2921,
    "Closing Rank": 2921
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3613,
    "Closing Rank": 4927
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6371,
    "Closing Rank": 8223
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 542,
    "Closing Rank": 542
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2148,
    "Closing Rank": 2605
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4803,
    "Closing Rank": 4803
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 604,
    "Closing Rank": 870
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2215,
    "Closing Rank": 2215
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6010,
    "Closing Rank": 9231
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12293,
    "Closing Rank": 18170
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 217,
    "Closing Rank": 217
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1469,
    "Closing Rank": 1469
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1335,
    "Closing Rank": 1580
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3126,
    "Closing Rank": 3164
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2662,
    "Closing Rank": 3859
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5248,
    "Closing Rank": 6445
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 183,
    "Closing Rank": 183
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1111,
    "Closing Rank": 2050
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3461,
    "Closing Rank": 3883
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 986,
    "Closing Rank": 1546
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3457,
    "Closing Rank": 3457
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5805,
    "Closing Rank": 7707
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10646,
    "Closing Rank": 14134
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 307,
    "Closing Rank": 307
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1282,
    "Closing Rank": 1282
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1059,
    "Closing Rank": 1402
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2368,
    "Closing Rank": 2368
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1823,
    "Closing Rank": 3043
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4773,
    "Closing Rank": 6250
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 266,
    "Closing Rank": 266
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1321,
    "Closing Rank": 2062
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2365,
    "Closing Rank": 2365
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 199,
    "Closing Rank": 199
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 513,
    "Closing Rank": 823
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1578,
    "Closing Rank": 1665
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11365,
    "Closing Rank": 11794
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19712,
    "Closing Rank": 19712
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 524,
    "Closing Rank": 524
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2056,
    "Closing Rank": 2056
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 266,
    "Closing Rank": 266
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4931,
    "Closing Rank": 5531
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9447,
    "Closing Rank": 9447
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2587,
    "Closing Rank": 2891
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2347,
    "Closing Rank": 2347
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11306,
    "Closing Rank": 11650
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17028,
    "Closing Rank": 17028
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2177,
    "Closing Rank": 2177
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3958,
    "Closing Rank": 5233
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7500,
    "Closing Rank": 7500
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2879,
    "Closing Rank": 3141
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5623,
    "Closing Rank": 5623
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1374,
    "Closing Rank": 1374
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26249,
    "Closing Rank": 30318
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36170,
    "Closing Rank": 36170
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1863,
    "Closing Rank": 1863
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5411,
    "Closing Rank": 5411
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9718,
    "Closing Rank": 13882
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18280,
    "Closing Rank": 18280
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3838,
    "Closing Rank": 5162
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5733,
    "Closing Rank": 5733
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36256,
    "Closing Rank": 38008
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48623,
    "Closing Rank": 48623
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6475,
    "Closing Rank": 6475
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12778,
    "Closing Rank": 13589
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18572,
    "Closing Rank": 18572
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5366,
    "Closing Rank": 7293
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9817,
    "Closing Rank": 9817
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2091,
    "Closing Rank": 2091
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10836,
    "Closing Rank": 19615
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29041,
    "Closing Rank": 33524
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 590,
    "Closing Rank": 590
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3185,
    "Closing Rank": 3435
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5806,
    "Closing Rank": 6001
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 170,
    "Closing Rank": 170
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5592,
    "Closing Rank": 7622
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9530,
    "Closing Rank": 13762
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 477,
    "Closing Rank": 477
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3197,
    "Closing Rank": 3587
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5984,
    "Closing Rank": 6972
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2376,
    "Closing Rank": 2560
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3534,
    "Closing Rank": 3534
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14116,
    "Closing Rank": 19748
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27632,
    "Closing Rank": 33989
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 512,
    "Closing Rank": 512
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3555,
    "Closing Rank": 3555
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3105,
    "Closing Rank": 3486
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6246,
    "Closing Rank": 6258
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5652,
    "Closing Rank": 7823
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11708,
    "Closing Rank": 15527
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 568,
    "Closing Rank": 568
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3566,
    "Closing Rank": 4759
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5887,
    "Closing Rank": 6522
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1531,
    "Closing Rank": 1643
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2450,
    "Closing Rank": 2450
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23990,
    "Closing Rank": 32872
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43130,
    "Closing Rank": 50847
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2019,
    "Closing Rank": 2019
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5442,
    "Closing Rank": 5612
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8813,
    "Closing Rank": 8813
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9663,
    "Closing Rank": 12822
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4647,
    "Closing Rank": 5828
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8962,
    "Closing Rank": 8962
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3228,
    "Closing Rank": 4689
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26669,
    "Closing Rank": 35559
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34608,
    "Closing Rank": 42691
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3306,
    "Closing Rank": 3306
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5763,
    "Closing Rank": 5770
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7540,
    "Closing Rank": 7540
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10877,
    "Closing Rank": 13268
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16190,
    "Closing Rank": 16190
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5737,
    "Closing Rank": 6676
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9814,
    "Closing Rank": 9814
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2824,
    "Closing Rank": 2824
  },
  {
    "Institute": "Motilal Nehru National Institute of Technology Allahabad",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4455,
    "Closing Rank": 4455
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 106936,
    "Closing Rank": 247596
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 129019,
    "Closing Rank": 151489
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25408,
    "Closing Rank": 27811
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30280,
    "Closing Rank": 30280
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16005,
    "Closing Rank": 40112
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5057,
    "Closing Rank": 12047
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50675,
    "Closing Rank": 55914
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60642,
    "Closing Rank": 60830
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4279,
    "Closing Rank": 4279
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9128,
    "Closing Rank": 9404
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11132,
    "Closing Rank": 11132
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17206,
    "Closing Rank": 18438
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22242,
    "Closing Rank": 22576
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7903,
    "Closing Rank": 8743
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12234,
    "Closing Rank": 12234
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Biotechnology and Biochemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3665,
    "Closing Rank": 3705
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 173466,
    "Closing Rank": 231324
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 161018,
    "Closing Rank": 206719
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 98955,
    "Closing Rank": 98955
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14023,
    "Closing Rank": 24924
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30885,
    "Closing Rank": 30885
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10761,
    "Closing Rank": 32970
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25473,
    "Closing Rank": 30949
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42401,
    "Closing Rank": 50320
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51123,
    "Closing Rank": 56710
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8140,
    "Closing Rank": 8333
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9633,
    "Closing Rank": 9633
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15180,
    "Closing Rank": 16505
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19905,
    "Closing Rank": 21312
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7195,
    "Closing Rank": 7798
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10661,
    "Closing Rank": 10661
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2896,
    "Closing Rank": 3003
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4823,
    "Closing Rank": 4823
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 246115,
    "Closing Rank": 299281
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35119,
    "Closing Rank": 36730
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12083,
    "Closing Rank": 37412
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55119,
    "Closing Rank": 57997
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9708,
    "Closing Rank": 9708
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17943,
    "Closing Rank": 18999
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9573,
    "Closing Rank": 9614
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3851,
    "Closing Rank": 3851
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 153525,
    "Closing Rank": 217289
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 175593,
    "Closing Rank": 231406
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15017,
    "Closing Rank": 24520
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18921,
    "Closing Rank": 23676
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8900,
    "Closing Rank": 19732
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6867,
    "Closing Rank": 12901
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41899,
    "Closing Rank": 53657
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 64979,
    "Closing Rank": 66493
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8726,
    "Closing Rank": 8852
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11028,
    "Closing Rank": 11028
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16363,
    "Closing Rank": 17896
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23256,
    "Closing Rank": 23792
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7730,
    "Closing Rank": 8072
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11300,
    "Closing Rank": 11687
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2579,
    "Closing Rank": 2654
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3123,
    "Closing Rank": 3123
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computational Mathematics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 100545,
    "Closing Rank": 174131
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computational Mathematics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16797,
    "Closing Rank": 19043
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computational Mathematics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27913,
    "Closing Rank": 34842
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computational Mathematics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6188,
    "Closing Rank": 6188
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computational Mathematics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13665,
    "Closing Rank": 14458
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computational Mathematics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5013,
    "Closing Rank": 6296
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computational Mathematics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2162,
    "Closing Rank": 2162
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9028,
    "Closing Rank": 75987
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29647,
    "Closing Rank": 62001
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2276,
    "Closing Rank": 2276
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14754,
    "Closing Rank": 25178
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18530,
    "Closing Rank": 25228
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3000,
    "Closing Rank": 6092
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5038,
    "Closing Rank": 8848
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 221,
    "Closing Rank": 221
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 755,
    "Closing Rank": 14266
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2326,
    "Closing Rank": 7155
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8121,
    "Closing Rank": 20298
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27900,
    "Closing Rank": 35092
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3288,
    "Closing Rank": 3310
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5774,
    "Closing Rank": 5997
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 185,
    "Closing Rank": 204
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6076,
    "Closing Rank": 7198
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10241,
    "Closing Rank": 13401
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 577,
    "Closing Rank": 577
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2461,
    "Closing Rank": 3907
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4935,
    "Closing Rank": 6133
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 77,
    "Closing Rank": 77
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1113,
    "Closing Rank": 1464
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2510,
    "Closing Rank": 2510
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57501,
    "Closing Rank": 121194
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 101741,
    "Closing Rank": 114900
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40805,
    "Closing Rank": 90195
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39400,
    "Closing Rank": 39400
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11357,
    "Closing Rank": 12676
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15030,
    "Closing Rank": 18979
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9409,
    "Closing Rank": 41930
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13838,
    "Closing Rank": 28459
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27996,
    "Closing Rank": 38044
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42555,
    "Closing Rank": 47349
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2132,
    "Closing Rank": 2132
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6235,
    "Closing Rank": 6685
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7789,
    "Closing Rank": 7789
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10820,
    "Closing Rank": 13148
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16383,
    "Closing Rank": 17517
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5431,
    "Closing Rank": 6214
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8287,
    "Closing Rank": 8596
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2221,
    "Closing Rank": 2779
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4451,
    "Closing Rank": 4451
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36570,
    "Closing Rank": 96256
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 79447,
    "Closing Rank": 101333
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22888,
    "Closing Rank": 32467
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36354,
    "Closing Rank": 36354
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5922,
    "Closing Rank": 10847
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9882,
    "Closing Rank": 14348
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1338,
    "Closing Rank": 28056
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16884,
    "Closing Rank": 24537
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22067,
    "Closing Rank": 27158
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35635,
    "Closing Rank": 39625
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 957,
    "Closing Rank": 957
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4383,
    "Closing Rank": 4584
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6371,
    "Closing Rank": 6371
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7535,
    "Closing Rank": 8982
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13844,
    "Closing Rank": 14694
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 689,
    "Closing Rank": 689
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3784,
    "Closing Rank": 4937
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6370,
    "Closing Rank": 6717
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1656,
    "Closing Rank": 1942
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2795,
    "Closing Rank": 2795
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 96471,
    "Closing Rank": 142075
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 118749,
    "Closing Rank": 131661
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40078,
    "Closing Rank": 57351
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12724,
    "Closing Rank": 14041
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18821,
    "Closing Rank": 19995
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22986,
    "Closing Rank": 37244
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42990,
    "Closing Rank": 45959
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1434,
    "Closing Rank": 2441
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6116,
    "Closing Rank": 6304
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8940,
    "Closing Rank": 8940
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11040,
    "Closing Rank": 13512
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19032,
    "Closing Rank": 19679
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5414,
    "Closing Rank": 6686
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9526,
    "Closing Rank": 9526
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2716,
    "Closing Rank": 2716
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3979,
    "Closing Rank": 3979
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 179536,
    "Closing Rank": 226647
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25867,
    "Closing Rank": 32523
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43591,
    "Closing Rank": 45559
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7624,
    "Closing Rank": 7624
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13855,
    "Closing Rank": 16543
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7485,
    "Closing Rank": 9252
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Engineering Physics (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3509,
    "Closing Rank": 3509
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56381,
    "Closing Rank": 265899
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62273,
    "Closing Rank": 62273
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19129,
    "Closing Rank": 19129
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8498,
    "Closing Rank": 29783
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26272,
    "Closing Rank": 31639
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5223,
    "Closing Rank": 5223
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 226,
    "Closing Rank": 226
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12265,
    "Closing Rank": 13839
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6072,
    "Closing Rank": 6702
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2728,
    "Closing Rank": 2728
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 92131,
    "Closing Rank": 173117
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 144503,
    "Closing Rank": 184292
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36207,
    "Closing Rank": 36207
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 100431,
    "Closing Rank": 100431
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14230,
    "Closing Rank": 21527
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20185,
    "Closing Rank": 26256
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2657,
    "Closing Rank": 45933
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35554,
    "Closing Rank": 46675
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49305,
    "Closing Rank": 58864
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2981,
    "Closing Rank": 2981
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7516,
    "Closing Rank": 7830
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10115,
    "Closing Rank": 10115
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13657,
    "Closing Rank": 15431
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20713,
    "Closing Rank": 21142
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6404,
    "Closing Rank": 7660
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11044,
    "Closing Rank": 11080
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2457,
    "Closing Rank": 2928
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4034,
    "Closing Rank": 4034
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 127978,
    "Closing Rank": 236979
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25507,
    "Closing Rank": 25507
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21009,
    "Closing Rank": 21009
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18136,
    "Closing Rank": 51420
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16888,
    "Closing Rank": 18714
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9053,
    "Closing Rank": 9441
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3126,
    "Closing Rank": 3126
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 227666,
    "Closing Rank": 307180
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 229506,
    "Closing Rank": 261203
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24331,
    "Closing Rank": 32881
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21619,
    "Closing Rank": 34040
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21673,
    "Closing Rank": 21673
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46759,
    "Closing Rank": 56865
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59503,
    "Closing Rank": 65780
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9248,
    "Closing Rank": 9382
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10987,
    "Closing Rank": 10987
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17430,
    "Closing Rank": 18933
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22896,
    "Closing Rank": 24115
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8204,
    "Closing Rank": 9301
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11860,
    "Closing Rank": 12376
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3017,
    "Closing Rank": 3622
  },
  {
    "Institute": "National Institute of Technology Agartala",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4762,
    "Closing Rank": 4762
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 68,
    "Closing Rank": 271
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 83,
    "Closing Rank": 138
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62,
    "Closing Rank": 62
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 211,
    "Closing Rank": 595
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 479,
    "Closing Rank": 479
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 78,
    "Closing Rank": 133
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 96,
    "Closing Rank": 105
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13,
    "Closing Rank": 289
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 298,
    "Closing Rank": 298
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51,
    "Closing Rank": 415
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 239,
    "Closing Rank": 466
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 256,
    "Closing Rank": 292
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38,
    "Closing Rank": 38
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 121,
    "Closing Rank": 136
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 135,
    "Closing Rank": 299
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 217,
    "Closing Rank": 217
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 86,
    "Closing Rank": 104
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52,
    "Closing Rank": 52
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85,
    "Closing Rank": 85
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55,
    "Closing Rank": 55
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30858,
    "Closing Rank": 32869
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18789,
    "Closing Rank": 18789
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1696,
    "Closing Rank": 3033
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59839,
    "Closing Rank": 59839
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16197,
    "Closing Rank": 16197
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11012,
    "Closing Rank": 11278
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9267,
    "Closing Rank": 9267
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1440,
    "Closing Rank": 1440
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3526,
    "Closing Rank": 9508
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20287,
    "Closing Rank": 20287
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23927,
    "Closing Rank": 35922
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27660,
    "Closing Rank": 32481
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6209,
    "Closing Rank": 6217
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11113,
    "Closing Rank": 12280
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12604,
    "Closing Rank": 12604
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4956,
    "Closing Rank": 5691
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8909,
    "Closing Rank": 8909
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1867,
    "Closing Rank": 1867
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19284,
    "Closing Rank": 30934
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21455,
    "Closing Rank": 27393
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26082,
    "Closing Rank": 43883
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10755,
    "Closing Rank": 10755
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8945,
    "Closing Rank": 10457
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9567,
    "Closing Rank": 10150
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12039,
    "Closing Rank": 14849
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10671,
    "Closing Rank": 10671
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19037,
    "Closing Rank": 29399
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12872,
    "Closing Rank": 12872
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20381,
    "Closing Rank": 26194
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25156,
    "Closing Rank": 34601
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1016,
    "Closing Rank": 1016
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4434,
    "Closing Rank": 4781
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5876,
    "Closing Rank": 5876
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7395,
    "Closing Rank": 9306
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10490,
    "Closing Rank": 11908
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 573,
    "Closing Rank": 573
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3732,
    "Closing Rank": 5176
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6099,
    "Closing Rank": 6197
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1628,
    "Closing Rank": 1689
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1887,
    "Closing Rank": 1887
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26868,
    "Closing Rank": 40264
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22602,
    "Closing Rank": 35697
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2250,
    "Closing Rank": 2250
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3628,
    "Closing Rank": 3628
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51312,
    "Closing Rank": 79174
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26099,
    "Closing Rank": 57585
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11567,
    "Closing Rank": 14202
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11488,
    "Closing Rank": 11853
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1558,
    "Closing Rank": 1558
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7322,
    "Closing Rank": 16371
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11136,
    "Closing Rank": 14575
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8191,
    "Closing Rank": 34138
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12142,
    "Closing Rank": 21106
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25679,
    "Closing Rank": 33650
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40047,
    "Closing Rank": 43260
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1743,
    "Closing Rank": 1743
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5455,
    "Closing Rank": 5714
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7051,
    "Closing Rank": 7051
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9754,
    "Closing Rank": 11102
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13508,
    "Closing Rank": 14612
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 885,
    "Closing Rank": 885
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 881,
    "Closing Rank": 881
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4832,
    "Closing Rank": 5615
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6352,
    "Closing Rank": 7665
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1230,
    "Closing Rank": 1515
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1967,
    "Closing Rank": 1967
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4488,
    "Closing Rank": 9271
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5110,
    "Closing Rank": 9330
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 170,
    "Closing Rank": 170
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 193,
    "Closing Rank": 193
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4562,
    "Closing Rank": 20059
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14720,
    "Closing Rank": 14720
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2084,
    "Closing Rank": 3220
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2709,
    "Closing Rank": 4350
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 165,
    "Closing Rank": 165
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 515,
    "Closing Rank": 3043
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5952,
    "Closing Rank": 7468
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 784,
    "Closing Rank": 4056
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2705,
    "Closing Rank": 2705
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2614,
    "Closing Rank": 4482
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4989,
    "Closing Rank": 8068
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 94,
    "Closing Rank": 94
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 606,
    "Closing Rank": 645
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1232,
    "Closing Rank": 1309
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22,
    "Closing Rank": 22
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1103,
    "Closing Rank": 1457
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2056,
    "Closing Rank": 3125
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 98,
    "Closing Rank": 98
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 482,
    "Closing Rank": 843
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1084,
    "Closing Rank": 1131
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18,
    "Closing Rank": 18
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 229,
    "Closing Rank": 269
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 555,
    "Closing Rank": 555
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25,
    "Closing Rank": 25
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12281,
    "Closing Rank": 15954
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14981,
    "Closing Rank": 19402
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 413,
    "Closing Rank": 521
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15206,
    "Closing Rank": 26831
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25910,
    "Closing Rank": 25910
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4117,
    "Closing Rank": 5301
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5869,
    "Closing Rank": 6507
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 260,
    "Closing Rank": 260
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 475,
    "Closing Rank": 475
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7084,
    "Closing Rank": 9899
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4532,
    "Closing Rank": 7981
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2835,
    "Closing Rank": 16825
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16680,
    "Closing Rank": 16680
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7989,
    "Closing Rank": 11415
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13966,
    "Closing Rank": 15780
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 371,
    "Closing Rank": 371
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 902,
    "Closing Rank": 902
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1712,
    "Closing Rank": 2011
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2679,
    "Closing Rank": 2753
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 132,
    "Closing Rank": 132
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2877,
    "Closing Rank": 4310
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5370,
    "Closing Rank": 7253
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 328,
    "Closing Rank": 328
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1837,
    "Closing Rank": 2686
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2512,
    "Closing Rank": 2919
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 468,
    "Closing Rank": 869
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1446,
    "Closing Rank": 1446
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8055,
    "Closing Rank": 12264
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9897,
    "Closing Rank": 13963
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58,
    "Closing Rank": 58
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13380,
    "Closing Rank": 22874
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13421,
    "Closing Rank": 13421
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3028,
    "Closing Rank": 4082
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4401,
    "Closing Rank": 5134
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 279,
    "Closing Rank": 279
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1152,
    "Closing Rank": 6719
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7748,
    "Closing Rank": 9031
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1387,
    "Closing Rank": 6198
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5467,
    "Closing Rank": 5467
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4721,
    "Closing Rank": 7433
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9361,
    "Closing Rank": 10983
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 707,
    "Closing Rank": 707
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1034,
    "Closing Rank": 1260
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1589,
    "Closing Rank": 1589
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56,
    "Closing Rank": 91
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1845,
    "Closing Rank": 2574
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3889,
    "Closing Rank": 4372
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 133,
    "Closing Rank": 133
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1009,
    "Closing Rank": 1665
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2631,
    "Closing Rank": 3773
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 320,
    "Closing Rank": 411
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 906,
    "Closing Rank": 1220
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15963,
    "Closing Rank": 21437
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24186,
    "Closing Rank": 24186
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33778,
    "Closing Rank": 33778
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 88460,
    "Closing Rank": 88460
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8388,
    "Closing Rank": 9819
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9539,
    "Closing Rank": 9539
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5321,
    "Closing Rank": 5321
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10926,
    "Closing Rank": 10926
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1685,
    "Closing Rank": 1685
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8191,
    "Closing Rank": 16218
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25618,
    "Closing Rank": 26715
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1671,
    "Closing Rank": 1671
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3153,
    "Closing Rank": 3333
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5434,
    "Closing Rank": 6978
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12361,
    "Closing Rank": 12361
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3752,
    "Closing Rank": 4856
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1258,
    "Closing Rank": 1258
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4205,
    "Closing Rank": 4205
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30870,
    "Closing Rank": 36082
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32439,
    "Closing Rank": 32439
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1128,
    "Closing Rank": 1128
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33781,
    "Closing Rank": 33781
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10999,
    "Closing Rank": 12355
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10680,
    "Closing Rank": 10680
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15399,
    "Closing Rank": 16049
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18073,
    "Closing Rank": 18073
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36888,
    "Closing Rank": 36888
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20228,
    "Closing Rank": 33674
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44098,
    "Closing Rank": 44098
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6173,
    "Closing Rank": 6173
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8786,
    "Closing Rank": 8786
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11558,
    "Closing Rank": 12440
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14486,
    "Closing Rank": 14486
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6324,
    "Closing Rank": 6885
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1940,
    "Closing Rank": 1940
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16058,
    "Closing Rank": 22280
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20145,
    "Closing Rank": 27770
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 917,
    "Closing Rank": 1321
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16862,
    "Closing Rank": 36039
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31528,
    "Closing Rank": 38676
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5850,
    "Closing Rank": 7893
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8509,
    "Closing Rank": 9916
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1872,
    "Closing Rank": 1872
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1928,
    "Closing Rank": 1928
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4815,
    "Closing Rank": 13856
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9900,
    "Closing Rank": 10192
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5369,
    "Closing Rank": 15322
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15174,
    "Closing Rank": 20611
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26987,
    "Closing Rank": 30239
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 963,
    "Closing Rank": 963
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1683,
    "Closing Rank": 1683
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3225,
    "Closing Rank": 3546
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5707,
    "Closing Rank": 5911
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5475,
    "Closing Rank": 6889
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10373,
    "Closing Rank": 11749
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 998,
    "Closing Rank": 998
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3436,
    "Closing Rank": 4556
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6362,
    "Closing Rank": 6662
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1090,
    "Closing Rank": 1466
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2063,
    "Closing Rank": 2253
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34977,
    "Closing Rank": 41705
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35414,
    "Closing Rank": 38975
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48505,
    "Closing Rank": 59113
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 76781,
    "Closing Rank": 76781
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13040,
    "Closing Rank": 14647
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12663,
    "Closing Rank": 12726
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17614,
    "Closing Rank": 18762
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18532,
    "Closing Rank": 18532
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27217,
    "Closing Rank": 33433
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39536,
    "Closing Rank": 41373
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2272,
    "Closing Rank": 2272
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5964,
    "Closing Rank": 6564
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9971,
    "Closing Rank": 12253
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14652,
    "Closing Rank": 14652
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1002,
    "Closing Rank": 1002
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5488,
    "Closing Rank": 5806
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8081,
    "Closing Rank": 8081
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1889,
    "Closing Rank": 1889
  },
  {
    "Institute": "National Institute of Technology Calicut",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3414,
    "Closing Rank": 3414
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17286,
    "Closing Rank": 19407
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32479,
    "Closing Rank": 32479
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7633,
    "Closing Rank": 7633
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10416,
    "Closing Rank": 13550
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2853,
    "Closing Rank": 2853
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6929,
    "Closing Rank": 6929
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3567,
    "Closing Rank": 3567
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5998,
    "Closing Rank": 14243
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6370,
    "Closing Rank": 6370
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5142,
    "Closing Rank": 5142
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 442,
    "Closing Rank": 442
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3899,
    "Closing Rank": 4743
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2236,
    "Closing Rank": 2236
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 785,
    "Closing Rank": 785
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9112,
    "Closing Rank": 12510
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16573,
    "Closing Rank": 16573
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2280,
    "Closing Rank": 2280
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5695,
    "Closing Rank": 6267
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10865,
    "Closing Rank": 10865
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2684,
    "Closing Rank": 2684
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2525,
    "Closing Rank": 2525
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8339,
    "Closing Rank": 8572
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13111,
    "Closing Rank": 13111
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 194,
    "Closing Rank": 194
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1318,
    "Closing Rank": 1318
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2012,
    "Closing Rank": 2105
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5621,
    "Closing Rank": 5621
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1172,
    "Closing Rank": 1621
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32836,
    "Closing Rank": 36329
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23962,
    "Closing Rank": 23962
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7683,
    "Closing Rank": 7683
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17807,
    "Closing Rank": 18429
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27213,
    "Closing Rank": 27213
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5498,
    "Closing Rank": 5498
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7381,
    "Closing Rank": 7381
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3188,
    "Closing Rank": 3188
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22416,
    "Closing Rank": 27045
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36193,
    "Closing Rank": 36193
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7874,
    "Closing Rank": 7874
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9017,
    "Closing Rank": 9096
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3317,
    "Closing Rank": 3471
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 379,
    "Closing Rank": 379
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5545,
    "Closing Rank": 12455
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10306,
    "Closing Rank": 16787
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 579,
    "Closing Rank": 579
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2181,
    "Closing Rank": 3311
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2971,
    "Closing Rank": 2971
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3664,
    "Closing Rank": 5688
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4950,
    "Closing Rank": 7187
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 247,
    "Closing Rank": 247
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1543,
    "Closing Rank": 2641
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4528,
    "Closing Rank": 4640
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 96,
    "Closing Rank": 96
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 149,
    "Closing Rank": 617
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2135,
    "Closing Rank": 2135
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2438,
    "Closing Rank": 8018
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11811,
    "Closing Rank": 13868
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 403,
    "Closing Rank": 403
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1168,
    "Closing Rank": 1419
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2128,
    "Closing Rank": 2128
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57,
    "Closing Rank": 57
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2900,
    "Closing Rank": 3444
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3484,
    "Closing Rank": 3595
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 498,
    "Closing Rank": 498
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 681,
    "Closing Rank": 1584
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2305,
    "Closing Rank": 2872
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 334,
    "Closing Rank": 443
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 913,
    "Closing Rank": 913
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17542,
    "Closing Rank": 25757
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28805,
    "Closing Rank": 34150
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4099,
    "Closing Rank": 4769
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11505,
    "Closing Rank": 12598
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18717,
    "Closing Rank": 19632
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3065,
    "Closing Rank": 3586
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6912,
    "Closing Rank": 6912
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2634,
    "Closing Rank": 3938
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12167,
    "Closing Rank": 16739
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25168,
    "Closing Rank": 25855
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 602,
    "Closing Rank": 602
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2731,
    "Closing Rank": 2875
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4125,
    "Closing Rank": 4125
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5354,
    "Closing Rank": 6190
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10436,
    "Closing Rank": 10436
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 371,
    "Closing Rank": 371
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2114,
    "Closing Rank": 2935
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4063,
    "Closing Rank": 4063
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1337,
    "Closing Rank": 1448
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1595,
    "Closing Rank": 1595
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13130,
    "Closing Rank": 16179
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19091,
    "Closing Rank": 22726
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3314,
    "Closing Rank": 3977
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6613,
    "Closing Rank": 10568
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11232,
    "Closing Rank": 17102
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2692,
    "Closing Rank": 3671
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5450,
    "Closing Rank": 5450
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1619,
    "Closing Rank": 2529
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8844,
    "Closing Rank": 12080
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16788,
    "Closing Rank": 17923
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 459,
    "Closing Rank": 459
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1912,
    "Closing Rank": 1919
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3074,
    "Closing Rank": 3074
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3977,
    "Closing Rank": 4713
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 628,
    "Closing Rank": 628
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2168,
    "Closing Rank": 2536
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1752,
    "Closing Rank": 1752
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 905,
    "Closing Rank": 991
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1518,
    "Closing Rank": 1518
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17066,
    "Closing Rank": 30040
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34334,
    "Closing Rank": 36212
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6376,
    "Closing Rank": 6684
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14699,
    "Closing Rank": 16253
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22258,
    "Closing Rank": 22258
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5448,
    "Closing Rank": 6093
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4069,
    "Closing Rank": 4069
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8873,
    "Closing Rank": 8873
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1737,
    "Closing Rank": 22208
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26420,
    "Closing Rank": 26420
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 803,
    "Closing Rank": 803
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3563,
    "Closing Rank": 3772
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6616,
    "Closing Rank": 8710
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10275,
    "Closing Rank": 16086
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2369,
    "Closing Rank": 3934
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6847,
    "Closing Rank": 6847
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1423,
    "Closing Rank": 1423
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17771,
    "Closing Rank": 18485
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24228,
    "Closing Rank": 24228
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 615,
    "Closing Rank": 615
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4822,
    "Closing Rank": 5478
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11436,
    "Closing Rank": 11925
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21378,
    "Closing Rank": 21378
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3763,
    "Closing Rank": 3763
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7775,
    "Closing Rank": 7775
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10688,
    "Closing Rank": 15438
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22510,
    "Closing Rank": 22510
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4598,
    "Closing Rank": 4598
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5195,
    "Closing Rank": 5708
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3799,
    "Closing Rank": 3930
  },
  {
    "Institute": "National Institute of Technology Delhi",
    "Academic Program Name": "VLSI Design and Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1730,
    "Closing Rank": 1730
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12811,
    "Closing Rank": 19108
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21013,
    "Closing Rank": 26580
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2391,
    "Closing Rank": 2391
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4484,
    "Closing Rank": 4901
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10441,
    "Closing Rank": 10441
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6095,
    "Closing Rank": 11771
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16503,
    "Closing Rank": 21124
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1659,
    "Closing Rank": 2715
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6640,
    "Closing Rank": 6640
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4615,
    "Closing Rank": 4615
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5506,
    "Closing Rank": 5506
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9154,
    "Closing Rank": 12077
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16354,
    "Closing Rank": 18005
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1816,
    "Closing Rank": 1907
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3610,
    "Closing Rank": 3610
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3645,
    "Closing Rank": 4234
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8392,
    "Closing Rank": 8885
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 448,
    "Closing Rank": 448
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2191,
    "Closing Rank": 2746
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 847,
    "Closing Rank": 847
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2125,
    "Closing Rank": 2125
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44728,
    "Closing Rank": 52643
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25088,
    "Closing Rank": 41918
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2200,
    "Closing Rank": 2200
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15786,
    "Closing Rank": 16724
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35087,
    "Closing Rank": 35087
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19544,
    "Closing Rank": 37828
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30378,
    "Closing Rank": 32414
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6513,
    "Closing Rank": 8588
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4342,
    "Closing Rank": 4342
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7590,
    "Closing Rank": 8845
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15221,
    "Closing Rank": 15221
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35794,
    "Closing Rank": 47888
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45243,
    "Closing Rank": 52107
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1296,
    "Closing Rank": 1296
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7756,
    "Closing Rank": 8113
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8502,
    "Closing Rank": 8502
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14215,
    "Closing Rank": 15137
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20041,
    "Closing Rank": 20311
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1073,
    "Closing Rank": 1073
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4583,
    "Closing Rank": 7130
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9530,
    "Closing Rank": 9530
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2439,
    "Closing Rank": 2906
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4401,
    "Closing Rank": 4401
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39332,
    "Closing Rank": 39332
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36277,
    "Closing Rank": 36277
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38653,
    "Closing Rank": 38653
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9379,
    "Closing Rank": 9379
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41093,
    "Closing Rank": 47996
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Biotechnology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2920,
    "Closing Rank": 2920
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33720,
    "Closing Rank": 38799
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33919,
    "Closing Rank": 35209
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1164,
    "Closing Rank": 1164
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9875,
    "Closing Rank": 11999
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16315,
    "Closing Rank": 16315
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22063,
    "Closing Rank": 25528
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28881,
    "Closing Rank": 29454
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4097,
    "Closing Rank": 5637
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9137,
    "Closing Rank": 9137
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9475,
    "Closing Rank": 9928
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13528,
    "Closing Rank": 13528
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28000,
    "Closing Rank": 33696
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38597,
    "Closing Rank": 40727
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1813,
    "Closing Rank": 1813
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5615,
    "Closing Rank": 6165
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7214,
    "Closing Rank": 7214
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10183,
    "Closing Rank": 11747
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15496,
    "Closing Rank": 16013
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1674,
    "Closing Rank": 1674
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6037,
    "Closing Rank": 6367
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7267,
    "Closing Rank": 7267
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2030,
    "Closing Rank": 2084
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3390,
    "Closing Rank": 3390
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34347,
    "Closing Rank": 39810
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27262,
    "Closing Rank": 27262
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6450,
    "Closing Rank": 6450
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34405,
    "Closing Rank": 34405
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44280,
    "Closing Rank": 44280
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12673,
    "Closing Rank": 12673
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53000,
    "Closing Rank": 55579
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55746,
    "Closing Rank": 55746
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17155,
    "Closing Rank": 17155
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40381,
    "Closing Rank": 40766
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10026,
    "Closing Rank": 10026
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52850,
    "Closing Rank": 54181
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 62067,
    "Closing Rank": 62067
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9327,
    "Closing Rank": 9327
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16447,
    "Closing Rank": 18415
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20371,
    "Closing Rank": 20371
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9454,
    "Closing Rank": 9454
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3238,
    "Closing Rank": 3238
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30900,
    "Closing Rank": 44468
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48151,
    "Closing Rank": 53154
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2552,
    "Closing Rank": 2552
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14606,
    "Closing Rank": 15717
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22652,
    "Closing Rank": 22652
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20325,
    "Closing Rank": 29500
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38758,
    "Closing Rank": 41915
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6952,
    "Closing Rank": 8362
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10541,
    "Closing Rank": 10736
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5669,
    "Closing Rank": 6811
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9528,
    "Closing Rank": 9528
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33236,
    "Closing Rank": 40215
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49272,
    "Closing Rank": 50702
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6519,
    "Closing Rank": 6556
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8293,
    "Closing Rank": 8293
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12376,
    "Closing Rank": 12892
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18934,
    "Closing Rank": 20111
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 792,
    "Closing Rank": 792
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4276,
    "Closing Rank": 5957
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8736,
    "Closing Rank": 9038
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1543,
    "Closing Rank": 1585
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3107,
    "Closing Rank": 3107
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5446,
    "Closing Rank": 14090
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10671,
    "Closing Rank": 18785
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 107,
    "Closing Rank": 107
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2182,
    "Closing Rank": 4275
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3454,
    "Closing Rank": 7651
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 481,
    "Closing Rank": 481
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3463,
    "Closing Rank": 8278
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11890,
    "Closing Rank": 15435
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 226,
    "Closing Rank": 226
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 618,
    "Closing Rank": 1466
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1173,
    "Closing Rank": 2262
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21,
    "Closing Rank": 21
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 251,
    "Closing Rank": 2160
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2653,
    "Closing Rank": 3203
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5973,
    "Closing Rank": 8765
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9300,
    "Closing Rank": 11623
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 288,
    "Closing Rank": 288
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1224,
    "Closing Rank": 1397
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1892,
    "Closing Rank": 2151
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2054,
    "Closing Rank": 3182
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4212,
    "Closing Rank": 5182
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 180,
    "Closing Rank": 180
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1449,
    "Closing Rank": 1934
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1334,
    "Closing Rank": 3009
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 393,
    "Closing Rank": 458
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 736,
    "Closing Rank": 1188
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20004,
    "Closing Rank": 23223
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27454,
    "Closing Rank": 32123
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1171,
    "Closing Rank": 1171
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6409,
    "Closing Rank": 6620
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13464,
    "Closing Rank": 13464
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13028,
    "Closing Rank": 17401
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19734,
    "Closing Rank": 28372
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3159,
    "Closing Rank": 3940
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4390,
    "Closing Rank": 6515
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2644,
    "Closing Rank": 3373
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1808,
    "Closing Rank": 1808
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14715,
    "Closing Rank": 19559
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23914,
    "Closing Rank": 31268
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1410,
    "Closing Rank": 1410
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3033,
    "Closing Rank": 3226
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5302,
    "Closing Rank": 5302
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5783,
    "Closing Rank": 6475
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9082,
    "Closing Rank": 9504
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 646,
    "Closing Rank": 737
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3207,
    "Closing Rank": 3955
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5518,
    "Closing Rank": 5717
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1093,
    "Closing Rank": 1139
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2100,
    "Closing Rank": 2100
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12069,
    "Closing Rank": 19252
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22279,
    "Closing Rank": 23007
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 414,
    "Closing Rank": 414
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5951,
    "Closing Rank": 6124
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9393,
    "Closing Rank": 9393
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7273,
    "Closing Rank": 12936
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15608,
    "Closing Rank": 18159
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 177,
    "Closing Rank": 177
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1739,
    "Closing Rank": 2901
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4056,
    "Closing Rank": 4056
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1478,
    "Closing Rank": 3002
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4938,
    "Closing Rank": 4938
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9270,
    "Closing Rank": 13608
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17085,
    "Closing Rank": 20568
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 593,
    "Closing Rank": 593
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2057,
    "Closing Rank": 2149
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3292,
    "Closing Rank": 3292
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3555,
    "Closing Rank": 4631
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7282,
    "Closing Rank": 7588
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2052,
    "Closing Rank": 2851
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4032,
    "Closing Rank": 4059
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 603,
    "Closing Rank": 757
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1885,
    "Closing Rank": 1885
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24328,
    "Closing Rank": 32976
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36478,
    "Closing Rank": 49258
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 775,
    "Closing Rank": 775
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6923,
    "Closing Rank": 10116
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15078,
    "Closing Rank": 16616
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13392,
    "Closing Rank": 23991
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29317,
    "Closing Rank": 38269
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1635,
    "Closing Rank": 1635
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3057,
    "Closing Rank": 5700
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6668,
    "Closing Rank": 8236
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4083,
    "Closing Rank": 6067
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7859,
    "Closing Rank": 12899
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22071,
    "Closing Rank": 27957
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32026,
    "Closing Rank": 34829
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1584,
    "Closing Rank": 1584
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4799,
    "Closing Rank": 4949
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6113,
    "Closing Rank": 6290
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7689,
    "Closing Rank": 9834
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14257,
    "Closing Rank": 16603
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 952,
    "Closing Rank": 952
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4568,
    "Closing Rank": 5360
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7434,
    "Closing Rank": 8350
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1242,
    "Closing Rank": 1915
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3161,
    "Closing Rank": 3291
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39831,
    "Closing Rank": 48076
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49336,
    "Closing Rank": 51939
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1701,
    "Closing Rank": 1701
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10527,
    "Closing Rank": 15542
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26789,
    "Closing Rank": 26789
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26579,
    "Closing Rank": 34536
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30120,
    "Closing Rank": 30120
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6380,
    "Closing Rank": 8419
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9734,
    "Closing Rank": 9734
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9580,
    "Closing Rank": 9580
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15725,
    "Closing Rank": 15725
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31491,
    "Closing Rank": 44399
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52069,
    "Closing Rank": 56452
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2586,
    "Closing Rank": 2586
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7376,
    "Closing Rank": 7413
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10053,
    "Closing Rank": 10053
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12908,
    "Closing Rank": 14964
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19737,
    "Closing Rank": 19737
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1364,
    "Closing Rank": 1364
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7072,
    "Closing Rank": 7887
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7234,
    "Closing Rank": 7234
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2660,
    "Closing Rank": 2844
  },
  {
    "Institute": "National Institute of Technology Durgapur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4353,
    "Closing Rank": 4353
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50452,
    "Closing Rank": 83278
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 104222,
    "Closing Rank": 104222
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 105446,
    "Closing Rank": 138934
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 221362,
    "Closing Rank": 221362
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10577,
    "Closing Rank": 55000
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13278,
    "Closing Rank": 13278
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 89452,
    "Closing Rank": 89452
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 674299,
    "Closing Rank": 674299
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39186,
    "Closing Rank": 39186
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50206,
    "Closing Rank": 50206
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40468,
    "Closing Rank": 44014
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48672,
    "Closing Rank": 52955
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7434,
    "Closing Rank": 7475
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12995,
    "Closing Rank": 14663
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16071,
    "Closing Rank": 16071
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3758,
    "Closing Rank": 3758
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8058,
    "Closing Rank": 8058
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2138,
    "Closing Rank": 2138
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7705,
    "Closing Rank": 27417
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37084,
    "Closing Rank": 38936
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4481,
    "Closing Rank": 4481
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12370,
    "Closing Rank": 22662
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24550,
    "Closing Rank": 24550
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4853,
    "Closing Rank": 9679
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2738,
    "Closing Rank": 2738
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2941,
    "Closing Rank": 4273
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22250,
    "Closing Rank": 34858
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8961,
    "Closing Rank": 8961
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23454,
    "Closing Rank": 23454
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7914,
    "Closing Rank": 12511
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13339,
    "Closing Rank": 13339
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 420,
    "Closing Rank": 420
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1924,
    "Closing Rank": 1929
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3143,
    "Closing Rank": 3143
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3783,
    "Closing Rank": 4746
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5554,
    "Closing Rank": 5554
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2234,
    "Closing Rank": 2782
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3243,
    "Closing Rank": 3243
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 626,
    "Closing Rank": 626
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47017,
    "Closing Rank": 64325
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 99933,
    "Closing Rank": 99933
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25843,
    "Closing Rank": 25843
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26608,
    "Closing Rank": 65730
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 63399,
    "Closing Rank": 63399
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18622,
    "Closing Rank": 34483
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42641,
    "Closing Rank": 42641
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9418,
    "Closing Rank": 9418
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46614,
    "Closing Rank": 46614
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 67170,
    "Closing Rank": 67170
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23815,
    "Closing Rank": 23815
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31653,
    "Closing Rank": 31653
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3139,
    "Closing Rank": 3139
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17714,
    "Closing Rank": 23326
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26478,
    "Closing Rank": 26968
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4013,
    "Closing Rank": 4154
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6954,
    "Closing Rank": 8251
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11162,
    "Closing Rank": 14902
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 766,
    "Closing Rank": 766
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4164,
    "Closing Rank": 4601
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6334,
    "Closing Rank": 6334
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 83,
    "Closing Rank": 83
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22186,
    "Closing Rank": 37814
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47556,
    "Closing Rank": 77244
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21983,
    "Closing Rank": 24335
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30818,
    "Closing Rank": 54193
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12472,
    "Closing Rank": 16039
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32521,
    "Closing Rank": 32521
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5463,
    "Closing Rank": 5463
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35548,
    "Closing Rank": 41968
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34941,
    "Closing Rank": 34941
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14472,
    "Closing Rank": 14472
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14870,
    "Closing Rank": 16935
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24488,
    "Closing Rank": 25449
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 747,
    "Closing Rank": 747
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2654,
    "Closing Rank": 2877
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6094,
    "Closing Rank": 6777
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10317,
    "Closing Rank": 10317
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3332,
    "Closing Rank": 4114
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5823,
    "Closing Rank": 5823
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1085,
    "Closing Rank": 1474
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2121,
    "Closing Rank": 2121
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36144,
    "Closing Rank": 79077
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 75251,
    "Closing Rank": 75251
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40543,
    "Closing Rank": 40543
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70874,
    "Closing Rank": 88700
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 104473,
    "Closing Rank": 104473
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36358,
    "Closing Rank": 36358
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "GO",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4625,
    "Closing Rank": 4625
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58547,
    "Closing Rank": 58547
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 95332,
    "Closing Rank": 95332
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22654,
    "Closing Rank": 22654
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25708,
    "Closing Rank": 31124
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43643,
    "Closing Rank": 43643
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7750,
    "Closing Rank": 7750
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 440,
    "Closing Rank": 440
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9934,
    "Closing Rank": 11719
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18160,
    "Closing Rank": 18160
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5009,
    "Closing Rank": 6034
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2319,
    "Closing Rank": 2319
  },
  {
    "Institute": "National Institute of Technology Goa",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3189,
    "Closing Rank": 3189
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1781,
    "Closing Rank": 5973
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1860,
    "Closing Rank": 3126
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2971,
    "Closing Rank": 2971
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2863,
    "Closing Rank": 12832
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4222,
    "Closing Rank": 4222
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 526,
    "Closing Rank": 657
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1933,
    "Closing Rank": 1933
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 264,
    "Closing Rank": 264
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 414,
    "Closing Rank": 414
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 903,
    "Closing Rank": 1616
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1096,
    "Closing Rank": 1268
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 637,
    "Closing Rank": 701
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 478,
    "Closing Rank": 478
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 562,
    "Closing Rank": 1051
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 891,
    "Closing Rank": 975
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 212,
    "Closing Rank": 286
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 167,
    "Closing Rank": 167
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 120,
    "Closing Rank": 161
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 71215,
    "Closing Rank": 105917
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 70239,
    "Closing Rank": 100330
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17800,
    "Closing Rank": 22831
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21652,
    "Closing Rank": 21652
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 96220,
    "Closing Rank": 132676
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 78812,
    "Closing Rank": 98660
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6583,
    "Closing Rank": 11880
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13144,
    "Closing Rank": 13144
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4918,
    "Closing Rank": 5033
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25381,
    "Closing Rank": 39188
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37417,
    "Closing Rank": 48228
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6502,
    "Closing Rank": 6577
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8469,
    "Closing Rank": 8469
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11504,
    "Closing Rank": 13273
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19659,
    "Closing Rank": 20010
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1796,
    "Closing Rank": 1796
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5392,
    "Closing Rank": 6695
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7505,
    "Closing Rank": 7505
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2369,
    "Closing Rank": 2886
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4031,
    "Closing Rank": 4031
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62609,
    "Closing Rank": 111536
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 81065,
    "Closing Rank": 106671
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20356,
    "Closing Rank": 23418
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23266,
    "Closing Rank": 23266
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36141,
    "Closing Rank": 102398
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 108289,
    "Closing Rank": 132760
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8681,
    "Closing Rank": 11140
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6852,
    "Closing Rank": 12843
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2421,
    "Closing Rank": 4169
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3891,
    "Closing Rank": 3891
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37193,
    "Closing Rank": 45502
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50581,
    "Closing Rank": 54283
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3228,
    "Closing Rank": 3228
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7447,
    "Closing Rank": 7618
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9378,
    "Closing Rank": 9378
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14078,
    "Closing Rank": 15449
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20726,
    "Closing Rank": 21019
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6040,
    "Closing Rank": 7373
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7205,
    "Closing Rank": 8067
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1715,
    "Closing Rank": 1911
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3152,
    "Closing Rank": 3152
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12871,
    "Closing Rank": 28278
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21216,
    "Closing Rank": 34884
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2685,
    "Closing Rank": 2685
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5073,
    "Closing Rank": 9221
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11326,
    "Closing Rank": 11326
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7944,
    "Closing Rank": 21983
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11888,
    "Closing Rank": 23531
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2246,
    "Closing Rank": 3299
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2358,
    "Closing Rank": 2778
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 768,
    "Closing Rank": 998
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2158,
    "Closing Rank": 2158
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 94,
    "Closing Rank": 94
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8376,
    "Closing Rank": 12236
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15238,
    "Closing Rank": 19951
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 456,
    "Closing Rank": 456
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1852,
    "Closing Rank": 2092
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3193,
    "Closing Rank": 3193
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3487,
    "Closing Rank": 4356
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5896,
    "Closing Rank": 7593
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 289,
    "Closing Rank": 289
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2311,
    "Closing Rank": 2773
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3882,
    "Closing Rank": 4181
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 137,
    "Closing Rank": 137
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 676,
    "Closing Rank": 1084
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1779,
    "Closing Rank": 1779
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28887,
    "Closing Rank": 35206
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44148,
    "Closing Rank": 44148
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2279,
    "Closing Rank": 2279
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10912,
    "Closing Rank": 10912
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17592,
    "Closing Rank": 28385
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24565,
    "Closing Rank": 24565
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3478,
    "Closing Rank": 3478
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5545,
    "Closing Rank": 5545
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1941,
    "Closing Rank": 1941
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12499,
    "Closing Rank": 14106
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22553,
    "Closing Rank": 22553
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2169,
    "Closing Rank": 2248
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3680,
    "Closing Rank": 4747
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11001,
    "Closing Rank": 12130
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3037,
    "Closing Rank": 3056
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1227,
    "Closing Rank": 1227
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53347,
    "Closing Rank": 70818
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 61297,
    "Closing Rank": 72389
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1798,
    "Closing Rank": 1798
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12637,
    "Closing Rank": 14926
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20626,
    "Closing Rank": 20626
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53591,
    "Closing Rank": 75819
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 57021,
    "Closing Rank": 65846
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3901,
    "Closing Rank": 7585
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10579,
    "Closing Rank": 10625
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2507,
    "Closing Rank": 3584
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2859,
    "Closing Rank": 2859
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20800,
    "Closing Rank": 25782
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23756,
    "Closing Rank": 32832
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1936,
    "Closing Rank": 1936
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3219,
    "Closing Rank": 3219
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4215,
    "Closing Rank": 4309
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6110,
    "Closing Rank": 6110
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7286,
    "Closing Rank": 8894
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12602,
    "Closing Rank": 14267
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4018,
    "Closing Rank": 5040
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7291,
    "Closing Rank": 7291
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1568,
    "Closing Rank": 2029
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3135,
    "Closing Rank": 3135
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32762,
    "Closing Rank": 52872
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35733,
    "Closing Rank": 53435
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10884,
    "Closing Rank": 11470
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12916,
    "Closing Rank": 12916
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30073,
    "Closing Rank": 52383
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44071,
    "Closing Rank": 52306
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3644,
    "Closing Rank": 6504
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9097,
    "Closing Rank": 9097
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1165,
    "Closing Rank": 2120
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2371,
    "Closing Rank": 2857
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13651,
    "Closing Rank": 18421
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26899,
    "Closing Rank": 28062
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1129,
    "Closing Rank": 1129
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2856,
    "Closing Rank": 3042
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4488,
    "Closing Rank": 4488
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5300,
    "Closing Rank": 6647
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9832,
    "Closing Rank": 11973
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 699,
    "Closing Rank": 699
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3098,
    "Closing Rank": 4219
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5147,
    "Closing Rank": 5620
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1281,
    "Closing Rank": 1416
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1986,
    "Closing Rank": 1986
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37997,
    "Closing Rank": 55371
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52712,
    "Closing Rank": 52712
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12702,
    "Closing Rank": 14253
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53867,
    "Closing Rank": 58802
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56098,
    "Closing Rank": 76922
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7116,
    "Closing Rank": 8096
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2630,
    "Closing Rank": 2630
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18631,
    "Closing Rank": 19942
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28452,
    "Closing Rank": 28452
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1035,
    "Closing Rank": 1035
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3408,
    "Closing Rank": 3408
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5615,
    "Closing Rank": 6794
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13927,
    "Closing Rank": 13927
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4553,
    "Closing Rank": 4553
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7393,
    "Closing Rank": 7393
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2164,
    "Closing Rank": 2164
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 68752,
    "Closing Rank": 111665
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 90040,
    "Closing Rank": 98630
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23439,
    "Closing Rank": 23850
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 101593,
    "Closing Rank": 139038
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 157849,
    "Closing Rank": 208815
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9989,
    "Closing Rank": 12227
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2845,
    "Closing Rank": 2845
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5965,
    "Closing Rank": 5965
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21249,
    "Closing Rank": 35666
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39193,
    "Closing Rank": 39672
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3298,
    "Closing Rank": 3298
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6820,
    "Closing Rank": 7008
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7178,
    "Closing Rank": 7178
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10874,
    "Closing Rank": 13008
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18296,
    "Closing Rank": 18296
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1646,
    "Closing Rank": 1646
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4508,
    "Closing Rank": 6164
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9741,
    "Closing Rank": 9741
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3096,
    "Closing Rank": 3096
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 97104,
    "Closing Rank": 114907
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 98224,
    "Closing Rank": 98224
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26318,
    "Closing Rank": 26318
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24137,
    "Closing Rank": 24137
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 100303,
    "Closing Rank": 147929
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 104062,
    "Closing Rank": 104062
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12420,
    "Closing Rank": 13248
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5182,
    "Closing Rank": 5182
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5627,
    "Closing Rank": 5627
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45178,
    "Closing Rank": 46742
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50743,
    "Closing Rank": 54073
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7769,
    "Closing Rank": 8023
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15579,
    "Closing Rank": 15755
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19476,
    "Closing Rank": 19476
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1778,
    "Closing Rank": 1778
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7673,
    "Closing Rank": 7872
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10675,
    "Closing Rank": 10675
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Materials Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3110,
    "Closing Rank": 3191
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29739,
    "Closing Rank": 41618
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49236,
    "Closing Rank": 52970
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1898,
    "Closing Rank": 1898
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9810,
    "Closing Rank": 10749
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13339,
    "Closing Rank": 13339
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26275,
    "Closing Rank": 41279
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44142,
    "Closing Rank": 44142
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3826,
    "Closing Rank": 3906
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12279,
    "Closing Rank": 12279
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1682,
    "Closing Rank": 1682
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13016,
    "Closing Rank": 16502
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23682,
    "Closing Rank": 23719
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2575,
    "Closing Rank": 2579
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4765,
    "Closing Rank": 5800
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7692,
    "Closing Rank": 10138
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1973,
    "Closing Rank": 3642
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1521,
    "Closing Rank": 1521
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3812,
    "Closing Rank": 3812
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48580,
    "Closing Rank": 79041
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 77720,
    "Closing Rank": 111152
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2227,
    "Closing Rank": 2227
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15307,
    "Closing Rank": 16560
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23856,
    "Closing Rank": 23856
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 82799,
    "Closing Rank": 127894
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 109967,
    "Closing Rank": 181980
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7344,
    "Closing Rank": 10457
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13393,
    "Closing Rank": 13393
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3397,
    "Closing Rank": 4073
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5206,
    "Closing Rank": 5206
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27676,
    "Closing Rank": 35236
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43377,
    "Closing Rank": 48988
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2213,
    "Closing Rank": 2213
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5771,
    "Closing Rank": 5902
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8074,
    "Closing Rank": 8074
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 477,
    "Closing Rank": 477
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10868,
    "Closing Rank": 12148
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16906,
    "Closing Rank": 19232
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1784,
    "Closing Rank": 1784
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5896,
    "Closing Rank": 6233
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9296,
    "Closing Rank": 9422
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2095,
    "Closing Rank": 2365
  },
  {
    "Institute": "National Institute of Technology Hamirpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4020,
    "Closing Rank": 4020
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3114,
    "Closing Rank": 3717
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3948,
    "Closing Rank": 6233
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1321,
    "Closing Rank": 1321
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 897,
    "Closing Rank": 2383
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7052,
    "Closing Rank": 7052
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 144,
    "Closing Rank": 144
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 976,
    "Closing Rank": 1555
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 314,
    "Closing Rank": 314
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 810,
    "Closing Rank": 810
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2138,
    "Closing Rank": 2594
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4354,
    "Closing Rank": 4354
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 109,
    "Closing Rank": 109
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 356,
    "Closing Rank": 398
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 732,
    "Closing Rank": 732
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 586,
    "Closing Rank": 740
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1664,
    "Closing Rank": 1664
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 348,
    "Closing Rank": 586
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 774,
    "Closing Rank": 774
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 224,
    "Closing Rank": 224
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9458,
    "Closing Rank": 19080
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17900,
    "Closing Rank": 22991
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1036,
    "Closing Rank": 1036
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3109,
    "Closing Rank": 4447
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6977,
    "Closing Rank": 12125
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13595,
    "Closing Rank": 13595
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4237,
    "Closing Rank": 5478
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5142,
    "Closing Rank": 5142
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1223,
    "Closing Rank": 1223
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1566,
    "Closing Rank": 1566
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11762,
    "Closing Rank": 15195
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20811,
    "Closing Rank": 21059
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 458,
    "Closing Rank": 458
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2717,
    "Closing Rank": 2732
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4191,
    "Closing Rank": 4191
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5079,
    "Closing Rank": 5917
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6937,
    "Closing Rank": 8267
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 495,
    "Closing Rank": 495
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2271,
    "Closing Rank": 3857
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4926,
    "Closing Rank": 4926
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1206,
    "Closing Rank": 1229
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10933,
    "Closing Rank": 32691
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29523,
    "Closing Rank": 39258
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 955,
    "Closing Rank": 955
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5793,
    "Closing Rank": 7099
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10601,
    "Closing Rank": 10601
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10291,
    "Closing Rank": 15103
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20322,
    "Closing Rank": 21170
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 957,
    "Closing Rank": 957
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2116,
    "Closing Rank": 7168
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6070,
    "Closing Rank": 6070
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1395,
    "Closing Rank": 1807
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1909,
    "Closing Rank": 1909
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14301,
    "Closing Rank": 22939
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27846,
    "Closing Rank": 31390
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1194,
    "Closing Rank": 1367
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3603,
    "Closing Rank": 3680
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5487,
    "Closing Rank": 5487
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 251,
    "Closing Rank": 251
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6789,
    "Closing Rank": 8050
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9118,
    "Closing Rank": 10217
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 547,
    "Closing Rank": 547
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3291,
    "Closing Rank": 4134
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5709,
    "Closing Rank": 5921
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 571,
    "Closing Rank": 1010
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1841,
    "Closing Rank": 1841
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3437,
    "Closing Rank": 4786
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7008,
    "Closing Rank": 7008
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 181,
    "Closing Rank": 181
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1424,
    "Closing Rank": 1424
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2466,
    "Closing Rank": 3272
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10866,
    "Closing Rank": 10866
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1819,
    "Closing Rank": 2096
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2439,
    "Closing Rank": 2439
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 578,
    "Closing Rank": 578
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2299,
    "Closing Rank": 3348
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4443,
    "Closing Rank": 4443
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 556,
    "Closing Rank": 556
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1064,
    "Closing Rank": 1064
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 765,
    "Closing Rank": 1110
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1878,
    "Closing Rank": 1878
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 970,
    "Closing Rank": 979
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computational and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 232,
    "Closing Rank": 232
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1607,
    "Closing Rank": 2933
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2636,
    "Closing Rank": 3358
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93,
    "Closing Rank": 93
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 757,
    "Closing Rank": 1163
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1369,
    "Closing Rank": 1369
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66,
    "Closing Rank": 66
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 593,
    "Closing Rank": 2099
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2053,
    "Closing Rank": 4021
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 151,
    "Closing Rank": 151
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 296,
    "Closing Rank": 1223
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 837,
    "Closing Rank": 837
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2,
    "Closing Rank": 2
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58,
    "Closing Rank": 146
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 213,
    "Closing Rank": 213
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14,
    "Closing Rank": 1615
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 729,
    "Closing Rank": 2433
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 297,
    "Closing Rank": 297
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 222,
    "Closing Rank": 309
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 526,
    "Closing Rank": 526
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 391,
    "Closing Rank": 532
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1059,
    "Closing Rank": 1627
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 74,
    "Closing Rank": 74
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 225,
    "Closing Rank": 324
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 542,
    "Closing Rank": 679
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 107,
    "Closing Rank": 153
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 174,
    "Closing Rank": 174
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5905,
    "Closing Rank": 7809
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10016,
    "Closing Rank": 11553
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 509,
    "Closing Rank": 509
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2036,
    "Closing Rank": 2660
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5650,
    "Closing Rank": 5650
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4606,
    "Closing Rank": 5845
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5921,
    "Closing Rank": 11224
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 388,
    "Closing Rank": 388
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2124,
    "Closing Rank": 2598
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4271,
    "Closing Rank": 5153
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 643,
    "Closing Rank": 982
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1235,
    "Closing Rank": 1235
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5368,
    "Closing Rank": 7132
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10100,
    "Closing Rank": 10912
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 616,
    "Closing Rank": 616
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1042,
    "Closing Rank": 1314
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2260,
    "Closing Rank": 2260
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1639,
    "Closing Rank": 2495
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2782,
    "Closing Rank": 3968
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 86,
    "Closing Rank": 86
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 384,
    "Closing Rank": 1710
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1910,
    "Closing Rank": 1910
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 80,
    "Closing Rank": 80
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 143,
    "Closing Rank": 366
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 879,
    "Closing Rank": 879
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 77,
    "Closing Rank": 77
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3410,
    "Closing Rank": 5662
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5537,
    "Closing Rank": 7934
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 119,
    "Closing Rank": 119
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1198,
    "Closing Rank": 1792
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4098,
    "Closing Rank": 4098
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 138,
    "Closing Rank": 138
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3018,
    "Closing Rank": 4406
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9596,
    "Closing Rank": 10889
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 132,
    "Closing Rank": 132
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1069,
    "Closing Rank": 1959
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2409,
    "Closing Rank": 2438
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 705,
    "Closing Rank": 707
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 484,
    "Closing Rank": 484
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20,
    "Closing Rank": 20
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1991,
    "Closing Rank": 4227
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5959,
    "Closing Rank": 7035
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 382,
    "Closing Rank": 382
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 647,
    "Closing Rank": 725
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1225,
    "Closing Rank": 1225
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1252,
    "Closing Rank": 1580
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2253,
    "Closing Rank": 2632
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 667,
    "Closing Rank": 986
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1580,
    "Closing Rank": 1580
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 65,
    "Closing Rank": 65
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 271,
    "Closing Rank": 309
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 345,
    "Closing Rank": 345
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3173,
    "Closing Rank": 4452
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3814,
    "Closing Rank": 5617
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 164,
    "Closing Rank": 164
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1293,
    "Closing Rank": 1333
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5570,
    "Closing Rank": 5570
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2122,
    "Closing Rank": 3268
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6129,
    "Closing Rank": 7201
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 500,
    "Closing Rank": 500
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1253,
    "Closing Rank": 1860
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2572,
    "Closing Rank": 2572
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 506,
    "Closing Rank": 613
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 958,
    "Closing Rank": 958
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1697,
    "Closing Rank": 2944
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2850,
    "Closing Rank": 4876
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 198,
    "Closing Rank": 198
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 382,
    "Closing Rank": 478
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 720,
    "Closing Rank": 720
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 662,
    "Closing Rank": 1038
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1783,
    "Closing Rank": 2180
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 639,
    "Closing Rank": 852
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 877,
    "Closing Rank": 877
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 61,
    "Closing Rank": 61
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 158,
    "Closing Rank": 158
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11,
    "Closing Rank": 11
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3340,
    "Closing Rank": 14011
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14820,
    "Closing Rank": 27829
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1166,
    "Closing Rank": 1166
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2994,
    "Closing Rank": 3965
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4440,
    "Closing Rank": 7630
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 197,
    "Closing Rank": 197
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3853,
    "Closing Rank": 10194
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14408,
    "Closing Rank": 19383
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 680,
    "Closing Rank": 680
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 926,
    "Closing Rank": 4424
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5809,
    "Closing Rank": 6192
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 333,
    "Closing Rank": 333
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1221,
    "Closing Rank": 1618
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3183,
    "Closing Rank": 3183
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4101,
    "Closing Rank": 12380
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20398,
    "Closing Rank": 22425
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 423,
    "Closing Rank": 478
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1305,
    "Closing Rank": 1305
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1921,
    "Closing Rank": 2080
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3973,
    "Closing Rank": 4458
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3286,
    "Closing Rank": 4994
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7829,
    "Closing Rank": 8557
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 377,
    "Closing Rank": 377
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 566,
    "Closing Rank": 2633
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4513,
    "Closing Rank": 4897
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 127,
    "Closing Rank": 127
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 955,
    "Closing Rank": 1162
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1705,
    "Closing Rank": 1765
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11386,
    "Closing Rank": 26584
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28212,
    "Closing Rank": 32742
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2028,
    "Closing Rank": 2028
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4647,
    "Closing Rank": 5369
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13264,
    "Closing Rank": 15553
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19899,
    "Closing Rank": 21498
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7582,
    "Closing Rank": 9437
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9288,
    "Closing Rank": 9288
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1965,
    "Closing Rank": 1965
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2990,
    "Closing Rank": 2990
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14034,
    "Closing Rank": 23412
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31977,
    "Closing Rank": 35840
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 922,
    "Closing Rank": 922
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3997,
    "Closing Rank": 4418
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6862,
    "Closing Rank": 6862
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8792,
    "Closing Rank": 9052
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13323,
    "Closing Rank": 13901
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 492,
    "Closing Rank": 492
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4966,
    "Closing Rank": 5257
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5721,
    "Closing Rank": 5721
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1958,
    "Closing Rank": 2069
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27486,
    "Closing Rank": 41318
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41413,
    "Closing Rank": 60709
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7555,
    "Closing Rank": 7757
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15715,
    "Closing Rank": 17436
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20090,
    "Closing Rank": 30036
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7324,
    "Closing Rank": 9388
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9554,
    "Closing Rank": 9554
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1861,
    "Closing Rank": 1861
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21500,
    "Closing Rank": 27766
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38204,
    "Closing Rank": 48048
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4482,
    "Closing Rank": 4517
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7776,
    "Closing Rank": 7776
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9288,
    "Closing Rank": 10611
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15377,
    "Closing Rank": 15377
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5051,
    "Closing Rank": 5712
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6118,
    "Closing Rank": 6118
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1465,
    "Closing Rank": 1538
  },
  {
    "Institute": "National Institute of Technology Karnataka, Surathkal",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3223,
    "Closing Rank": 3223
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 169930,
    "Closing Rank": 169930
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 296304,
    "Closing Rank": 296304
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2211,
    "Closing Rank": 12084
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7985,
    "Closing Rank": 8698
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45085,
    "Closing Rank": 49836
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60488,
    "Closing Rank": 65404
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8643,
    "Closing Rank": 8643
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17008,
    "Closing Rank": 18001
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23119,
    "Closing Rank": 23119
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7619,
    "Closing Rank": 7662
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2156,
    "Closing Rank": 2156
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31545,
    "Closing Rank": 31545
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 198857,
    "Closing Rank": 198857
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30856,
    "Closing Rank": 30856
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17255,
    "Closing Rank": 19897
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2763,
    "Closing Rank": 7348
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4705,
    "Closing Rank": 4705
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14656,
    "Closing Rank": 20039
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35153,
    "Closing Rank": 35153
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3386,
    "Closing Rank": 3386
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 323,
    "Closing Rank": 323
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5251,
    "Closing Rank": 7086
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13146,
    "Closing Rank": 13146
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3059,
    "Closing Rank": 3059
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5288,
    "Closing Rank": 5288
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1031,
    "Closing Rank": 1031
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 188231,
    "Closing Rank": 188231
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 459245,
    "Closing Rank": 459245
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39020,
    "Closing Rank": 39020
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27941,
    "Closing Rank": 27941
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3797,
    "Closing Rank": 18350
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18898,
    "Closing Rank": 19966
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29518,
    "Closing Rank": 36106
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43307,
    "Closing Rank": 43307
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6271,
    "Closing Rank": 6309
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11675,
    "Closing Rank": 12834
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18540,
    "Closing Rank": 18540
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1040,
    "Closing Rank": 1040
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7111,
    "Closing Rank": 7193
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3815,
    "Closing Rank": 3815
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55668,
    "Closing Rank": 102988
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 365786,
    "Closing Rank": 365786
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2172,
    "Closing Rank": 18329
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8248,
    "Closing Rank": 21839
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27786,
    "Closing Rank": 29484
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43057,
    "Closing Rank": 43057
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1802,
    "Closing Rank": 1802
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4726,
    "Closing Rank": 4726
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7028,
    "Closing Rank": 7028
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9316,
    "Closing Rank": 9487
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18059,
    "Closing Rank": 18059
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4878,
    "Closing Rank": 4878
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6961,
    "Closing Rank": 6961
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1463,
    "Closing Rank": 1463
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 162491,
    "Closing Rank": 162491
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 458027,
    "Closing Rank": 458027
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39038,
    "Closing Rank": 39038
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11165,
    "Closing Rank": 18638
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16864,
    "Closing Rank": 16864
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42899,
    "Closing Rank": 48332
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53708,
    "Closing Rank": 53708
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7819,
    "Closing Rank": 7819
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14581,
    "Closing Rank": 14596
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19920,
    "Closing Rank": 19920
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7521,
    "Closing Rank": 7688
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11251,
    "Closing Rank": 11251
  },
  {
    "Institute": "National Institute of Technology Meghalaya",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2944,
    "Closing Rank": 2952
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3299,
    "Closing Rank": 11973
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5162,
    "Closing Rank": 7255
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51582,
    "Closing Rank": 57171
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 67166,
    "Closing Rank": 68939
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9413,
    "Closing Rank": 9480
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17567,
    "Closing Rank": 18671
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24688,
    "Closing Rank": 24688
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8435,
    "Closing Rank": 8804
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11364,
    "Closing Rank": 11364
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5615,
    "Closing Rank": 10791
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6603,
    "Closing Rank": 12883
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22429,
    "Closing Rank": 31391
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38249,
    "Closing Rank": 38249
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1770,
    "Closing Rank": 1770
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5204,
    "Closing Rank": 5233
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 465,
    "Closing Rank": 465
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9073,
    "Closing Rank": 10561
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1748,
    "Closing Rank": 1748
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4460,
    "Closing Rank": 5063
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11211,
    "Closing Rank": 21638
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19133,
    "Closing Rank": 20361
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44822,
    "Closing Rank": 47335
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52564,
    "Closing Rank": 54153
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3249,
    "Closing Rank": 3249
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7865,
    "Closing Rank": 7966
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14309,
    "Closing Rank": 15609
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19496,
    "Closing Rank": 19496
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6738,
    "Closing Rank": 6738
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9576,
    "Closing Rank": 9576
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13098,
    "Closing Rank": 29330
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6132,
    "Closing Rank": 13947
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33367,
    "Closing Rank": 42889
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44560,
    "Closing Rank": 51406
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6950,
    "Closing Rank": 7052
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13248,
    "Closing Rank": 13259
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17431,
    "Closing Rank": 17431
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6353,
    "Closing Rank": 6439
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19650,
    "Closing Rank": 32284
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13872,
    "Closing Rank": 13872
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45710,
    "Closing Rank": 47676
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 54484,
    "Closing Rank": 55653
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3597,
    "Closing Rank": 3597
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8044,
    "Closing Rank": 8393
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15835,
    "Closing Rank": 16457
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20988,
    "Closing Rank": 20988
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7728,
    "Closing Rank": 8353
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6358,
    "Closing Rank": 19275
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25400,
    "Closing Rank": 25400
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48153,
    "Closing Rank": 51973
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 63079,
    "Closing Rank": 68738
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8706,
    "Closing Rank": 8968
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17479,
    "Closing Rank": 17683
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23737,
    "Closing Rank": 24205
  },
  {
    "Institute": "National Institute of Technology Nagaland",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6658,
    "Closing Rank": 7715
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2451,
    "Closing Rank": 6575
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3357,
    "Closing Rank": 3720
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1130,
    "Closing Rank": 1276
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1075,
    "Closing Rank": 1075
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2509,
    "Closing Rank": 4069
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2224,
    "Closing Rank": 2224
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 491,
    "Closing Rank": 765
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 547,
    "Closing Rank": 547
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 954,
    "Closing Rank": 954
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1069,
    "Closing Rank": 1687
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 932,
    "Closing Rank": 1065
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 723,
    "Closing Rank": 723
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 609,
    "Closing Rank": 609
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 793,
    "Closing Rank": 915
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 907,
    "Closing Rank": 907
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 138,
    "Closing Rank": 203
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 336,
    "Closing Rank": 336
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 167,
    "Closing Rank": 167
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45827,
    "Closing Rank": 45827
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52806,
    "Closing Rank": 52806
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8799,
    "Closing Rank": 8799
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16172,
    "Closing Rank": 16172
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9246,
    "Closing Rank": 9246
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36034,
    "Closing Rank": 36034
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50778,
    "Closing Rank": 50778
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3410,
    "Closing Rank": 3410
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Chemical Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14774,
    "Closing Rank": 16182
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35673,
    "Closing Rank": 49025
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53789,
    "Closing Rank": 64356
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1510,
    "Closing Rank": 1510
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7944,
    "Closing Rank": 8226
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10876,
    "Closing Rank": 10876
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14520,
    "Closing Rank": 16024
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20889,
    "Closing Rank": 22692
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1016,
    "Closing Rank": 1016
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6426,
    "Closing Rank": 7940
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9955,
    "Closing Rank": 10400
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3386,
    "Closing Rank": 4153
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9682,
    "Closing Rank": 9682
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42153,
    "Closing Rank": 46400
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49493,
    "Closing Rank": 58458
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1530,
    "Closing Rank": 1530
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7508,
    "Closing Rank": 7697
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9832,
    "Closing Rank": 9832
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14398,
    "Closing Rank": 15943
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19337,
    "Closing Rank": 20721
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1397,
    "Closing Rank": 1397
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6090,
    "Closing Rank": 7302
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9430,
    "Closing Rank": 9447
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1766,
    "Closing Rank": 2105
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3382,
    "Closing Rank": 3382
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49139,
    "Closing Rank": 49139
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60312,
    "Closing Rank": 60312
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16735,
    "Closing Rank": 16735
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8408,
    "Closing Rank": 8408
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42114,
    "Closing Rank": 42411
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 58957,
    "Closing Rank": 58957
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15967,
    "Closing Rank": 15967
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Civil Engineering with Specialization in Construction Technology and Management (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7321,
    "Closing Rank": 7321
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8776,
    "Closing Rank": 18320
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16772,
    "Closing Rank": 29874
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 407,
    "Closing Rank": 407
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1865,
    "Closing Rank": 1865
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2825,
    "Closing Rank": 3099
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4878,
    "Closing Rank": 4878
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 160,
    "Closing Rank": 160
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4640,
    "Closing Rank": 6254
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8641,
    "Closing Rank": 12189
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 197,
    "Closing Rank": 197
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2255,
    "Closing Rank": 3557
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4872,
    "Closing Rank": 5792
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 163,
    "Closing Rank": 163
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1530,
    "Closing Rank": 2476
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2449,
    "Closing Rank": 4917
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9288,
    "Closing Rank": 14442
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18483,
    "Closing Rank": 21664
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 381,
    "Closing Rank": 497
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 622,
    "Closing Rank": 622
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2197,
    "Closing Rank": 2363
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3850,
    "Closing Rank": 3850
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 74,
    "Closing Rank": 74
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3932,
    "Closing Rank": 4872
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7873,
    "Closing Rank": 9771
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 329,
    "Closing Rank": 329
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2357,
    "Closing Rank": 3026
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2147,
    "Closing Rank": 3680
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55,
    "Closing Rank": 55
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 688,
    "Closing Rank": 963
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1793,
    "Closing Rank": 1962
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32,
    "Closing Rank": 32
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22226,
    "Closing Rank": 23853
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43731,
    "Closing Rank": 43731
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 705,
    "Closing Rank": 705
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3830,
    "Closing Rank": 3830
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7434,
    "Closing Rank": 7539
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15504,
    "Closing Rank": 15504
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4670,
    "Closing Rank": 4670
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2945,
    "Closing Rank": 2945
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16249,
    "Closing Rank": 16444
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26014,
    "Closing Rank": 26014
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2665,
    "Closing Rank": 2665
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4330,
    "Closing Rank": 5641
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11259,
    "Closing Rank": 11259
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 431,
    "Closing Rank": 431
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3626,
    "Closing Rank": 3626
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Cyber Security (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1436,
    "Closing Rank": 1436
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19041,
    "Closing Rank": 20655
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38069,
    "Closing Rank": 38069
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1048,
    "Closing Rank": 1048
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3261,
    "Closing Rank": 3261
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7261,
    "Closing Rank": 7406
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13334,
    "Closing Rank": 13334
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4050,
    "Closing Rank": 4050
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8379,
    "Closing Rank": 8379
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2504,
    "Closing Rank": 2504
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11908,
    "Closing Rank": 15659
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23901,
    "Closing Rank": 23901
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2558,
    "Closing Rank": 2558
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 218,
    "Closing Rank": 218
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5583,
    "Closing Rank": 5654
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10950,
    "Closing Rank": 10950
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2766,
    "Closing Rank": 2766
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Computer Science and Engineering with Specialization in Data Science (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1461,
    "Closing Rank": 1461
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26553,
    "Closing Rank": 32823
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40594,
    "Closing Rank": 47916
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1739,
    "Closing Rank": 1739
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5241,
    "Closing Rank": 5601
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8356,
    "Closing Rank": 8356
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9066,
    "Closing Rank": 10334
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13992,
    "Closing Rank": 16714
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 772,
    "Closing Rank": 772
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5449,
    "Closing Rank": 6901
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8548,
    "Closing Rank": 9747
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3446,
    "Closing Rank": 4246
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6131,
    "Closing Rank": 6131
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22524,
    "Closing Rank": 26872
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34034,
    "Closing Rank": 37159
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1209,
    "Closing Rank": 1209
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4266,
    "Closing Rank": 4530
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6511,
    "Closing Rank": 6511
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7480,
    "Closing Rank": 8664
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13534,
    "Closing Rank": 15507
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 807,
    "Closing Rank": 807
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4791,
    "Closing Rank": 5351
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7217,
    "Closing Rank": 7457
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1928,
    "Closing Rank": 2117
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3093,
    "Closing Rank": 3093
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35581,
    "Closing Rank": 36942
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43898,
    "Closing Rank": 43898
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1783,
    "Closing Rank": 1783
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6449,
    "Closing Rank": 6449
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10551,
    "Closing Rank": 10870
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17824,
    "Closing Rank": 17824
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1013,
    "Closing Rank": 1013
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6996,
    "Closing Rank": 6996
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8812,
    "Closing Rank": 8812
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21904,
    "Closing Rank": 27448
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43415,
    "Closing Rank": 43415
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4656,
    "Closing Rank": 4656
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9044,
    "Closing Rank": 9100
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13962,
    "Closing Rank": 13962
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5378,
    "Closing Rank": 5378
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electrical Engineering with Specialization In Power System Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2218,
    "Closing Rank": 2218
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20750,
    "Closing Rank": 25962
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25779,
    "Closing Rank": 35980
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 980,
    "Closing Rank": 980
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4137,
    "Closing Rank": 4459
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6437,
    "Closing Rank": 6495
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7031,
    "Closing Rank": 8288
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10362,
    "Closing Rank": 13019
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 432,
    "Closing Rank": 432
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3742,
    "Closing Rank": 5041
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7493,
    "Closing Rank": 9692
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2723,
    "Closing Rank": 3405
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7125,
    "Closing Rank": 7125
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15389,
    "Closing Rank": 20286
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27115,
    "Closing Rank": 31021
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1251,
    "Closing Rank": 1251
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3208,
    "Closing Rank": 3281
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4955,
    "Closing Rank": 5176
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5490,
    "Closing Rank": 6882
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10177,
    "Closing Rank": 10976
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 684,
    "Closing Rank": 684
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3415,
    "Closing Rank": 4340
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5228,
    "Closing Rank": 5940
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 232,
    "Closing Rank": 232
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1431,
    "Closing Rank": 1540
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2287,
    "Closing Rank": 2287
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26094,
    "Closing Rank": 32786
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42145,
    "Closing Rank": 42145
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5852,
    "Closing Rank": 5852
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9578,
    "Closing Rank": 9933
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15569,
    "Closing Rank": 15569
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5433,
    "Closing Rank": 5433
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3448,
    "Closing Rank": 3448
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21967,
    "Closing Rank": 22769
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22579,
    "Closing Rank": 22579
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3590,
    "Closing Rank": 3590
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6633,
    "Closing Rank": 6633
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14291,
    "Closing Rank": 14291
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 655,
    "Closing Rank": 655
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4542,
    "Closing Rank": 4542
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Electronics and Communication Engineering with Specialization in Microelectronics and VLSI System Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1553,
    "Closing Rank": 1553
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50323,
    "Closing Rank": 50323
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8610,
    "Closing Rank": 8610
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12248,
    "Closing Rank": 12248
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17463,
    "Closing Rank": 17463
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10034,
    "Closing Rank": 10034
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37241,
    "Closing Rank": 37241
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59996,
    "Closing Rank": 59996
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6442,
    "Closing Rank": 6442
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Material Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10700,
    "Closing Rank": 13860
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23529,
    "Closing Rank": 23529
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40217,
    "Closing Rank": 40217
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 727,
    "Closing Rank": 727
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7178,
    "Closing Rank": 7178
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4836,
    "Closing Rank": 4836
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15362,
    "Closing Rank": 15362
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26231,
    "Closing Rank": 26231
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mathematics and Computing Technology (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5777,
    "Closing Rank": 5804
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32089,
    "Closing Rank": 42589
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39769,
    "Closing Rank": 58835
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2780,
    "Closing Rank": 2780
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6843,
    "Closing Rank": 7165
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9951,
    "Closing Rank": 9951
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12342,
    "Closing Rank": 13452
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18387,
    "Closing Rank": 21209
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1566,
    "Closing Rank": 1566
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6138,
    "Closing Rank": 7785
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10349,
    "Closing Rank": 11177
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3633,
    "Closing Rank": 4569
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7871,
    "Closing Rank": 7871
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29366,
    "Closing Rank": 36958
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43090,
    "Closing Rank": 48516
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3082,
    "Closing Rank": 3082
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5928,
    "Closing Rank": 6140
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8035,
    "Closing Rank": 8035
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11227,
    "Closing Rank": 12299
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17499,
    "Closing Rank": 19192
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1713,
    "Closing Rank": 1713
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5948,
    "Closing Rank": 6674
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8906,
    "Closing Rank": 9003
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2296,
    "Closing Rank": 2334
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3782,
    "Closing Rank": 3782
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43864,
    "Closing Rank": 48315
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 64005,
    "Closing Rank": 64005
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8134,
    "Closing Rank": 8134
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14289,
    "Closing Rank": 14533
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22747,
    "Closing Rank": 22747
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8318,
    "Closing Rank": 8318
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11415,
    "Closing Rank": 11415
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4946,
    "Closing Rank": 4946
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37408,
    "Closing Rank": 39044
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53845,
    "Closing Rank": 53845
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1563,
    "Closing Rank": 1563
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6990,
    "Closing Rank": 6990
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11327,
    "Closing Rank": 12515
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20027,
    "Closing Rank": 20027
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6805,
    "Closing Rank": 6805
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechanical Engineering with Specialization in Manufacturing and Industrial Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2620,
    "Closing Rank": 2620
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32615,
    "Closing Rank": 40085
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49479,
    "Closing Rank": 49479
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7036,
    "Closing Rank": 7036
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12229,
    "Closing Rank": 12693
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17402,
    "Closing Rank": 17402
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7703,
    "Closing Rank": 7703
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11754,
    "Closing Rank": 11754
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4513,
    "Closing Rank": 4513
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20681,
    "Closing Rank": 24053
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45543,
    "Closing Rank": 45543
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4450,
    "Closing Rank": 4450
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7970,
    "Closing Rank": 8711
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15141,
    "Closing Rank": 15141
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5183,
    "Closing Rank": 5641
  },
  {
    "Institute": "National Institute of Technology Patna",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8820,
    "Closing Rank": 8820
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 138928,
    "Closing Rank": 206045
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 244840,
    "Closing Rank": 257167
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 87784,
    "Closing Rank": 87784
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75745,
    "Closing Rank": 102063
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 95480,
    "Closing Rank": 95480
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38366,
    "Closing Rank": 39489
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44970,
    "Closing Rank": 47854
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41684,
    "Closing Rank": 44659
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8080,
    "Closing Rank": 8214
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14205,
    "Closing Rank": 15806
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 997,
    "Closing Rank": 997
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6029,
    "Closing Rank": 7386
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7476,
    "Closing Rank": 7476
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2265,
    "Closing Rank": 2265
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3768,
    "Closing Rank": 3768
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27384,
    "Closing Rank": 63717
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60539,
    "Closing Rank": 71250
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51905,
    "Closing Rank": 66844
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20214,
    "Closing Rank": 26198
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22808,
    "Closing Rank": 38465
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1300,
    "Closing Rank": 1300
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7964,
    "Closing Rank": 11782
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8926,
    "Closing Rank": 17166
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9489,
    "Closing Rank": 9489
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6318,
    "Closing Rank": 18515
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9484,
    "Closing Rank": 18657
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1161,
    "Closing Rank": 1161
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2876,
    "Closing Rank": 3108
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3870,
    "Closing Rank": 3870
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5281,
    "Closing Rank": 6550
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6144,
    "Closing Rank": 6144
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1551,
    "Closing Rank": 3195
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3782,
    "Closing Rank": 3782
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 788,
    "Closing Rank": 792
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 102541,
    "Closing Rank": 120719
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 152553,
    "Closing Rank": 195851
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40202,
    "Closing Rank": 45980
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 70795,
    "Closing Rank": 72797
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13938,
    "Closing Rank": 36217
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41593,
    "Closing Rank": 41593
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20487,
    "Closing Rank": 29032
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33318,
    "Closing Rank": 37184
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1472,
    "Closing Rank": 1472
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5184,
    "Closing Rank": 5488
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7007,
    "Closing Rank": 7007
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8540,
    "Closing Rank": 11164
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13738,
    "Closing Rank": 13738
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5216,
    "Closing Rank": 5873
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2681,
    "Closing Rank": 2681
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1858,
    "Closing Rank": 1858
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3260,
    "Closing Rank": 3260
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50168,
    "Closing Rank": 90244
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 83677,
    "Closing Rank": 95611
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40008,
    "Closing Rank": 40008
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 66874,
    "Closing Rank": 66874
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28910,
    "Closing Rank": 31554
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40490,
    "Closing Rank": 47439
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13100,
    "Closing Rank": 30924
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11866,
    "Closing Rank": 11866
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16370,
    "Closing Rank": 24073
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25204,
    "Closing Rank": 28650
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 674,
    "Closing Rank": 674
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3933,
    "Closing Rank": 3964
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4826,
    "Closing Rank": 4826
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6745,
    "Closing Rank": 8478
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8536,
    "Closing Rank": 8536
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4577,
    "Closing Rank": 4902
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3837,
    "Closing Rank": 3837
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1228,
    "Closing Rank": 1296
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2684,
    "Closing Rank": 2684
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55842,
    "Closing Rank": 160684
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 199749,
    "Closing Rank": 228923
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55290,
    "Closing Rank": 58051
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 84572,
    "Closing Rank": 88661
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9712,
    "Closing Rank": 25648
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20988,
    "Closing Rank": 20988
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34874,
    "Closing Rank": 42247
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 46509,
    "Closing Rank": 47344
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7232,
    "Closing Rank": 7249
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9059,
    "Closing Rank": 9059
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12632,
    "Closing Rank": 13373
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15865,
    "Closing Rank": 15865
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5853,
    "Closing Rank": 6980
  },
  {
    "Institute": "National Institute of Technology Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4032,
    "Closing Rank": 4032
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2104,
    "Closing Rank": 4948
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1333,
    "Closing Rank": 1333
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 611,
    "Closing Rank": 611
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2351,
    "Closing Rank": 4642
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3118,
    "Closing Rank": 3118
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1045,
    "Closing Rank": 1391
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1174,
    "Closing Rank": 1174
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1250,
    "Closing Rank": 1250
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 988,
    "Closing Rank": 1538
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 815,
    "Closing Rank": 826
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 650,
    "Closing Rank": 712
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 636,
    "Closing Rank": 836
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 972,
    "Closing Rank": 972
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 310,
    "Closing Rank": 369
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 416,
    "Closing Rank": 416
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 201,
    "Closing Rank": 201
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75374,
    "Closing Rank": 111467
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 87615,
    "Closing Rank": 106148
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20910,
    "Closing Rank": 23501
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38985,
    "Closing Rank": 41367
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42802,
    "Closing Rank": 42827
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14791,
    "Closing Rank": 20294
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17416,
    "Closing Rank": 17416
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6239,
    "Closing Rank": 6341
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43187,
    "Closing Rank": 52916
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 46758,
    "Closing Rank": 59352
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8800,
    "Closing Rank": 9086
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10583,
    "Closing Rank": 10583
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17406,
    "Closing Rank": 18155
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20877,
    "Closing Rank": 21223
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7779,
    "Closing Rank": 8114
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10322,
    "Closing Rank": 10696
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3330,
    "Closing Rank": 3472
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46734,
    "Closing Rank": 97437
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 69571,
    "Closing Rank": 105430
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17982,
    "Closing Rank": 20159
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21309,
    "Closing Rank": 21309
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34905,
    "Closing Rank": 38955
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39905,
    "Closing Rank": 40955
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13875,
    "Closing Rank": 19748
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9840,
    "Closing Rank": 9840
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4085,
    "Closing Rank": 4220
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6916,
    "Closing Rank": 6916
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42017,
    "Closing Rank": 52108
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44205,
    "Closing Rank": 59252
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8501,
    "Closing Rank": 8912
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15441,
    "Closing Rank": 17373
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19178,
    "Closing Rank": 19331
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7520,
    "Closing Rank": 8412
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8796,
    "Closing Rank": 8796
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3210,
    "Closing Rank": 3250
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3950,
    "Closing Rank": 3950
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52136,
    "Closing Rank": 69308
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 65822,
    "Closing Rank": 70635
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11438,
    "Closing Rank": 13973
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15006,
    "Closing Rank": 15006
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23067,
    "Closing Rank": 26468
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26078,
    "Closing Rank": 35379
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9915,
    "Closing Rank": 13113
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14268,
    "Closing Rank": 14268
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4603,
    "Closing Rank": 4784
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34623,
    "Closing Rank": 39335
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40344,
    "Closing Rank": 44955
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2241,
    "Closing Rank": 2241
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6331,
    "Closing Rank": 6687
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7945,
    "Closing Rank": 7945
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12563,
    "Closing Rank": 13880
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17352,
    "Closing Rank": 18792
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1931,
    "Closing Rank": 1931
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6938,
    "Closing Rank": 7361
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8443,
    "Closing Rank": 8443
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2404,
    "Closing Rank": 2543
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3946,
    "Closing Rank": 3946
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44737,
    "Closing Rank": 71977
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 72607,
    "Closing Rank": 84881
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14315,
    "Closing Rank": 16769
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16790,
    "Closing Rank": 16790
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25272,
    "Closing Rank": 31024
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29642,
    "Closing Rank": 35384
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6970,
    "Closing Rank": 12893
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15080,
    "Closing Rank": 15080
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1741,
    "Closing Rank": 4119
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3899,
    "Closing Rank": 3899
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41341,
    "Closing Rank": 44960
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52932,
    "Closing Rank": 54393
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1197,
    "Closing Rank": 1197
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7256,
    "Closing Rank": 7615
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9466,
    "Closing Rank": 9466
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14217,
    "Closing Rank": 15396
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20026,
    "Closing Rank": 20429
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1233,
    "Closing Rank": 1233
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6412,
    "Closing Rank": 7286
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9993,
    "Closing Rank": 9994
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1926,
    "Closing Rank": 2353
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3105,
    "Closing Rank": 3105
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11194,
    "Closing Rank": 21328
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20758,
    "Closing Rank": 33617
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 538,
    "Closing Rank": 538
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3587,
    "Closing Rank": 5343
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6805,
    "Closing Rank": 6805
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5565,
    "Closing Rank": 8819
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11701,
    "Closing Rank": 18437
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 622,
    "Closing Rank": 622
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4189,
    "Closing Rank": 5847
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1814,
    "Closing Rank": 5804
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36,
    "Closing Rank": 36
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1124,
    "Closing Rank": 1490
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2332,
    "Closing Rank": 2332
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10323,
    "Closing Rank": 13177
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16367,
    "Closing Rank": 19455
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 449,
    "Closing Rank": 449
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2012,
    "Closing Rank": 2095
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3218,
    "Closing Rank": 3218
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 463,
    "Closing Rank": 463
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3578,
    "Closing Rank": 4263
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5146,
    "Closing Rank": 6019
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 268,
    "Closing Rank": 268
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2278,
    "Closing Rank": 2895
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3036,
    "Closing Rank": 3036
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48,
    "Closing Rank": 48
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 540,
    "Closing Rank": 974
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32187,
    "Closing Rank": 44565
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51171,
    "Closing Rank": 62262
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8809,
    "Closing Rank": 9379
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12008,
    "Closing Rank": 12008
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17044,
    "Closing Rank": 19578
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21791,
    "Closing Rank": 22028
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9436,
    "Closing Rank": 10632
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9484,
    "Closing Rank": 14432
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2224,
    "Closing Rank": 3720
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6000,
    "Closing Rank": 6000
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20355,
    "Closing Rank": 26898
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28398,
    "Closing Rank": 37242
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 698,
    "Closing Rank": 698
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4265,
    "Closing Rank": 4360
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6167,
    "Closing Rank": 6474
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7292,
    "Closing Rank": 8569
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11677,
    "Closing Rank": 14207
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3582,
    "Closing Rank": 5118
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7473,
    "Closing Rank": 7764
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1767,
    "Closing Rank": 1937
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2606,
    "Closing Rank": 2606
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27680,
    "Closing Rank": 35860
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48104,
    "Closing Rank": 50840
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1000,
    "Closing Rank": 1000
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7397,
    "Closing Rank": 8766
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10767,
    "Closing Rank": 10767
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10520,
    "Closing Rank": 16842
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17614,
    "Closing Rank": 20795
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6153,
    "Closing Rank": 9322
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8963,
    "Closing Rank": 8963
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1780,
    "Closing Rank": 2740
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3168,
    "Closing Rank": 3168
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15698,
    "Closing Rank": 18823
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24169,
    "Closing Rank": 26938
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1133,
    "Closing Rank": 1133
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1223,
    "Closing Rank": 1223
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2968,
    "Closing Rank": 3216
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4335,
    "Closing Rank": 4335
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 200,
    "Closing Rank": 200
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5109,
    "Closing Rank": 6657
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9033,
    "Closing Rank": 9606
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 638,
    "Closing Rank": 638
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4066,
    "Closing Rank": 4457
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5428,
    "Closing Rank": 5925
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 762,
    "Closing Rank": 1520
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21427,
    "Closing Rank": 27785
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35732,
    "Closing Rank": 47018
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1685,
    "Closing Rank": 1685
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4559,
    "Closing Rank": 6319
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10500,
    "Closing Rank": 10500
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8877,
    "Closing Rank": 12333
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19890,
    "Closing Rank": 21020
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5492,
    "Closing Rank": 7144
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8232,
    "Closing Rank": 8232
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1680,
    "Closing Rank": 2090
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2589,
    "Closing Rank": 2589
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13256,
    "Closing Rank": 16070
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20109,
    "Closing Rank": 22715
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 731,
    "Closing Rank": 731
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2437,
    "Closing Rank": 2453
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3580,
    "Closing Rank": 3580
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 112,
    "Closing Rank": 112
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4381,
    "Closing Rank": 5511
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6845,
    "Closing Rank": 8233
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3136,
    "Closing Rank": 3569
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4165,
    "Closing Rank": 4423
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1233,
    "Closing Rank": 1357
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30103,
    "Closing Rank": 55150
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 63339,
    "Closing Rank": 69123
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11980,
    "Closing Rank": 13459
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14394,
    "Closing Rank": 14394
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17598,
    "Closing Rank": 22783
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23619,
    "Closing Rank": 29463
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9879,
    "Closing Rank": 11961
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13374,
    "Closing Rank": 14539
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3801,
    "Closing Rank": 4161
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5184,
    "Closing Rank": 5184
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28086,
    "Closing Rank": 35628
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39551,
    "Closing Rank": 45711
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1858,
    "Closing Rank": 1858
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5813,
    "Closing Rank": 6249
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7993,
    "Closing Rank": 7993
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10782,
    "Closing Rank": 11705
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16421,
    "Closing Rank": 19238
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6401,
    "Closing Rank": 6631
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8373,
    "Closing Rank": 8373
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1999,
    "Closing Rank": 2392
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3418,
    "Closing Rank": 3418
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58711,
    "Closing Rank": 76780
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 88804,
    "Closing Rank": 95943
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13095,
    "Closing Rank": 20092
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17481,
    "Closing Rank": 17481
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24336,
    "Closing Rank": 34688
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37602,
    "Closing Rank": 41044
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13523,
    "Closing Rank": 15652
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13462,
    "Closing Rank": 16169
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5112,
    "Closing Rank": 5596
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6847,
    "Closing Rank": 6847
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42536,
    "Closing Rank": 49080
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53602,
    "Closing Rank": 58540
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2483,
    "Closing Rank": 2483
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8262,
    "Closing Rank": 8407
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9956,
    "Closing Rank": 9956
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16123,
    "Closing Rank": 17275
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20775,
    "Closing Rank": 21240
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7686,
    "Closing Rank": 8079
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10292,
    "Closing Rank": 10292
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2848,
    "Closing Rank": 3070
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4388,
    "Closing Rank": 4495
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49174,
    "Closing Rank": 82515
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 96021,
    "Closing Rank": 101515
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14313,
    "Closing Rank": 17486
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20986,
    "Closing Rank": 20986
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28479,
    "Closing Rank": 35058
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44835,
    "Closing Rank": 50062
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11340,
    "Closing Rank": 14577
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16205,
    "Closing Rank": 16711
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4082,
    "Closing Rank": 5082
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41692,
    "Closing Rank": 51623
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60652,
    "Closing Rank": 62417
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8388,
    "Closing Rank": 8702
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10311,
    "Closing Rank": 10311
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15344,
    "Closing Rank": 16882
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22589,
    "Closing Rank": 23453
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6247,
    "Closing Rank": 8387
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11591,
    "Closing Rank": 11591
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2428,
    "Closing Rank": 3156
  },
  {
    "Institute": "National Institute of Technology Raipur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2212,
    "Closing Rank": 2212
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 118100,
    "Closing Rank": 305662
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 102401,
    "Closing Rank": 940500
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 200817,
    "Closing Rank": 354738
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6322,
    "Closing Rank": 6322
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30037,
    "Closing Rank": 33955
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31349,
    "Closing Rank": 31349
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9842,
    "Closing Rank": 11033
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17291,
    "Closing Rank": 17291
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2843,
    "Closing Rank": 2843
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6483,
    "Closing Rank": 6483
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1424,
    "Closing Rank": 1424
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 564098,
    "Closing Rank": 1368129
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 506019,
    "Closing Rank": 1094961
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48432,
    "Closing Rank": 54266
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 66468,
    "Closing Rank": 66468
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16109,
    "Closing Rank": 17851
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23479,
    "Closing Rank": 23479
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8011,
    "Closing Rank": 8222
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11614,
    "Closing Rank": 11614
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54736,
    "Closing Rank": 143919
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 268637,
    "Closing Rank": 399819
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 69229,
    "Closing Rank": 141737
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 218072,
    "Closing Rank": 218072
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27032,
    "Closing Rank": 113186
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 63611,
    "Closing Rank": 63611
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6688,
    "Closing Rank": 9743
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23245,
    "Closing Rank": 28284
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34107,
    "Closing Rank": 35891
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1195,
    "Closing Rank": 1195
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8076,
    "Closing Rank": 9886
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10642,
    "Closing Rank": 10642
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3458,
    "Closing Rank": 3803
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5977,
    "Closing Rank": 5977
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1764,
    "Closing Rank": 1764
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 194239,
    "Closing Rank": 377626
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 914560,
    "Closing Rank": 914560
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 213608,
    "Closing Rank": 455479
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 71174,
    "Closing Rank": 114675
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30150,
    "Closing Rank": 30150
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33265,
    "Closing Rank": 42538
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51162,
    "Closing Rank": 51162
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12203,
    "Closing Rank": 13343
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19267,
    "Closing Rank": 19267
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5979,
    "Closing Rank": 6005
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9132,
    "Closing Rank": 9132
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2325,
    "Closing Rank": 2325
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 83257,
    "Closing Rank": 209475
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 523629,
    "Closing Rank": 683058
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 199997,
    "Closing Rank": 283915
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 322677,
    "Closing Rank": 322677
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11512,
    "Closing Rank": 70749
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27450,
    "Closing Rank": 27450
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28551,
    "Closing Rank": 37004
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44971,
    "Closing Rank": 44971
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11382,
    "Closing Rank": 12628
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18298,
    "Closing Rank": 18298
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1288,
    "Closing Rank": 1314
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5244,
    "Closing Rank": 5705
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8495,
    "Closing Rank": 8495
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2000,
    "Closing Rank": 2000
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 507133,
    "Closing Rank": 663130
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32334,
    "Closing Rank": 32334
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41613,
    "Closing Rank": 47070
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51398,
    "Closing Rank": 54473
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13716,
    "Closing Rank": 14850
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23130,
    "Closing Rank": 23130
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7347,
    "Closing Rank": 7347
  },
  {
    "Institute": "National Institute of Technology Sikkim",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2798,
    "Closing Rank": 2798
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 189756,
    "Closing Rank": 189756
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 172311,
    "Closing Rank": 172311
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93275,
    "Closing Rank": 93275
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2778,
    "Closing Rank": 6345
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4092,
    "Closing Rank": 9273
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51179,
    "Closing Rank": 55692
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 65179,
    "Closing Rank": 65179
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9112,
    "Closing Rank": 9112
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17184,
    "Closing Rank": 18279
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24201,
    "Closing Rank": 24201
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1738,
    "Closing Rank": 1738
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8389,
    "Closing Rank": 8598
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2694,
    "Closing Rank": 2694
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 68434,
    "Closing Rank": 71299
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11663,
    "Closing Rank": 11663
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35104,
    "Closing Rank": 35104
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3207,
    "Closing Rank": 8735
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12486,
    "Closing Rank": 20835
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22046,
    "Closing Rank": 28514
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40714,
    "Closing Rank": 40714
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4830,
    "Closing Rank": 4986
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10648,
    "Closing Rank": 11122
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17556,
    "Closing Rank": 17556
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4295,
    "Closing Rank": 4890
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1794,
    "Closing Rank": 1794
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 99436,
    "Closing Rank": 182035
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45740,
    "Closing Rank": 45740
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 96720,
    "Closing Rank": 96720
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9152,
    "Closing Rank": 12717
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5598,
    "Closing Rank": 17190
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39935,
    "Closing Rank": 45212
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50543,
    "Closing Rank": 50543
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7662,
    "Closing Rank": 7662
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9036,
    "Closing Rank": 9036
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13627,
    "Closing Rank": 14504
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6456,
    "Closing Rank": 7062
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2588,
    "Closing Rank": 2588
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 73439,
    "Closing Rank": 73439
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24212,
    "Closing Rank": 24212
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59209,
    "Closing Rank": 59209
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29426,
    "Closing Rank": 29426
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8120,
    "Closing Rank": 27924
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8951,
    "Closing Rank": 26671
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32471,
    "Closing Rank": 40722
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43507,
    "Closing Rank": 43507
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1613,
    "Closing Rank": 1963
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6830,
    "Closing Rank": 6914
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12222,
    "Closing Rank": 12655
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20251,
    "Closing Rank": 20251
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5980,
    "Closing Rank": 5980
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2196,
    "Closing Rank": 2196
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 156476,
    "Closing Rank": 156476
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 105947,
    "Closing Rank": 105947
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44024,
    "Closing Rank": 44024
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12991,
    "Closing Rank": 35049
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6747,
    "Closing Rank": 19495
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46036,
    "Closing Rank": 50666
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 64642,
    "Closing Rank": 64642
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8525,
    "Closing Rank": 8525
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16062,
    "Closing Rank": 16754
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23261,
    "Closing Rank": 23261
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7631,
    "Closing Rank": 8019
  },
  {
    "Institute": "National Institute of Technology Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4308,
    "Closing Rank": 4308
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36595,
    "Closing Rank": 47939
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 65323,
    "Closing Rank": 74915
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2088,
    "Closing Rank": 2088
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7814,
    "Closing Rank": 9163
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14009,
    "Closing Rank": 16278
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26561,
    "Closing Rank": 29807
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7290,
    "Closing Rank": 9062
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13185,
    "Closing Rank": 13299
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2402,
    "Closing Rank": 2577
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4263,
    "Closing Rank": 4263
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33533,
    "Closing Rank": 40903
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52147,
    "Closing Rank": 52986
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1601,
    "Closing Rank": 1601
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6593,
    "Closing Rank": 6921
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9519,
    "Closing Rank": 9519
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12247,
    "Closing Rank": 13542
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19683,
    "Closing Rank": 20034
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5622,
    "Closing Rank": 6443
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9312,
    "Closing Rank": 9577
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1479,
    "Closing Rank": 2060
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2788,
    "Closing Rank": 2788
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6449,
    "Closing Rank": 13775
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14057,
    "Closing Rank": 22346
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1107,
    "Closing Rank": 1107
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2399,
    "Closing Rank": 2723
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4697,
    "Closing Rank": 4697
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3455,
    "Closing Rank": 5016
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6780,
    "Closing Rank": 9790
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 549,
    "Closing Rank": 549
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1999,
    "Closing Rank": 3382
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7075,
    "Closing Rank": 9173
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 138,
    "Closing Rank": 138
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 699,
    "Closing Rank": 1268
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1792,
    "Closing Rank": 1792
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4935,
    "Closing Rank": 7944
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13055,
    "Closing Rank": 15201
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 467,
    "Closing Rank": 467
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1096,
    "Closing Rank": 1147
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2641,
    "Closing Rank": 2641
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45,
    "Closing Rank": 45
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2220,
    "Closing Rank": 2879
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6335,
    "Closing Rank": 6747
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 178,
    "Closing Rank": 178
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1627,
    "Closing Rank": 1784
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2652,
    "Closing Rank": 2875
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47,
    "Closing Rank": 47
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 524,
    "Closing Rank": 748
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1203,
    "Closing Rank": 1203
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19024,
    "Closing Rank": 24481
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32218,
    "Closing Rank": 42038
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1535,
    "Closing Rank": 2050
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2433,
    "Closing Rank": 2433
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4089,
    "Closing Rank": 4429
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7422,
    "Closing Rank": 7422
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6492,
    "Closing Rank": 7797
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13162,
    "Closing Rank": 16019
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4811,
    "Closing Rank": 5874
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12046,
    "Closing Rank": 12046
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1324,
    "Closing Rank": 1733
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13550,
    "Closing Rank": 17387
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25280,
    "Closing Rank": 28759
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 791,
    "Closing Rank": 945
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2687,
    "Closing Rank": 3000
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6058,
    "Closing Rank": 6058
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5593,
    "Closing Rank": 6623
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10794,
    "Closing Rank": 12583
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 613,
    "Closing Rank": 613
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3151,
    "Closing Rank": 3839
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5184,
    "Closing Rank": 5184
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1303,
    "Closing Rank": 1763
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14414,
    "Closing Rank": 18723
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22528,
    "Closing Rank": 30387
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1365,
    "Closing Rank": 2510
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3047,
    "Closing Rank": 3758
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6348,
    "Closing Rank": 6410
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5083,
    "Closing Rank": 6088
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10349,
    "Closing Rank": 12380
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 697,
    "Closing Rank": 697
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2685,
    "Closing Rank": 4714
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10639,
    "Closing Rank": 10714
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1442,
    "Closing Rank": 1593
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2309,
    "Closing Rank": 2309
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9974,
    "Closing Rank": 13080
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17422,
    "Closing Rank": 21844
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1386,
    "Closing Rank": 1386
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1956,
    "Closing Rank": 2062
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3419,
    "Closing Rank": 3419
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 176,
    "Closing Rank": 176
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3748,
    "Closing Rank": 4567
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6844,
    "Closing Rank": 7410
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 461,
    "Closing Rank": 461
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1888,
    "Closing Rank": 2955
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4756,
    "Closing Rank": 4909
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 256,
    "Closing Rank": 256
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 677,
    "Closing Rank": 970
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1995,
    "Closing Rank": 1995
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24960,
    "Closing Rank": 27104
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45456,
    "Closing Rank": 45456
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2262,
    "Closing Rank": 2262
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5740,
    "Closing Rank": 5740
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7421,
    "Closing Rank": 7421
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8354,
    "Closing Rank": 8866
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16453,
    "Closing Rank": 16453
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7664,
    "Closing Rank": 9453
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2442,
    "Closing Rank": 2948
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15063,
    "Closing Rank": 19206
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30105,
    "Closing Rank": 30105
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2998,
    "Closing Rank": 3269
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5138,
    "Closing Rank": 6409
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11433,
    "Closing Rank": 11433
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4519,
    "Closing Rank": 4580
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7432,
    "Closing Rank": 7432
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Engineering and Computational Mechanics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2002,
    "Closing Rank": 2173
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26403,
    "Closing Rank": 35978
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47884,
    "Closing Rank": 55289
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6241,
    "Closing Rank": 7020
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9085,
    "Closing Rank": 9085
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10131,
    "Closing Rank": 12897
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19506,
    "Closing Rank": 23877
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4842,
    "Closing Rank": 7252
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12107,
    "Closing Rank": 12111
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1356,
    "Closing Rank": 2191
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2943,
    "Closing Rank": 2943
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22450,
    "Closing Rank": 29147
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34790,
    "Closing Rank": 40459
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4655,
    "Closing Rank": 4972
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7130,
    "Closing Rank": 7130
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8051,
    "Closing Rank": 9551
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16340,
    "Closing Rank": 17361
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 853,
    "Closing Rank": 853
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4754,
    "Closing Rank": 5718
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8127,
    "Closing Rank": 8628
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1961,
    "Closing Rank": 2251
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3520,
    "Closing Rank": 3520
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36583,
    "Closing Rank": 52186
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37802,
    "Closing Rank": 71436
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2170,
    "Closing Rank": 2170
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8601,
    "Closing Rank": 8632
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13258,
    "Closing Rank": 13258
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15442,
    "Closing Rank": 18521
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22381,
    "Closing Rank": 22867
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8077,
    "Closing Rank": 9972
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12285,
    "Closing Rank": 12285
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2971,
    "Closing Rank": 3935
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4373,
    "Closing Rank": 4373
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36801,
    "Closing Rank": 46367
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53079,
    "Closing Rank": 56449
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7465,
    "Closing Rank": 7608
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13847,
    "Closing Rank": 15272
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20161,
    "Closing Rank": 20727
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6879,
    "Closing Rank": 7737
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9262,
    "Closing Rank": 9262
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2984,
    "Closing Rank": 3215
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4556,
    "Closing Rank": 4556
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41425,
    "Closing Rank": 49001
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50962,
    "Closing Rank": 58520
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8583,
    "Closing Rank": 9476
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12256,
    "Closing Rank": 12256
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15656,
    "Closing Rank": 18746
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27894,
    "Closing Rank": 28840
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10441,
    "Closing Rank": 10886
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15523,
    "Closing Rank": 15523
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4505,
    "Closing Rank": 4680
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36326,
    "Closing Rank": 39744
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47504,
    "Closing Rank": 50396
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2573,
    "Closing Rank": 2573
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6368,
    "Closing Rank": 6816
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9396,
    "Closing Rank": 9396
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13590,
    "Closing Rank": 14759
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20485,
    "Closing Rank": 20958
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7071,
    "Closing Rank": 7597
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10368,
    "Closing Rank": 10368
  },
  {
    "Institute": "National Institute of Technology, Jamshedpur",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2871,
    "Closing Rank": 3073
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1161,
    "Closing Rank": 2741
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1580,
    "Closing Rank": 2003
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 961,
    "Closing Rank": 1940
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 963,
    "Closing Rank": 4408
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1059,
    "Closing Rank": 1059
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 493,
    "Closing Rank": 493
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1010,
    "Closing Rank": 1010
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 645,
    "Closing Rank": 1605
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 741,
    "Closing Rank": 741
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 450,
    "Closing Rank": 450
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 556,
    "Closing Rank": 556
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 741,
    "Closing Rank": 1086
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 615,
    "Closing Rank": 615
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 337,
    "Closing Rank": 363
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 283,
    "Closing Rank": 283
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Architecture and Planning (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 174,
    "Closing Rank": 195
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9571,
    "Closing Rank": 11951
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15257,
    "Closing Rank": 20264
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2071,
    "Closing Rank": 2074
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 209,
    "Closing Rank": 209
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5111,
    "Closing Rank": 6447
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8839,
    "Closing Rank": 9398
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 780,
    "Closing Rank": 780
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2654,
    "Closing Rank": 3180
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4324,
    "Closing Rank": 4324
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3337,
    "Closing Rank": 3337
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14451,
    "Closing Rank": 14451
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6431,
    "Closing Rank": 7485
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10327,
    "Closing Rank": 13103
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1179,
    "Closing Rank": 1415
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2477,
    "Closing Rank": 3026
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5515,
    "Closing Rank": 5515
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1742,
    "Closing Rank": 1807
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3181,
    "Closing Rank": 3181
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 543,
    "Closing Rank": 588
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1234,
    "Closing Rank": 1234
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5056,
    "Closing Rank": 10215
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17769,
    "Closing Rank": 19534
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1889,
    "Closing Rank": 1935
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3417,
    "Closing Rank": 3417
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4111,
    "Closing Rank": 6336
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7100,
    "Closing Rank": 7848
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 416,
    "Closing Rank": 416
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2567,
    "Closing Rank": 2689
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2861,
    "Closing Rank": 2861
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4115,
    "Closing Rank": 4115
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6202,
    "Closing Rank": 8432
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12877,
    "Closing Rank": 13139
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 363,
    "Closing Rank": 363
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1199,
    "Closing Rank": 1343
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2973,
    "Closing Rank": 3395
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4733,
    "Closing Rank": 5522
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 546,
    "Closing Rank": 1685
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3214,
    "Closing Rank": 3214
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 519,
    "Closing Rank": 519
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1452,
    "Closing Rank": 1452
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9853,
    "Closing Rank": 41698
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36990,
    "Closing Rank": 48635
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6888,
    "Closing Rank": 7067
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8795,
    "Closing Rank": 8795
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12990,
    "Closing Rank": 17154
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17405,
    "Closing Rank": 19972
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3488,
    "Closing Rank": 5909
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5719,
    "Closing Rank": 5719
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12145,
    "Closing Rank": 16510
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13062,
    "Closing Rank": 13062
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26282,
    "Closing Rank": 34647
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37470,
    "Closing Rank": 48121
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 269,
    "Closing Rank": 269
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5720,
    "Closing Rank": 5826
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8297,
    "Closing Rank": 8297
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10214,
    "Closing Rank": 12000
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19239,
    "Closing Rank": 19522
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2560,
    "Closing Rank": 5161
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8354,
    "Closing Rank": 8532
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 966,
    "Closing Rank": 1146
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2312,
    "Closing Rank": 2312
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44751,
    "Closing Rank": 44751
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 54115,
    "Closing Rank": 54115
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17037,
    "Closing Rank": 17037
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5908,
    "Closing Rank": 5908
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34173,
    "Closing Rank": 34173
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 46156,
    "Closing Rank": 46156
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6716,
    "Closing Rank": 6716
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13769,
    "Closing Rank": 13789
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Civil Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6042,
    "Closing Rank": 6042
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5078,
    "Closing Rank": 8475
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8593,
    "Closing Rank": 11320
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 378,
    "Closing Rank": 378
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1228,
    "Closing Rank": 1551
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2010,
    "Closing Rank": 2010
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1847,
    "Closing Rank": 4101
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3498,
    "Closing Rank": 4661
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 325,
    "Closing Rank": 325
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1373,
    "Closing Rank": 2332
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1020,
    "Closing Rank": 3515
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 485,
    "Closing Rank": 3421
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3029,
    "Closing Rank": 3029
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4472,
    "Closing Rank": 6000
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5961,
    "Closing Rank": 9179
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 147,
    "Closing Rank": 147
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 879,
    "Closing Rank": 942
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1604,
    "Closing Rank": 1604
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50,
    "Closing Rank": 50
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1835,
    "Closing Rank": 2281
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3441,
    "Closing Rank": 4331
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 258,
    "Closing Rank": 258
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1310,
    "Closing Rank": 1479
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1482,
    "Closing Rank": 1618
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 212,
    "Closing Rank": 515
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1052,
    "Closing Rank": 1052
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9956,
    "Closing Rank": 11043
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 581,
    "Closing Rank": 581
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5393,
    "Closing Rank": 5393
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8395,
    "Closing Rank": 8395
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12079,
    "Closing Rank": 12079
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7717,
    "Closing Rank": 7717
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13385,
    "Closing Rank": 13385
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1515,
    "Closing Rank": 1515
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2635,
    "Closing Rank": 2635
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1446,
    "Closing Rank": 2129
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17154,
    "Closing Rank": 21795
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26539,
    "Closing Rank": 28566
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3703,
    "Closing Rank": 4167
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5632,
    "Closing Rank": 5632
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7697,
    "Closing Rank": 10693
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10515,
    "Closing Rank": 13393
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4349,
    "Closing Rank": 5338
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6776,
    "Closing Rank": 6776
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7156,
    "Closing Rank": 22089
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7600,
    "Closing Rank": 7600
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14406,
    "Closing Rank": 17104
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24057,
    "Closing Rank": 25788
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2301,
    "Closing Rank": 2301
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2692,
    "Closing Rank": 2831
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4357,
    "Closing Rank": 4357
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4583,
    "Closing Rank": 5903
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8673,
    "Closing Rank": 10509
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 383,
    "Closing Rank": 383
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2917,
    "Closing Rank": 3342
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4939,
    "Closing Rank": 4939
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1201,
    "Closing Rank": 1282
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22845,
    "Closing Rank": 23037
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6514,
    "Closing Rank": 6514
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11628,
    "Closing Rank": 11628
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4766,
    "Closing Rank": 4766
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15897,
    "Closing Rank": 15897
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26833,
    "Closing Rank": 26833
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1151,
    "Closing Rank": 1151
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6472,
    "Closing Rank": 7088
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electrical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2990,
    "Closing Rank": 2990
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11097,
    "Closing Rank": 15830
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17185,
    "Closing Rank": 22498
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2522,
    "Closing Rank": 2522
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2509,
    "Closing Rank": 2682
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4024,
    "Closing Rank": 4024
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5264,
    "Closing Rank": 7634
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9990,
    "Closing Rank": 11869
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3050,
    "Closing Rank": 3767
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4636,
    "Closing Rank": 4767
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6956,
    "Closing Rank": 14426
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9042,
    "Closing Rank": 11489
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15085,
    "Closing Rank": 17577
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 564,
    "Closing Rank": 564
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1713,
    "Closing Rank": 1838
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2926,
    "Closing Rank": 2926
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 110,
    "Closing Rank": 110
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3540,
    "Closing Rank": 4418
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7657,
    "Closing Rank": 8102
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 393,
    "Closing Rank": 393
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2287,
    "Closing Rank": 2540
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3910,
    "Closing Rank": 3910
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 724,
    "Closing Rank": 959
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1692,
    "Closing Rank": 1692
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14593,
    "Closing Rank": 14593
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24499,
    "Closing Rank": 24499
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8542,
    "Closing Rank": 8542
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3174,
    "Closing Rank": 3174
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12066,
    "Closing Rank": 12066
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22141,
    "Closing Rank": 22141
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2327,
    "Closing Rank": 2327
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4080,
    "Closing Rank": 5026
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3146,
    "Closing Rank": 3146
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17163,
    "Closing Rank": 19912
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31643,
    "Closing Rank": 35620
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4324,
    "Closing Rank": 4848
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8458,
    "Closing Rank": 12802
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17224,
    "Closing Rank": 17224
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3277,
    "Closing Rank": 5997
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8977,
    "Closing Rank": 8977
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9817,
    "Closing Rank": 16603
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23443,
    "Closing Rank": 24040
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 722,
    "Closing Rank": 722
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2534,
    "Closing Rank": 2725
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4233,
    "Closing Rank": 4233
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6255,
    "Closing Rank": 7568
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9194,
    "Closing Rank": 9194
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 653,
    "Closing Rank": 653
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3873,
    "Closing Rank": 4124
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6149,
    "Closing Rank": 6149
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1611,
    "Closing Rank": 1611
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Industrial Internet of Things (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3745,
    "Closing Rank": 3745
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8871,
    "Closing Rank": 11454
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12969,
    "Closing Rank": 13652
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1499,
    "Closing Rank": 1499
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1805,
    "Closing Rank": 2060
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2423,
    "Closing Rank": 2423
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3840,
    "Closing Rank": 5042
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6439,
    "Closing Rank": 7064
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2333,
    "Closing Rank": 2467
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4148,
    "Closing Rank": 4148
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4444,
    "Closing Rank": 5957
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6109,
    "Closing Rank": 7512
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9440,
    "Closing Rank": 12090
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1087,
    "Closing Rank": 1188
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2375,
    "Closing Rank": 3010
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5665,
    "Closing Rank": 5665
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1598,
    "Closing Rank": 1830
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3383,
    "Closing Rank": 3383
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25,
    "Closing Rank": 25
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 636,
    "Closing Rank": 786
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1269,
    "Closing Rank": 1269
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10686,
    "Closing Rank": 13410
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18969,
    "Closing Rank": 21684
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2075,
    "Closing Rank": 2075
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2245,
    "Closing Rank": 2415
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5480,
    "Closing Rank": 8366
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11668,
    "Closing Rank": 11668
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3334,
    "Closing Rank": 3854
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5656,
    "Closing Rank": 5656
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23484,
    "Closing Rank": 23484
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6603,
    "Closing Rank": 9556
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12313,
    "Closing Rank": 14332
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1447,
    "Closing Rank": 1497
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2547,
    "Closing Rank": 2547
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3200,
    "Closing Rank": 3778
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6389,
    "Closing Rank": 6389
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 202,
    "Closing Rank": 202
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1840,
    "Closing Rank": 1893
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4789,
    "Closing Rank": 4789
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 171,
    "Closing Rank": 171
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 872,
    "Closing Rank": 872
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2348,
    "Closing Rank": 2348
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11141,
    "Closing Rank": 28538
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31800,
    "Closing Rank": 37214
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4591,
    "Closing Rank": 5413
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6692,
    "Closing Rank": 6692
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11146,
    "Closing Rank": 15278
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14072,
    "Closing Rank": 17815
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5167,
    "Closing Rank": 6722
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8246,
    "Closing Rank": 8246
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11941,
    "Closing Rank": 27243
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11252,
    "Closing Rank": 11252
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17691,
    "Closing Rank": 23310
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34479,
    "Closing Rank": 36213
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3391,
    "Closing Rank": 3391
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3660,
    "Closing Rank": 3963
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6947,
    "Closing Rank": 6947
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7962,
    "Closing Rank": 8695
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14570,
    "Closing Rank": 15856
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 480,
    "Closing Rank": 480
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3385,
    "Closing Rank": 4874
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7851,
    "Closing Rank": 7851
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1735,
    "Closing Rank": 2004
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22052,
    "Closing Rank": 30337
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8314,
    "Closing Rank": 8314
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13571,
    "Closing Rank": 13571
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6716,
    "Closing Rank": 6716
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18205,
    "Closing Rank": 18205
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38832,
    "Closing Rank": 38832
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2265,
    "Closing Rank": 2265
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9210,
    "Closing Rank": 9821
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5408,
    "Closing Rank": 5408
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16175,
    "Closing Rank": 22127
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23981,
    "Closing Rank": 29509
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3670,
    "Closing Rank": 3670
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6813,
    "Closing Rank": 6813
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8937,
    "Closing Rank": 12067
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13723,
    "Closing Rank": 13971
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4574,
    "Closing Rank": 5300
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6932,
    "Closing Rank": 6932
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9391,
    "Closing Rank": 9391
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11638,
    "Closing Rank": 14399
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18323,
    "Closing Rank": 20440
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2178,
    "Closing Rank": 2377
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3612,
    "Closing Rank": 3612
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4427,
    "Closing Rank": 5868
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10732,
    "Closing Rank": 10732
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3517,
    "Closing Rank": 3947
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5049,
    "Closing Rank": 5049
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1410,
    "Closing Rank": 1410
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Microelectronics & VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2569,
    "Closing Rank": 2569
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35564,
    "Closing Rank": 43737
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44341,
    "Closing Rank": 52632
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7191,
    "Closing Rank": 7229
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16450,
    "Closing Rank": 16848
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19600,
    "Closing Rank": 19600
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6886,
    "Closing Rank": 7229
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10222,
    "Closing Rank": 10222
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30321,
    "Closing Rank": 39314
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43809,
    "Closing Rank": 43809
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2410,
    "Closing Rank": 2410
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6375,
    "Closing Rank": 6375
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8991,
    "Closing Rank": 8991
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12501,
    "Closing Rank": 13788
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16531,
    "Closing Rank": 16531
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5981,
    "Closing Rank": 6730
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9098,
    "Closing Rank": 9098
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2851,
    "Closing Rank": 2885
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13571,
    "Closing Rank": 16498
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23755,
    "Closing Rank": 24033
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2846,
    "Closing Rank": 3457
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6454,
    "Closing Rank": 6454
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7753,
    "Closing Rank": 11903
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12757,
    "Closing Rank": 12757
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4035,
    "Closing Rank": 4554
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5267,
    "Closing Rank": 5267
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6529,
    "Closing Rank": 11581
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14850,
    "Closing Rank": 14850
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 916,
    "Closing Rank": 1040
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1843,
    "Closing Rank": 1944
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3454,
    "Closing Rank": 5021
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8030,
    "Closing Rank": 8035
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 483,
    "Closing Rank": 483
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 947,
    "Closing Rank": 2089
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4775,
    "Closing Rank": 5402
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "ROBOTICS & AUTOMATION (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 369,
    "Closing Rank": 1468
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31305,
    "Closing Rank": 45275
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41130,
    "Closing Rank": 46270
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8172,
    "Closing Rank": 8202
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15481,
    "Closing Rank": 17859
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21996,
    "Closing Rank": 21996
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7500,
    "Closing Rank": 9124
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9126,
    "Closing Rank": 9126
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15875,
    "Closing Rank": 32019
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44034,
    "Closing Rank": 47032
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5376,
    "Closing Rank": 6673
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8366,
    "Closing Rank": 8366
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11494,
    "Closing Rank": 13485
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16117,
    "Closing Rank": 16117
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4923,
    "Closing Rank": 6718
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10137,
    "Closing Rank": 10137
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2764,
    "Closing Rank": 3224
  },
  {
    "Institute": "National Institute of Technology, Kurukshetra",
    "Academic Program Name": "SUSTAINABLE ENERGY TECHNOLOGIES (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4371,
    "Closing Rank": 4371
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 120347,
    "Closing Rank": 230962
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 131071,
    "Closing Rank": 131071
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 82211,
    "Closing Rank": 125402
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 134344,
    "Closing Rank": 134344
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31415,
    "Closing Rank": 35941
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 71782,
    "Closing Rank": 71782
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4464,
    "Closing Rank": 7357
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54655,
    "Closing Rank": 56358
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 68676,
    "Closing Rank": 68676
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3669,
    "Closing Rank": 3669
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9246,
    "Closing Rank": 9246
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11245,
    "Closing Rank": 11245
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17384,
    "Closing Rank": 18425
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21724,
    "Closing Rank": 21724
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8397,
    "Closing Rank": 8678
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4453,
    "Closing Rank": 4453
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62074,
    "Closing Rank": 214958
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 83760,
    "Closing Rank": 163308
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 76480,
    "Closing Rank": 105233
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 122335,
    "Closing Rank": 148070
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17281,
    "Closing Rank": 27870
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15596,
    "Closing Rank": 15596
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3800,
    "Closing Rank": 3800
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5900,
    "Closing Rank": 5900
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15616,
    "Closing Rank": 30611
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38759,
    "Closing Rank": 43862
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4985,
    "Closing Rank": 5178
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7058,
    "Closing Rank": 7058
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8750,
    "Closing Rank": 11118
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15757,
    "Closing Rank": 15757
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 675,
    "Closing Rank": 675
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4503,
    "Closing Rank": 4921
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6541,
    "Closing Rank": 6541
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1864,
    "Closing Rank": 1922
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 216734,
    "Closing Rank": 461282
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 354850,
    "Closing Rank": 363608
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 180226,
    "Closing Rank": 426716
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 241931,
    "Closing Rank": 241931
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39056,
    "Closing Rank": 42632
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13459,
    "Closing Rank": 13459
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20333,
    "Closing Rank": 20333
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38450,
    "Closing Rank": 47728
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52138,
    "Closing Rank": 55730
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7726,
    "Closing Rank": 7857
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15054,
    "Closing Rank": 15926
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20363,
    "Closing Rank": 20363
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7137,
    "Closing Rank": 7497
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2546,
    "Closing Rank": 2546
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 251398,
    "Closing Rank": 476458
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 425686,
    "Closing Rank": 571950
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 177663,
    "Closing Rank": 299067
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 271955,
    "Closing Rank": 271955
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46778,
    "Closing Rank": 123813
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15068,
    "Closing Rank": 16654
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35204,
    "Closing Rank": 40126
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44558,
    "Closing Rank": 49722
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6532,
    "Closing Rank": 6532
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8183,
    "Closing Rank": 8183
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11634,
    "Closing Rank": 12615
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19120,
    "Closing Rank": 19120
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6935,
    "Closing Rank": 7814
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2521,
    "Closing Rank": 2521
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 317560,
    "Closing Rank": 476682
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 194433,
    "Closing Rank": 716081
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 190456,
    "Closing Rank": 453798
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 379896,
    "Closing Rank": 379896
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 89902,
    "Closing Rank": 89902
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10607,
    "Closing Rank": 10607
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46956,
    "Closing Rank": 50535
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 63193,
    "Closing Rank": 66426
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8375,
    "Closing Rank": 8375
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11067,
    "Closing Rank": 11067
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16235,
    "Closing Rank": 17474
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22708,
    "Closing Rank": 22708
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8152,
    "Closing Rank": 8264
  },
  {
    "Institute": "National Institute of Technology, Manipur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3076,
    "Closing Rank": 3076
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 373312,
    "Closing Rank": 574304
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 846887,
    "Closing Rank": 846887
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14578,
    "Closing Rank": 14578
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43818,
    "Closing Rank": 56579
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 69070,
    "Closing Rank": 69524
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9265,
    "Closing Rank": 9265
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11447,
    "Closing Rank": 11447
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18847,
    "Closing Rank": 18857
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25124,
    "Closing Rank": 25124
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8462,
    "Closing Rank": 8515
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11990,
    "Closing Rank": 11990
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2710,
    "Closing Rank": 2710
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4066,
    "Closing Rank": 4066
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 309946,
    "Closing Rank": 712487
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 370684,
    "Closing Rank": 544345
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62026,
    "Closing Rank": 62026
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18700,
    "Closing Rank": 18700
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31598,
    "Closing Rank": 33181
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35200,
    "Closing Rank": 43362
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1542,
    "Closing Rank": 1542
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5469,
    "Closing Rank": 5469
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7883,
    "Closing Rank": 7883
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11236,
    "Closing Rank": 11783
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17814,
    "Closing Rank": 17814
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5066,
    "Closing Rank": 5269
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5473,
    "Closing Rank": 5473
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1943,
    "Closing Rank": 1943
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 713287,
    "Closing Rank": 1187663
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 500521,
    "Closing Rank": 500521
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43594,
    "Closing Rank": 47514
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55169,
    "Closing Rank": 56224
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7822,
    "Closing Rank": 7822
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9190,
    "Closing Rank": 9190
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14818,
    "Closing Rank": 16394
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21993,
    "Closing Rank": 21993
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7708,
    "Closing Rank": 7896
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2726,
    "Closing Rank": 2801
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 293110,
    "Closing Rank": 1187082
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 772989,
    "Closing Rank": 772989
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40683,
    "Closing Rank": 43128
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49188,
    "Closing Rank": 53289
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2312,
    "Closing Rank": 2312
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7124,
    "Closing Rank": 7124
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8871,
    "Closing Rank": 8871
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12786,
    "Closing Rank": 13285
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20366,
    "Closing Rank": 20366
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6523,
    "Closing Rank": 6735
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6888,
    "Closing Rank": 6888
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2166,
    "Closing Rank": 2166
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 596054,
    "Closing Rank": 743618
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36273,
    "Closing Rank": 36482
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45505,
    "Closing Rank": 45505
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6120,
    "Closing Rank": 6120
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13693,
    "Closing Rank": 13693
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 313293,
    "Closing Rank": 1294100
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49167,
    "Closing Rank": 52165
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60713,
    "Closing Rank": 60713
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4235,
    "Closing Rank": 4235
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8552,
    "Closing Rank": 8552
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11353,
    "Closing Rank": 11353
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16441,
    "Closing Rank": 17686
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23426,
    "Closing Rank": 23426
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7668,
    "Closing Rank": 8299
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3078,
    "Closing Rank": 3078
  },
  {
    "Institute": "National Institute of Technology, Mizoram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4396,
    "Closing Rank": 4396
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 552,
    "Closing Rank": 1334
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 458,
    "Closing Rank": 458
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1600,
    "Closing Rank": 1600
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1173,
    "Closing Rank": 1546
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 468,
    "Closing Rank": 468
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 593,
    "Closing Rank": 593
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 98,
    "Closing Rank": 98
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 464,
    "Closing Rank": 716
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 255,
    "Closing Rank": 255
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 147,
    "Closing Rank": 147
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 286,
    "Closing Rank": 290
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 236,
    "Closing Rank": 236
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 91,
    "Closing Rank": 91
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56,
    "Closing Rank": 56
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7885,
    "Closing Rank": 13848
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17069,
    "Closing Rank": 18539
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 226,
    "Closing Rank": 226
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3532,
    "Closing Rank": 4658
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5374,
    "Closing Rank": 7567
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8069,
    "Closing Rank": 12507
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2446,
    "Closing Rank": 2941
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1151,
    "Closing Rank": 1283
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3125,
    "Closing Rank": 3983
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6548,
    "Closing Rank": 7563
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 547,
    "Closing Rank": 578
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1115,
    "Closing Rank": 1308
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2794,
    "Closing Rank": 3658
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 595,
    "Closing Rank": 821
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1728,
    "Closing Rank": 1728
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 315,
    "Closing Rank": 315
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48464,
    "Closing Rank": 54280
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52327,
    "Closing Rank": 55431
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10173,
    "Closing Rank": 12449
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17875,
    "Closing Rank": 23527
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22506,
    "Closing Rank": 22506
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5546,
    "Closing Rank": 5632
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2769,
    "Closing Rank": 2769
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28053,
    "Closing Rank": 37811
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34783,
    "Closing Rank": 37497
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6582,
    "Closing Rank": 6793
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11852,
    "Closing Rank": 12921
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15879,
    "Closing Rank": 15879
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6552,
    "Closing Rank": 6559
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8262,
    "Closing Rank": 8262
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2534,
    "Closing Rank": 2534
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31466,
    "Closing Rank": 48211
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43472,
    "Closing Rank": 47049
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 422,
    "Closing Rank": 422
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9784,
    "Closing Rank": 9784
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18310,
    "Closing Rank": 21917
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26367,
    "Closing Rank": 26367
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11514,
    "Closing Rank": 12212
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3045,
    "Closing Rank": 3045
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21536,
    "Closing Rank": 29495
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32656,
    "Closing Rank": 33148
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5658,
    "Closing Rank": 5658
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10652,
    "Closing Rank": 12323
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15165,
    "Closing Rank": 15165
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 871,
    "Closing Rank": 4251
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7844,
    "Closing Rank": 7844
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2276,
    "Closing Rank": 2276
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44990,
    "Closing Rank": 47298
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 65597,
    "Closing Rank": 66664
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10459,
    "Closing Rank": 11488
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17612,
    "Closing Rank": 20623
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28898,
    "Closing Rank": 28898
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10204,
    "Closing Rank": 10726
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19210,
    "Closing Rank": 19210
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4563,
    "Closing Rank": 4563
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30050,
    "Closing Rank": 36000
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42737,
    "Closing Rank": 51113
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3036,
    "Closing Rank": 3036
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6338,
    "Closing Rank": 6344
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11253,
    "Closing Rank": 12506
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16871,
    "Closing Rank": 16871
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5610,
    "Closing Rank": 6396
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1860,
    "Closing Rank": 1860
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4390,
    "Closing Rank": 4390
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54658,
    "Closing Rank": 55726
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 67684,
    "Closing Rank": 67684
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22349,
    "Closing Rank": 24049
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12621,
    "Closing Rank": 12621
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36588,
    "Closing Rank": 39253
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52689,
    "Closing Rank": 52689
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14651,
    "Closing Rank": 14845
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Ceramic Engineering and M.Tech Industrial Ceramic (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6666,
    "Closing Rank": 6666
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23194,
    "Closing Rank": 29496
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23380,
    "Closing Rank": 32945
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 724,
    "Closing Rank": 724
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8528,
    "Closing Rank": 8939
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10186,
    "Closing Rank": 13451
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17103,
    "Closing Rank": 19453
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5934,
    "Closing Rank": 8952
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6815,
    "Closing Rank": 6815
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3541,
    "Closing Rank": 4305
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6330,
    "Closing Rank": 6330
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15345,
    "Closing Rank": 18958
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23211,
    "Closing Rank": 28865
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1575,
    "Closing Rank": 1575
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3131,
    "Closing Rank": 3466
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6899,
    "Closing Rank": 7172
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7822,
    "Closing Rank": 9983
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3829,
    "Closing Rank": 4709
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6361,
    "Closing Rank": 6361
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1126,
    "Closing Rank": 1252
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46142,
    "Closing Rank": 47227
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15601,
    "Closing Rank": 15601
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21598,
    "Closing Rank": 21598
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7149,
    "Closing Rank": 7149
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21024,
    "Closing Rank": 22091
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30894,
    "Closing Rank": 30894
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4431,
    "Closing Rank": 4431
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8043,
    "Closing Rank": 8043
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4787,
    "Closing Rank": 4787
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70542,
    "Closing Rank": 73400
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 73003,
    "Closing Rank": 73003
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15883,
    "Closing Rank": 15883
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30245,
    "Closing Rank": 32789
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38799,
    "Closing Rank": 38799
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13809,
    "Closing Rank": 13948
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5582,
    "Closing Rank": 5582
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42967,
    "Closing Rank": 48289
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38846,
    "Closing Rank": 38846
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8076,
    "Closing Rank": 8076
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14349,
    "Closing Rank": 15838
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19783,
    "Closing Rank": 19783
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6903,
    "Closing Rank": 8428
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2819,
    "Closing Rank": 2819
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28165,
    "Closing Rank": 40516
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28606,
    "Closing Rank": 40193
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 178,
    "Closing Rank": 178
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9565,
    "Closing Rank": 10254
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14324,
    "Closing Rank": 15882
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21022,
    "Closing Rank": 25964
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 212,
    "Closing Rank": 212
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5115,
    "Closing Rank": 7966
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10126,
    "Closing Rank": 10126
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3482,
    "Closing Rank": 3609
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17880,
    "Closing Rank": 25893
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31009,
    "Closing Rank": 38826
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 462,
    "Closing Rank": 462
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4320,
    "Closing Rank": 4465
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7005,
    "Closing Rank": 8742
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11024,
    "Closing Rank": 11289
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 982,
    "Closing Rank": 982
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3675,
    "Closing Rank": 4447
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4781,
    "Closing Rank": 4781
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 987,
    "Closing Rank": 1129
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2379,
    "Closing Rank": 7807
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5554,
    "Closing Rank": 12843
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 91,
    "Closing Rank": 91
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 46,
    "Closing Rank": 46
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1977,
    "Closing Rank": 3098
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23,
    "Closing Rank": 23
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1685,
    "Closing Rank": 4455
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3757,
    "Closing Rank": 8061
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 111,
    "Closing Rank": 111
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 716,
    "Closing Rank": 2401
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1510,
    "Closing Rank": 1510
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23,
    "Closing Rank": 23
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 667,
    "Closing Rank": 1359
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4033,
    "Closing Rank": 4033
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 591,
    "Closing Rank": 2940
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3724,
    "Closing Rank": 6112
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 137,
    "Closing Rank": 137
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 396,
    "Closing Rank": 454
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 996,
    "Closing Rank": 996
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32,
    "Closing Rank": 32
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 816,
    "Closing Rank": 1081
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1778,
    "Closing Rank": 1977
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48,
    "Closing Rank": 48
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 346,
    "Closing Rank": 523
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 722,
    "Closing Rank": 809
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7,
    "Closing Rank": 7
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32,
    "Closing Rank": 32
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 76,
    "Closing Rank": 202
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 177,
    "Closing Rank": 177
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6,
    "Closing Rank": 6
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14003,
    "Closing Rank": 19279
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21948,
    "Closing Rank": 25181
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 641,
    "Closing Rank": 641
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 671,
    "Closing Rank": 671
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4958,
    "Closing Rank": 7657
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8685,
    "Closing Rank": 8685
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 290,
    "Closing Rank": 290
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7814,
    "Closing Rank": 11759
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11770,
    "Closing Rank": 14143
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 457,
    "Closing Rank": 457
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 515,
    "Closing Rank": 515
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3297,
    "Closing Rank": 5584
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10101,
    "Closing Rank": 10101
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1491,
    "Closing Rank": 2299
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3925,
    "Closing Rank": 3925
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6573,
    "Closing Rank": 10462
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11102,
    "Closing Rank": 16658
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 402,
    "Closing Rank": 402
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 993,
    "Closing Rank": 993
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1566,
    "Closing Rank": 1780
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2822,
    "Closing Rank": 2822
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 104,
    "Closing Rank": 104
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2902,
    "Closing Rank": 3839
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4531,
    "Closing Rank": 4635
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 173,
    "Closing Rank": 173
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 718,
    "Closing Rank": 718
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1229,
    "Closing Rank": 2112
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2615,
    "Closing Rank": 2615
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 245,
    "Closing Rank": 245
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 402,
    "Closing Rank": 414
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1399,
    "Closing Rank": 1399
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8119,
    "Closing Rank": 13280
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18168,
    "Closing Rank": 19473
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 125,
    "Closing Rank": 125
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3800,
    "Closing Rank": 5169
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5815,
    "Closing Rank": 5815
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4592,
    "Closing Rank": 6723
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9665,
    "Closing Rank": 9665
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1491,
    "Closing Rank": 3055
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7369,
    "Closing Rank": 7369
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1412,
    "Closing Rank": 1462
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4352,
    "Closing Rank": 4352
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3958,
    "Closing Rank": 5721
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8260,
    "Closing Rank": 9496
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 136,
    "Closing Rank": 136
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 794,
    "Closing Rank": 816
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1395,
    "Closing Rank": 1395
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1653,
    "Closing Rank": 1827
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3226,
    "Closing Rank": 3226
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 239,
    "Closing Rank": 706
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1713,
    "Closing Rank": 1713
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 343,
    "Closing Rank": 356
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 928,
    "Closing Rank": 928
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13463,
    "Closing Rank": 16937
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19966,
    "Closing Rank": 20728
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 335,
    "Closing Rank": 335
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5102,
    "Closing Rank": 5242
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7388,
    "Closing Rank": 7388
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8078,
    "Closing Rank": 10265
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11525,
    "Closing Rank": 11525
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3400,
    "Closing Rank": 5232
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8088,
    "Closing Rank": 8088
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1582,
    "Closing Rank": 1582
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5517,
    "Closing Rank": 5517
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7450,
    "Closing Rank": 10785
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16629,
    "Closing Rank": 17092
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1659,
    "Closing Rank": 1710
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3127,
    "Closing Rank": 3127
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2890,
    "Closing Rank": 4328
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5758,
    "Closing Rank": 5967
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 631,
    "Closing Rank": 2055
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 566,
    "Closing Rank": 735
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48950,
    "Closing Rank": 53926
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 70560,
    "Closing Rank": 70560
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11767,
    "Closing Rank": 12657
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23462,
    "Closing Rank": 23683
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35880,
    "Closing Rank": 35880
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12506,
    "Closing Rank": 12506
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12930,
    "Closing Rank": 12930
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5108,
    "Closing Rank": 5108
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34557,
    "Closing Rank": 41605
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29089,
    "Closing Rank": 29089
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6874,
    "Closing Rank": 6970
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13476,
    "Closing Rank": 13687
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16573,
    "Closing Rank": 16573
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6525,
    "Closing Rank": 6525
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9774,
    "Closing Rank": 9774
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Food Process Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2123,
    "Closing Rank": 2123
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26976,
    "Closing Rank": 42172
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43549,
    "Closing Rank": 64092
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9165,
    "Closing Rank": 9165
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13346,
    "Closing Rank": 19627
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21530,
    "Closing Rank": 21530
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6485,
    "Closing Rank": 10169
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3948,
    "Closing Rank": 3948
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27601,
    "Closing Rank": 29601
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24803,
    "Closing Rank": 34264
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4981,
    "Closing Rank": 4981
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8854,
    "Closing Rank": 10689
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11685,
    "Closing Rank": 11685
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5357,
    "Closing Rank": 5760
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Industrial Design (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2397,
    "Closing Rank": 2397
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66885,
    "Closing Rank": 74550
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 73791,
    "Closing Rank": 73791
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15993,
    "Closing Rank": 15993
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33867,
    "Closing Rank": 34452
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37056,
    "Closing Rank": 37056
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17118,
    "Closing Rank": 17118
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7682,
    "Closing Rank": 7682
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38622,
    "Closing Rank": 45442
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59030,
    "Closing Rank": 59030
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7534,
    "Closing Rank": 7534
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13448,
    "Closing Rank": 15138
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18597,
    "Closing Rank": 18597
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6210,
    "Closing Rank": 6210
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Life Science (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2820,
    "Closing Rank": 2820
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23934,
    "Closing Rank": 62446
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 58403,
    "Closing Rank": 58403
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13756,
    "Closing Rank": 13756
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25070,
    "Closing Rank": 29231
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12218,
    "Closing Rank": 14237
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5925,
    "Closing Rank": 5925
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21341,
    "Closing Rank": 28659
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36666,
    "Closing Rank": 36666
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5486,
    "Closing Rank": 5486
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10650,
    "Closing Rank": 13475
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2135,
    "Closing Rank": 6775
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2717,
    "Closing Rank": 2717
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9600,
    "Closing Rank": 25338
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23912,
    "Closing Rank": 29689
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 647,
    "Closing Rank": 647
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6553,
    "Closing Rank": 8394
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10313,
    "Closing Rank": 10313
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8027,
    "Closing Rank": 12979
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14120,
    "Closing Rank": 16389
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 526,
    "Closing Rank": 526
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4666,
    "Closing Rank": 6616
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5994,
    "Closing Rank": 9825
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2037,
    "Closing Rank": 2516
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5889,
    "Closing Rank": 5889
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12405,
    "Closing Rank": 15037
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19501,
    "Closing Rank": 25029
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 636,
    "Closing Rank": 636
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2323,
    "Closing Rank": 2586
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4702,
    "Closing Rank": 4702
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4649,
    "Closing Rank": 5904
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9774,
    "Closing Rank": 10173
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 489,
    "Closing Rank": 489
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1154,
    "Closing Rank": 1154
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2450,
    "Closing Rank": 3371
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4951,
    "Closing Rank": 5221
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 933,
    "Closing Rank": 1365
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1830,
    "Closing Rank": 1830
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34,
    "Closing Rank": 34
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23197,
    "Closing Rank": 41491
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35681,
    "Closing Rank": 56122
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1830,
    "Closing Rank": 1830
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9222,
    "Closing Rank": 9871
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13552,
    "Closing Rank": 17210
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21868,
    "Closing Rank": 26622
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1084,
    "Closing Rank": 1084
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7512,
    "Closing Rank": 9908
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10695,
    "Closing Rank": 10695
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4621,
    "Closing Rank": 4780
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24831,
    "Closing Rank": 28678
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38375,
    "Closing Rank": 43900
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2254,
    "Closing Rank": 2254
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5202,
    "Closing Rank": 5517
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8840,
    "Closing Rank": 9899
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15017,
    "Closing Rank": 15170
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1030,
    "Closing Rank": 1030
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6001,
    "Closing Rank": 6294
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6683,
    "Closing Rank": 6683
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1972,
    "Closing Rank": 2275
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2054,
    "Closing Rank": 2054
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41527,
    "Closing Rank": 54330
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21857,
    "Closing Rank": 21857
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35598,
    "Closing Rank": 35598
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9307,
    "Closing Rank": 9307
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25209,
    "Closing Rank": 35955
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48201,
    "Closing Rank": 48201
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13024,
    "Closing Rank": 13680
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Metallurgical and Materials Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6647,
    "Closing Rank": 6647
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38674,
    "Closing Rank": 43406
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52364,
    "Closing Rank": 57296
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11140,
    "Closing Rank": 11288
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23397,
    "Closing Rank": 23397
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15631,
    "Closing Rank": 18903
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24766,
    "Closing Rank": 24766
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8579,
    "Closing Rank": 9204
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11717,
    "Closing Rank": 11717
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3701,
    "Closing Rank": 3701
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8501,
    "Closing Rank": 8501
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29168,
    "Closing Rank": 31895
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 46295,
    "Closing Rank": 49630
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5425,
    "Closing Rank": 5941
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8374,
    "Closing Rank": 8374
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11714,
    "Closing Rank": 12811
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18631,
    "Closing Rank": 18981
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5935,
    "Closing Rank": 5969
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7101,
    "Closing Rank": 7101
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2076,
    "Closing Rank": 2076
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39054,
    "Closing Rank": 49516
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19581,
    "Closing Rank": 19581
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37927,
    "Closing Rank": 37927
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10423,
    "Closing Rank": 10423
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4409,
    "Closing Rank": 4409
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30720,
    "Closing Rank": 32461
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6521,
    "Closing Rank": 6521
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19779,
    "Closing Rank": 19779
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5775,
    "Closing Rank": 5775
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Mining Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2462,
    "Closing Rank": 2462
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58952,
    "Closing Rank": 71897
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 79497,
    "Closing Rank": 79497
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19690,
    "Closing Rank": 19690
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22073,
    "Closing Rank": 23392
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36120,
    "Closing Rank": 36120
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12055,
    "Closing Rank": 15681
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4052,
    "Closing Rank": 4052
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17440,
    "Closing Rank": 22008
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33147,
    "Closing Rank": 33147
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5838,
    "Closing Rank": 5838
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9015,
    "Closing Rank": 11503
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1277,
    "Closing Rank": 1771
  },
  {
    "Institute": "National Institute of Technology, Rourkela",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1869,
    "Closing Rank": 1869
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25665,
    "Closing Rank": 84759
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37500,
    "Closing Rank": 102786
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 685,
    "Closing Rank": 685
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15780,
    "Closing Rank": 18040
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17686,
    "Closing Rank": 17686
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28731,
    "Closing Rank": 49818
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50295,
    "Closing Rank": 74407
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 735,
    "Closing Rank": 735
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6721,
    "Closing Rank": 13614
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11003,
    "Closing Rank": 14254
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2011,
    "Closing Rank": 3012
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3024,
    "Closing Rank": 3024
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33067,
    "Closing Rank": 45391
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47132,
    "Closing Rank": 57074
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3048,
    "Closing Rank": 3048
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7351,
    "Closing Rank": 7661
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9318,
    "Closing Rank": 9433
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13204,
    "Closing Rank": 15129
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19861,
    "Closing Rank": 21034
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5999,
    "Closing Rank": 7283
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9770,
    "Closing Rank": 10133
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1750,
    "Closing Rank": 2038
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2222,
    "Closing Rank": 2222
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6492,
    "Closing Rank": 26892
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30006,
    "Closing Rank": 39836
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 325,
    "Closing Rank": 325
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4937,
    "Closing Rank": 7075
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6435,
    "Closing Rank": 12329
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8089,
    "Closing Rank": 15380
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21277,
    "Closing Rank": 41348
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 521,
    "Closing Rank": 676
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1197,
    "Closing Rank": 3094
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7828,
    "Closing Rank": 10088
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 139,
    "Closing Rank": 139
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 373,
    "Closing Rank": 1331
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2460,
    "Closing Rank": 2460
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5736,
    "Closing Rank": 11930
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11300,
    "Closing Rank": 18846
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 390,
    "Closing Rank": 390
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1765,
    "Closing Rank": 1910
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3195,
    "Closing Rank": 3203
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 97,
    "Closing Rank": 97
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2779,
    "Closing Rank": 3848
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6788,
    "Closing Rank": 8489
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 229,
    "Closing Rank": 229
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1444,
    "Closing Rank": 2425
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3840,
    "Closing Rank": 3862
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 276,
    "Closing Rank": 276
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 318,
    "Closing Rank": 806
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1186,
    "Closing Rank": 1186
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33991,
    "Closing Rank": 62022
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60072,
    "Closing Rank": 76071
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2566,
    "Closing Rank": 2980
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10414,
    "Closing Rank": 12556
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19039,
    "Closing Rank": 19384
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22680,
    "Closing Rank": 37149
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41851,
    "Closing Rank": 60194
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3350,
    "Closing Rank": 9836
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12622,
    "Closing Rank": 12958
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1427,
    "Closing Rank": 2197
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3429,
    "Closing Rank": 3429
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19075,
    "Closing Rank": 24365
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34545,
    "Closing Rank": 36438
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1733,
    "Closing Rank": 1733
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2542,
    "Closing Rank": 2542
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3853,
    "Closing Rank": 3994
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6069,
    "Closing Rank": 6562
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6520,
    "Closing Rank": 8343
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13966,
    "Closing Rank": 14969
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 943,
    "Closing Rank": 943
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3981,
    "Closing Rank": 5339
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6263,
    "Closing Rank": 6542
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1473,
    "Closing Rank": 1991
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2364,
    "Closing Rank": 2364
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26983,
    "Closing Rank": 42983
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41560,
    "Closing Rank": 57804
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7131,
    "Closing Rank": 7991
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14039,
    "Closing Rank": 17866
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15953,
    "Closing Rank": 22375
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32424,
    "Closing Rank": 46520
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3406,
    "Closing Rank": 6107
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10440,
    "Closing Rank": 12406
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1095,
    "Closing Rank": 1371
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2208,
    "Closing Rank": 2208
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12183,
    "Closing Rank": 17339
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19580,
    "Closing Rank": 26263
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 493,
    "Closing Rank": 493
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1208,
    "Closing Rank": 1208
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2743,
    "Closing Rank": 2820
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4775,
    "Closing Rank": 5367
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 148,
    "Closing Rank": 234
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4970,
    "Closing Rank": 6082
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9250,
    "Closing Rank": 10135
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 406,
    "Closing Rank": 406
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3027,
    "Closing Rank": 4139
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3786,
    "Closing Rank": 6174
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 808,
    "Closing Rank": 1404
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1557,
    "Closing Rank": 1557
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33,
    "Closing Rank": 33
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43847,
    "Closing Rank": 51593
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59443,
    "Closing Rank": 71031
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8621,
    "Closing Rank": 10728
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18602,
    "Closing Rank": 18602
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22567,
    "Closing Rank": 33187
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33975,
    "Closing Rank": 55215
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5517,
    "Closing Rank": 6580
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12300,
    "Closing Rank": 12300
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1768,
    "Closing Rank": 2204
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3887,
    "Closing Rank": 3887
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17669,
    "Closing Rank": 21926
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27237,
    "Closing Rank": 32943
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3426,
    "Closing Rank": 3523
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6201,
    "Closing Rank": 6201
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6079,
    "Closing Rank": 7994
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11750,
    "Closing Rank": 14278
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4415,
    "Closing Rank": 4635
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6219,
    "Closing Rank": 6219
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1786,
    "Closing Rank": 2118
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2300,
    "Closing Rank": 2300
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49948,
    "Closing Rank": 72011
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 54213,
    "Closing Rank": 91301
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12566,
    "Closing Rank": 14891
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18752,
    "Closing Rank": 19656
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23829,
    "Closing Rank": 48118
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30905,
    "Closing Rank": 77859
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2711,
    "Closing Rank": 12259
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13290,
    "Closing Rank": 13810
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2522,
    "Closing Rank": 3258
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4274,
    "Closing Rank": 4274
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22243,
    "Closing Rank": 32251
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43284,
    "Closing Rank": 47054
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1779,
    "Closing Rank": 1779
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3276,
    "Closing Rank": 3276
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5396,
    "Closing Rank": 5789
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7912,
    "Closing Rank": 8228
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8959,
    "Closing Rank": 11217
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17509,
    "Closing Rank": 19533
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 920,
    "Closing Rank": 1393
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4881,
    "Closing Rank": 6013
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9190,
    "Closing Rank": 10046
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2126,
    "Closing Rank": 2297
  },
  {
    "Institute": "National Institute of Technology, Silchar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3192,
    "Closing Rank": 3192
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75874,
    "Closing Rank": 120403
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 100839,
    "Closing Rank": 137443
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14867,
    "Closing Rank": 29182
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 58707,
    "Closing Rank": 128053
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4527,
    "Closing Rank": 7916
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 349784,
    "Closing Rank": 349784
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41898,
    "Closing Rank": 51760
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47392,
    "Closing Rank": 58828
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3694,
    "Closing Rank": 3694
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8419,
    "Closing Rank": 8540
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10368,
    "Closing Rank": 10368
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16090,
    "Closing Rank": 17471
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21213,
    "Closing Rank": 22281
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2995,
    "Closing Rank": 8253
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10611,
    "Closing Rank": 10635
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3007,
    "Closing Rank": 3088
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4497,
    "Closing Rank": 4497
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38474,
    "Closing Rank": 101648
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 80405,
    "Closing Rank": 125416
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2421,
    "Closing Rank": 3224
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36118,
    "Closing Rank": 86607
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38798,
    "Closing Rank": 38798
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58549,
    "Closing Rank": 295468
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6131,
    "Closing Rank": 26278
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26694,
    "Closing Rank": 32911
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1647,
    "Closing Rank": 4023
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14206,
    "Closing Rank": 14206
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 252507,
    "Closing Rank": 252507
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 266792,
    "Closing Rank": 266792
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45564,
    "Closing Rank": 55070
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 61452,
    "Closing Rank": 68202
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4326,
    "Closing Rank": 4326
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8962,
    "Closing Rank": 9150
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11262,
    "Closing Rank": 11339
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16843,
    "Closing Rank": 18246
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22886,
    "Closing Rank": 24363
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7449,
    "Closing Rank": 8448
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12021,
    "Closing Rank": 12370
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1567,
    "Closing Rank": 2648
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3963,
    "Closing Rank": 3963
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9106,
    "Closing Rank": 31827
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31019,
    "Closing Rank": 49390
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 549,
    "Closing Rank": 549
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6666,
    "Closing Rank": 12613
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15337,
    "Closing Rank": 15337
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13609,
    "Closing Rank": 42105
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 73424,
    "Closing Rank": 136454
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6048,
    "Closing Rank": 9094
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8015,
    "Closing Rank": 8015
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1934,
    "Closing Rank": 3240
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6857,
    "Closing Rank": 6857
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 162986,
    "Closing Rank": 162986
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12751,
    "Closing Rank": 21839
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24950,
    "Closing Rank": 36322
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 543,
    "Closing Rank": 543
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3481,
    "Closing Rank": 3485
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6061,
    "Closing Rank": 6061
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6808,
    "Closing Rank": 7619
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12251,
    "Closing Rank": 12735
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 415,
    "Closing Rank": 415
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3164,
    "Closing Rank": 3843
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5762,
    "Closing Rank": 5782
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1083,
    "Closing Rank": 1630
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53003,
    "Closing Rank": 73846
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 82369,
    "Closing Rank": 114629
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3145,
    "Closing Rank": 3145
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 78238,
    "Closing Rank": 79879
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17441,
    "Closing Rank": 22882
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18558,
    "Closing Rank": 24398
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5178,
    "Closing Rank": 6383
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3265,
    "Closing Rank": 3265
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 248474,
    "Closing Rank": 248474
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 377420,
    "Closing Rank": 377420
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31608,
    "Closing Rank": 40442
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44720,
    "Closing Rank": 49672
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2384,
    "Closing Rank": 2928
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6566,
    "Closing Rank": 6703
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8965,
    "Closing Rank": 8965
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12561,
    "Closing Rank": 13744
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17933,
    "Closing Rank": 18270
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1454,
    "Closing Rank": 1454
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5181,
    "Closing Rank": 6347
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8660,
    "Closing Rank": 9203
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2468,
    "Closing Rank": 2473
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4144,
    "Closing Rank": 4144
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40070,
    "Closing Rank": 52959
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 58543,
    "Closing Rank": 82834
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2860,
    "Closing Rank": 2860
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36904,
    "Closing Rank": 62342
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38885,
    "Closing Rank": 437563
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 332267,
    "Closing Rank": 332267
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13712,
    "Closing Rank": 16732
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23581,
    "Closing Rank": 30121
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5087,
    "Closing Rank": 6277
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20780,
    "Closing Rank": 20780
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 328365,
    "Closing Rank": 328365
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 383654,
    "Closing Rank": 383654
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24337,
    "Closing Rank": 33605
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41464,
    "Closing Rank": 43622
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2399,
    "Closing Rank": 2399
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5470,
    "Closing Rank": 5685
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7026,
    "Closing Rank": 7026
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9925,
    "Closing Rank": 11753
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17096,
    "Closing Rank": 17726
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1321,
    "Closing Rank": 1321
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4936,
    "Closing Rank": 5756
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8219,
    "Closing Rank": 8510
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1923,
    "Closing Rank": 2322
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3205,
    "Closing Rank": 3205
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32584,
    "Closing Rank": 41053
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55449,
    "Closing Rank": 77972
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1809,
    "Closing Rank": 1809
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19715,
    "Closing Rank": 41203
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43934,
    "Closing Rank": 220082
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 112797,
    "Closing Rank": 322491
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9408,
    "Closing Rank": 13526
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11932,
    "Closing Rank": 11932
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4275,
    "Closing Rank": 4528
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9005,
    "Closing Rank": 9005
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 210829,
    "Closing Rank": 210829
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22938,
    "Closing Rank": 26370
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37177,
    "Closing Rank": 37980
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 865,
    "Closing Rank": 865
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4202,
    "Closing Rank": 4466
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6789,
    "Closing Rank": 6789
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7156,
    "Closing Rank": 9167
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13694,
    "Closing Rank": 16141
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4004,
    "Closing Rank": 4843
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6540,
    "Closing Rank": 8065
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1745,
    "Closing Rank": 2261
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2486,
    "Closing Rank": 2486
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56935,
    "Closing Rank": 89013
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 116427,
    "Closing Rank": 122218
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1808,
    "Closing Rank": 1808
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 69126,
    "Closing Rank": 100279
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49600,
    "Closing Rank": 249274
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17498,
    "Closing Rank": 27315
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40216,
    "Closing Rank": 76887
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5650,
    "Closing Rank": 6550
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 304164,
    "Closing Rank": 304164
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 388057,
    "Closing Rank": 388057
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40623,
    "Closing Rank": 47482
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56374,
    "Closing Rank": 62389
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3181,
    "Closing Rank": 3181
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7806,
    "Closing Rank": 8070
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10446,
    "Closing Rank": 10446
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13937,
    "Closing Rank": 15893
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19406,
    "Closing Rank": 22568
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6677,
    "Closing Rank": 7579
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10737,
    "Closing Rank": 11334
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2686,
    "Closing Rank": 2707
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4491,
    "Closing Rank": 4491
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 102741,
    "Closing Rank": 133070
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 142540,
    "Closing Rank": 158691
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31349,
    "Closing Rank": 35343
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 132222,
    "Closing Rank": 132222
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "JK",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8141,
    "Closing Rank": 8695
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "LA",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 367592,
    "Closing Rank": 367592
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44051,
    "Closing Rank": 55447
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 66054,
    "Closing Rank": 66731
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3284,
    "Closing Rank": 3284
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9115,
    "Closing Rank": 9273
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11522,
    "Closing Rank": 11522
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16916,
    "Closing Rank": 18785
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24089,
    "Closing Rank": 25142
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1581,
    "Closing Rank": 1581
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8842,
    "Closing Rank": 9242
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12132,
    "Closing Rank": 12519
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2961,
    "Closing Rank": 3556
  },
  {
    "Institute": "National Institute of Technology, Srinagar",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4136,
    "Closing Rank": 4136
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2,
    "Closing Rank": 486
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49,
    "Closing Rank": 50
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 115,
    "Closing Rank": 115
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1767,
    "Closing Rank": 1802
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2356,
    "Closing Rank": 2356
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 144,
    "Closing Rank": 311
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 169,
    "Closing Rank": 300
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 186,
    "Closing Rank": 299
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17,
    "Closing Rank": 17
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 302,
    "Closing Rank": 492
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29,
    "Closing Rank": 404
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 240,
    "Closing Rank": 240
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23,
    "Closing Rank": 23
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59,
    "Closing Rank": 102
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 156,
    "Closing Rank": 168
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 152,
    "Closing Rank": 152
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16,
    "Closing Rank": 50
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 84,
    "Closing Rank": 84
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41,
    "Closing Rank": 41
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39,
    "Closing Rank": 39
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12770,
    "Closing Rank": 20789
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9835,
    "Closing Rank": 25656
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11147,
    "Closing Rank": 15371
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23612,
    "Closing Rank": 23612
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5350,
    "Closing Rank": 6345
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8169,
    "Closing Rank": 9868
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 451,
    "Closing Rank": 451
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3608,
    "Closing Rank": 4436
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3808,
    "Closing Rank": 3808
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2903,
    "Closing Rank": 6910
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5802,
    "Closing Rank": 5802
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7175,
    "Closing Rank": 13950
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13588,
    "Closing Rank": 20366
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 689,
    "Closing Rank": 689
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2494,
    "Closing Rank": 2572
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3710,
    "Closing Rank": 3710
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5002,
    "Closing Rank": 5625
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6386,
    "Closing Rank": 6386
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 576,
    "Closing Rank": 576
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3015,
    "Closing Rank": 3704
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3485,
    "Closing Rank": 3485
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 815,
    "Closing Rank": 1001
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 87,
    "Closing Rank": 87
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19131,
    "Closing Rank": 36104
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26597,
    "Closing Rank": 43172
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15751,
    "Closing Rank": 31894
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26143,
    "Closing Rank": 26143
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10090,
    "Closing Rank": 11601
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12876,
    "Closing Rank": 13119
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 486,
    "Closing Rank": 486
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 685,
    "Closing Rank": 685
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3622,
    "Closing Rank": 4957
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3234,
    "Closing Rank": 4154
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 353,
    "Closing Rank": 353
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6880,
    "Closing Rank": 8901
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1851,
    "Closing Rank": 1851
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6660,
    "Closing Rank": 18965
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20469,
    "Closing Rank": 27581
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 118,
    "Closing Rank": 118
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3034,
    "Closing Rank": 3742
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4696,
    "Closing Rank": 4696
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 342,
    "Closing Rank": 342
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5137,
    "Closing Rank": 6991
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10303,
    "Closing Rank": 11348
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 436,
    "Closing Rank": 436
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 810,
    "Closing Rank": 2819
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1393,
    "Closing Rank": 3524
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 339,
    "Closing Rank": 712
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1432,
    "Closing Rank": 1432
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1196,
    "Closing Rank": 5066
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3258,
    "Closing Rank": 9721
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 331,
    "Closing Rank": 331
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 366,
    "Closing Rank": 366
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1094,
    "Closing Rank": 4239
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4052,
    "Closing Rank": 4052
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 231,
    "Closing Rank": 231
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1157,
    "Closing Rank": 1633
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2267,
    "Closing Rank": 2814
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 490,
    "Closing Rank": 1284
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 493,
    "Closing Rank": 804
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 167,
    "Closing Rank": 167
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 350,
    "Closing Rank": 2538
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1657,
    "Closing Rank": 1657
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 299,
    "Closing Rank": 1224
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1426,
    "Closing Rank": 2172
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60,
    "Closing Rank": 60
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 144,
    "Closing Rank": 201
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 448,
    "Closing Rank": 448
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 215,
    "Closing Rank": 369
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 426,
    "Closing Rank": 939
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26,
    "Closing Rank": 26
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 100,
    "Closing Rank": 204
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 160,
    "Closing Rank": 467
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45,
    "Closing Rank": 123
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 115,
    "Closing Rank": 115
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6688,
    "Closing Rank": 9828
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16092,
    "Closing Rank": 18393
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 359,
    "Closing Rank": 359
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8013,
    "Closing Rank": 10773
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8917,
    "Closing Rank": 8917
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2440,
    "Closing Rank": 2905
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4677,
    "Closing Rank": 5746
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1780,
    "Closing Rank": 2230
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1736,
    "Closing Rank": 2318
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 169,
    "Closing Rank": 169
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1740,
    "Closing Rank": 3253
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3464,
    "Closing Rank": 3464
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3029,
    "Closing Rank": 6040
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9351,
    "Closing Rank": 10054
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 384,
    "Closing Rank": 384
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 851,
    "Closing Rank": 1155
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1909,
    "Closing Rank": 1909
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1365,
    "Closing Rank": 2175
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3194,
    "Closing Rank": 3377
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 283,
    "Closing Rank": 283
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 999,
    "Closing Rank": 1242
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2209,
    "Closing Rank": 2511
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 118,
    "Closing Rank": 123
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 127,
    "Closing Rank": 329
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 517,
    "Closing Rank": 517
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2712,
    "Closing Rank": 7036
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11473,
    "Closing Rank": 15587
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 256,
    "Closing Rank": 256
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4778,
    "Closing Rank": 8222
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1654,
    "Closing Rank": 2294
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3900,
    "Closing Rank": 4264
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1255,
    "Closing Rank": 1688
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1460,
    "Closing Rank": 1513
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 158,
    "Closing Rank": 158
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1747,
    "Closing Rank": 2873
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3686,
    "Closing Rank": 3686
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 917,
    "Closing Rank": 3546
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3874,
    "Closing Rank": 5481
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 72,
    "Closing Rank": 72
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 461,
    "Closing Rank": 498
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 849,
    "Closing Rank": 849
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 775,
    "Closing Rank": 1350
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1641,
    "Closing Rank": 2035
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54,
    "Closing Rank": 54
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 394,
    "Closing Rank": 633
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 268,
    "Closing Rank": 1563
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35,
    "Closing Rank": 35
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 171,
    "Closing Rank": 223
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 222,
    "Closing Rank": 222
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10149,
    "Closing Rank": 14697
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18611,
    "Closing Rank": 23662
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 662,
    "Closing Rank": 662
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10690,
    "Closing Rank": 11696
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3690,
    "Closing Rank": 4495
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7162,
    "Closing Rank": 8805
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2155,
    "Closing Rank": 3464
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3033,
    "Closing Rank": 3424
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 231,
    "Closing Rank": 231
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4494,
    "Closing Rank": 5524
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5317,
    "Closing Rank": 5317
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7435,
    "Closing Rank": 11001
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11048,
    "Closing Rank": 15619
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 797,
    "Closing Rank": 797
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1591,
    "Closing Rank": 1851
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2818,
    "Closing Rank": 2818
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3399,
    "Closing Rank": 4306
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4382,
    "Closing Rank": 7952
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 438,
    "Closing Rank": 438
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2159,
    "Closing Rank": 2646
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4620,
    "Closing Rank": 4786
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 269,
    "Closing Rank": 269
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1081,
    "Closing Rank": 1166
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1890,
    "Closing Rank": 1890
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9999,
    "Closing Rank": 16002
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21318,
    "Closing Rank": 27827
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6326,
    "Closing Rank": 13658
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11154,
    "Closing Rank": 11154
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3956,
    "Closing Rank": 4930
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8269,
    "Closing Rank": 9181
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 334,
    "Closing Rank": 334
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 682,
    "Closing Rank": 682
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2645,
    "Closing Rank": 2969
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2283,
    "Closing Rank": 3664
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 301,
    "Closing Rank": 301
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3444,
    "Closing Rank": 5423
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6741,
    "Closing Rank": 6741
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5023,
    "Closing Rank": 9305
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14067,
    "Closing Rank": 16912
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 391,
    "Closing Rank": 391
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1417,
    "Closing Rank": 1817
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3036,
    "Closing Rank": 3036
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 109,
    "Closing Rank": 109
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2114,
    "Closing Rank": 3737
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5267,
    "Closing Rank": 6831
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 224,
    "Closing Rank": 224
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1029,
    "Closing Rank": 2390
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2534,
    "Closing Rank": 4749
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 556,
    "Closing Rank": 818
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1488,
    "Closing Rank": 1488
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18454,
    "Closing Rank": 30319
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29482,
    "Closing Rank": 43127
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16300,
    "Closing Rank": 29781
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15905,
    "Closing Rank": 15905
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8332,
    "Closing Rank": 10279
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12679,
    "Closing Rank": 13641
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 314,
    "Closing Rank": 314
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5130,
    "Closing Rank": 5275
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4484,
    "Closing Rank": 4484
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7232,
    "Closing Rank": 8185
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8128,
    "Closing Rank": 8128
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15466,
    "Closing Rank": 22521
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24127,
    "Closing Rank": 28329
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 898,
    "Closing Rank": 898
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3588,
    "Closing Rank": 3796
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6254,
    "Closing Rank": 6254
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6712,
    "Closing Rank": 8593
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12688,
    "Closing Rank": 12688
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1540,
    "Closing Rank": 1540
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4003,
    "Closing Rank": 4931
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4673,
    "Closing Rank": 4673
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1100,
    "Closing Rank": 1607
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19582,
    "Closing Rank": 24973
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32476,
    "Closing Rank": 35594
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15910,
    "Closing Rank": 21395
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31256,
    "Closing Rank": 31256
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7025,
    "Closing Rank": 8074
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11207,
    "Closing Rank": 12315
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 449,
    "Closing Rank": 449
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 629,
    "Closing Rank": 629
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3467,
    "Closing Rank": 4426
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4265,
    "Closing Rank": 4794
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 370,
    "Closing Rank": 370
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7363,
    "Closing Rank": 8872
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7106,
    "Closing Rank": 7106
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14668,
    "Closing Rank": 24286
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29750,
    "Closing Rank": 36782
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1552,
    "Closing Rank": 1552
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3887,
    "Closing Rank": 4264
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6560,
    "Closing Rank": 6560
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 264,
    "Closing Rank": 264
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7307,
    "Closing Rank": 8996
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15736,
    "Closing Rank": 16122
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1115,
    "Closing Rank": 1115
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3904,
    "Closing Rank": 5186
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6159,
    "Closing Rank": 6291
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1475,
    "Closing Rank": 1681
  },
  {
    "Institute": "National Institute of Technology, Tiruchirappalli",
    "Academic Program Name": "Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1723,
    "Closing Rank": 1723
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48084,
    "Closing Rank": 59638
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60895,
    "Closing Rank": 60895
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10532,
    "Closing Rank": 10532
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19005,
    "Closing Rank": 19005
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27867,
    "Closing Rank": 32031
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41426,
    "Closing Rank": 41999
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8395,
    "Closing Rank": 8424
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4812,
    "Closing Rank": 4812
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37276,
    "Closing Rank": 48366
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47116,
    "Closing Rank": 47116
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8126,
    "Closing Rank": 8155
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9904,
    "Closing Rank": 9904
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15771,
    "Closing Rank": 16069
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7294,
    "Closing Rank": 7295
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8778,
    "Closing Rank": 8778
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2133,
    "Closing Rank": 2133
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4021,
    "Closing Rank": 4021
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18865,
    "Closing Rank": 31282
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39753,
    "Closing Rank": 41844
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 213,
    "Closing Rank": 213
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6379,
    "Closing Rank": 6464
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7399,
    "Closing Rank": 7399
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13151,
    "Closing Rank": 18467
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5854,
    "Closing Rank": 7260
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8036,
    "Closing Rank": 8036
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3460,
    "Closing Rank": 5729
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14598,
    "Closing Rank": 18039
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22891,
    "Closing Rank": 28529
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 726,
    "Closing Rank": 726
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2752,
    "Closing Rank": 2752
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4587,
    "Closing Rank": 4587
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 120,
    "Closing Rank": 120
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6134,
    "Closing Rank": 6645
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7982,
    "Closing Rank": 10525
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2971,
    "Closing Rank": 3720
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1516,
    "Closing Rank": 1516
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45575,
    "Closing Rank": 50715
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53705,
    "Closing Rank": 53705
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8254,
    "Closing Rank": 8254
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24682,
    "Closing Rank": 26776
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32277,
    "Closing Rank": 32277
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8557,
    "Closing Rank": 9809
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14979,
    "Closing Rank": 14979
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5795,
    "Closing Rank": 6368
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30197,
    "Closing Rank": 32287
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33741,
    "Closing Rank": 33741
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5154,
    "Closing Rank": 5154
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9256,
    "Closing Rank": 10001
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15597,
    "Closing Rank": 16265
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5439,
    "Closing Rank": 5885
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7399,
    "Closing Rank": 7399
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1857,
    "Closing Rank": 1857
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40057,
    "Closing Rank": 45096
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50662,
    "Closing Rank": 50662
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8053,
    "Closing Rank": 8053
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15429,
    "Closing Rank": 24485
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31540,
    "Closing Rank": 31540
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7696,
    "Closing Rank": 7772
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9326,
    "Closing Rank": 13877
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4016,
    "Closing Rank": 4016
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7228,
    "Closing Rank": 7228
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22097,
    "Closing Rank": 26226
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30330,
    "Closing Rank": 33827
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1932,
    "Closing Rank": 1932
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4208,
    "Closing Rank": 4451
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7577,
    "Closing Rank": 8855
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14145,
    "Closing Rank": 14145
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 912,
    "Closing Rank": 912
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4405,
    "Closing Rank": 4958
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1844,
    "Closing Rank": 1844
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54064,
    "Closing Rank": 59119
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55951,
    "Closing Rank": 55951
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9912,
    "Closing Rank": 10456
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26911,
    "Closing Rank": 30113
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41960,
    "Closing Rank": 41960
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10097,
    "Closing Rank": 10489
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6247,
    "Closing Rank": 6247
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37781,
    "Closing Rank": 40565
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51410,
    "Closing Rank": 51410
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6705,
    "Closing Rank": 6705
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11885,
    "Closing Rank": 13637
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19455,
    "Closing Rank": 19455
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5742,
    "Closing Rank": 6770
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9074,
    "Closing Rank": 9074
  },
  {
    "Institute": "National Institute of Technology, Uttarakhand",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2395,
    "Closing Rank": 2395
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27094,
    "Closing Rank": 39862
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29664,
    "Closing Rank": 35190
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1316,
    "Closing Rank": 1316
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6826,
    "Closing Rank": 6933
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6430,
    "Closing Rank": 6430
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11597,
    "Closing Rank": 13402
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11613,
    "Closing Rank": 11613
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6298,
    "Closing Rank": 6988
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5210,
    "Closing Rank": 5210
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1471,
    "Closing Rank": 1471
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2574,
    "Closing Rank": 2574
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15149,
    "Closing Rank": 30707
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15370,
    "Closing Rank": 22944
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4999,
    "Closing Rank": 5332
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9626,
    "Closing Rank": 12024
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12136,
    "Closing Rank": 12177
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 369,
    "Closing Rank": 369
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5989,
    "Closing Rank": 6168
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7126,
    "Closing Rank": 7126
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2314,
    "Closing Rank": 2432
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4307,
    "Closing Rank": 4307
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10578,
    "Closing Rank": 26477
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23428,
    "Closing Rank": 26391
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1303,
    "Closing Rank": 1303
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2116,
    "Closing Rank": 2116
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4184,
    "Closing Rank": 4411
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4825,
    "Closing Rank": 4825
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7065,
    "Closing Rank": 9036
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9620,
    "Closing Rank": 11061
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4706,
    "Closing Rank": 5723
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5286,
    "Closing Rank": 6200
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1178,
    "Closing Rank": 1433
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1973,
    "Closing Rank": 1973
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15610,
    "Closing Rank": 18833
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20825,
    "Closing Rank": 22134
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1252,
    "Closing Rank": 1252
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3115,
    "Closing Rank": 3453
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4281,
    "Closing Rank": 4281
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5011,
    "Closing Rank": 6696
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7319,
    "Closing Rank": 9341
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 569,
    "Closing Rank": 569
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1578,
    "Closing Rank": 4538
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3687,
    "Closing Rank": 4470
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1440,
    "Closing Rank": 1498
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1925,
    "Closing Rank": 1925
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44550,
    "Closing Rank": 45516
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42267,
    "Closing Rank": 42267
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2645,
    "Closing Rank": 2645
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8801,
    "Closing Rank": 8801
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17628,
    "Closing Rank": 17955
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19635,
    "Closing Rank": 19635
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9017,
    "Closing Rank": 9017
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2946,
    "Closing Rank": 2946
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25299,
    "Closing Rank": 31513
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42773,
    "Closing Rank": 42773
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7340,
    "Closing Rank": 7340
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13490,
    "Closing Rank": 14365
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7127,
    "Closing Rank": 7127
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10523,
    "Closing Rank": 10523
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1862,
    "Closing Rank": 1862
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23188,
    "Closing Rank": 29824
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31822,
    "Closing Rank": 36132
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 755,
    "Closing Rank": 755
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4943,
    "Closing Rank": 5137
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6071,
    "Closing Rank": 6071
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8801,
    "Closing Rank": 11042
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11070,
    "Closing Rank": 11376
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3384,
    "Closing Rank": 4052
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4168,
    "Closing Rank": 4565
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 143,
    "Closing Rank": 143
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 720,
    "Closing Rank": 1080
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1469,
    "Closing Rank": 1469
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19905,
    "Closing Rank": 25857
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32645,
    "Closing Rank": 36778
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 634,
    "Closing Rank": 1112
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4107,
    "Closing Rank": 4223
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6347,
    "Closing Rank": 6347
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 268,
    "Closing Rank": 268
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7161,
    "Closing Rank": 8896
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11958,
    "Closing Rank": 12950
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4245,
    "Closing Rank": 4698
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4650,
    "Closing Rank": 5422
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 793,
    "Closing Rank": 1014
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1299,
    "Closing Rank": 1299
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 787,
    "Closing Rank": 3387
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4672,
    "Closing Rank": 4695
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 202,
    "Closing Rank": 202
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 561,
    "Closing Rank": 592
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 778,
    "Closing Rank": 778
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 659,
    "Closing Rank": 985
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1612,
    "Closing Rank": 1612
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 572,
    "Closing Rank": 715
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 433,
    "Closing Rank": 433
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45,
    "Closing Rank": 45
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 71,
    "Closing Rank": 71
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 548,
    "Closing Rank": 548
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2419,
    "Closing Rank": 2948
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3262,
    "Closing Rank": 3621
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 87,
    "Closing Rank": 87
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 374,
    "Closing Rank": 416
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 539,
    "Closing Rank": 539
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 778,
    "Closing Rank": 1066
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1322,
    "Closing Rank": 1322
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85,
    "Closing Rank": 85
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 360,
    "Closing Rank": 484
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 743,
    "Closing Rank": 743
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 176,
    "Closing Rank": 176
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering ( Artificial Intelligence & Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 435,
    "Closing Rank": 435
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 755,
    "Closing Rank": 2804
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1921,
    "Closing Rank": 4683
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85,
    "Closing Rank": 85
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 380,
    "Closing Rank": 507
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 872,
    "Closing Rank": 872
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 531,
    "Closing Rank": 824
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1117,
    "Closing Rank": 1389
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66,
    "Closing Rank": 66
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 295,
    "Closing Rank": 625
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 411,
    "Closing Rank": 707
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 110,
    "Closing Rank": 159
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 504,
    "Closing Rank": 504
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5,
    "Closing Rank": 5
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1043,
    "Closing Rank": 2186
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3296,
    "Closing Rank": 3609
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24,
    "Closing Rank": 24
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 300,
    "Closing Rank": 329
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 523,
    "Closing Rank": 523
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 58,
    "Closing Rank": 58
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 452,
    "Closing Rank": 679
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 975,
    "Closing Rank": 1257
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 68,
    "Closing Rank": 68
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 255,
    "Closing Rank": 409
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 668,
    "Closing Rank": 683
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17,
    "Closing Rank": 201
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 649,
    "Closing Rank": 649
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6469,
    "Closing Rank": 9887
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9310,
    "Closing Rank": 12542
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 314,
    "Closing Rank": 314
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1433,
    "Closing Rank": 1681
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1930,
    "Closing Rank": 1930
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2360,
    "Closing Rank": 3669
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4235,
    "Closing Rank": 4573
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 139,
    "Closing Rank": 139
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 870,
    "Closing Rank": 2348
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2509,
    "Closing Rank": 2509
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 201,
    "Closing Rank": 201
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 420,
    "Closing Rank": 628
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 899,
    "Closing Rank": 899
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6030,
    "Closing Rank": 7851
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11501,
    "Closing Rank": 13388
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 560,
    "Closing Rank": 560
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1229,
    "Closing Rank": 1435
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2500,
    "Closing Rank": 2500
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1926,
    "Closing Rank": 2805
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4364,
    "Closing Rank": 4494
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 366,
    "Closing Rank": 366
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1361,
    "Closing Rank": 1835
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2951,
    "Closing Rank": 3640
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85,
    "Closing Rank": 89
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 501,
    "Closing Rank": 660
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1690,
    "Closing Rank": 1690
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3393,
    "Closing Rank": 5463
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5724,
    "Closing Rank": 7241
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 780,
    "Closing Rank": 836
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1122,
    "Closing Rank": 1122
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27,
    "Closing Rank": 27
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1359,
    "Closing Rank": 2134
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2733,
    "Closing Rank": 3143
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 84,
    "Closing Rank": 84
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 727,
    "Closing Rank": 1599
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 782,
    "Closing Rank": 1544
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 276,
    "Closing Rank": 311
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3637,
    "Closing Rank": 5014
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7357,
    "Closing Rank": 9308
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 165,
    "Closing Rank": 165
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 700,
    "Closing Rank": 734
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1391,
    "Closing Rank": 1391
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1185,
    "Closing Rank": 1736
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2334,
    "Closing Rank": 3708
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 745,
    "Closing Rank": 1025
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2218,
    "Closing Rank": 2218
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60,
    "Closing Rank": 60
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 233,
    "Closing Rank": 306
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 881,
    "Closing Rank": 881
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10,
    "Closing Rank": 10
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4321,
    "Closing Rank": 6271
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7610,
    "Closing Rank": 7613
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 208,
    "Closing Rank": 208
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 876,
    "Closing Rank": 891
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1789,
    "Closing Rank": 2189
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2630,
    "Closing Rank": 2630
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1413,
    "Closing Rank": 1794
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2004,
    "Closing Rank": 2004
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 406,
    "Closing Rank": 406
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2108,
    "Closing Rank": 5678
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8041,
    "Closing Rank": 8041
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 303,
    "Closing Rank": 303
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 840,
    "Closing Rank": 840
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1434,
    "Closing Rank": 1434
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1843,
    "Closing Rank": 1881
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2479,
    "Closing Rank": 2479
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 140,
    "Closing Rank": 140
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1133,
    "Closing Rank": 1250
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 486,
    "Closing Rank": 486
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Electronics and Communication Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1288,
    "Closing Rank": 1288
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30763,
    "Closing Rank": 44795
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43889,
    "Closing Rank": 43889
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7359,
    "Closing Rank": 7359
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14567,
    "Closing Rank": 14567
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 826,
    "Closing Rank": 826
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7848,
    "Closing Rank": 8240
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4243,
    "Closing Rank": 4243
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19576,
    "Closing Rank": 23183
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33298,
    "Closing Rank": 33298
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4367,
    "Closing Rank": 4367
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6106,
    "Closing Rank": 7230
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16026,
    "Closing Rank": 16026
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6132,
    "Closing Rank": 6132
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2415,
    "Closing Rank": 2415
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3403,
    "Closing Rank": 4102
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6672,
    "Closing Rank": 6672
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 599,
    "Closing Rank": 599
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1089,
    "Closing Rank": 1089
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 935,
    "Closing Rank": 1223
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2138,
    "Closing Rank": 2138
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 225,
    "Closing Rank": 225
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1114,
    "Closing Rank": 1264
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 381,
    "Closing Rank": 381
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2617,
    "Closing Rank": 3436
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4553,
    "Closing Rank": 4553
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 490,
    "Closing Rank": 490
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 750,
    "Closing Rank": 936
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2286,
    "Closing Rank": 2286
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 748,
    "Closing Rank": 862
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1427,
    "Closing Rank": 1427
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 375,
    "Closing Rank": 375
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10334,
    "Closing Rank": 17079
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16605,
    "Closing Rank": 23288
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 393,
    "Closing Rank": 393
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2631,
    "Closing Rank": 2962
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4851,
    "Closing Rank": 4851
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4466,
    "Closing Rank": 5905
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7449,
    "Closing Rank": 9828
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 357,
    "Closing Rank": 357
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2192,
    "Closing Rank": 3806
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4336,
    "Closing Rank": 4589
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1176,
    "Closing Rank": 1456
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 585,
    "Closing Rank": 585
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11580,
    "Closing Rank": 14333
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19168,
    "Closing Rank": 25047
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1214,
    "Closing Rank": 1214
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2122,
    "Closing Rank": 2359
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4278,
    "Closing Rank": 4278
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 213,
    "Closing Rank": 213
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3904,
    "Closing Rank": 5307
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6694,
    "Closing Rank": 10048
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 709,
    "Closing Rank": 709
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2224,
    "Closing Rank": 2818
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4065,
    "Closing Rank": 4294
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1323,
    "Closing Rank": 1405
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2015,
    "Closing Rank": 2015
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29668,
    "Closing Rank": 39273
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30711,
    "Closing Rank": 34591
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1749,
    "Closing Rank": 2011
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2277,
    "Closing Rank": 2277
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6917,
    "Closing Rank": 7272
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7000,
    "Closing Rank": 7000
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11171,
    "Closing Rank": 13164
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11515,
    "Closing Rank": 12205
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4102,
    "Closing Rank": 5931
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5727,
    "Closing Rank": 5727
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1817,
    "Closing Rank": 1843
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25037,
    "Closing Rank": 28135
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29050,
    "Closing Rank": 33978
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1973,
    "Closing Rank": 1973
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4479,
    "Closing Rank": 4549
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6890,
    "Closing Rank": 6890
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9382,
    "Closing Rank": 10309
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13934,
    "Closing Rank": 14476
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5334,
    "Closing Rank": 6154
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5569,
    "Closing Rank": 5569
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1791,
    "Closing Rank": 2157
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3356,
    "Closing Rank": 3356
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31119,
    "Closing Rank": 37580
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52205,
    "Closing Rank": 52205
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7682,
    "Closing Rank": 7682
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12137,
    "Closing Rank": 14403
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7351,
    "Closing Rank": 7351
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1658,
    "Closing Rank": 1658
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4351,
    "Closing Rank": 4351
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11345,
    "Closing Rank": 22490
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24447,
    "Closing Rank": 24447
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5716,
    "Closing Rank": 5716
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9982,
    "Closing Rank": 12401
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16120,
    "Closing Rank": 16120
  },
  {
    "Institute": "National Institute of Technology, Warangal",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4609,
    "Closing Rank": 4609
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6960,
    "Closing Rank": 10289
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10282,
    "Closing Rank": 19691
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 944,
    "Closing Rank": 944
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1538,
    "Closing Rank": 1782
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3303,
    "Closing Rank": 3303
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3888,
    "Closing Rank": 5521
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9952,
    "Closing Rank": 17542
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1379,
    "Closing Rank": 1379
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1285,
    "Closing Rank": 2777
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3880,
    "Closing Rank": 3880
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 152,
    "Closing Rank": 152
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 830,
    "Closing Rank": 1353
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1364,
    "Closing Rank": 1364
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6925,
    "Closing Rank": 9454
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9014,
    "Closing Rank": 15050
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 529,
    "Closing Rank": 529
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1405,
    "Closing Rank": 1599
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2657,
    "Closing Rank": 2657
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2451,
    "Closing Rank": 3750
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7190,
    "Closing Rank": 7364
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 219,
    "Closing Rank": 219
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2123,
    "Closing Rank": 2571
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2533,
    "Closing Rank": 2617
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 72,
    "Closing Rank": 72
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 431,
    "Closing Rank": 944
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1482,
    "Closing Rank": 1482
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10459,
    "Closing Rank": 12529
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15428,
    "Closing Rank": 15428
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2098,
    "Closing Rank": 2098
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5671,
    "Closing Rank": 6042
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16271,
    "Closing Rank": 16271
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3380,
    "Closing Rank": 3633
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8762,
    "Closing Rank": 8762
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1372,
    "Closing Rank": 1372
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10624,
    "Closing Rank": 12267
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19369,
    "Closing Rank": 19369
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1821,
    "Closing Rank": 1821
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4055,
    "Closing Rank": 4524
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9158,
    "Closing Rank": 9158
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2520,
    "Closing Rank": 2758
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5217,
    "Closing Rank": 5217
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Artificial Intelligence (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1200,
    "Closing Rank": 1200
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27439,
    "Closing Rank": 38431
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36745,
    "Closing Rank": 43891
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6187,
    "Closing Rank": 7002
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8188,
    "Closing Rank": 8188
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11920,
    "Closing Rank": 21031
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28751,
    "Closing Rank": 36394
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4755,
    "Closing Rank": 9463
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6613,
    "Closing Rank": 9402
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1305,
    "Closing Rank": 2743
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1503,
    "Closing Rank": 1503
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26451,
    "Closing Rank": 34327
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32907,
    "Closing Rank": 39516
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3063,
    "Closing Rank": 3063
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5527,
    "Closing Rank": 5939
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6349,
    "Closing Rank": 6349
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10793,
    "Closing Rank": 12100
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15296,
    "Closing Rank": 16830
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1395,
    "Closing Rank": 1395
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6058,
    "Closing Rank": 6869
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7516,
    "Closing Rank": 8295
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2236,
    "Closing Rank": 2416
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2708,
    "Closing Rank": 2708
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49977,
    "Closing Rank": 74869
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14044,
    "Closing Rank": 14226
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41509,
    "Closing Rank": 66368
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17885,
    "Closing Rank": 23503
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6831,
    "Closing Rank": 9357
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39815,
    "Closing Rank": 54901
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9170,
    "Closing Rank": 9315
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18187,
    "Closing Rank": 19040
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1649,
    "Closing Rank": 1649
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8733,
    "Closing Rank": 9518
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3495,
    "Closing Rank": 3572
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17078,
    "Closing Rank": 44474
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 36264,
    "Closing Rank": 55500
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1306,
    "Closing Rank": 1306
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7173,
    "Closing Rank": 7419
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9595,
    "Closing Rank": 9595
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21192,
    "Closing Rank": 28007
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26927,
    "Closing Rank": 41049
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5845,
    "Closing Rank": 12306
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13581,
    "Closing Rank": 14789
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 796,
    "Closing Rank": 3005
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2817,
    "Closing Rank": 2817
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33336,
    "Closing Rank": 42166
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44649,
    "Closing Rank": 46667
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1381,
    "Closing Rank": 1381
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1756,
    "Closing Rank": 1756
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6989,
    "Closing Rank": 7092
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7622,
    "Closing Rank": 7622
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 496,
    "Closing Rank": 496
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12331,
    "Closing Rank": 13766
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18354,
    "Closing Rank": 19805
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5686,
    "Closing Rank": 6942
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9232,
    "Closing Rank": 9306
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1256,
    "Closing Rank": 1674
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2373,
    "Closing Rank": 2373
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3106,
    "Closing Rank": 6827
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2821,
    "Closing Rank": 10759
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 225,
    "Closing Rank": 225
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1002,
    "Closing Rank": 1164
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1717,
    "Closing Rank": 1717
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 103,
    "Closing Rank": 103
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1529,
    "Closing Rank": 3543
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2684,
    "Closing Rank": 3871
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 188,
    "Closing Rank": 188
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 511,
    "Closing Rank": 1183
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 682,
    "Closing Rank": 2203
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 295,
    "Closing Rank": 718
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 625,
    "Closing Rank": 625
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5587,
    "Closing Rank": 7113
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10021,
    "Closing Rank": 11508
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 362,
    "Closing Rank": 362
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1006,
    "Closing Rank": 1079
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1810,
    "Closing Rank": 1810
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1820,
    "Closing Rank": 2911
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4269,
    "Closing Rank": 5796
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 175,
    "Closing Rank": 175
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1670,
    "Closing Rank": 1926
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1770,
    "Closing Rank": 1984
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62,
    "Closing Rank": 62
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 530,
    "Closing Rank": 568
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1434,
    "Closing Rank": 1434
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23,
    "Closing Rank": 23
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14510,
    "Closing Rank": 21713
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23383,
    "Closing Rank": 29969
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3400,
    "Closing Rank": 3792
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6480,
    "Closing Rank": 6480
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8468,
    "Closing Rank": 11928
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22186,
    "Closing Rank": 29326
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2544,
    "Closing Rank": 7791
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4783,
    "Closing Rank": 7110
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1414,
    "Closing Rank": 2264
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2520,
    "Closing Rank": 2520
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15845,
    "Closing Rank": 19425
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21695,
    "Closing Rank": 24722
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1134,
    "Closing Rank": 1134
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3078,
    "Closing Rank": 3322
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4446,
    "Closing Rank": 4446
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5614,
    "Closing Rank": 6864
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9713,
    "Closing Rank": 12649
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3126,
    "Closing Rank": 3920
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6110,
    "Closing Rank": 6792
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1347,
    "Closing Rank": 1708
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2001,
    "Closing Rank": 2001
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59,
    "Closing Rank": 59
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7702,
    "Closing Rank": 14376
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16037,
    "Closing Rank": 20293
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2214,
    "Closing Rank": 2326
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3863,
    "Closing Rank": 3863
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4636,
    "Closing Rank": 6938
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8620,
    "Closing Rank": 16317
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1765,
    "Closing Rank": 3496
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3203,
    "Closing Rank": 3728
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1575,
    "Closing Rank": 1774
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1556,
    "Closing Rank": 1556
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9908,
    "Closing Rank": 13942
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16061,
    "Closing Rank": 19933
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 406,
    "Closing Rank": 406
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2116,
    "Closing Rank": 2338
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3086,
    "Closing Rank": 3086
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 127,
    "Closing Rank": 127
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3833,
    "Closing Rank": 5084
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8002,
    "Closing Rank": 9282
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 348,
    "Closing Rank": 348
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2906,
    "Closing Rank": 3338
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4946,
    "Closing Rank": 5180
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1034,
    "Closing Rank": 1159
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1248,
    "Closing Rank": 1248
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13158,
    "Closing Rank": 16775
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21745,
    "Closing Rank": 22994
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2906,
    "Closing Rank": 2995
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7087,
    "Closing Rank": 9196
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16486,
    "Closing Rank": 16486
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4193,
    "Closing Rank": 5008
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5255,
    "Closing Rank": 5255
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2343,
    "Closing Rank": 2343
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4722,
    "Closing Rank": 4722
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13519,
    "Closing Rank": 16600
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22633,
    "Closing Rank": 23295
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1105,
    "Closing Rank": 1105
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2708,
    "Closing Rank": 2733
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4756,
    "Closing Rank": 4756
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5269,
    "Closing Rank": 6113
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10537,
    "Closing Rank": 10537
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2193,
    "Closing Rank": 3701
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5551,
    "Closing Rank": 5551
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1460,
    "Closing Rank": 1460
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Electronics and VLSI Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2727,
    "Closing Rank": 2727
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7284,
    "Closing Rank": 35992
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43741,
    "Closing Rank": 43741
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8641,
    "Closing Rank": 8737
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25601,
    "Closing Rank": 29100
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30048,
    "Closing Rank": 30048
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11975,
    "Closing Rank": 11975
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12268,
    "Closing Rank": 12268
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2540,
    "Closing Rank": 2540
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12447,
    "Closing Rank": 24363
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34876,
    "Closing Rank": 34876
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4928,
    "Closing Rank": 4928
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11388,
    "Closing Rank": 12743
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16395,
    "Closing Rank": 16395
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5029,
    "Closing Rank": 6222
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8729,
    "Closing Rank": 8729
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Engineering Physics (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2956,
    "Closing Rank": 2956
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42069,
    "Closing Rank": 49761
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44819,
    "Closing Rank": 44819
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8422,
    "Closing Rank": 8422
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29419,
    "Closing Rank": 30775
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59079,
    "Closing Rank": 59079
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12751,
    "Closing Rank": 12751
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9601,
    "Closing Rank": 9601
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5603,
    "Closing Rank": 5603
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44273,
    "Closing Rank": 46870
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52698,
    "Closing Rank": 52698
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3431,
    "Closing Rank": 3431
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7761,
    "Closing Rank": 7980
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14363,
    "Closing Rank": 14821
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22139,
    "Closing Rank": 22139
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6971,
    "Closing Rank": 6971
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11198,
    "Closing Rank": 11198
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Industrial Chemistry (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3234,
    "Closing Rank": 3234
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33517,
    "Closing Rank": 63356
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10431,
    "Closing Rank": 10702
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31173,
    "Closing Rank": 64532
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14220,
    "Closing Rank": 19908
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3741,
    "Closing Rank": 3992
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21547,
    "Closing Rank": 45094
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 246,
    "Closing Rank": 246
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7648,
    "Closing Rank": 7963
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14654,
    "Closing Rank": 17056
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8045,
    "Closing Rank": 8841
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3323,
    "Closing Rank": 3402
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7495,
    "Closing Rank": 9379
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16644,
    "Closing Rank": 16644
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1326,
    "Closing Rank": 1352
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2596,
    "Closing Rank": 5484
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4354,
    "Closing Rank": 4354
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1416,
    "Closing Rank": 1416
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2867,
    "Closing Rank": 2867
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 769,
    "Closing Rank": 769
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7683,
    "Closing Rank": 9746
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15060,
    "Closing Rank": 15060
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1831,
    "Closing Rank": 1831
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3991,
    "Closing Rank": 4073
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8145,
    "Closing Rank": 8145
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 306,
    "Closing Rank": 306
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2788,
    "Closing Rank": 2788
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4412,
    "Closing Rank": 4412
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 904,
    "Closing Rank": 904
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11061,
    "Closing Rank": 28844
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24403,
    "Closing Rank": 44522
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4625,
    "Closing Rank": 6013
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8325,
    "Closing Rank": 8857
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10724,
    "Closing Rank": 21559
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22168,
    "Closing Rank": 33138
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8669,
    "Closing Rank": 11963
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7992,
    "Closing Rank": 12004
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1709,
    "Closing Rank": 2898
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1850,
    "Closing Rank": 3245
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17989,
    "Closing Rank": 27163
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24826,
    "Closing Rank": 39305
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2308,
    "Closing Rank": 2559
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2810,
    "Closing Rank": 2810
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4326,
    "Closing Rank": 4854
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6381,
    "Closing Rank": 6625
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 412,
    "Closing Rank": 412
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7405,
    "Closing Rank": 10419
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15425,
    "Closing Rank": 18189
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1192,
    "Closing Rank": 1192
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4005,
    "Closing Rank": 6061
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8730,
    "Closing Rank": 9112
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 646,
    "Closing Rank": 2096
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3465,
    "Closing Rank": 3683
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24904,
    "Closing Rank": 56712
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11195,
    "Closing Rank": 12701
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33642,
    "Closing Rank": 58883
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14555,
    "Closing Rank": 23150
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10132,
    "Closing Rank": 10368
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27103,
    "Closing Rank": 43334
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7325,
    "Closing Rank": 8014
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 283,
    "Closing Rank": 283
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13332,
    "Closing Rank": 18352
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8560,
    "Closing Rank": 9139
  },
  {
    "Institute": "Sardar Vallabhbhai National Institute of Technology, Surat",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3230,
    "Closing Rank": 3230
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 265,
    "Closing Rank": 1350
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 540,
    "Closing Rank": 683
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 102,
    "Closing Rank": 102
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 572,
    "Closing Rank": 725
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 159,
    "Closing Rank": 159
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 539,
    "Closing Rank": 1089
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 552,
    "Closing Rank": 785
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 338,
    "Closing Rank": 441
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 190,
    "Closing Rank": 383
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 191,
    "Closing Rank": 367
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 537,
    "Closing Rank": 1002
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 224,
    "Closing Rank": 495
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 357,
    "Closing Rank": 398
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 164,
    "Closing Rank": 164
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 376,
    "Closing Rank": 659
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 399,
    "Closing Rank": 489
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 225,
    "Closing Rank": 319
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 115,
    "Closing Rank": 166
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 180,
    "Closing Rank": 217
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27294,
    "Closing Rank": 33853
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29914,
    "Closing Rank": 37856
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1494,
    "Closing Rank": 1494
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5497,
    "Closing Rank": 5772
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6302,
    "Closing Rank": 6302
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9678,
    "Closing Rank": 12042
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12010,
    "Closing Rank": 14595
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4312,
    "Closing Rank": 6564
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7834,
    "Closing Rank": 7834
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3319,
    "Closing Rank": 3675
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3042,
    "Closing Rank": 3042
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24181,
    "Closing Rank": 28900
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29901,
    "Closing Rank": 36394
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2016,
    "Closing Rank": 2120
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4605,
    "Closing Rank": 5141
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6828,
    "Closing Rank": 6828
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9238,
    "Closing Rank": 10597
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11710,
    "Closing Rank": 14936
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1179,
    "Closing Rank": 1179
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5069,
    "Closing Rank": 5899
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6511,
    "Closing Rank": 7093
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1742,
    "Closing Rank": 2086
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3065,
    "Closing Rank": 3065
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25756,
    "Closing Rank": 40606
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27131,
    "Closing Rank": 49110
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 400,
    "Closing Rank": 400
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6708,
    "Closing Rank": 7481
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8463,
    "Closing Rank": 8463
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 509,
    "Closing Rank": 509
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12145,
    "Closing Rank": 15638
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16425,
    "Closing Rank": 18032
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5307,
    "Closing Rank": 8211
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10110,
    "Closing Rank": 10110
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1704,
    "Closing Rank": 3385
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3780,
    "Closing Rank": 3780
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29465,
    "Closing Rank": 36655
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44880,
    "Closing Rank": 46899
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2286,
    "Closing Rank": 2286
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5937,
    "Closing Rank": 6109
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7625,
    "Closing Rank": 7670
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 392,
    "Closing Rank": 430
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10706,
    "Closing Rank": 12302
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15945,
    "Closing Rank": 18057
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1047,
    "Closing Rank": 1047
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5119,
    "Closing Rank": 5784
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6433,
    "Closing Rank": 7618
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1184,
    "Closing Rank": 1231
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2755,
    "Closing Rank": 2755
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5196,
    "Closing Rank": 7583
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8334,
    "Closing Rank": 12192
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 108,
    "Closing Rank": 108
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1153,
    "Closing Rank": 1520
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3011,
    "Closing Rank": 3011
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1727,
    "Closing Rank": 2481
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4414,
    "Closing Rank": 6084
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93,
    "Closing Rank": 93
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 547,
    "Closing Rank": 1430
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 773,
    "Closing Rank": 1540
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 618,
    "Closing Rank": 919
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 921,
    "Closing Rank": 921
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4555,
    "Closing Rank": 5795
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7112,
    "Closing Rank": 9039
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 251,
    "Closing Rank": 251
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 791,
    "Closing Rank": 813
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1398,
    "Closing Rank": 1398
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42,
    "Closing Rank": 42
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1674,
    "Closing Rank": 2049
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3179,
    "Closing Rank": 3594
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 148,
    "Closing Rank": 148
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 946,
    "Closing Rank": 1610
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1773,
    "Closing Rank": 1773
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66,
    "Closing Rank": 66
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 353,
    "Closing Rank": 376
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 565,
    "Closing Rank": 565
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11915,
    "Closing Rank": 16773
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19560,
    "Closing Rank": 23986
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 567,
    "Closing Rank": 580
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2630,
    "Closing Rank": 3136
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4423,
    "Closing Rank": 4423
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 291,
    "Closing Rank": 291
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5185,
    "Closing Rank": 6651
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9088,
    "Closing Rank": 10456
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 565,
    "Closing Rank": 565
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2979,
    "Closing Rank": 3974
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3711,
    "Closing Rank": 3711
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 266,
    "Closing Rank": 266
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1065,
    "Closing Rank": 2313
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1738,
    "Closing Rank": 1738
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10226,
    "Closing Rank": 14761
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15851,
    "Closing Rank": 21015
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 623,
    "Closing Rank": 623
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2244,
    "Closing Rank": 2588
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3625,
    "Closing Rank": 3625
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3914,
    "Closing Rank": 5476
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6707,
    "Closing Rank": 8925
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 420,
    "Closing Rank": 420
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2695,
    "Closing Rank": 3393
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5424,
    "Closing Rank": 5424
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 142,
    "Closing Rank": 142
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 749,
    "Closing Rank": 1092
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1753,
    "Closing Rank": 1753
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8178,
    "Closing Rank": 11804
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13183,
    "Closing Rank": 17121
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 301,
    "Closing Rank": 304
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1939,
    "Closing Rank": 2183
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3087,
    "Closing Rank": 3087
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2810,
    "Closing Rank": 5027
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7909,
    "Closing Rank": 8307
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 257,
    "Closing Rank": 257
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1755,
    "Closing Rank": 2731
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2306,
    "Closing Rank": 3769
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 594,
    "Closing Rank": 2422
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1030,
    "Closing Rank": 1030
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6740,
    "Closing Rank": 9858
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11512,
    "Closing Rank": 14361
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 608,
    "Closing Rank": 608
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1414,
    "Closing Rank": 1684
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2166,
    "Closing Rank": 2166
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2821,
    "Closing Rank": 3789
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4817,
    "Closing Rank": 5043
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 294,
    "Closing Rank": 294
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1853,
    "Closing Rank": 2469
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3078,
    "Closing Rank": 3109
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 398,
    "Closing Rank": 672
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1247,
    "Closing Rank": 1247
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16064,
    "Closing Rank": 23543
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24414,
    "Closing Rank": 34428
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1803,
    "Closing Rank": 1803
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3725,
    "Closing Rank": 4277
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5524,
    "Closing Rank": 5524
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6735,
    "Closing Rank": 8779
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11287,
    "Closing Rank": 13039
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 840,
    "Closing Rank": 840
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2874,
    "Closing Rank": 4885
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8349,
    "Closing Rank": 8662
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2678,
    "Closing Rank": 3160
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4408,
    "Closing Rank": 4408
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20084,
    "Closing Rank": 23108
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30997,
    "Closing Rank": 35972
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 426,
    "Closing Rank": 426
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3616,
    "Closing Rank": 3727
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6389,
    "Closing Rank": 6389
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7040,
    "Closing Rank": 8219
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11514,
    "Closing Rank": 11696
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 560,
    "Closing Rank": 560
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3613,
    "Closing Rank": 5102
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6820,
    "Closing Rank": 7215
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1492,
    "Closing Rank": 1584
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2230,
    "Closing Rank": 2230
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36006,
    "Closing Rank": 45286
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 39285,
    "Closing Rank": 50945
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1872,
    "Closing Rank": 1872
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7826,
    "Closing Rank": 8120
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9127,
    "Closing Rank": 9209
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14500,
    "Closing Rank": 18336
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19268,
    "Closing Rank": 23269
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1086,
    "Closing Rank": 1086
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7735,
    "Closing Rank": 9749
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6065,
    "Closing Rank": 7624
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3886,
    "Closing Rank": 3933
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3689,
    "Closing Rank": 3689
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35542,
    "Closing Rank": 42379
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48089,
    "Closing Rank": 52484
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2702,
    "Closing Rank": 2706
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6934,
    "Closing Rank": 7282
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9263,
    "Closing Rank": 9263
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12270,
    "Closing Rank": 14017
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16209,
    "Closing Rank": 19451
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6742,
    "Closing Rank": 7441
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7803,
    "Closing Rank": 7803
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2262,
    "Closing Rank": 2687
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3019,
    "Closing Rank": 3019
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46460,
    "Closing Rank": 49561
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 55945,
    "Closing Rank": 60977
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8193,
    "Closing Rank": 8271
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15085,
    "Closing Rank": 18445
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20902,
    "Closing Rank": 24984
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8512,
    "Closing Rank": 9930
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10852,
    "Closing Rank": 10852
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4664,
    "Closing Rank": 4806
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29535,
    "Closing Rank": 44834
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53308,
    "Closing Rank": 55854
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7382,
    "Closing Rank": 7494
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13797,
    "Closing Rank": 14531
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19925,
    "Closing Rank": 20165
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6444,
    "Closing Rank": 7573
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9555,
    "Closing Rank": 9555
  },
  {
    "Institute": "Visvesvaraya National Institute of Technology, Nagpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2570,
    "Closing Rank": 2833
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49880,
    "Closing Rank": 57705
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47868,
    "Closing Rank": 50296
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9584,
    "Closing Rank": 9584
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18458,
    "Closing Rank": 18882
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20213,
    "Closing Rank": 20213
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8119,
    "Closing Rank": 8269
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3340,
    "Closing Rank": 3340
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49212,
    "Closing Rank": 51528
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38473,
    "Closing Rank": 38473
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8861,
    "Closing Rank": 8861
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10618,
    "Closing Rank": 10618
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15796,
    "Closing Rank": 17323
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17484,
    "Closing Rank": 17484
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 895,
    "Closing Rank": 1006
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8406,
    "Closing Rank": 8947
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3660,
    "Closing Rank": 3660
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48170,
    "Closing Rank": 51854
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 52600,
    "Closing Rank": 52600
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1944,
    "Closing Rank": 2165
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8619,
    "Closing Rank": 8619
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8897,
    "Closing Rank": 8897
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15701,
    "Closing Rank": 16203
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19285,
    "Closing Rank": 19285
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7299,
    "Closing Rank": 7610
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3190,
    "Closing Rank": 3190
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39826,
    "Closing Rank": 45161
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45529,
    "Closing Rank": 45529
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7535,
    "Closing Rank": 7535
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9938,
    "Closing Rank": 9938
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14323,
    "Closing Rank": 15529
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18824,
    "Closing Rank": 18824
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7337,
    "Closing Rank": 7567
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2808,
    "Closing Rank": 2808
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46504,
    "Closing Rank": 51738
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56517,
    "Closing Rank": 57487
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8541,
    "Closing Rank": 8842
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9386,
    "Closing Rank": 9386
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15574,
    "Closing Rank": 17782
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20277,
    "Closing Rank": 20277
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5580,
    "Closing Rank": 7995
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8811,
    "Closing Rank": 8811
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2864,
    "Closing Rank": 2970
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46501,
    "Closing Rank": 50048
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 54758,
    "Closing Rank": 59247
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1449,
    "Closing Rank": 1449
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8081,
    "Closing Rank": 8129
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9982,
    "Closing Rank": 9982
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15874,
    "Closing Rank": 16352
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18239,
    "Closing Rank": 18239
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1318,
    "Closing Rank": 1318
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6839,
    "Closing Rank": 7566
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8016,
    "Closing Rank": 8016
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2254,
    "Closing Rank": 2471
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8043,
    "Closing Rank": 14707
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4033,
    "Closing Rank": 16697
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 210,
    "Closing Rank": 210
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2204,
    "Closing Rank": 2258
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2982,
    "Closing Rank": 2982
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 164,
    "Closing Rank": 164
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3898,
    "Closing Rank": 6509
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6840,
    "Closing Rank": 9041
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1342,
    "Closing Rank": 2809
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1019,
    "Closing Rank": 1019
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 795,
    "Closing Rank": 1418
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 698,
    "Closing Rank": 698
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10309,
    "Closing Rank": 18548
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20759,
    "Closing Rank": 24130
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 347,
    "Closing Rank": 347
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2913,
    "Closing Rank": 3029
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4106,
    "Closing Rank": 4106
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4822,
    "Closing Rank": 6595
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6915,
    "Closing Rank": 8347
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 158,
    "Closing Rank": 158
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2721,
    "Closing Rank": 3468
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2642,
    "Closing Rank": 2642
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49,
    "Closing Rank": 49
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 817,
    "Closing Rank": 940
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 865,
    "Closing Rank": 865
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26924,
    "Closing Rank": 37297
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26966,
    "Closing Rank": 34389
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 936,
    "Closing Rank": 1330
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6053,
    "Closing Rank": 6091
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6865,
    "Closing Rank": 6865
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 293,
    "Closing Rank": 293
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11147,
    "Closing Rank": 13239
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12956,
    "Closing Rank": 13822
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4579,
    "Closing Rank": 6117
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6399,
    "Closing Rank": 6399
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1990,
    "Closing Rank": 2044
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3641,
    "Closing Rank": 3641
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28472,
    "Closing Rank": 34473
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38521,
    "Closing Rank": 41210
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1379,
    "Closing Rank": 1379
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5739,
    "Closing Rank": 6393
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7820,
    "Closing Rank": 7820
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9928,
    "Closing Rank": 12197
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14610,
    "Closing Rank": 15756
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 871,
    "Closing Rank": 871
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3672,
    "Closing Rank": 6402
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7463,
    "Closing Rank": 7463
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1953,
    "Closing Rank": 1953
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2330,
    "Closing Rank": 2330
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38,
    "Closing Rank": 52
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18177,
    "Closing Rank": 24588
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21132,
    "Closing Rank": 23576
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 576,
    "Closing Rank": 576
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3942,
    "Closing Rank": 4081
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3927,
    "Closing Rank": 3927
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6819,
    "Closing Rank": 8736
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7202,
    "Closing Rank": 9548
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 641,
    "Closing Rank": 641
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3172,
    "Closing Rank": 4768
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3966,
    "Closing Rank": 3966
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1801,
    "Closing Rank": 1876
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2685,
    "Closing Rank": 2685
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19421,
    "Closing Rank": 25707
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25501,
    "Closing Rank": 31426
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 508,
    "Closing Rank": 508
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4212,
    "Closing Rank": 4387
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5278,
    "Closing Rank": 5278
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7495,
    "Closing Rank": 9042
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9338,
    "Closing Rank": 9544
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2462,
    "Closing Rank": 4867
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5100,
    "Closing Rank": 5100
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 282,
    "Closing Rank": 282
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1550,
    "Closing Rank": 1865
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2384,
    "Closing Rank": 2384
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31092,
    "Closing Rank": 42921
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45416,
    "Closing Rank": 50311
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1740,
    "Closing Rank": 1740
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6910,
    "Closing Rank": 7063
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8328,
    "Closing Rank": 8328
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13005,
    "Closing Rank": 14502
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18678,
    "Closing Rank": 18678
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6843,
    "Closing Rank": 7418
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9399,
    "Closing Rank": 9399
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2679,
    "Closing Rank": 2709
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34097,
    "Closing Rank": 41191
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38752,
    "Closing Rank": 50950
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6899,
    "Closing Rank": 7248
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8604,
    "Closing Rank": 8604
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12246,
    "Closing Rank": 13445
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18463,
    "Closing Rank": 18463
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1158,
    "Closing Rank": 1158
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6064,
    "Closing Rank": 7318
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7827,
    "Closing Rank": 7827
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2107,
    "Closing Rank": 2130
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51861,
    "Closing Rank": 57008
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59744,
    "Closing Rank": 59744
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3225,
    "Closing Rank": 3225
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9364,
    "Closing Rank": 9364
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11306,
    "Closing Rank": 11306
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17629,
    "Closing Rank": 18395
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21030,
    "Closing Rank": 21030
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6750,
    "Closing Rank": 9382
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3354,
    "Closing Rank": 3354
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49440,
    "Closing Rank": 51428
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59618,
    "Closing Rank": 59618
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8708,
    "Closing Rank": 8708
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15214,
    "Closing Rank": 17407
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19607,
    "Closing Rank": 19607
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8164,
    "Closing Rank": 8341
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10128,
    "Closing Rank": 10128
  },
  {
    "Institute": "National Institute of Technology, Andhra Pradesh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3162,
    "Closing Rank": 3162
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20875,
    "Closing Rank": 32833
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38974,
    "Closing Rank": 44531
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1502,
    "Closing Rank": 1502
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11077,
    "Closing Rank": 11077
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19680,
    "Closing Rank": 25727
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25263,
    "Closing Rank": 25263
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5240,
    "Closing Rank": 5724
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8345,
    "Closing Rank": 8345
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4048,
    "Closing Rank": 4048
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8917,
    "Closing Rank": 8917
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10762,
    "Closing Rank": 21831
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35307,
    "Closing Rank": 36197
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3770,
    "Closing Rank": 4535
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7259,
    "Closing Rank": 7259
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6682,
    "Closing Rank": 7881
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14899,
    "Closing Rank": 14899
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 100,
    "Closing Rank": 100
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1954,
    "Closing Rank": 3257
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4137,
    "Closing Rank": 4137
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1739,
    "Closing Rank": 1739
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 194,
    "Closing Rank": 527
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 219,
    "Closing Rank": 219
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1654,
    "Closing Rank": 1654
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2475,
    "Closing Rank": 2768
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 713,
    "Closing Rank": 713
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 132,
    "Closing Rank": 132
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 272,
    "Closing Rank": 272
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 119,
    "Closing Rank": 119
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1115,
    "Closing Rank": 1576
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 999,
    "Closing Rank": 999
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 505,
    "Closing Rank": 724
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 533,
    "Closing Rank": 533
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 259,
    "Closing Rank": 259
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 123,
    "Closing Rank": 123
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33868,
    "Closing Rank": 50745
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 48985,
    "Closing Rank": 56828
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1632,
    "Closing Rank": 1632
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8321,
    "Closing Rank": 14721
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32685,
    "Closing Rank": 32685
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18645,
    "Closing Rank": 37820
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44475,
    "Closing Rank": 49449
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6063,
    "Closing Rank": 9270
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8407,
    "Closing Rank": 10331
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4356,
    "Closing Rank": 10635
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13918,
    "Closing Rank": 13918
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36890,
    "Closing Rank": 49591
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51516,
    "Closing Rank": 59688
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2955,
    "Closing Rank": 2955
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8018,
    "Closing Rank": 8213
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9960,
    "Closing Rank": 9960
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15169,
    "Closing Rank": 16884
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19244,
    "Closing Rank": 21112
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7554,
    "Closing Rank": 8382
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10356,
    "Closing Rank": 10436
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1787,
    "Closing Rank": 2056
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2787,
    "Closing Rank": 2787
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8066,
    "Closing Rank": 15062
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10218,
    "Closing Rank": 18284
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 124,
    "Closing Rank": 124
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3089,
    "Closing Rank": 3832
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6544,
    "Closing Rank": 6544
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6319,
    "Closing Rank": 11129
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9880,
    "Closing Rank": 12616
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1228,
    "Closing Rank": 2205
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1462,
    "Closing Rank": 1462
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1483,
    "Closing Rank": 2024
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3730,
    "Closing Rank": 3730
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6068,
    "Closing Rank": 14635
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19472,
    "Closing Rank": 22547
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 206,
    "Closing Rank": 206
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2240,
    "Closing Rank": 2330
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4311,
    "Closing Rank": 4311
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5106,
    "Closing Rank": 5770
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9162,
    "Closing Rank": 9509
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 975,
    "Closing Rank": 3155
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3418,
    "Closing Rank": 3418
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 86,
    "Closing Rank": 86
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1121,
    "Closing Rank": 1349
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1818,
    "Closing Rank": 1818
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6608,
    "Closing Rank": 27313
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29625,
    "Closing Rank": 34425
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 913,
    "Closing Rank": 913
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6836,
    "Closing Rank": 7461
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13649,
    "Closing Rank": 13649
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12444,
    "Closing Rank": 20094
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25220,
    "Closing Rank": 27942
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3301,
    "Closing Rank": 5018
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4391,
    "Closing Rank": 4391
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4588,
    "Closing Rank": 6584
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7149,
    "Closing Rank": 7149
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50,
    "Closing Rank": 50
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20438,
    "Closing Rank": 28389
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30187,
    "Closing Rank": 39661
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2195,
    "Closing Rank": 2195
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4506,
    "Closing Rank": 4920
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6804,
    "Closing Rank": 6804
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8437,
    "Closing Rank": 9816
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12595,
    "Closing Rank": 14822
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5225,
    "Closing Rank": 6023
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8682,
    "Closing Rank": 8682
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2098,
    "Closing Rank": 2259
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3043,
    "Closing Rank": 3043
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11608,
    "Closing Rank": 21591
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23856,
    "Closing Rank": 27739
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5875,
    "Closing Rank": 6223
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10660,
    "Closing Rank": 10660
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11269,
    "Closing Rank": 15577
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22924,
    "Closing Rank": 22924
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2553,
    "Closing Rank": 3637
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3451,
    "Closing Rank": 3451
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3130,
    "Closing Rank": 4179
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5939,
    "Closing Rank": 5939
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16738,
    "Closing Rank": 21078
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30256,
    "Closing Rank": 31775
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1362,
    "Closing Rank": 1362
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3833,
    "Closing Rank": 3881
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6142,
    "Closing Rank": 6142
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7382,
    "Closing Rank": 7907
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10504,
    "Closing Rank": 14579
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 715,
    "Closing Rank": 715
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4795,
    "Closing Rank": 5116
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6855,
    "Closing Rank": 6855
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1646,
    "Closing Rank": 1646
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16371,
    "Closing Rank": 20873
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18799,
    "Closing Rank": 26441
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 483,
    "Closing Rank": 483
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5131,
    "Closing Rank": 5822
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10013,
    "Closing Rank": 10013
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11699,
    "Closing Rank": 13803
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18130,
    "Closing Rank": 23972
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2451,
    "Closing Rank": 3112
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4354,
    "Closing Rank": 4354
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3092,
    "Closing Rank": 3574
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5505,
    "Closing Rank": 5505
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13607,
    "Closing Rank": 16840
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23943,
    "Closing Rank": 28350
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 823,
    "Closing Rank": 823
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2625,
    "Closing Rank": 2697
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4621,
    "Closing Rank": 4621
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4415,
    "Closing Rank": 6398
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10133,
    "Closing Rank": 12570
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 556,
    "Closing Rank": 556
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3162,
    "Closing Rank": 3977
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4845,
    "Closing Rank": 4845
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1622,
    "Closing Rank": 1819
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2304,
    "Closing Rank": 2304
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26830,
    "Closing Rank": 35986
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38504,
    "Closing Rank": 48563
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1804,
    "Closing Rank": 1908
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7976,
    "Closing Rank": 9789
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19038,
    "Closing Rank": 19038
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10172,
    "Closing Rank": 24852
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26403,
    "Closing Rank": 33900
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5155,
    "Closing Rank": 6181
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6861,
    "Closing Rank": 8062
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6598,
    "Closing Rank": 10575
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17215,
    "Closing Rank": 17215
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20930,
    "Closing Rank": 35857
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42118,
    "Closing Rank": 45574
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2924,
    "Closing Rank": 2924
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5765,
    "Closing Rank": 6094
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8916,
    "Closing Rank": 8916
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10392,
    "Closing Rank": 12482
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16869,
    "Closing Rank": 18368
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5283,
    "Closing Rank": 7266
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8908,
    "Closing Rank": 10158
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2323,
    "Closing Rank": 2461
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36754,
    "Closing Rank": 51555
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35082,
    "Closing Rank": 49799
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2370,
    "Closing Rank": 2370
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11357,
    "Closing Rank": 11357
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35598,
    "Closing Rank": 35598
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25728,
    "Closing Rank": 37857
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38329,
    "Closing Rank": 38329
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7325,
    "Closing Rank": 8621
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10780,
    "Closing Rank": 10780
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11528,
    "Closing Rank": 11528
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42662,
    "Closing Rank": 50256
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 62214,
    "Closing Rank": 63620
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2366,
    "Closing Rank": 2366
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8169,
    "Closing Rank": 8296
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15602,
    "Closing Rank": 17236
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21448,
    "Closing Rank": 21448
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7690,
    "Closing Rank": 8466
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10247,
    "Closing Rank": 10247
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3201,
    "Closing Rank": 3201
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4446,
    "Closing Rank": 4446
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39453,
    "Closing Rank": 53757
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60795,
    "Closing Rank": 61103
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16166,
    "Closing Rank": 16934
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32594,
    "Closing Rank": 38510
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 49152,
    "Closing Rank": 49152
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8279,
    "Closing Rank": 8915
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10704,
    "Closing Rank": 10704
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5955,
    "Closing Rank": 6846
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17297,
    "Closing Rank": 17297
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48976,
    "Closing Rank": 53043
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 62896,
    "Closing Rank": 65852
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8746,
    "Closing Rank": 8952
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17277,
    "Closing Rank": 17817
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21607,
    "Closing Rank": 22321
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8705,
    "Closing Rank": 9090
  },
  {
    "Institute": "Indian Institute of Engineering Science and Technology, Shibpur",
    "Academic Program Name": "Mining Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3275,
    "Closing Rank": 3372
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3710,
    "Closing Rank": 8044
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10050,
    "Closing Rank": 11529
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 212,
    "Closing Rank": 212
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1127,
    "Closing Rank": 1365
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2013,
    "Closing Rank": 2013
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2658,
    "Closing Rank": 3625
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5669,
    "Closing Rank": 6214
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 195,
    "Closing Rank": 195
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1737,
    "Closing Rank": 2195
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3246,
    "Closing Rank": 3313
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 67,
    "Closing Rank": 67
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 746,
    "Closing Rank": 1343
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1493,
    "Closing Rank": 1493
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75,
    "Closing Rank": 75
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10447,
    "Closing Rank": 17195
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24344,
    "Closing Rank": 25669
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1334,
    "Closing Rank": 1334
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2740,
    "Closing Rank": 2803
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4312,
    "Closing Rank": 4312
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5855,
    "Closing Rank": 6543
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10596,
    "Closing Rank": 13502
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 973,
    "Closing Rank": 973
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3776,
    "Closing Rank": 5088
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4852,
    "Closing Rank": 4852
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1508,
    "Closing Rank": 1671
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9708,
    "Closing Rank": 13577
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18625,
    "Closing Rank": 22022
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 776,
    "Closing Rank": 776
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 555,
    "Closing Rank": 555
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2069,
    "Closing Rank": 2370
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3731,
    "Closing Rank": 3809
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4349,
    "Closing Rank": 6241
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7705,
    "Closing Rank": 10475
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 557,
    "Closing Rank": 557
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2426,
    "Closing Rank": 4293
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4280,
    "Closing Rank": 5371
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1116,
    "Closing Rank": 1981
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2568,
    "Closing Rank": 2568
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13833,
    "Closing Rank": 16856
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22303,
    "Closing Rank": 24511
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1098,
    "Closing Rank": 1098
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2615,
    "Closing Rank": 2790
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4010,
    "Closing Rank": 4010
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 219,
    "Closing Rank": 219
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5953,
    "Closing Rank": 7482
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10304,
    "Closing Rank": 10838
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 746,
    "Closing Rank": 746
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3595,
    "Closing Rank": 4746
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5701,
    "Closing Rank": 5701
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2239,
    "Closing Rank": 2526
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2793,
    "Closing Rank": 2793
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8643,
    "Closing Rank": 11180
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16057,
    "Closing Rank": 18094
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 719,
    "Closing Rank": 719
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1724,
    "Closing Rank": 1908
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3232,
    "Closing Rank": 3232
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4634,
    "Closing Rank": 4863
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7390,
    "Closing Rank": 9429
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2706,
    "Closing Rank": 3476
  },
  {
    "Institute": "Atal Bihari Vajpayee Indian Institute of Information Technology & Management Gwalior",
    "Academic Program Name": "Mathematics and Scientific Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1394,
    "Closing Rank": 1494
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14413,
    "Closing Rank": 27124
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 29212,
    "Closing Rank": 34478
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1997,
    "Closing Rank": 2185
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4354,
    "Closing Rank": 4855
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6523,
    "Closing Rank": 6523
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8497,
    "Closing Rank": 11440
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14792,
    "Closing Rank": 15390
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1715,
    "Closing Rank": 1715
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5471,
    "Closing Rank": 7643
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10037,
    "Closing Rank": 10142
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2830,
    "Closing Rank": 3559
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Artificial Intelligence and Data Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4802,
    "Closing Rank": 4802
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14863,
    "Closing Rank": 24416
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25882,
    "Closing Rank": 34423
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1261,
    "Closing Rank": 1619
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2035,
    "Closing Rank": 2035
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3896,
    "Closing Rank": 4630
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5759,
    "Closing Rank": 6604
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 229,
    "Closing Rank": 229
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7053,
    "Closing Rank": 10658
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13265,
    "Closing Rank": 14625
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 671,
    "Closing Rank": 1525
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3959,
    "Closing Rank": 7363
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6501,
    "Closing Rank": 8483
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2621,
    "Closing Rank": 3475
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3681,
    "Closing Rank": 3832
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25813,
    "Closing Rank": 34167
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34794,
    "Closing Rank": 36589
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 295,
    "Closing Rank": 295
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5548,
    "Closing Rank": 6098
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6775,
    "Closing Rank": 6833
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12480,
    "Closing Rank": 14779
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17748,
    "Closing Rank": 18565
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1891,
    "Closing Rank": 1891
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6950,
    "Closing Rank": 8653
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9767,
    "Closing Rank": 10738
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1655,
    "Closing Rank": 3604
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT)Kota, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4075,
    "Closing Rank": 4075
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8898,
    "Closing Rank": 20821
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26461,
    "Closing Rank": 30584
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 915,
    "Closing Rank": 1082
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2453,
    "Closing Rank": 2453
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3243,
    "Closing Rank": 3551
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5082,
    "Closing Rank": 5385
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5512,
    "Closing Rank": 8445
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10414,
    "Closing Rank": 13481
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1398,
    "Closing Rank": 1399
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3874,
    "Closing Rank": 5209
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4417,
    "Closing Rank": 6963
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 141,
    "Closing Rank": 141
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1335,
    "Closing Rank": 2701
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3079,
    "Closing Rank": 3264
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17226,
    "Closing Rank": 30437
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33374,
    "Closing Rank": 38176
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1332,
    "Closing Rank": 2723
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4982,
    "Closing Rank": 5528
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6177,
    "Closing Rank": 6177
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8786,
    "Closing Rank": 13518
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16034,
    "Closing Rank": 17942
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2945,
    "Closing Rank": 7079
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5484,
    "Closing Rank": 8445
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3015,
    "Closing Rank": 3531
  },
  {
    "Institute": "Indian Institute of Information Technology Guwahati",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3982,
    "Closing Rank": 3982
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28211,
    "Closing Rank": 39991
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35632,
    "Closing Rank": 38130
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 293,
    "Closing Rank": 1271
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6424,
    "Closing Rank": 6978
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7343,
    "Closing Rank": 7343
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13588,
    "Closing Rank": 16367
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16308,
    "Closing Rank": 18234
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 736,
    "Closing Rank": 1432
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3847,
    "Closing Rank": 9255
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6254,
    "Closing Rank": 8992
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 263,
    "Closing Rank": 263
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3499,
    "Closing Rank": 4176
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4314,
    "Closing Rank": 4314
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40334,
    "Closing Rank": 43031
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40847,
    "Closing Rank": 42026
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3291,
    "Closing Rank": 3291
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7084,
    "Closing Rank": 7241
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7628,
    "Closing Rank": 7628
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16578,
    "Closing Rank": 17077
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15369,
    "Closing Rank": 15369
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6259,
    "Closing Rank": 9708
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10443,
    "Closing Rank": 10443
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kalyani, West Bengal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4204,
    "Closing Rank": 4901
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18772,
    "Closing Rank": 28994
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 629,
    "Closing Rank": 1922
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4618,
    "Closing Rank": 5038
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8650,
    "Closing Rank": 12954
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1074,
    "Closing Rank": 1382
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4009,
    "Closing Rank": 8043
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3181,
    "Closing Rank": 4306
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "CSE ( Data Science & Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25414,
    "Closing Rank": 31601
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "CSE ( Data Science & Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2644,
    "Closing Rank": 2644
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "CSE ( Data Science & Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5041,
    "Closing Rank": 5264
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "CSE ( Data Science & Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12987,
    "Closing Rank": 14380
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "CSE ( Data Science & Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1279,
    "Closing Rank": 1279
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "CSE ( Data Science & Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7401,
    "Closing Rank": 9056
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "CSE ( Data Science & Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4101,
    "Closing Rank": 4443
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26120,
    "Closing Rank": 32173
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1547,
    "Closing Rank": 1547
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5249,
    "Closing Rank": 5444
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13134,
    "Closing Rank": 14394
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7943,
    "Closing Rank": 8919
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kilohrad, Sonepat, Haryana",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3806,
    "Closing Rank": 4544
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17649,
    "Closing Rank": 28335
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28756,
    "Closing Rank": 28910
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 709,
    "Closing Rank": 1505
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4629,
    "Closing Rank": 5056
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5436,
    "Closing Rank": 5436
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8416,
    "Closing Rank": 12090
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 966,
    "Closing Rank": 966
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3954,
    "Closing Rank": 6890
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3614,
    "Closing Rank": 3981
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24797,
    "Closing Rank": 31872
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 484,
    "Closing Rank": 484
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5543,
    "Closing Rank": 5586
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10451,
    "Closing Rank": 13111
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6991,
    "Closing Rank": 8712
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4542,
    "Closing Rank": 4580
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27980,
    "Closing Rank": 30485
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5311,
    "Closing Rank": 5484
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11265,
    "Closing Rank": 13073
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7494,
    "Closing Rank": 7990
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4490,
    "Closing Rank": 4605
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28230,
    "Closing Rank": 37935
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37389,
    "Closing Rank": 37389
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3688,
    "Closing Rank": 3688
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6161,
    "Closing Rank": 6728
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12339,
    "Closing Rank": 16185
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17889,
    "Closing Rank": 17889
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7166,
    "Closing Rank": 9737
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10873,
    "Closing Rank": 10873
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3118,
    "Closing Rank": 4987
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29569,
    "Closing Rank": 33023
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30920,
    "Closing Rank": 30920
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1717,
    "Closing Rank": 1717
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5420,
    "Closing Rank": 6044
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12245,
    "Closing Rank": 14170
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17018,
    "Closing Rank": 17018
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5026,
    "Closing Rank": 9195
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3694,
    "Closing Rank": 4937
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Una, Himachal Pradesh",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4365,
    "Closing Rank": 4365
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12583,
    "Closing Rank": 28509
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25567,
    "Closing Rank": 32036
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 710,
    "Closing Rank": 710
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4532,
    "Closing Rank": 4983
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8346,
    "Closing Rank": 11996
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12461,
    "Closing Rank": 12461
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5423,
    "Closing Rank": 7771
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2718,
    "Closing Rank": 3902
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15850,
    "Closing Rank": 26444
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11742,
    "Closing Rank": 30676
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1162,
    "Closing Rank": 1335
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4199,
    "Closing Rank": 4857
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5250,
    "Closing Rank": 5599
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 295,
    "Closing Rank": 295
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7089,
    "Closing Rank": 11112
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10622,
    "Closing Rank": 12540
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 555,
    "Closing Rank": 882
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4696,
    "Closing Rank": 8121
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7118,
    "Closing Rank": 7928
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 184,
    "Closing Rank": 184
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2438,
    "Closing Rank": 3930
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2895,
    "Closing Rank": 2895
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27898,
    "Closing Rank": 36490
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31852,
    "Closing Rank": 38333
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1774,
    "Closing Rank": 1774
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6078,
    "Closing Rank": 6535
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11733,
    "Closing Rank": 14065
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12833,
    "Closing Rank": 13601
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1333,
    "Closing Rank": 1333
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6520,
    "Closing Rank": 9656
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10281,
    "Closing Rank": 10281
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT), Sri City, Chittoor",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3861,
    "Closing Rank": 4141
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10274,
    "Closing Rank": 21838
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23161,
    "Closing Rank": 30490
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1383,
    "Closing Rank": 1946
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 681,
    "Closing Rank": 681
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3655,
    "Closing Rank": 4092
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5021,
    "Closing Rank": 6025
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 390,
    "Closing Rank": 390
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6971,
    "Closing Rank": 9909
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8473,
    "Closing Rank": 15791
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1113,
    "Closing Rank": 1205
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3805,
    "Closing Rank": 7532
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5936,
    "Closing Rank": 7556
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1345,
    "Closing Rank": 3451
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2494,
    "Closing Rank": 2926
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21896,
    "Closing Rank": 23624
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31434,
    "Closing Rank": 33467
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2100,
    "Closing Rank": 2100
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4101,
    "Closing Rank": 4255
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6420,
    "Closing Rank": 6420
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8800,
    "Closing Rank": 11316
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15814,
    "Closing Rank": 16080
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7544,
    "Closing Rank": 8179
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6596,
    "Closing Rank": 6596
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT), Vadodara, Gujrat",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2882,
    "Closing Rank": 3507
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5569,
    "Closing Rank": 7758
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10761,
    "Closing Rank": 14319
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 337,
    "Closing Rank": 344
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 487,
    "Closing Rank": 487
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1063,
    "Closing Rank": 1376
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2165,
    "Closing Rank": 2348
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 92,
    "Closing Rank": 92
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2428,
    "Closing Rank": 3558
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6185,
    "Closing Rank": 7281
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 193,
    "Closing Rank": 193
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1144,
    "Closing Rank": 2402
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2564,
    "Closing Rank": 4416
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 88,
    "Closing Rank": 88
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 910,
    "Closing Rank": 1310
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2680,
    "Closing Rank": 2784
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1581,
    "Closing Rank": 5202
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4142,
    "Closing Rank": 9457
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 229,
    "Closing Rank": 284
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1011,
    "Closing Rank": 1011
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 724,
    "Closing Rank": 986
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1389,
    "Closing Rank": 1801
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52,
    "Closing Rank": 52
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1119,
    "Closing Rank": 2509
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2473,
    "Closing Rank": 5104
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 174,
    "Closing Rank": 261
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 502,
    "Closing Rank": 502
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 609,
    "Closing Rank": 1562
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1036,
    "Closing Rank": 3003
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27,
    "Closing Rank": 44
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 441,
    "Closing Rank": 888
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 962,
    "Closing Rank": 1879
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4252,
    "Closing Rank": 5392
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9159,
    "Closing Rank": 9754
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 280,
    "Closing Rank": 280
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 887,
    "Closing Rank": 994
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2009,
    "Closing Rank": 2009
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1950,
    "Closing Rank": 2639
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5184,
    "Closing Rank": 5423
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 278,
    "Closing Rank": 278
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1023,
    "Closing Rank": 1695
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 836,
    "Closing Rank": 836
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 916,
    "Closing Rank": 952
  },
  {
    "Institute": "Indian Institute of Information Technology, Allahabad",
    "Academic Program Name": "Information Technology-Business Informatics (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1893,
    "Closing Rank": 1893
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24530,
    "Closing Rank": 39290
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6555,
    "Closing Rank": 7490
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11596,
    "Closing Rank": 15798
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1244,
    "Closing Rank": 1244
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6697,
    "Closing Rank": 8025
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech in Mechanical Engineering and M.Tech in AI and Robotics (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2782,
    "Closing Rank": 3122
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25389,
    "Closing Rank": 32117
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 33257,
    "Closing Rank": 40124
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2109,
    "Closing Rank": 2109
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5569,
    "Closing Rank": 5662
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10937,
    "Closing Rank": 11387
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14391,
    "Closing Rank": 14391
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5458,
    "Closing Rank": 6619
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8319,
    "Closing Rank": 8319
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in Communication Systems (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2960,
    "Closing Rank": 2960
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20405,
    "Closing Rank": 27848
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27612,
    "Closing Rank": 33349
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2146,
    "Closing Rank": 2146
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5284,
    "Closing Rank": 6451
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6754,
    "Closing Rank": 6754
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9175,
    "Closing Rank": 11284
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10683,
    "Closing Rank": 10683
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5224,
    "Closing Rank": 6008
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2651,
    "Closing Rank": 2651
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "B.Tech. in Electronics and Communication Engineering and M.Tech. in VLSI Design (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3518,
    "Closing Rank": 3518
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7422,
    "Closing Rank": 18654
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11864,
    "Closing Rank": 19604
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 655,
    "Closing Rank": 655
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 571,
    "Closing Rank": 571
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2862,
    "Closing Rank": 3058
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3113,
    "Closing Rank": 3215
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 173,
    "Closing Rank": 173
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5428,
    "Closing Rank": 7575
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5188,
    "Closing Rank": 9420
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 265,
    "Closing Rank": 265
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2162,
    "Closing Rank": 4366
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3179,
    "Closing Rank": 5547
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1476,
    "Closing Rank": 2146
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1976,
    "Closing Rank": 1976
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19308,
    "Closing Rank": 22849
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21711,
    "Closing Rank": 26500
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 866,
    "Closing Rank": 866
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3639,
    "Closing Rank": 4185
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5384,
    "Closing Rank": 5384
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6828,
    "Closing Rank": 8562
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12015,
    "Closing Rank": 13194
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 594,
    "Closing Rank": 594
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3794,
    "Closing Rank": 5281
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5681,
    "Closing Rank": 6597
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 302,
    "Closing Rank": 302
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2219,
    "Closing Rank": 2375
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2683,
    "Closing Rank": 2683
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11923,
    "Closing Rank": 18702
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16255,
    "Closing Rank": 22163
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 436,
    "Closing Rank": 436
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3090,
    "Closing Rank": 3188
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3765,
    "Closing Rank": 3765
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6221,
    "Closing Rank": 7850
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10721,
    "Closing Rank": 11166
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 385,
    "Closing Rank": 385
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4092,
    "Closing Rank": 4700
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5862,
    "Closing Rank": 5862
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1662,
    "Closing Rank": 2148
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2009,
    "Closing Rank": 2009
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19426,
    "Closing Rank": 27634
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23341,
    "Closing Rank": 27644
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 859,
    "Closing Rank": 1007
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 654,
    "Closing Rank": 654
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4433,
    "Closing Rank": 5126
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4954,
    "Closing Rank": 5652
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 186,
    "Closing Rank": 186
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7612,
    "Closing Rank": 10157
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10230,
    "Closing Rank": 13591
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 615,
    "Closing Rank": 615
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4952,
    "Closing Rank": 6140
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4928,
    "Closing Rank": 6911
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1836,
    "Closing Rank": 2609
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2528,
    "Closing Rank": 2575
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19452,
    "Closing Rank": 43513
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45347,
    "Closing Rank": 47534
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2030,
    "Closing Rank": 2030
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2249,
    "Closing Rank": 2249
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7361,
    "Closing Rank": 7902
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9275,
    "Closing Rank": 9672
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12644,
    "Closing Rank": 16443
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15779,
    "Closing Rank": 19279
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1186,
    "Closing Rank": 1186
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6455,
    "Closing Rank": 8018
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9024,
    "Closing Rank": 10505
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2977,
    "Closing Rank": 3320
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3247,
    "Closing Rank": 3247
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31783,
    "Closing Rank": 48128
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44987,
    "Closing Rank": 56354
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1828,
    "Closing Rank": 1828
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8145,
    "Closing Rank": 8656
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10224,
    "Closing Rank": 10224
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14059,
    "Closing Rank": 17165
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18808,
    "Closing Rank": 19374
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1173,
    "Closing Rank": 1173
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8144,
    "Closing Rank": 9063
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9853,
    "Closing Rank": 9853
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3599,
    "Closing Rank": 3652
  },
  {
    "Institute": "Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4853,
    "Closing Rank": 4853
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8150,
    "Closing Rank": 14757
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16151,
    "Closing Rank": 23022
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 425,
    "Closing Rank": 974
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1402,
    "Closing Rank": 1402
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2212,
    "Closing Rank": 2443
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3726,
    "Closing Rank": 4150
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 179,
    "Closing Rank": 179
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3671,
    "Closing Rank": 5785
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7265,
    "Closing Rank": 9914
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 380,
    "Closing Rank": 497
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 728,
    "Closing Rank": 728
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 863,
    "Closing Rank": 3905
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3656,
    "Closing Rank": 5201
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 107,
    "Closing Rank": 107
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1222,
    "Closing Rank": 2003
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2205,
    "Closing Rank": 2610
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12242,
    "Closing Rank": 19106
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18506,
    "Closing Rank": 27458
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1190,
    "Closing Rank": 1279
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1925,
    "Closing Rank": 1925
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2975,
    "Closing Rank": 3231
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4403,
    "Closing Rank": 4699
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 284,
    "Closing Rank": 284
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5831,
    "Closing Rank": 7514
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10550,
    "Closing Rank": 12756
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 768,
    "Closing Rank": 768
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3737,
    "Closing Rank": 4945
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5425,
    "Closing Rank": 7356
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1994,
    "Closing Rank": 2464
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2894,
    "Closing Rank": 3108
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28690,
    "Closing Rank": 41353
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43819,
    "Closing Rank": 50222
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2412,
    "Closing Rank": 2412
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6646,
    "Closing Rank": 7237
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8257,
    "Closing Rank": 8257
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11867,
    "Closing Rank": 16157
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20153,
    "Closing Rank": 20662
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5818,
    "Closing Rank": 7511
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5674,
    "Closing Rank": 9237
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2739,
    "Closing Rank": 2964
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4155,
    "Closing Rank": 4155
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41026,
    "Closing Rank": 49439
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43821,
    "Closing Rank": 55789
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3322,
    "Closing Rank": 3322
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8065,
    "Closing Rank": 8414
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10027,
    "Closing Rank": 10027
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16022,
    "Closing Rank": 18176
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 21409,
    "Closing Rank": 22444
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7825,
    "Closing Rank": 9556
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11116,
    "Closing Rank": 11422
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3466,
    "Closing Rank": 3818
  },
  {
    "Institute": "Pt. Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacture Jabalpur",
    "Academic Program Name": "Smart Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4862,
    "Closing Rank": 4862
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40493,
    "Closing Rank": 50509
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44722,
    "Closing Rank": 52943
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8163,
    "Closing Rank": 8512
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9021,
    "Closing Rank": 9469
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15819,
    "Closing Rank": 18624
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20233,
    "Closing Rank": 21886
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9487,
    "Closing Rank": 10707
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10674,
    "Closing Rank": 11636
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4051,
    "Closing Rank": 4697
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5014,
    "Closing Rank": 5132
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37547,
    "Closing Rank": 47179
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7705,
    "Closing Rank": 8361
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16738,
    "Closing Rank": 18235
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9058,
    "Closing Rank": 10255
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4416,
    "Closing Rank": 4768
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46236,
    "Closing Rank": 55833
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 57045,
    "Closing Rank": 67091
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9143,
    "Closing Rank": 9513
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10977,
    "Closing Rank": 11091
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17234,
    "Closing Rank": 19882
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22255,
    "Closing Rank": 23856
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10383,
    "Closing Rank": 11358
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10921,
    "Closing Rank": 12681
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4614,
    "Closing Rank": 5242
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5118,
    "Closing Rank": 5504
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45745,
    "Closing Rank": 56550
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9255,
    "Closing Rank": 9658
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18010,
    "Closing Rank": 20128
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9682,
    "Closing Rank": 11176
  },
  {
    "Institute": "INDIAN INSTITUTE OF INFORMATION TECHNOLOGY SENAPATI MANIPUR",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in VLSI and Embedded Systems (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4799,
    "Closing Rank": 5349
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10666,
    "Closing Rank": 29303
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15479,
    "Closing Rank": 32237
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 827,
    "Closing Rank": 827
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1710,
    "Closing Rank": 1710
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4678,
    "Closing Rank": 5173
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6191,
    "Closing Rank": 6443
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7941,
    "Closing Rank": 11994
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13035,
    "Closing Rank": 15906
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1360,
    "Closing Rank": 5985
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6241,
    "Closing Rank": 8427
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1400,
    "Closing Rank": 2170
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3086,
    "Closing Rank": 3293
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11247,
    "Closing Rank": 36702
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38757,
    "Closing Rank": 41732
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1699,
    "Closing Rank": 1699
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6227,
    "Closing Rank": 6710
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7134,
    "Closing Rank": 7703
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10675,
    "Closing Rank": 15120
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16099,
    "Closing Rank": 17553
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1104,
    "Closing Rank": 1104
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1178,
    "Closing Rank": 1178
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5644,
    "Closing Rank": 7890
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8674,
    "Closing Rank": 9822
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2387,
    "Closing Rank": 3408
  },
  {
    "Institute": "Indian Institute of Information Technology Tiruchirappalli",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1183,
    "Closing Rank": 3853
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7341,
    "Closing Rank": 9824
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10857,
    "Closing Rank": 13117
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 482,
    "Closing Rank": 482
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1459,
    "Closing Rank": 1639
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2778,
    "Closing Rank": 4262
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6458,
    "Closing Rank": 7118
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 403,
    "Closing Rank": 403
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2211,
    "Closing Rank": 3139
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4672,
    "Closing Rank": 5463
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1202,
    "Closing Rank": 1776
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2783,
    "Closing Rank": 2783
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6127,
    "Closing Rank": 9384
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9235,
    "Closing Rank": 14286
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 230,
    "Closing Rank": 230
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1422,
    "Closing Rank": 1575
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2265,
    "Closing Rank": 2265
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2935,
    "Closing Rank": 4064
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7134,
    "Closing Rank": 8046
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 264,
    "Closing Rank": 264
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1362,
    "Closing Rank": 3240
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5200,
    "Closing Rank": 5200
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 170,
    "Closing Rank": 170
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 922,
    "Closing Rank": 1751
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1822,
    "Closing Rank": 1822
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9303,
    "Closing Rank": 11380
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17888,
    "Closing Rank": 18827
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 528,
    "Closing Rank": 528
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1666,
    "Closing Rank": 1857
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2928,
    "Closing Rank": 2928
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3472,
    "Closing Rank": 4884
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8180,
    "Closing Rank": 8268
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 437,
    "Closing Rank": 437
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1035,
    "Closing Rank": 1035
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2599,
    "Closing Rank": 3888
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5190,
    "Closing Rank": 6157
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2110,
    "Closing Rank": 2255
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Computer Science and Business (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3442,
    "Closing Rank": 3442
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5814,
    "Closing Rank": 11494
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16257,
    "Closing Rank": 18522
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 648,
    "Closing Rank": 648
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1927,
    "Closing Rank": 1927
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1786,
    "Closing Rank": 2055
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3019,
    "Closing Rank": 3019
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4116,
    "Closing Rank": 4959
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8960,
    "Closing Rank": 9152
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 630,
    "Closing Rank": 630
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3523,
    "Closing Rank": 4019
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5519,
    "Closing Rank": 6111
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2061,
    "Closing Rank": 2178
  },
  {
    "Institute": "Indian Institute of Information Technology Lucknow",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3716,
    "Closing Rank": 3716
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24106,
    "Closing Rank": 37419
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20028,
    "Closing Rank": 39855
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 601,
    "Closing Rank": 1246
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6032,
    "Closing Rank": 6763
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6713,
    "Closing Rank": 7570
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 308,
    "Closing Rank": 308
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11172,
    "Closing Rank": 15972
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14252,
    "Closing Rank": 17907
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 519,
    "Closing Rank": 785
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2716,
    "Closing Rank": 9390
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6796,
    "Closing Rank": 9769
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 99,
    "Closing Rank": 99
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2789,
    "Closing Rank": 3974
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1812,
    "Closing Rank": 2087
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28213,
    "Closing Rank": 39077
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 37154,
    "Closing Rank": 41400
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 448,
    "Closing Rank": 990
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6641,
    "Closing Rank": 6996
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6913,
    "Closing Rank": 7668
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 223,
    "Closing Rank": 373
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12624,
    "Closing Rank": 16643
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17328,
    "Closing Rank": 18574
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1135,
    "Closing Rank": 1135
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8720,
    "Closing Rank": 9861
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8124,
    "Closing Rank": 10212
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4160,
    "Closing Rank": 4447
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3514,
    "Closing Rank": 3514
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11215,
    "Closing Rank": 41143
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41496,
    "Closing Rank": 43215
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1253,
    "Closing Rank": 1378
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7001,
    "Closing Rank": 7224
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6983,
    "Closing Rank": 7285
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14136,
    "Closing Rank": 16841
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18551,
    "Closing Rank": 18957
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 635,
    "Closing Rank": 635
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6091,
    "Closing Rank": 10252
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10261,
    "Closing Rank": 10883
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 144,
    "Closing Rank": 144
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2967,
    "Closing Rank": 4685
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3771,
    "Closing Rank": 3771
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Dharwad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 92,
    "Closing Rank": 92
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20771,
    "Closing Rank": 36999
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35334,
    "Closing Rank": 38027
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 578,
    "Closing Rank": 578
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5965,
    "Closing Rank": 6561
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6571,
    "Closing Rank": 6571
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12134,
    "Closing Rank": 14516
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13672,
    "Closing Rank": 13940
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 412,
    "Closing Rank": 412
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4717,
    "Closing Rank": 6190
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6661,
    "Closing Rank": 6661
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2155,
    "Closing Rank": 2934
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3498,
    "Closing Rank": 3498
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21428,
    "Closing Rank": 32761
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24883,
    "Closing Rank": 33001
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 491,
    "Closing Rank": 491
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5379,
    "Closing Rank": 5862
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5702,
    "Closing Rank": 5702
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9114,
    "Closing Rank": 13128
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11429,
    "Closing Rank": 12828
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 317,
    "Closing Rank": 317
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4291,
    "Closing Rank": 5596
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5148,
    "Closing Rank": 5148
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2046,
    "Closing Rank": 2305
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2129,
    "Closing Rank": 2129
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31320,
    "Closing Rank": 37958
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6205,
    "Closing Rank": 6378
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13283,
    "Closing Rank": 15367
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 546,
    "Closing Rank": 546
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6318,
    "Closing Rank": 6939
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1814,
    "Closing Rank": 1814
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40614,
    "Closing Rank": 42606
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2076,
    "Closing Rank": 2076
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6896,
    "Closing Rank": 7207
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14604,
    "Closing Rank": 16891
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7122,
    "Closing Rank": 7918
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3357,
    "Closing Rank": 3398
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33048,
    "Closing Rank": 41985
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 35056,
    "Closing Rank": 37555
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 926,
    "Closing Rank": 926
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6814,
    "Closing Rank": 7053
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7043,
    "Closing Rank": 7043
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13258,
    "Closing Rank": 16831
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13679,
    "Closing Rank": 13679
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7044,
    "Closing Rank": 7695
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7776,
    "Closing Rank": 7776
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2713,
    "Closing Rank": 3301
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39952,
    "Closing Rank": 50444
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8584,
    "Closing Rank": 8770
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14877,
    "Closing Rank": 18228
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6428,
    "Closing Rank": 9460
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3582,
    "Closing Rank": 3824
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42489,
    "Closing Rank": 49886
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 50261,
    "Closing Rank": 52652
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2884,
    "Closing Rank": 2884
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8147,
    "Closing Rank": 8530
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17006,
    "Closing Rank": 18149
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19787,
    "Closing Rank": 19787
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1477,
    "Closing Rank": 1477
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7583,
    "Closing Rank": 8903
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10907,
    "Closing Rank": 10907
  },
  {
    "Institute": "Indian Institute of Information Technology Design & Manufacturing Kurnool, Andhra Pradesh",
    "Academic Program Name": "Mechanical Engineering with specialization in Design and Manufacturing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3549,
    "Closing Rank": 3817
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19546,
    "Closing Rank": 35996
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19945,
    "Closing Rank": 40151
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1349,
    "Closing Rank": 2524
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1475,
    "Closing Rank": 1475
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5795,
    "Closing Rank": 6586
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6528,
    "Closing Rank": 7599
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 378,
    "Closing Rank": 378
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10312,
    "Closing Rank": 14629
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12122,
    "Closing Rank": 15875
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1207,
    "Closing Rank": 1509
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1136,
    "Closing Rank": 1136
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5635,
    "Closing Rank": 9967
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6953,
    "Closing Rank": 10470
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2355,
    "Closing Rank": 4217
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3526,
    "Closing Rank": 4454
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12989,
    "Closing Rank": 32727
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18954,
    "Closing Rank": 33437
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 961,
    "Closing Rank": 961
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5287,
    "Closing Rank": 5717
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6782,
    "Closing Rank": 6782
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9265,
    "Closing Rank": 12084
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11290,
    "Closing Rank": 12777
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 488,
    "Closing Rank": 488
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3545,
    "Closing Rank": 8046
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9185,
    "Closing Rank": 9891
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2806,
    "Closing Rank": 3731
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4576,
    "Closing Rank": 4576
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25771,
    "Closing Rank": 36976
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38818,
    "Closing Rank": 41306
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1597,
    "Closing Rank": 1597
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6057,
    "Closing Rank": 6670
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7650,
    "Closing Rank": 7650
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12242,
    "Closing Rank": 14983
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15371,
    "Closing Rank": 15932
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 931,
    "Closing Rank": 931
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6998,
    "Closing Rank": 9327
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9433,
    "Closing Rank": 10065
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3378,
    "Closing Rank": 4219
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4845,
    "Closing Rank": 4845
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19045,
    "Closing Rank": 39875
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34914,
    "Closing Rank": 40028
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2395,
    "Closing Rank": 2395
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6530,
    "Closing Rank": 6912
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7621,
    "Closing Rank": 7621
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11792,
    "Closing Rank": 15467
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15340,
    "Closing Rank": 16560
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1696,
    "Closing Rank": 1696
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9377,
    "Closing Rank": 10131
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9468,
    "Closing Rank": 10207
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3809,
    "Closing Rank": 4152
  },
  {
    "Institute": "Indian Institute of Information Technology(IIIT) Kottayam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4659,
    "Closing Rank": 4659
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20517,
    "Closing Rank": 32429
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1044,
    "Closing Rank": 1538
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5259,
    "Closing Rank": 5894
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 255,
    "Closing Rank": 255
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9170,
    "Closing Rank": 13690
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1377,
    "Closing Rank": 1588
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6142,
    "Closing Rank": 8439
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2581,
    "Closing Rank": 4172
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25552,
    "Closing Rank": 31336
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1514,
    "Closing Rank": 1514
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5092,
    "Closing Rank": 5465
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8903,
    "Closing Rank": 13354
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7471,
    "Closing Rank": 8131
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Computer Science and Engineering (with Specialization of Data Science and Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4267,
    "Closing Rank": 4382
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30138,
    "Closing Rank": 40194
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2141,
    "Closing Rank": 2651
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6770,
    "Closing Rank": 7045
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11678,
    "Closing Rank": 16809
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1355,
    "Closing Rank": 1355
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9064,
    "Closing Rank": 10111
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4567,
    "Closing Rank": 4995
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35309,
    "Closing Rank": 39295
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3250,
    "Closing Rank": 3250
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6893,
    "Closing Rank": 7047
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15263,
    "Closing Rank": 16460
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1662,
    "Closing Rank": 1662
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7220,
    "Closing Rank": 9990
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3404,
    "Closing Rank": 4691
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17234,
    "Closing Rank": 27356
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 830,
    "Closing Rank": 914
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4340,
    "Closing Rank": 5189
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 165,
    "Closing Rank": 165
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7541,
    "Closing Rank": 11755
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 469,
    "Closing Rank": 620
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3999,
    "Closing Rank": 8228
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 211,
    "Closing Rank": 211
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2917,
    "Closing Rank": 3711
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18517,
    "Closing Rank": 27105
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 986,
    "Closing Rank": 986
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4412,
    "Closing Rank": 4984
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7990,
    "Closing Rank": 11474
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 510,
    "Closing Rank": 510
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4086,
    "Closing Rank": 7396
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2412,
    "Closing Rank": 3381
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Data Science and Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20112,
    "Closing Rank": 28763
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Data Science and Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1008,
    "Closing Rank": 1008
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Data Science and Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5271,
    "Closing Rank": 5560
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Data Science and Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8121,
    "Closing Rank": 12500
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Data Science and Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 741,
    "Closing Rank": 741
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Data Science and Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4075,
    "Closing Rank": 8418
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Data Science and Analytics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2759,
    "Closing Rank": 3395
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23181,
    "Closing Rank": 30403
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1022,
    "Closing Rank": 1070
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5014,
    "Closing Rank": 5672
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10565,
    "Closing Rank": 13132
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 987,
    "Closing Rank": 987
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6209,
    "Closing Rank": 8541
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Computer Science Engineering (Human Computer lnteraction and Gaming Technology) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3927,
    "Closing Rank": 4174
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27541,
    "Closing Rank": 37530
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 559,
    "Closing Rank": 2341
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6084,
    "Closing Rank": 6658
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 383,
    "Closing Rank": 383
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10606,
    "Closing Rank": 15792
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1694,
    "Closing Rank": 1808
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8190,
    "Closing Rank": 9718
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3708,
    "Closing Rank": 4706
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (Internet of Things) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22824,
    "Closing Rank": 35608
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (Internet of Things) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1558,
    "Closing Rank": 1558
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (Internet of Things) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6196,
    "Closing Rank": 6585
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (Internet of Things) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12080,
    "Closing Rank": 15472
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (Internet of Things) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1123,
    "Closing Rank": 1607
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (Internet of Things) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4463,
    "Closing Rank": 9588
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Nagpur",
    "Academic Program Name": "Electronics and Communication Engineering (Internet of Things) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 536,
    "Closing Rank": 4628
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9557,
    "Closing Rank": 18252
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18487,
    "Closing Rank": 26196
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 460,
    "Closing Rank": 856
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 396,
    "Closing Rank": 396
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2859,
    "Closing Rank": 3401
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4219,
    "Closing Rank": 4864
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 247,
    "Closing Rank": 247
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4909,
    "Closing Rank": 8377
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8888,
    "Closing Rank": 12521
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 665,
    "Closing Rank": 775
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 454,
    "Closing Rank": 454
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2845,
    "Closing Rank": 5476
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4061,
    "Closing Rank": 6155
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93,
    "Closing Rank": 129
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2019,
    "Closing Rank": 3030
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 1906,
    "Closing Rank": 3331
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17293,
    "Closing Rank": 23592
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 26570,
    "Closing Rank": 30741
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 973,
    "Closing Rank": 1068
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3844,
    "Closing Rank": 4365
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5155,
    "Closing Rank": 5572
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8044,
    "Closing Rank": 11092
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13133,
    "Closing Rank": 14755
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 925,
    "Closing Rank": 925
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5558,
    "Closing Rank": 7873
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8403,
    "Closing Rank": 8827
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2587,
    "Closing Rank": 3346
  },
  {
    "Institute": "Indian Institute of Information Technology (IIIT) Pune",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3394,
    "Closing Rank": 3394
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24375,
    "Closing Rank": 39481
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32777,
    "Closing Rank": 41440
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6352,
    "Closing Rank": 6738
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7432,
    "Closing Rank": 7698
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11807,
    "Closing Rank": 15743
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13124,
    "Closing Rank": 17805
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5416,
    "Closing Rank": 9116
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8195,
    "Closing Rank": 9129
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3542,
    "Closing Rank": 4956
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4662,
    "Closing Rank": 4662
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37978,
    "Closing Rank": 42488
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42934,
    "Closing Rank": 43392
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7080,
    "Closing Rank": 7193
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8638,
    "Closing Rank": 8638
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16016,
    "Closing Rank": 17203
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16492,
    "Closing Rank": 16492
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9438,
    "Closing Rank": 10168
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10406,
    "Closing Rank": 11219
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4516,
    "Closing Rank": 5101
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5089,
    "Closing Rank": 5089
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22083,
    "Closing Rank": 39975
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6423,
    "Closing Rank": 6423
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7770,
    "Closing Rank": 7770
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15752,
    "Closing Rank": 16801
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18225,
    "Closing Rank": 18225
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6856,
    "Closing Rank": 10243
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4652,
    "Closing Rank": 5028
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5003,
    "Closing Rank": 5003
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42025,
    "Closing Rank": 47663
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45233,
    "Closing Rank": 48683
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7780,
    "Closing Rank": 7926
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8329,
    "Closing Rank": 8329
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17081,
    "Closing Rank": 18110
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20225,
    "Closing Rank": 21609
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10318,
    "Closing Rank": 10529
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11213,
    "Closing Rank": 11213
  },
  {
    "Institute": "Indian Institute of Information Technology Bhagalpur",
    "Academic Program Name": "Mechatronics and Automation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4027,
    "Closing Rank": 5194
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15159,
    "Closing Rank": 23999
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1111,
    "Closing Rank": 1581
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3789,
    "Closing Rank": 4572
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7232,
    "Closing Rank": 10997
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 725,
    "Closing Rank": 755
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4374,
    "Closing Rank": 6860
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3159,
    "Closing Rank": 3841
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Artificial Intelligence) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11406,
    "Closing Rank": 23637
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Artificial Intelligence) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1920,
    "Closing Rank": 1920
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Artificial Intelligence) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3704,
    "Closing Rank": 3784
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Artificial Intelligence) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 269,
    "Closing Rank": 269
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Artificial Intelligence) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7573,
    "Closing Rank": 10710
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Artificial Intelligence) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5420,
    "Closing Rank": 5954
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Artificial Intelligence) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3068,
    "Closing Rank": 3097
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Physical System) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24564,
    "Closing Rank": 30659
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Physical System) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4947,
    "Closing Rank": 5121
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Physical System) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11484,
    "Closing Rank": 12626
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Physical System) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5646,
    "Closing Rank": 7342
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Physical System) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3915,
    "Closing Rank": 3996
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16823,
    "Closing Rank": 27858
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4527,
    "Closing Rank": 4877
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8315,
    "Closing Rank": 11432
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 983,
    "Closing Rank": 983
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6416,
    "Closing Rank": 6900
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Cyber Security) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3759,
    "Closing Rank": 3997
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12196,
    "Closing Rank": 24335
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 903,
    "Closing Rank": 903
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3876,
    "Closing Rank": 4749
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7241,
    "Closing Rank": 10496
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3604,
    "Closing Rank": 6122
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Computer Science and Engineering (Data Science) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2612,
    "Closing Rank": 2816
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20535,
    "Closing Rank": 35355
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2648,
    "Closing Rank": 2844
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5721,
    "Closing Rank": 6367
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10489,
    "Closing Rank": 14641
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7232,
    "Closing Rank": 9202
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2766,
    "Closing Rank": 4749
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15057,
    "Closing Rank": 30065
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1531,
    "Closing Rank": 1726
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4800,
    "Closing Rank": 5347
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9161,
    "Closing Rank": 12751
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 999,
    "Closing Rank": 1648
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6198,
    "Closing Rank": 8371
  },
  {
    "Institute": "Indian Institute of Information Technology Bhopal",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2688,
    "Closing Rank": 4354
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12117,
    "Closing Rank": 25108
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31653,
    "Closing Rank": 33933
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 971,
    "Closing Rank": 1644
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4192,
    "Closing Rank": 4627
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5445,
    "Closing Rank": 5947
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8876,
    "Closing Rank": 11302
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13447,
    "Closing Rank": 15434
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 846,
    "Closing Rank": 846
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3175,
    "Closing Rank": 7837
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8026,
    "Closing Rank": 8505
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2114,
    "Closing Rank": 3112
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3605,
    "Closing Rank": 3605
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22702,
    "Closing Rank": 34263
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 32016,
    "Closing Rank": 35609
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2722,
    "Closing Rank": 2811
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5827,
    "Closing Rank": 6391
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7177,
    "Closing Rank": 7363
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13335,
    "Closing Rank": 15703
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15751,
    "Closing Rank": 16798
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1608,
    "Closing Rank": 1608
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5294,
    "Closing Rank": 8904
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9166,
    "Closing Rank": 9986
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2721,
    "Closing Rank": 3755
  },
  {
    "Institute": "Indian Institute of Information Technology Surat",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4558,
    "Closing Rank": 4558
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23898,
    "Closing Rank": 39356
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 30606,
    "Closing Rank": 44100
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6602,
    "Closing Rank": 6856
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7848,
    "Closing Rank": 7848
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12044,
    "Closing Rank": 16297
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 18037,
    "Closing Rank": 19570
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4576,
    "Closing Rank": 9014
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9794,
    "Closing Rank": 11310
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3557,
    "Closing Rank": 4278
  },
  {
    "Institute": "Indian Institute of Information Technology, Agartala",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4931,
    "Closing Rank": 4931
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15185,
    "Closing Rank": 37788
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 40191,
    "Closing Rank": 42319
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1719,
    "Closing Rank": 1738
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6174,
    "Closing Rank": 6747
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6840,
    "Closing Rank": 7471
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11531,
    "Closing Rank": 15884
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15983,
    "Closing Rank": 17427
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1388,
    "Closing Rank": 1388
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6707,
    "Closing Rank": 9414
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6690,
    "Closing Rank": 6902
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3306,
    "Closing Rank": 3896
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Artificial Intelligence and Data Science (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 3727,
    "Closing Rank": 3727
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21174,
    "Closing Rank": 34282
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 31894,
    "Closing Rank": 38721
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1090,
    "Closing Rank": 1090
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5514,
    "Closing Rank": 5934
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6233,
    "Closing Rank": 6273
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 230,
    "Closing Rank": 230
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11332,
    "Closing Rank": 14422
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12962,
    "Closing Rank": 15948
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 506,
    "Closing Rank": 506
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4887,
    "Closing Rank": 9130
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8342,
    "Closing Rank": 8823
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1954,
    "Closing Rank": 3696
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2997,
    "Closing Rank": 2997
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25874,
    "Closing Rank": 39100
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34619,
    "Closing Rank": 41131
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1431,
    "Closing Rank": 1431
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6727,
    "Closing Rank": 6986
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7666,
    "Closing Rank": 7666
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12307,
    "Closing Rank": 16256
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16693,
    "Closing Rank": 18537
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8333,
    "Closing Rank": 10071
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10660,
    "Closing Rank": 11471
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3798,
    "Closing Rank": 4460
  },
  {
    "Institute": "Indian institute of information technology, Raichur, Karnataka",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4854,
    "Closing Rank": 4854
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17310,
    "Closing Rank": 32396
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34583,
    "Closing Rank": 38839
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1924,
    "Closing Rank": 2211
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 2221,
    "Closing Rank": 2221
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5191,
    "Closing Rank": 5983
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 6850,
    "Closing Rank": 7291
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11851,
    "Closing Rank": 15207
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16116,
    "Closing Rank": 18963
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1077,
    "Closing Rank": 1077
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4212,
    "Closing Rank": 9163
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8542,
    "Closing Rank": 11061
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3469,
    "Closing Rank": 4629
  },
  {
    "Institute": "Indian Institute of Information Technology, Vadodara International Campus Diu (IIITVICD)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4099,
    "Closing Rank": 4099
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 67259,
    "Closing Rank": 73418
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 73632,
    "Closing Rank": 73632
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12126,
    "Closing Rank": 14062
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23145,
    "Closing Rank": 27706
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28093,
    "Closing Rank": 28093
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10347,
    "Closing Rank": 12598
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5529,
    "Closing Rank": 5656
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 129172,
    "Closing Rank": 251170
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 134705,
    "Closing Rank": 134705
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41077,
    "Closing Rank": 42335
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 88617,
    "Closing Rank": 126820
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 97172,
    "Closing Rank": 97172
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21045,
    "Closing Rank": 26400
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20184,
    "Closing Rank": 20184
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6721,
    "Closing Rank": 7509
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43020,
    "Closing Rank": 50836
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45992,
    "Closing Rank": 45992
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3213,
    "Closing Rank": 3213
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8558,
    "Closing Rank": 8667
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17774,
    "Closing Rank": 19161
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16318,
    "Closing Rank": 16318
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8748,
    "Closing Rank": 10422
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 948,
    "Closing Rank": 3587
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 71669,
    "Closing Rank": 97431
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 89527,
    "Closing Rank": 89527
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3505,
    "Closing Rank": 3505
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18955,
    "Closing Rank": 20222
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40817,
    "Closing Rank": 54011
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 68589,
    "Closing Rank": 68589
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11271,
    "Closing Rank": 16105
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16723,
    "Closing Rank": 16723
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4280,
    "Closing Rank": 4500
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51079,
    "Closing Rank": 58657
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56760,
    "Closing Rank": 56760
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9699,
    "Closing Rank": 9849
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19277,
    "Closing Rank": 20863
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23076,
    "Closing Rank": 23076
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10231,
    "Closing Rank": 10900
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3662,
    "Closing Rank": 4260
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 95469,
    "Closing Rank": 134701
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 114979,
    "Closing Rank": 114979
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26117,
    "Closing Rank": 27284
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55437,
    "Closing Rank": 72701
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 79757,
    "Closing Rank": 79757
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16927,
    "Closing Rank": 19772
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 17430,
    "Closing Rank": 17430
  },
  {
    "Institute": "Assam University, Silchar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5944,
    "Closing Rank": 6665
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 944,
    "Closing Rank": 1667
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 665,
    "Closing Rank": 678
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 597,
    "Closing Rank": 1085
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 351,
    "Closing Rank": 431
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 169,
    "Closing Rank": 229
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1948,
    "Closing Rank": 9499
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3588,
    "Closing Rank": 3677
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15530,
    "Closing Rank": 19251
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24376,
    "Closing Rank": 26589
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1263,
    "Closing Rank": 1263
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3116,
    "Closing Rank": 3699
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8515,
    "Closing Rank": 9552
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3996,
    "Closing Rank": 5803
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2982,
    "Closing Rank": 3401
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30408,
    "Closing Rank": 35711
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42917,
    "Closing Rank": 50188
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8190,
    "Closing Rank": 8301
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7782,
    "Closing Rank": 11559
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7462,
    "Closing Rank": 8510
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55039,
    "Closing Rank": 57068
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 58078,
    "Closing Rank": 58078
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4107,
    "Closing Rank": 4107
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9618,
    "Closing Rank": 9637
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18082,
    "Closing Rank": 19723
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9545,
    "Closing Rank": 11859
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5217,
    "Closing Rank": 5217
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 82385,
    "Closing Rank": 91376
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 75883,
    "Closing Rank": 103780
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17978,
    "Closing Rank": 21535
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18066,
    "Closing Rank": 21374
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8782,
    "Closing Rank": 23727
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39389,
    "Closing Rank": 49909
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 44873,
    "Closing Rank": 55026
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3436,
    "Closing Rank": 3436
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8164,
    "Closing Rank": 8624
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15871,
    "Closing Rank": 19536
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5195,
    "Closing Rank": 11282
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5115,
    "Closing Rank": 5664
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55520,
    "Closing Rank": 82308
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 74966,
    "Closing Rank": 88813
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14447,
    "Closing Rank": 17013
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19733,
    "Closing Rank": 23663
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10592,
    "Closing Rank": 20337
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24167,
    "Closing Rank": 53237
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 57562,
    "Closing Rank": 58887
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4021,
    "Closing Rank": 4021
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8744,
    "Closing Rank": 8927
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17793,
    "Closing Rank": 19326
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8960,
    "Closing Rank": 10729
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3225,
    "Closing Rank": 4233
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66886,
    "Closing Rank": 75669
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 87312,
    "Closing Rank": 103051
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13333,
    "Closing Rank": 13358
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18397,
    "Closing Rank": 19045
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5617,
    "Closing Rank": 13668
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10209,
    "Closing Rank": 18030
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 16893,
    "Closing Rank": 23503
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 880,
    "Closing Rank": 1050
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2812,
    "Closing Rank": 3374
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5218,
    "Closing Rank": 8471
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1119,
    "Closing Rank": 1119
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3233,
    "Closing Rank": 5582
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1587,
    "Closing Rank": 2666
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18357,
    "Closing Rank": 30267
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27403,
    "Closing Rank": 41767
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5048,
    "Closing Rank": 7644
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6039,
    "Closing Rank": 11295
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2682,
    "Closing Rank": 7292
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24787,
    "Closing Rank": 31361
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 38006,
    "Closing Rank": 38276
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2617,
    "Closing Rank": 2617
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5890,
    "Closing Rank": 6123
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13841,
    "Closing Rank": 14718
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9347,
    "Closing Rank": 9786
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4639,
    "Closing Rank": 4968
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49318,
    "Closing Rank": 53882
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43789,
    "Closing Rank": 65415
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9993,
    "Closing Rank": 10104
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13787,
    "Closing Rank": 17040
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9444,
    "Closing Rank": 15107
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19878,
    "Closing Rank": 27924
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25185,
    "Closing Rank": 34915
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1265,
    "Closing Rank": 1347
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4589,
    "Closing Rank": 5474
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9020,
    "Closing Rank": 13424
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5697,
    "Closing Rank": 9079
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3732,
    "Closing Rank": 4734
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31805,
    "Closing Rank": 50935
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 47572,
    "Closing Rank": 59919
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8406,
    "Closing Rank": 9640
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10893,
    "Closing Rank": 16922
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5264,
    "Closing Rank": 11639
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52599,
    "Closing Rank": 59904
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 62176,
    "Closing Rank": 62176
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3005,
    "Closing Rank": 3005
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9919,
    "Closing Rank": 10078
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19816,
    "Closing Rank": 20906
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11315,
    "Closing Rank": 12189
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4694,
    "Closing Rank": 4694
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 96783,
    "Closing Rank": 106423
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 122392,
    "Closing Rank": 122465
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20503,
    "Closing Rank": 24026
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28013,
    "Closing Rank": 28023
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24601,
    "Closing Rank": 32498
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22223,
    "Closing Rank": 31152
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 34930,
    "Closing Rank": 38704
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5400,
    "Closing Rank": 6806
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11706,
    "Closing Rank": 14854
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8737,
    "Closing Rank": 10330
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4151,
    "Closing Rank": 5214
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 49445,
    "Closing Rank": 65629
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51690,
    "Closing Rank": 65932
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10745,
    "Closing Rank": 12558
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12272,
    "Closing Rank": 18705
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mathematics and Computing (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11504,
    "Closing Rank": 18958
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31389,
    "Closing Rank": 43489
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 43915,
    "Closing Rank": 53467
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7332,
    "Closing Rank": 8057
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13840,
    "Closing Rank": 18290
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9380,
    "Closing Rank": 10594
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5119,
    "Closing Rank": 5597
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54254,
    "Closing Rank": 66709
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 78404,
    "Closing Rank": 87225
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10935,
    "Closing Rank": 13321
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15605,
    "Closing Rank": 19036
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11043,
    "Closing Rank": 21703
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47152,
    "Closing Rank": 55470
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59109,
    "Closing Rank": 59109
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9268,
    "Closing Rank": 9690
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19924,
    "Closing Rank": 20661
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4837,
    "Closing Rank": 10899
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4389,
    "Closing Rank": 4389
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70351,
    "Closing Rank": 102547
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 99214,
    "Closing Rank": 110795
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23484,
    "Closing Rank": 24483
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25447,
    "Closing Rank": 25447
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46644,
    "Closing Rank": 55546
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56611,
    "Closing Rank": 64083
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3666,
    "Closing Rank": 3666
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9139,
    "Closing Rank": 9529
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18730,
    "Closing Rank": 20374
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11402,
    "Closing Rank": 12042
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5219,
    "Closing Rank": 6182
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 67695,
    "Closing Rank": 94141
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 89481,
    "Closing Rank": 104699
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19217,
    "Closing Rank": 19800
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22024,
    "Closing Rank": 28086
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36220,
    "Closing Rank": 41337
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45399,
    "Closing Rank": 45399
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6937,
    "Closing Rank": 6951
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13669,
    "Closing Rank": 16456
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9405,
    "Closing Rank": 10942
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3795,
    "Closing Rank": 3795
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 65278,
    "Closing Rank": 70085
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 80691,
    "Closing Rank": 90331
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16109,
    "Closing Rank": 16723
  },
  {
    "Institute": "Birla Institute of Technology, Mesra, Ranchi",
    "Academic Program Name": "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25108,
    "Closing Rank": 25108
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40539,
    "Closing Rank": 72273
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3608,
    "Closing Rank": 3608
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11838,
    "Closing Rank": 12821
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22330,
    "Closing Rank": 26209
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8113,
    "Closing Rank": 12824
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3158,
    "Closing Rank": 6348
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56727,
    "Closing Rank": 78861
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12952,
    "Closing Rank": 14043
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25335,
    "Closing Rank": 30414
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8929,
    "Closing Rank": 13249
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4434,
    "Closing Rank": 6422
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56633,
    "Closing Rank": 82972
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3736,
    "Closing Rank": 3736
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13708,
    "Closing Rank": 14472
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26521,
    "Closing Rank": 29739
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9357,
    "Closing Rank": 14342
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6557,
    "Closing Rank": 6933
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66298,
    "Closing Rank": 97236
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16347,
    "Closing Rank": 17039
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31165,
    "Closing Rank": 36292
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13360,
    "Closing Rank": 16043
  },
  {
    "Institute": "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6414,
    "Closing Rank": 7283
  },
  {
    "Institute": "Indian Institute of Carpet Technology, Bhadohi",
    "Academic Program Name": "Carpet and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 72683,
    "Closing Rank": 108746
  },
  {
    "Institute": "Indian Institute of Carpet Technology, Bhadohi",
    "Academic Program Name": "Carpet and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18011,
    "Closing Rank": 18096
  },
  {
    "Institute": "Indian Institute of Carpet Technology, Bhadohi",
    "Academic Program Name": "Carpet and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37277,
    "Closing Rank": 37808
  },
  {
    "Institute": "Indian Institute of Carpet Technology, Bhadohi",
    "Academic Program Name": "Carpet and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13436,
    "Closing Rank": 16280
  },
  {
    "Institute": "Indian Institute of Carpet Technology, Bhadohi",
    "Academic Program Name": "Carpet and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6282,
    "Closing Rank": 6282
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54783,
    "Closing Rank": 71301
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2842,
    "Closing Rank": 2842
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11709,
    "Closing Rank": 11853
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22365,
    "Closing Rank": 24082
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10582,
    "Closing Rank": 12726
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4077,
    "Closing Rank": 4607
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27039,
    "Closing Rank": 40078
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6644,
    "Closing Rank": 7227
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13553,
    "Closing Rank": 18104
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7676,
    "Closing Rank": 9797
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3028,
    "Closing Rank": 4045
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41366,
    "Closing Rank": 52503
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2328,
    "Closing Rank": 2328
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8806,
    "Closing Rank": 9424
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18654,
    "Closing Rank": 21526
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10132,
    "Closing Rank": 11659
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5403,
    "Closing Rank": 5778
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47266,
    "Closing Rank": 60156
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2621,
    "Closing Rank": 2621
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10216,
    "Closing Rank": 10892
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18231,
    "Closing Rank": 23192
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11503,
    "Closing Rank": 12948
  },
  {
    "Institute": "Institute of Infrastructure, Technology, Research and Management-Ahmedabad",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5180,
    "Closing Rank": 5847
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59865,
    "Closing Rank": 98991
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16483,
    "Closing Rank": 17382
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31859,
    "Closing Rank": 36869
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14204,
    "Closing Rank": 16112
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6547,
    "Closing Rank": 7674
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64699,
    "Closing Rank": 100745
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16734,
    "Closing Rank": 17639
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32308,
    "Closing Rank": 35758
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12461,
    "Closing Rank": 14462
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4771,
    "Closing Rank": 6656
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47648,
    "Closing Rank": 65174
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2292,
    "Closing Rank": 3282
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10801,
    "Closing Rank": 11383
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20008,
    "Closing Rank": 23226
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9513,
    "Closing Rank": 12256
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4010,
    "Closing Rank": 5891
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56318,
    "Closing Rank": 90411
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14998,
    "Closing Rank": 15540
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28671,
    "Closing Rank": 31338
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13383,
    "Closing Rank": 14823
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6827,
    "Closing Rank": 7555
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58573,
    "Closing Rank": 79290
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3374,
    "Closing Rank": 3679
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13014,
    "Closing Rank": 13718
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24778,
    "Closing Rank": 28610
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11053,
    "Closing Rank": 13468
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4714,
    "Closing Rank": 6361
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63551,
    "Closing Rank": 110158
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18359,
    "Closing Rank": 18823
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35776,
    "Closing Rank": 38480
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15486,
    "Closing Rank": 16913
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Industrial and Production Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6817,
    "Closing Rank": 7944
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 54203,
    "Closing Rank": 77321
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12837,
    "Closing Rank": 13323
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23967,
    "Closing Rank": 28430
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12514,
    "Closing Rank": 14118
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5378,
    "Closing Rank": 6869
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62854,
    "Closing Rank": 96985
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16096,
    "Closing Rank": 16709
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31094,
    "Closing Rank": 35162
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12875,
    "Closing Rank": 15461
  },
  {
    "Institute": "School of Studies of Engineering and Technology, Guru Ghasidas Vishwavidyalaya, Bilaspur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7019,
    "Closing Rank": 7416
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24207,
    "Closing Rank": 43997
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1794,
    "Closing Rank": 1794
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7440,
    "Closing Rank": 8061
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13596,
    "Closing Rank": 18590
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1532,
    "Closing Rank": 1532
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3137,
    "Closing Rank": 8509
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Computer Science and Engineering with Major in Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4805,
    "Closing Rank": 5022
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46854,
    "Closing Rank": 56755
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2106,
    "Closing Rank": 2106
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9383,
    "Closing Rank": 9750
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19121,
    "Closing Rank": 21419
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8969,
    "Closing Rank": 10721
  },
  {
    "Institute": "J.K. Institute of Applied Physics & Technology, Department of Electronics & Communication, University of Allahabad- Allahabad",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1913,
    "Closing Rank": 5357
  },
  {
    "Institute": "National Institute of Electronics and Information Technology, Aurangabad (Maharashtra)",
    "Academic Program Name": "Electronic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35716,
    "Closing Rank": 54579
  },
  {
    "Institute": "National Institute of Electronics and Information Technology, Aurangabad (Maharashtra)",
    "Academic Program Name": "Electronic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2085,
    "Closing Rank": 3234
  },
  {
    "Institute": "National Institute of Electronics and Information Technology, Aurangabad (Maharashtra)",
    "Academic Program Name": "Electronic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9278,
    "Closing Rank": 10029
  },
  {
    "Institute": "National Institute of Electronics and Information Technology, Aurangabad (Maharashtra)",
    "Academic Program Name": "Electronic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17855,
    "Closing Rank": 21666
  },
  {
    "Institute": "National Institute of Electronics and Information Technology, Aurangabad (Maharashtra)",
    "Academic Program Name": "Electronic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10043,
    "Closing Rank": 11161
  },
  {
    "Institute": "National Institute of Electronics and Information Technology, Aurangabad (Maharashtra)",
    "Academic Program Name": "Electronic Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5002,
    "Closing Rank": 5561
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42537,
    "Closing Rank": 50906
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 46291,
    "Closing Rank": 51025
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1351,
    "Closing Rank": 1351
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8634,
    "Closing Rank": 9053
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 8506,
    "Closing Rank": 8506
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15888,
    "Closing Rank": 20121
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 19856,
    "Closing Rank": 22226
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8939,
    "Closing Rank": 10904
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 10772,
    "Closing Rank": 10884
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3597,
    "Closing Rank": 4701
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4547,
    "Closing Rank": 4547
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52106,
    "Closing Rank": 63321
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 64662,
    "Closing Rank": 73509
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10286,
    "Closing Rank": 11578
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12629,
    "Closing Rank": 12629
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19629,
    "Closing Rank": 22700
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 24484,
    "Closing Rank": 29050
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8805,
    "Closing Rank": 13189
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 12541,
    "Closing Rank": 13278
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4342,
    "Closing Rank": 5454
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5363,
    "Closing Rank": 5363
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53712,
    "Closing Rank": 76992
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 67067,
    "Closing Rank": 80113
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13202,
    "Closing Rank": 14360
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13284,
    "Closing Rank": 13284
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24645,
    "Closing Rank": 31555
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27188,
    "Closing Rank": 31896
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9839,
    "Closing Rank": 14440
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13813,
    "Closing Rank": 13971
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5195,
    "Closing Rank": 6951
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Metallurgy and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 7368,
    "Closing Rank": 7368
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47915,
    "Closing Rank": 77460
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 71986,
    "Closing Rank": 79577
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12910,
    "Closing Rank": 14739
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14256,
    "Closing Rank": 14256
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24087,
    "Closing Rank": 31537
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 28428,
    "Closing Rank": 33284
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10898,
    "Closing Rank": 14601
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13215,
    "Closing Rank": 14899
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5917,
    "Closing Rank": 6362
  },
  {
    "Institute": "National Institute of Advanced Manufacturing Technology, Ranchi",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5616,
    "Closing Rank": 5616
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70784,
    "Closing Rank": 92779
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15592,
    "Closing Rank": 16301
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30065,
    "Closing Rank": 34720
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15137,
    "Closing Rank": 16343
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6301,
    "Closing Rank": 7594
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64811,
    "Closing Rank": 98731
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16641,
    "Closing Rank": 17395
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31784,
    "Closing Rank": 36031
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14821,
    "Closing Rank": 14977
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Chemistry (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6963,
    "Closing Rank": 7434
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58667,
    "Closing Rank": 81582
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14487,
    "Closing Rank": 14487
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28332,
    "Closing Rank": 28899
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15336,
    "Closing Rank": 15336
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4575,
    "Closing Rank": 4575
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43050,
    "Closing Rank": 58940
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1622,
    "Closing Rank": 1622
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9822,
    "Closing Rank": 10476
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17681,
    "Closing Rank": 21600
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10550,
    "Closing Rank": 11695
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4864,
    "Closing Rank": 6080
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50522,
    "Closing Rank": 79475
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13150,
    "Closing Rank": 13209
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24832,
    "Closing Rank": 27272
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12043,
    "Closing Rank": 14217
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5460,
    "Closing Rank": 6463
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50193,
    "Closing Rank": 63568
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2918,
    "Closing Rank": 2918
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11018,
    "Closing Rank": 11595
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21143,
    "Closing Rank": 24424
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11694,
    "Closing Rank": 12534
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4818,
    "Closing Rank": 5200
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75867,
    "Closing Rank": 110525
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18527,
    "Closing Rank": 18856
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37116,
    "Closing Rank": 38887
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16288,
    "Closing Rank": 16773
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7423,
    "Closing Rank": 8087
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 65002,
    "Closing Rank": 89448
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15608,
    "Closing Rank": 17293
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30469,
    "Closing Rank": 34915
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13456,
    "Closing Rank": 16253
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Instrumentation and Control Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7942,
    "Closing Rank": 8088
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63813,
    "Closing Rank": 90733
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15321,
    "Closing Rank": 15464
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33145,
    "Closing Rank": 36305
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14584,
    "Closing Rank": 15839
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mathematics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7819,
    "Closing Rank": 7819
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64651,
    "Closing Rank": 94248
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15776,
    "Closing Rank": 16509
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30196,
    "Closing Rank": 35033
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10598,
    "Closing Rank": 15829
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6186,
    "Closing Rank": 7307
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 69891,
    "Closing Rank": 85605
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17164,
    "Closing Rank": 17352
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28766,
    "Closing Rank": 36808
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13701,
    "Closing Rank": 15420
  },
  {
    "Institute": "Sant Longowal Institute of Engineering and Technology",
    "Academic Program Name": "Physics (5 Years, Integrated Master of Science)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6758,
    "Closing Rank": 7403
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1696,
    "Closing Rank": 1863
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 769,
    "Closing Rank": 799
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 332,
    "Closing Rank": 453
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 241,
    "Closing Rank": 245
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85431,
    "Closing Rank": 97624
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16241,
    "Closing Rank": 16406
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14313,
    "Closing Rank": 15550
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4221,
    "Closing Rank": 6172
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41249,
    "Closing Rank": 68816
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11343,
    "Closing Rank": 12743
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9992,
    "Closing Rank": 11551
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5652,
    "Closing Rank": 6637
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70045,
    "Closing Rank": 84299
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14553,
    "Closing Rank": 15028
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12783,
    "Closing Rank": 14314
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5513,
    "Closing Rank": 6756
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 67991,
    "Closing Rank": 79337
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14179,
    "Closing Rank": 14423
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14018,
    "Closing Rank": 14436
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6535,
    "Closing Rank": 7405
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60076,
    "Closing Rank": 71045
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12773,
    "Closing Rank": 12822
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12069,
    "Closing Rank": 12636
  },
  {
    "Institute": "Mizoram University, Aizawl",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7257,
    "Closing Rank": 7364
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Bachelor of Design (4 Years, Bachelor of Design)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 88329,
    "Closing Rank": 90552
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Bachelor of Design (4 Years, Bachelor of Design)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34528,
    "Closing Rank": 34528
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Bachelor of Design (4 Years, Bachelor of Design)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15416,
    "Closing Rank": 15416
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60430,
    "Closing Rank": 96305
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16425,
    "Closing Rank": 17752
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31444,
    "Closing Rank": 33852
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12164,
    "Closing Rank": 13855
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5102,
    "Closing Rank": 5299
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44577,
    "Closing Rank": 56762
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9977,
    "Closing Rank": 10102
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17030,
    "Closing Rank": 19796
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7317,
    "Closing Rank": 8680
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3737,
    "Closing Rank": 3737
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59765,
    "Closing Rank": 72639
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14298,
    "Closing Rank": 14341
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24635,
    "Closing Rank": 25801
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10409,
    "Closing Rank": 10867
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5879,
    "Closing Rank": 5879
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62983,
    "Closing Rank": 69923
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11913,
    "Closing Rank": 12809
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22655,
    "Closing Rank": 24113
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8740,
    "Closing Rank": 9926
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4773,
    "Closing Rank": 5346
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 61553,
    "Closing Rank": 109043
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18467,
    "Closing Rank": 18487
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37557,
    "Closing Rank": 38826
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13424,
    "Closing Rank": 15840
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5951,
    "Closing Rank": 6458
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 69214,
    "Closing Rank": 81609
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14911,
    "Closing Rank": 15256
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25520,
    "Closing Rank": 29747
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12037,
    "Closing Rank": 13314
  },
  {
    "Institute": "School of Engineering, Tezpur University, Napaam, Tezpur",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5422,
    "Closing Rank": 5688
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59,
    "Closing Rank": 754
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 144,
    "Closing Rank": 144
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 129,
    "Closing Rank": 613
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 287,
    "Closing Rank": 702
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36,
    "Closing Rank": 312
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16,
    "Closing Rank": 166
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 109,
    "Closing Rank": 1093
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 184,
    "Closing Rank": 314
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 527,
    "Closing Rank": 725
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 174,
    "Closing Rank": 359
  },
  {
    "Institute": "School of Planning & Architecture, Bhopal",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 74,
    "Closing Rank": 189
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3,
    "Closing Rank": 238
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1,
    "Closing Rank": 47
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27,
    "Closing Rank": 339
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 76,
    "Closing Rank": 380
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9,
    "Closing Rank": 172
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12,
    "Closing Rank": 48
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57,
    "Closing Rank": 435
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12,
    "Closing Rank": 12
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 194,
    "Closing Rank": 256
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 156,
    "Closing Rank": 594
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24,
    "Closing Rank": 153
  },
  {
    "Institute": "School of Planning & Architecture, New Delhi",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19,
    "Closing Rank": 120
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60,
    "Closing Rank": 798
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93,
    "Closing Rank": 118
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 148,
    "Closing Rank": 711
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 271,
    "Closing Rank": 682
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 68,
    "Closing Rank": 375
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 88,
    "Closing Rank": 196
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 226,
    "Closing Rank": 1039
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 113,
    "Closing Rank": 113
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 223,
    "Closing Rank": 330
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 347,
    "Closing Rank": 704
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 82,
    "Closing Rank": 357
  },
  {
    "Institute": "School of Planning & Architecture: Vijayawada",
    "Academic Program Name": "Planning (4 Years, Bachelor of Planning)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 129,
    "Closing Rank": 224
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1780,
    "Closing Rank": 1857
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 800,
    "Closing Rank": 800
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1095,
    "Closing Rank": 1113
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 458,
    "Closing Rank": 458
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Architecture (5 Years, Bachelor of Architecture)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 202,
    "Closing Rank": 202
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55211,
    "Closing Rank": 66299
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10982,
    "Closing Rank": 11677
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22795,
    "Closing Rank": 25775
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12589,
    "Closing Rank": 13163
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "B.Tech in Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6506,
    "Closing Rank": 6812
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60123,
    "Closing Rank": 88532
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15414,
    "Closing Rank": 16680
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29341,
    "Closing Rank": 33127
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13891,
    "Closing Rank": 15430
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Bio Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6246,
    "Closing Rank": 7299
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 74669,
    "Closing Rank": 96085
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16829,
    "Closing Rank": 17015
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30925,
    "Closing Rank": 33348
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14924,
    "Closing Rank": 15733
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6724,
    "Closing Rank": 6799
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40771,
    "Closing Rank": 61593
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10284,
    "Closing Rank": 10737
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19589,
    "Closing Rank": 22726
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9602,
    "Closing Rank": 11893
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4703,
    "Closing Rank": 5787
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58793,
    "Closing Rank": 81774
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13637,
    "Closing Rank": 13922
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27375,
    "Closing Rank": 29406
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13624,
    "Closing Rank": 14602
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6696,
    "Closing Rank": 6958
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58693,
    "Closing Rank": 75947
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12747,
    "Closing Rank": 13599
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24225,
    "Closing Rank": 27267
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11333,
    "Closing Rank": 14144
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6407,
    "Closing Rank": 7227
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 67920,
    "Closing Rank": 89898
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15344,
    "Closing Rank": 15517
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29944,
    "Closing Rank": 32534
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12901,
    "Closing Rank": 14896
  },
  {
    "Institute": "Shri Mata Vaishno Devi University, Katra, Jammu & Kashmir",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7446,
    "Closing Rank": 7583
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15769,
    "Closing Rank": 18080
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3076,
    "Closing Rank": 3489
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6083,
    "Closing Rank": 8384
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4402,
    "Closing Rank": 5728
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56,
    "Closing Rank": 56
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2500,
    "Closing Rank": 2821
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19420,
    "Closing Rank": 22017
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 832,
    "Closing Rank": 832
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3491,
    "Closing Rank": 3700
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8768,
    "Closing Rank": 9569
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3440,
    "Closing Rank": 6158
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Data Science and Artificial Intelligence (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3195,
    "Closing Rank": 3578
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22711,
    "Closing Rank": 31363
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2319,
    "Closing Rank": 2319
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5232,
    "Closing Rank": 5969
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9866,
    "Closing Rank": 13017
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7362,
    "Closing Rank": 9285
  },
  {
    "Institute": "International Institute of Information Technology, Naya Raipur",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3144,
    "Closing Rank": 4239
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15170,
    "Closing Rank": 33350
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 741,
    "Closing Rank": 741
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5824,
    "Closing Rank": 6581
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9332,
    "Closing Rank": 14417
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 563,
    "Closing Rank": 563
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3887,
    "Closing Rank": 6804
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Computer Science and Engineering (5 Years, Bachelor and Master of Technology (Dual Degree))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 780,
    "Closing Rank": 3103
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Materials Engineering (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47922,
    "Closing Rank": 67449
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Materials Engineering (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 438,
    "Closing Rank": 438
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Materials Engineering (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11697,
    "Closing Rank": 12482
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Materials Engineering (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20811,
    "Closing Rank": 24094
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Materials Engineering (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 810,
    "Closing Rank": 810
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Materials Engineering (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9638,
    "Closing Rank": 12127
  },
  {
    "Institute": "University of Hyderabad",
    "Academic Program Name": "Materials Engineering (5 Years, Integrated Master of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5048,
    "Closing Rank": 5347
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23988,
    "Closing Rank": 49123
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15318,
    "Closing Rank": 15318
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9875,
    "Closing Rank": 11723
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18381,
    "Closing Rank": 30665
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6643,
    "Closing Rank": 7119
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6221,
    "Closing Rank": 6562
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Aerospace Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3072,
    "Closing Rank": 3072
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38672,
    "Closing Rank": 80056
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21639,
    "Closing Rank": 67000
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15769,
    "Closing Rank": 25411
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33309,
    "Closing Rank": 51670
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8416,
    "Closing Rank": 9136
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6872,
    "Closing Rank": 10485
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2729,
    "Closing Rank": 3040
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5942,
    "Closing Rank": 17512
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 228,
    "Closing Rank": 757
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5473,
    "Closing Rank": 9313
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 156,
    "Closing Rank": 156
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1567,
    "Closing Rank": 4240
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3809,
    "Closing Rank": 10498
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 415,
    "Closing Rank": 740
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1879,
    "Closing Rank": 2267
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 811,
    "Closing Rank": 3754
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 664,
    "Closing Rank": 1189
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12134,
    "Closing Rank": 18408
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9620,
    "Closing Rank": 10083
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1833,
    "Closing Rank": 2079
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7865,
    "Closing Rank": 11603
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 275,
    "Closing Rank": 275
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2234,
    "Closing Rank": 2234
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1743,
    "Closing Rank": 2973
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2065,
    "Closing Rank": 2065
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18527,
    "Closing Rank": 21573
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3420,
    "Closing Rank": 10314
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3422,
    "Closing Rank": 5305
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11394,
    "Closing Rank": 13915
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 772,
    "Closing Rank": 772
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2401,
    "Closing Rank": 2514
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2939,
    "Closing Rank": 3827
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2270,
    "Closing Rank": 2270
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32087,
    "Closing Rank": 43039
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17723,
    "Closing Rank": 21553
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10351,
    "Closing Rank": 13386
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21231,
    "Closing Rank": 27230
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2214,
    "Closing Rank": 3465
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4545,
    "Closing Rank": 4915
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3968,
    "Closing Rank": 6556
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2698,
    "Closing Rank": 3621
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16734,
    "Closing Rank": 30744
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12535,
    "Closing Rank": 15050
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5600,
    "Closing Rank": 9451
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14780,
    "Closing Rank": 19785
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1294,
    "Closing Rank": 1406
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3082,
    "Closing Rank": 3467
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3890,
    "Closing Rank": 5385
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2286,
    "Closing Rank": 3038
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24461,
    "Closing Rank": 32004
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16160,
    "Closing Rank": 16160
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9876,
    "Closing Rank": 10080
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18655,
    "Closing Rank": 21167
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3291,
    "Closing Rank": 3598
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5578,
    "Closing Rank": 5653
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Electronics Engineering (VLSI Design and Technology) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3170,
    "Closing Rank": 3170
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18756,
    "Closing Rank": 24451
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10478,
    "Closing Rank": 10478
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8200,
    "Closing Rank": 9452
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14098,
    "Closing Rank": 15800
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2852,
    "Closing Rank": 2863
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4160,
    "Closing Rank": 5548
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mathematics and Computing (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2478,
    "Closing Rank": 2478
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38352,
    "Closing Rank": 58061
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21404,
    "Closing Rank": 40381
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10343,
    "Closing Rank": 19994
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26661,
    "Closing Rank": 41055
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3446,
    "Closing Rank": 3569
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6780,
    "Closing Rank": 7476
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7029,
    "Closing Rank": 10105
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3313,
    "Closing Rank": 4054
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 53076,
    "Closing Rank": 85162
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15363,
    "Closing Rank": 27530
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31093,
    "Closing Rank": 54239
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9044,
    "Closing Rank": 9337
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9605,
    "Closing Rank": 11574
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Metallurgical and Materials Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4852,
    "Closing Rank": 4852
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43358,
    "Closing Rank": 68080
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20065,
    "Closing Rank": 24200
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44682,
    "Closing Rank": 48373
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3617,
    "Closing Rank": 3617
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7872,
    "Closing Rank": 8347
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10728,
    "Closing Rank": 11311
  },
  {
    "Institute": "Punjab Engineering College, Chandigarh",
    "Academic Program Name": "Production and Industrial Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4700,
    "Closing Rank": 4700
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9716,
    "Closing Rank": 27936
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 561,
    "Closing Rank": 561
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4747,
    "Closing Rank": 5606
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7591,
    "Closing Rank": 11255
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 354,
    "Closing Rank": 354
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3375,
    "Closing Rank": 5058
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1859,
    "Closing Rank": 2863
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28151,
    "Closing Rank": 35486
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 947,
    "Closing Rank": 947
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5801,
    "Closing Rank": 6953
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11884,
    "Closing Rank": 14360
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1241,
    "Closing Rank": 1241
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5673,
    "Closing Rank": 7300
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 215,
    "Closing Rank": 215
  },
  {
    "Institute": "Jawaharlal Nehru University, Delhi",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2774,
    "Closing Rank": 3349
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30873,
    "Closing Rank": 33451
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1199,
    "Closing Rank": 1199
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5571,
    "Closing Rank": 6228
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13423,
    "Closing Rank": 15505
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8029,
    "Closing Rank": 8942
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3687,
    "Closing Rank": 3820
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17692,
    "Closing Rank": 31001
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 739,
    "Closing Rank": 1145
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5103,
    "Closing Rank": 5401
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9232,
    "Closing Rank": 13241
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1142,
    "Closing Rank": 1142
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5599,
    "Closing Rank": 7847
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2488,
    "Closing Rank": 3504
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33820,
    "Closing Rank": 41584
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2172,
    "Closing Rank": 2172
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6824,
    "Closing Rank": 7145
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16077,
    "Closing Rank": 17238
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9492,
    "Closing Rank": 10152
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3944,
    "Closing Rank": 4791
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35866,
    "Closing Rank": 39955
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2996,
    "Closing Rank": 2996
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6995,
    "Closing Rank": 7049
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15003,
    "Closing Rank": 16850
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8321,
    "Closing Rank": 9386
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Electronics and Telecommunication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2106,
    "Closing Rank": 4587
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20238,
    "Closing Rank": 33712
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1456,
    "Closing Rank": 1456
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5923,
    "Closing Rank": 6164
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13269,
    "Closing Rank": 15432
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7999,
    "Closing Rank": 8719
  },
  {
    "Institute": "International Institute of Information Technology, Bhubaneswar",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4106,
    "Closing Rank": 4399
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63302,
    "Closing Rank": 63539
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 90158,
    "Closing Rank": 90158
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22824,
    "Closing Rank": 22824
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14040,
    "Closing Rank": 14040
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55057,
    "Closing Rank": 58033
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60710,
    "Closing Rank": 60710
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3060,
    "Closing Rank": 3060
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9586,
    "Closing Rank": 9586
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19530,
    "Closing Rank": 19530
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10963,
    "Closing Rank": 10963
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57634,
    "Closing Rank": 60026
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 75040,
    "Closing Rank": 75040
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23251,
    "Closing Rank": 23251
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 80446,
    "Closing Rank": 80446
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 104796,
    "Closing Rank": 104796
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33291,
    "Closing Rank": 33291
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15171,
    "Closing Rank": 15171
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 75537,
    "Closing Rank": 75537
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24946,
    "Closing Rank": 24946
  },
  {
    "Institute": "Central institute of Technology Kokrajar, Assam",
    "Academic Program Name": "Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5109,
    "Closing Rank": 5109
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57098,
    "Closing Rank": 57233
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11389,
    "Closing Rank": 11389
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20050,
    "Closing Rank": 20050
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Chemical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11604,
    "Closing Rank": 11604
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62229,
    "Closing Rank": 78491
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4119,
    "Closing Rank": 4119
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14047,
    "Closing Rank": 15048
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24859,
    "Closing Rank": 28744
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10506,
    "Closing Rank": 12740
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4456,
    "Closing Rank": 5462
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 262380,
    "Closing Rank": 534773
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 228701,
    "Closing Rank": 257334
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66435,
    "Closing Rank": 69036
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37156,
    "Closing Rank": 48768
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7927,
    "Closing Rank": 8485
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 382,
    "Closing Rank": 382
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15963,
    "Closing Rank": 19141
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7142,
    "Closing Rank": 10210
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5064,
    "Closing Rank": 5319
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 59941,
    "Closing Rank": 168596
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 62774,
    "Closing Rank": 72069
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40774,
    "Closing Rank": 41371
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56315,
    "Closing Rank": 56968
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9407,
    "Closing Rank": 9407
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18630,
    "Closing Rank": 18997
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9832,
    "Closing Rank": 9832
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 33884,
    "Closing Rank": 56014
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2139,
    "Closing Rank": 2139
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9283,
    "Closing Rank": 9711
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16907,
    "Closing Rank": 20173
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1405,
    "Closing Rank": 1405
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10260,
    "Closing Rank": 10928
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4976,
    "Closing Rank": 5515
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 149482,
    "Closing Rank": 227780
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 86234,
    "Closing Rank": 94433
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32271,
    "Closing Rank": 32378
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48436,
    "Closing Rank": 51227
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3601,
    "Closing Rank": 3641
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19916,
    "Closing Rank": 19916
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11035,
    "Closing Rank": 11035
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Electronics and Instrumentation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4424,
    "Closing Rank": 4424
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 41776,
    "Closing Rank": 44373
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1892,
    "Closing Rank": 2834
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18739,
    "Closing Rank": 18862
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9091,
    "Closing Rank": 9091
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50026,
    "Closing Rank": 69273
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11366,
    "Closing Rank": 12777
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21953,
    "Closing Rank": 24238
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8205,
    "Closing Rank": 12529
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5567,
    "Closing Rank": 5827
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 265489,
    "Closing Rank": 398302
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 149397,
    "Closing Rank": 158370
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38771,
    "Closing Rank": 58123
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47030,
    "Closing Rank": 60203
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9897,
    "Closing Rank": 9897
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20298,
    "Closing Rank": 20959
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11207,
    "Closing Rank": 12359
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4524,
    "Closing Rank": 5828
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 258325,
    "Closing Rank": 298097
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 109476,
    "Closing Rank": 116897
  },
  {
    "Institute": "Puducherry Technological University, Puducherry",
    "Academic Program Name": "Mechatronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32756,
    "Closing Rank": 32756
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 119059,
    "Closing Rank": 372966
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 69667,
    "Closing Rank": 79675
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 230452,
    "Closing Rank": 291815
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29434,
    "Closing Rank": 37297
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8859,
    "Closing Rank": 8859
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 65242,
    "Closing Rank": 96049
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16786,
    "Closing Rank": 17479
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31391,
    "Closing Rank": 33500
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12627,
    "Closing Rank": 14233
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Civil and Environmental Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6336,
    "Closing Rank": 7195
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 94996,
    "Closing Rank": 148356
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27123,
    "Closing Rank": 32399
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50536,
    "Closing Rank": 99021
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17962,
    "Closing Rank": 19292
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5700,
    "Closing Rank": 7564
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43934,
    "Closing Rank": 54826
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9000,
    "Closing Rank": 9047
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19212,
    "Closing Rank": 19872
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6726,
    "Closing Rank": 11389
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Computer Science Engineering (Artificial lntelligence and Machine Learning) (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5088,
    "Closing Rank": 5088
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 157168,
    "Closing Rank": 292228
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52127,
    "Closing Rank": 52710
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 143072,
    "Closing Rank": 169022
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19945,
    "Closing Rank": 21892
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6280,
    "Closing Rank": 7868
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 65050,
    "Closing Rank": 82676
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15047,
    "Closing Rank": 15047
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26526,
    "Closing Rank": 27391
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13559,
    "Closing Rank": 14808
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6208,
    "Closing Rank": 6208
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 449634,
    "Closing Rank": 527382
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 86290,
    "Closing Rank": 86290
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 259142,
    "Closing Rank": 298461
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40012,
    "Closing Rank": 41655
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11115,
    "Closing Rank": 11115
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85992,
    "Closing Rank": 105533
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17893,
    "Closing Rank": 17913
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 34960,
    "Closing Rank": 36947
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16644,
    "Closing Rank": 16811
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7768,
    "Closing Rank": 7768
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 193992,
    "Closing Rank": 356960
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70954,
    "Closing Rank": 72108
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 177860,
    "Closing Rank": 225386
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23520,
    "Closing Rank": 37145
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9445,
    "Closing Rank": 9445
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 66653,
    "Closing Rank": 83761
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14070,
    "Closing Rank": 14070
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29877,
    "Closing Rank": 30768
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9378,
    "Closing Rank": 14713
  },
  {
    "Institute": "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "OS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7471,
    "Closing Rank": 7471
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57727,
    "Closing Rank": 76993
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13667,
    "Closing Rank": 14017
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26413,
    "Closing Rank": 30875
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12611,
    "Closing Rank": 13878
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Bio Medical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5351,
    "Closing Rank": 5959
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32073,
    "Closing Rank": 49021
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3108,
    "Closing Rank": 3108
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8344,
    "Closing Rank": 8880
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16926,
    "Closing Rank": 19382
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7961,
    "Closing Rank": 10339
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3522,
    "Closing Rank": 4438
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51339,
    "Closing Rank": 57631
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9624,
    "Closing Rank": 9953
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18698,
    "Closing Rank": 21072
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10216,
    "Closing Rank": 11135
  },
  {
    "Institute": "Central University of Rajasthan, Rajasthan",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4627,
    "Closing Rank": 5121
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Kundli",
    "Academic Program Name": "Food Technology and Management (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58177,
    "Closing Rank": 106292
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Kundli",
    "Academic Program Name": "Food Technology and Management (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1226,
    "Closing Rank": 1226
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Kundli",
    "Academic Program Name": "Food Technology and Management (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18005,
    "Closing Rank": 18574
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Kundli",
    "Academic Program Name": "Food Technology and Management (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35066,
    "Closing Rank": 38117
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Kundli",
    "Academic Program Name": "Food Technology and Management (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10023,
    "Closing Rank": 16247
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Kundli",
    "Academic Program Name": "Food Technology and Management (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4463,
    "Closing Rank": 7767
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Thanjavur",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56416,
    "Closing Rank": 95412
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Thanjavur",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2490,
    "Closing Rank": 3466
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Thanjavur",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17909,
    "Closing Rank": 18352
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Thanjavur",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30626,
    "Closing Rank": 36784
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Thanjavur",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1554,
    "Closing Rank": 1554
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Thanjavur",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10537,
    "Closing Rank": 15077
  },
  {
    "Institute": "National Institute of Food Technology Entrepreneurship and Management, Thanjavur",
    "Academic Program Name": "Food Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4897,
    "Closing Rank": 7326
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12242,
    "Closing Rank": 12242
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Agricultural Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 29236,
    "Closing Rank": 29236
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12430,
    "Closing Rank": 12430
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25403,
    "Closing Rank": 25403
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50301,
    "Closing Rank": 55764
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10196,
    "Closing Rank": 11068
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11271,
    "Closing Rank": 11271
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5570,
    "Closing Rank": 5570
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10624,
    "Closing Rank": 10624
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12569,
    "Closing Rank": 12569
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 65512,
    "Closing Rank": 65512
  },
  {
    "Institute": "North Eastern Regional Institute of Science and Technology, Nirjuli-791109 (Itanagar),Arunachal Pradesh",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12191,
    "Closing Rank": 12191
  },
  {
    "Institute": "Indian Institute of Handloom Technology(IIHT), Varanasi",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58882,
    "Closing Rank": 102843
  },
  {
    "Institute": "Indian Institute of Handloom Technology(IIHT), Varanasi",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17586,
    "Closing Rank": 17998
  },
  {
    "Institute": "Indian Institute of Handloom Technology(IIHT), Varanasi",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36160,
    "Closing Rank": 37637
  },
  {
    "Institute": "Indian Institute of Handloom Technology(IIHT), Varanasi",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12951,
    "Closing Rank": 15494
  },
  {
    "Institute": "Indian Institute of Handloom Technology(IIHT), Varanasi",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6859,
    "Closing Rank": 7346
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 80755,
    "Closing Rank": 97484
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17530,
    "Closing Rank": 17795
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31676,
    "Closing Rank": 35867
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11171,
    "Closing Rank": 15786
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6680,
    "Closing Rank": 6690
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18805,
    "Closing Rank": 62904
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10925,
    "Closing Rank": 11105
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19204,
    "Closing Rank": 23266
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6976,
    "Closing Rank": 11875
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4779,
    "Closing Rank": 6026
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52422,
    "Closing Rank": 65702
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2796,
    "Closing Rank": 2796
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10928,
    "Closing Rank": 11625
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20195,
    "Closing Rank": 23292
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10568,
    "Closing Rank": 12172
  },
  {
    "Institute": "Chhattisgarh Swami Vivekanada Technical University, Bhilai (CSVTU Bhilai)",
    "Academic Program Name": "Computer Science Engineering (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5027,
    "Closing Rank": 5645
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28446,
    "Closing Rank": 56446
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4284,
    "Closing Rank": 4284
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10228,
    "Closing Rank": 10766
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18261,
    "Closing Rank": 21850
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10189,
    "Closing Rank": 11628
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4076,
    "Closing Rank": 6441
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64392,
    "Closing Rank": 76973
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18731,
    "Closing Rank": 20688
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "HS",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25460,
    "Closing Rank": 38259
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13218,
    "Closing Rank": 16608
  },
  {
    "Institute": "Institute of Chemical Technology, Mumbai: Indian Oil Odisha Campus, Bhubaneswar",
    "Academic Program Name": "Chemical Engineering (5 Years, Integrated Masters in Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8103,
    "Closing Rank": 9482
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63911,
    "Closing Rank": 94373
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17158,
    "Closing Rank": 17296
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 30977,
    "Closing Rank": 36592
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13151,
    "Closing Rank": 15601
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Biomedical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6583,
    "Closing Rank": 6702
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64491,
    "Closing Rank": 79951
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14601,
    "Closing Rank": 14610
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 26731,
    "Closing Rank": 29541
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9608,
    "Closing Rank": 12505
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5107,
    "Closing Rank": 5671
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 43720,
    "Closing Rank": 86765
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14382,
    "Closing Rank": 14421
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31158,
    "Closing Rank": 32653
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12587,
    "Closing Rank": 13847
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Energy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5831,
    "Closing Rank": 6123
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 42925,
    "Closing Rank": 74395
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3696,
    "Closing Rank": 3696
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13345,
    "Closing Rank": 13564
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23081,
    "Closing Rank": 25462
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12184,
    "Closing Rank": 12454
  },
  {
    "Institute": "North-Eastern Hill University, Shillong",
    "Academic Program Name": "Information Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4114,
    "Closing Rank": 5296
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 61741,
    "Closing Rank": 69094
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11329,
    "Closing Rank": 11580
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21657,
    "Closing Rank": 24411
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10270,
    "Closing Rank": 12049
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6209,
    "Closing Rank": 6353
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44623,
    "Closing Rank": 73171
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3483,
    "Closing Rank": 3483
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11979,
    "Closing Rank": 12181
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23108,
    "Closing Rank": 26252
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11703,
    "Closing Rank": 12554
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Computer Science and Engineering with specialization in Cyber Security (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6365,
    "Closing Rank": 6767
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58184,
    "Closing Rank": 78192
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4198,
    "Closing Rank": 4198
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13394,
    "Closing Rank": 13873
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24494,
    "Closing Rank": 27925
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12903,
    "Closing Rank": 14012
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6709,
    "Closing Rank": 6997
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (Avionics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52728,
    "Closing Rank": 74325
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (Avionics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13713,
    "Closing Rank": 13984
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (Avionics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23605,
    "Closing Rank": 29380
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (Avionics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13275,
    "Closing Rank": 14234
  },
  {
    "Institute": "Central University of Jammu",
    "Academic Program Name": "Electronics and Communication Engineering (Avionics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3998,
    "Closing Rank": 7234
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Aeronautical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 39303,
    "Closing Rank": 76456
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Aeronautical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12767,
    "Closing Rank": 14227
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Aeronautical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25144,
    "Closing Rank": 29074
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Aeronautical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5347,
    "Closing Rank": 11160
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Aeronautical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1212,
    "Closing Rank": 5909
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46633,
    "Closing Rank": 63665
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10695,
    "Closing Rank": 11273
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19493,
    "Closing Rank": 23270
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9093,
    "Closing Rank": 12158
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5126,
    "Closing Rank": 6189
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Dairy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 72856,
    "Closing Rank": 110311
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Dairy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18351,
    "Closing Rank": 18770
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Dairy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 36997,
    "Closing Rank": 38701
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Dairy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14051,
    "Closing Rank": 17043
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Dairy Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7660,
    "Closing Rank": 8143
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60269,
    "Closing Rank": 78073
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13604,
    "Closing Rank": 14307
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24350,
    "Closing Rank": 29078
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12157,
    "Closing Rank": 14526
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5701,
    "Closing Rank": 7408
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Fashion and Apparel Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 85229,
    "Closing Rank": 108526
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Fashion and Apparel Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18081,
    "Closing Rank": 18741
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Fashion and Apparel Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35846,
    "Closing Rank": 38340
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Fashion and Apparel Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14378,
    "Closing Rank": 16809
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Fashion and Apparel Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7141,
    "Closing Rank": 8091
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 96129,
    "Closing Rank": 113756
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18883,
    "Closing Rank": 18947
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38922,
    "Closing Rank": 39359
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15709,
    "Closing Rank": 17076
  },
  {
    "Institute": "Institute of Engineering and Technology, Dr. H. S. Gour University. Sagar (A Central University)",
    "Academic Program Name": "Food Engineering and Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8011,
    "Closing Rank": 8180
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58463,
    "Closing Rank": 88343
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15255,
    "Closing Rank": 15805
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28091,
    "Closing Rank": 33859
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12456,
    "Closing Rank": 14885
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2646,
    "Closing Rank": 5619
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40878,
    "Closing Rank": 55902
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3190,
    "Closing Rank": 4178
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9174,
    "Closing Rank": 10225
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19348,
    "Closing Rank": 21527
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4663,
    "Closing Rank": 10498
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4448,
    "Closing Rank": 5557
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 56176,
    "Closing Rank": 73646
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12099,
    "Closing Rank": 13203
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22852,
    "Closing Rank": 28416
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6934,
    "Closing Rank": 12098
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Electrical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6411,
    "Closing Rank": 6848
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Printing and Packaging Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 80388,
    "Closing Rank": 96179
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Printing and Packaging Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16025,
    "Closing Rank": 16194
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Printing and Packaging Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 32775,
    "Closing Rank": 35334
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Printing and Packaging Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13981,
    "Closing Rank": 14952
  },
  {
    "Institute": "Central University of Haryana",
    "Academic Program Name": "Printing and Packaging Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5687,
    "Closing Rank": 6912
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23803,
    "Closing Rank": 50774
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42369,
    "Closing Rank": 54665
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1952,
    "Closing Rank": 1952
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8477,
    "Closing Rank": 9228
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17583,
    "Closing Rank": 20157
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9979,
    "Closing Rank": 11489
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4713,
    "Closing Rank": 5718
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 60083,
    "Closing Rank": 99112
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 61976,
    "Closing Rank": 93450
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17031,
    "Closing Rank": 18701
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16536,
    "Closing Rank": 22212
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9407,
    "Closing Rank": 24343
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 50942,
    "Closing Rank": 58085
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 62816,
    "Closing Rank": 64024
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2943,
    "Closing Rank": 2943
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9671,
    "Closing Rank": 10185
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20279,
    "Closing Rank": 22016
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11483,
    "Closing Rank": 12363
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5632,
    "Closing Rank": 5632
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70579,
    "Closing Rank": 120113
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 122457,
    "Closing Rank": 122457
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22105,
    "Closing Rank": 23182
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27755,
    "Closing Rank": 30403
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20103,
    "Closing Rank": 30030
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 51183,
    "Closing Rank": 60736
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 51280,
    "Closing Rank": 65927
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10067,
    "Closing Rank": 10333
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18772,
    "Closing Rank": 21673
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10604,
    "Closing Rank": 12312
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5001,
    "Closing Rank": 6447
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 102962,
    "Closing Rank": 121375
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 101967,
    "Closing Rank": 122144
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21069,
    "Closing Rank": 23793
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13729,
    "Closing Rank": 28693
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16593,
    "Closing Rank": 36343
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63517,
    "Closing Rank": 68851
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 68585,
    "Closing Rank": 73040
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12826,
    "Closing Rank": 12942
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22055,
    "Closing Rank": 23395
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13846,
    "Closing Rank": 13950
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6290,
    "Closing Rank": 6290
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 87367,
    "Closing Rank": 121367
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 122820,
    "Closing Rank": 122820
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24142,
    "Closing Rank": 24504
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28100,
    "Closing Rank": 28487
  },
  {
    "Institute": "Birla Institute of Technology, Deoghar Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14926,
    "Closing Rank": 14926
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40482,
    "Closing Rank": 50586
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 42581,
    "Closing Rank": 49902
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1648,
    "Closing Rank": 1648
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8872,
    "Closing Rank": 9177
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17848,
    "Closing Rank": 19893
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9006,
    "Closing Rank": 11337
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5366,
    "Closing Rank": 5609
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 52299,
    "Closing Rank": 70616
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 67662,
    "Closing Rank": 80565
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11863,
    "Closing Rank": 13593
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13786,
    "Closing Rank": 17330
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Artificial Intelligence and Machine Learning (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8801,
    "Closing Rank": 8801
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 47109,
    "Closing Rank": 73960
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60046,
    "Closing Rank": 73723
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12239,
    "Closing Rank": 12661
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23815,
    "Closing Rank": 26923
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8047,
    "Closing Rank": 13453
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4617,
    "Closing Rank": 5253
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 93990,
    "Closing Rank": 108249
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 89801,
    "Closing Rank": 105566
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17967,
    "Closing Rank": 18207
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13872,
    "Closing Rank": 21816
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Civil Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10783,
    "Closing Rank": 10783
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 28630,
    "Closing Rank": 45956
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 45659,
    "Closing Rank": 48437
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 2839,
    "Closing Rank": 2839
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7758,
    "Closing Rank": 8096
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14084,
    "Closing Rank": 18466
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 1474,
    "Closing Rank": 1474
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9010,
    "Closing Rank": 10744
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5188,
    "Closing Rank": 5405
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46620,
    "Closing Rank": 61818
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 63823,
    "Closing Rank": 72038
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10605,
    "Closing Rank": 11201
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12187,
    "Closing Rank": 13611
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Computer Science and Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 7165,
    "Closing Rank": 7165
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 57312,
    "Closing Rank": 61655
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 60252,
    "Closing Rank": 63125
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10360,
    "Closing Rank": 10828
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20509,
    "Closing Rank": 23214
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11714,
    "Closing Rank": 13302
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5864,
    "Closing Rank": 6107
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63936,
    "Closing Rank": 92622
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 86322,
    "Closing Rank": 91840
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15530,
    "Closing Rank": 16205
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14903,
    "Closing Rank": 22079
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electrical and Electronics Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9936,
    "Closing Rank": 9936
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 44807,
    "Closing Rank": 56509
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 53408,
    "Closing Rank": 58096
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9440,
    "Closing Rank": 9910
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20329,
    "Closing Rank": 21241
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11756,
    "Closing Rank": 12263
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5432,
    "Closing Rank": 5829
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 64972,
    "Closing Rank": 84176
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 77418,
    "Closing Rank": 83253
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13920,
    "Closing Rank": 14580
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15851,
    "Closing Rank": 19977
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Electronics and Communication Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8260,
    "Closing Rank": 8260
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 55880,
    "Closing Rank": 68874
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 73325,
    "Closing Rank": 74275
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11401,
    "Closing Rank": 12083
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 22163,
    "Closing Rank": 23525
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13531,
    "Closing Rank": 13884
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6065,
    "Closing Rank": 6485
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 70794,
    "Closing Rank": 105046
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 94955,
    "Closing Rank": 96140
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17412,
    "Closing Rank": 17663
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 20328,
    "Closing Rank": 23126
  },
  {
    "Institute": "Birla Institute of Technology, Patna Off-Campus",
    "Academic Program Name": "Mechanical Engineering (4 Years, Bachelor of Technology)",
    "Quota": "HS",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10369,
    "Closing Rank": 10369
  },
  {
    "Institute": "Indian Institute of Handloom Technology, Salem",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 69188,
    "Closing Rank": 105409
  },
  {
    "Institute": "Indian Institute of Handloom Technology, Salem",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17757,
    "Closing Rank": 17931
  },
  {
    "Institute": "Indian Institute of Handloom Technology, Salem",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 35167,
    "Closing Rank": 36609
  },
  {
    "Institute": "Indian Institute of Handloom Technology, Salem",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11064,
    "Closing Rank": 14193
  },
  {
    "Institute": "Indian Institute of Handloom Technology, Salem",
    "Academic Program Name": "Handloom and Textile Technology (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4879,
    "Closing Rank": 6904
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 38282,
    "Closing Rank": 56220
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 41082,
    "Closing Rank": 46146
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9220,
    "Closing Rank": 10177
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11534,
    "Closing Rank": 11534
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 17644,
    "Closing Rank": 21729
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 20627,
    "Closing Rank": 20973
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 10456,
    "Closing Rank": 12397
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11688,
    "Closing Rank": 11688
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3971,
    "Closing Rank": 5756
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Artificial Intelligenece and Data Science (Transportation and Logistics) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 5723,
    "Closing Rank": 5723
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 24887,
    "Closing Rank": 66836
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 68084,
    "Closing Rank": 68766
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12184,
    "Closing Rank": 12644
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 14951,
    "Closing Rank": 14951
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 21913,
    "Closing Rank": 28475
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 25640,
    "Closing Rank": 25640
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12034,
    "Closing Rank": 12703
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 9796,
    "Closing Rank": 9796
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Aviation Engineering (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5584,
    "Closing Rank": 6478
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 58777,
    "Closing Rank": 86943
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 79828,
    "Closing Rank": 83617
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 14662,
    "Closing Rank": 15637
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15870,
    "Closing Rank": 15870
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 27793,
    "Closing Rank": 30639
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27903,
    "Closing Rank": 29789
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 8714,
    "Closing Rank": 16061
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 15079,
    "Closing Rank": 15079
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6195,
    "Closing Rank": 7150
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Civil Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 4867,
    "Closing Rank": 4867
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 46856,
    "Closing Rank": 69506
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 64097,
    "Closing Rank": 68995
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12029,
    "Closing Rank": 13457
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13005,
    "Closing Rank": 13005
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23545,
    "Closing Rank": 27538
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 27016,
    "Closing Rank": 27016
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9464,
    "Closing Rank": 13582
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13922,
    "Closing Rank": 13922
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electrical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 3754,
    "Closing Rank": 5672
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 48658,
    "Closing Rank": 63121
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 56628,
    "Closing Rank": 56628
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11552,
    "Closing Rank": 11955
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 19156,
    "Closing Rank": 24323
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 23816,
    "Closing Rank": 23816
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 9111,
    "Closing Rank": 12852
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 11712,
    "Closing Rank": 11712
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Electronics & Communication Engineering (Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 5819,
    "Closing Rank": 6158
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 40082,
    "Closing Rank": 74076
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 59637,
    "Closing Rank": 64579
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12113,
    "Closing Rank": 13674
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13343,
    "Closing Rank": 13343
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 23138,
    "Closing Rank": 28510
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 22964,
    "Closing Rank": 25695
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6237,
    "Closing Rank": 13332
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Female-only (including Supernumerary)",
    "Opening Rank": 13035,
    "Closing Rank": 13035
  },
  {
    "Institute": "Gati Shakti Vishwavidyalaya, Vadodara",
    "Academic Program Name": "B.Tech in Mechanical Engineering ( Rail Engineering) (4 Years, Bachelor of Technology)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4674,
    "Closing Rank": 6102
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Civil Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 63781,
    "Closing Rank": 110858
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Civil Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18411,
    "Closing Rank": 18582
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Civil Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37359,
    "Closing Rank": 38628
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Civil Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 11599,
    "Closing Rank": 16937
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Civil Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6543,
    "Closing Rank": 8041
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Computer Science & Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 45526,
    "Closing Rank": 79221
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Computer Science & Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13391,
    "Closing Rank": 13688
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Computer Science & Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 25235,
    "Closing Rank": 29829
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Computer Science & Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 12812,
    "Closing Rank": 13932
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Computer Science & Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4613,
    "Closing Rank": 6539
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Electrical Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 76789,
    "Closing Rank": 92728
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Electrical Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN (PwD)",
    "Gender": "Gender-Neutral",
    "Opening Rank": 4335,
    "Closing Rank": 4335
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Electrical Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 15576,
    "Closing Rank": 16278
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Electrical Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 31589,
    "Closing Rank": 36796
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Electrical Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 13538,
    "Closing Rank": 16326
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech. - M. Tech in Electrical Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6685,
    "Closing Rank": 7896
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech.- M. Tech in Metallurgical & Materials Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OPEN",
    "Gender": "Gender-Neutral",
    "Opening Rank": 91148,
    "Closing Rank": 111567
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech.- M. Tech in Metallurgical & Materials Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "EWS",
    "Gender": "Gender-Neutral",
    "Opening Rank": 18667,
    "Closing Rank": 18876
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech.- M. Tech in Metallurgical & Materials Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "OBC-NCL",
    "Gender": "Gender-Neutral",
    "Opening Rank": 37570,
    "Closing Rank": 39002
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech.- M. Tech in Metallurgical & Materials Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "SC",
    "Gender": "Gender-Neutral",
    "Opening Rank": 16473,
    "Closing Rank": 17172
  },
  {
    "Institute": "CU Jharkhand",
    "Academic Program Name": "Integrated B. Tech.- M. Tech in Metallurgical & Materials Engineering (5 Years, Integrated B. Tech. and M. Tech.)",
    "Quota": "AI",
    "Seat Type": "ST",
    "Gender": "Gender-Neutral",
    "Opening Rank": 6278,
    "Closing Rank": 8144
  }
]
