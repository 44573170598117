[
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No. OCF, Sector-A-8, Narela, Delhi-110040",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOHS",
    "Rank": 10777
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No. OCF, Sector-A-8, Narela, Delhi-110040",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOOS",
    "Rank": 3265
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No. OCF, Sector-A-8, Narela, Delhi-110040",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOHS",
    "Rank": 11840
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No. OCF, Sector-A-8, Narela, Delhi-110040",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOOS",
    "Rank": 10039
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No. OCF, Sector-A-8, Narela, Delhi-110040",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOHS",
    "Rank": 10424
  },
  {
    "Institute": "Chandarprabhu Jain College of Higher Studies & School of Law, Plot No. OCF, Sector-A-8, Narela, Delhi-110040",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOOS",
    "Rank": 7825
  },
  {
    "Institute": "Delhi Institute of Rural Development (Sister Branch of DIRD) G T Karnal Road Village, Nangli Puna DELHI 110036",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOHS",
    "Rank": 11966
  },
  {
    "Institute": "Delhi Institute of Rural Development (Sister Branch of DIRD) G T Karnal Road Village, Nangli Puna DELHI 110036",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "SCNOHS",
    "Rank": 11570
  },
  {
    "Institute": "Delhi Institute of Rural Development (Sister Branch of DIRD) G T Karnal Road Village, Nangli Puna DELHI 110036",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOOS",
    "Rank": 7511
  },
  {
    "Institute": "Delhi Institute of Rural Development (Sister Branch of DIRD) G T Karnal Road Village, Nangli Puna DELHI 110036",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOHS",
    "Rank": 11551
  },
  {
    "Institute": "Delhi Metropolitan Education (Sunshine\nEducation & Development Society), B-12, Sector 62, Noida, U.P.",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOOS",
    "Rank": 10571
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOHS",
    "Rank": 11790
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOOS",
    "Rank": 7457
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOHS",
    "Rank": 11366
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOOS",
    "Rank": 10981
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "OPNOHS",
    "Rank": 11853
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "OPNOOS",
    "Rank": 8903
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOHS",
    "Rank": 10881
  },
  {
    "Institute": "Fairfield Institute of Management & Technology\n, Plot No.1037/1, Kapashera, New Delhi-110037",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOOS",
    "Rank": 7602
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOHS",
    "Rank": 11647
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOOS",
    "Rank": 6070
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "SCNOOS",
    "Rank": 6411
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOHS",
    "Rank": 10082
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOOS",
    "Rank": 7563
  },
  {
    "Institute": "Gitarattan International Business School, Rohini Educational Society at PSP, Complex-II, Madhuban Chowk, Delhi",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOOS",
    "Rank": 6731
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi-110085",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOHS",
    "Rank": 11802
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi-110085",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOOS",
    "Rank": 5433
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi-110085",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "OPNOOS",
    "Rank": 6044
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi-110085",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOHS",
    "Rank": 10064
  },
  {
    "Institute": "Maharaja Agrasen Institute of Management Studies, Sector-22, Rohini, Delhi-110085",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOOS",
    "Rank": 2896
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOHS",
    "Rank": 8807
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "EWNOOS",
    "Rank": 10616
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOOS",
    "Rank": 1980
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "SCNOOS",
    "Rank": 5624
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPDFHS",
    "Rank": 5412
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOHS",
    "Rank": 4844
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "SCNOHS",
    "Rank": 7593
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "STNOHS",
    "Rank": 9461
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "LL.M. (Criminal Justice System) - LL.M (CJS)",
    "Category": "OPNOOS",
    "Rank": 1710
  },
  {
    "Institute": "University School of Law & Legal Studies,\nGGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "OPDFHS",
    "Rank": 6288
  },
  {
    "Institute": "University School of Law & Legal Studies,\nGGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "OPNOHS",
    "Rank": 5708
  },
  {
    "Institute": "University School of Law & Legal Studies,\nGGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "SCNOHS",
    "Rank": 7899
  },
  {
    "Institute": "University School of Law & Legal Studies,\nGGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "OPNOOS",
    "Rank": 2461
  },
  {
    "Institute": "University School of Law & Legal Studies,\nGGSIP University Campus, Sector 16C, Dwarka, New Delhi - 75",
    "Course": "LL.M. (Intellectual Property Right) - LL.M. (IPR)",
    "Category": "SCNOOS",
    "Rank": 2594
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOHS",
    "Rank": 4866
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "SCNOHS",
    "Rank": 11573
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOOS",
    "Rank": 2652
  },
  {
    "Institute": "University School of Law & Legal Studies, GGSIP University Campus, Sector 16C, Dwarka,\nNew Delhi - 75",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "STNOOS",
    "Rank": 7951
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New\nDelhi.",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOHS",
    "Rank": 11651
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New\nDelhi.",
    "Course": "LL.M. (Alternative Dispute Resolution) - LLM (ADR)",
    "Category": "OPNOOS",
    "Rank": 5226
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New\nDelhi.",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOHS",
    "Rank": 11099
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New\nDelhi.",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "OPNOOS",
    "Rank": 3445
  },
  {
    "Institute": "Vivekananda Institute of Professional Studies, AU Block (Outer Ring Road), Pitampura, New\nDelhi.",
    "Course": "Master of Law (LL.M.) (Corporate Law)",
    "Category": "SCNOOS",
    "Rank": 8325
  }
]