import React, { useState } from 'react';
import Sidebar from './sidebar'; // Adjust the path as necessary

const Facilities = (props) => {
  const [showMoreLikes, setShowMoreLikes] = useState(false);
  const [showMoreDislikes, setShowMoreDislikes] = useState(false);

  const additionalLikes = [
    "Improved flexibility for learning at my own pace",
    "Access to a wider variety of online resources",
  ];

  const additionalDislikes = [
    "Lack of immediate feedback from instructors",
    "Difficulty in building in-person relationships",
  ];

  return (
    <div className="flex flex-col lg:flex-row p-4 lg:p-8 bg-gray-50 min-h-screen">
      {/* Main Facilities Section */}
      <div className="w-full lg:w-2/3 pr-0 lg:pr-4 mb-6 lg:mb-0">
        {/* Title Section */}
        <div className="mb-6">
          <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
            {props.CollegeName} Campus
          </h3>
          <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2 text-gray-600">
            {props.CollegeFacilitiesInfo}
          </p>
        </div>

        {/* Campus Highlights Section */}
        <div className="mb-6">
          <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold">
            {props.CollegeName} Campus Highlights
          </h2>
          <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2 text-gray-600">
            {props.collegeHighlights}
          </p>
          <ul className="list-disc list-inside mt-4 space-y-2">
            {props.CampusFacility1 && <li>{props.CampusFacility1}</li>}
    		{props.CampusFacility2 && <li>{props.CampusFacility2}</li>}
    		{props.CampusFacility3 && <li>{props.CampusFacility3}</li>}
    		{props.CampusFacility4 && <li>{props.CampusFacility4}</li>}
    		{props.CampusFacility5 && <li>{props.CampusFacility5}</li>}
    		{props.CampusFacility6 && <li>{props.CampusFacility6}</li>}
    		{props.CampusFacility7 && <li>{props.CampusFacility7}</li>}
          </ul>
        </div>

        {/* Image Sections */}
            <div  className="mb-6">
              <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
                {props.CampusFacility1}
              </h3>
              <img
                src={props.CampusFacility1PhotoURL}
                className="mt-4 w-full object-cover rounded-md shadow-md"
              />
              <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-4 text-gray-600">
                {props.CampusFacility1Paragraph}
              </p>
            </div>
			<div  className="mb-6">
              <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
                {props.CampusFacility2}
              </h3>
              <img
                src={props.CampusFacility2PhotoURL}
                className="mt-4 w-full object-cover rounded-md shadow-md"
              />
              <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-4 text-gray-600">
                {props.CampusFacility2Paragraph}
              </p>
            </div>

		<div  className="mb-6">
              <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
                {props.CampusFacility3}
              </h3>
              <img
                src={props.CampusFacility3PhotoURL}
                className="mt-4 w-full object-cover rounded-md shadow-md"
              />
              <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-4 text-gray-600">
                {props.CampusFacility3Paragraph}
              </p>
            </div>
<div  className="mb-6">
              <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
                {props.CampusFacility4}
              </h3>
              <img
                src={props.CampusFacility4PhotoURL}
                className="mt-4 w-full object-cover rounded-md shadow-md"
              />
              <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-4 text-gray-600">
                {props.CampusFacility4Paragraph}
              </p>
            </div>
<div  className="mb-6">
              <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
                {props.CampusFacility5}
              </h3>
              <img
                src={props.CampusFacility5PhotoURL}
                className="mt-4 w-full object-cover rounded-md shadow-md"
              />
              <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-4 text-gray-600">
                {props.CampusFacility6Paragraph}
              </p>
            </div>
<div  className="mb-6">
              <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
                {props.CampusFacility7}
              </h3>
              <img
                src={props.CampusFacility7PhotoURL}
                className="mt-4 w-full object-cover rounded-md shadow-md"
              />
              <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-4 text-gray-600">
                {props.CampusFacility7Paragraph}
              </p>
            </div>

        {/* Hostel Section */}
        <div className="mb-6">
          <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold">
            {props.CollegeName} Hostel
          </h2>
          <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2 text-gray-600">
            {props.HostelParagraph}
          </p>
        </div>

        {/* Hostel Facilities */}
        <div className="mb-6">
          <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
            {props.CollegeName} Hostel Facilities
          </h3>
          <ul className="list-disc list-inside mt-4 space-y-2">
			{props.CollegeHostelFacility1 &&  <li>{props.CollegeHostelFacility1}</li>}
			{props.CollegeHostelFacility2 &&  <li>{props.CollegeHostelFacility2}</li>}
			{props.CollegeHostelFacility3 &&  <li>{props.CollegeHostelFacility4}</li>}
			{props.CollegeHostelFacility5 &&  <li>{props.CollegeHostelFacility5}</li>}
           
          </ul>
        </div>

        {/* Hostel Fee Section */}
        <div className="mb-6">
          <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
            {props.CollegeName} Hostel Fee
          </h3>
          <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2 text-gray-600">
            The hostel fee is added to the total course fee of{" "}
            <a href="#" className="text-blue-600">
              {props.CollegeName}
            </a>
            . Candidates can refer to the following table for specific fee
            details:
          </p>

          {/* Conditional Rendering for Hostel Fee Table */}
          {props.CollegeCautionFees || props.totalhostelfee ? (
            <table className="table-auto w-full mt-4 text-left border">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-2">Component</th>
                  <th className="px-4 py-2">Fee</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-t">
                  <td className="px-4 py-2">Caution deposit</td>
                  <td className="px-4 py-2">{props.CollegeCautionFees}</td>
                </tr>
                <tr className="border-t">
                  <td className="px-4 py-2">Total hostel fee</td>
                  <td className="px-4 py-2">{props.totalhostelfee}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p className="mt-4 text-gray-600">
              Currently, there are no specific fee details available. Please
              contact the administration for more information.
            </p>
          )}

          <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2 text-gray-600">
            Note: The fee mentioned here is as per an unofficial source. Hence,
            it is indicative.
          </p>
        </div>
      </div>

      {/* Sidebar Section */}
      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
};

export default Facilities;
