[
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 239,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 940,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 7,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 14,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 18,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 30,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 33,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 42,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 47,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 49,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 58,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 64,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 70,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 74,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 80,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 81,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 82,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 89,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 100,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 106,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 128,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 136,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 139,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 146,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 150,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 165,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 172,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 193,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 200,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 201,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 223,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 227,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 229,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 237,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 276,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 282,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 292,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 304,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 329,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 330,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 346,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 385,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 420,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 439,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 451,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 480,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "GC_KA",
    "Rank": 498,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 530,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 542,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 552,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 553,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 554,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 559,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 560,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 575,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 580,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 614,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "GC_KA",
    "Rank": 645,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 656,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 662,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 670,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 750,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 759,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "W",
    "Rank": 778,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 810,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 816,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 824,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 844,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 858,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 884,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 889,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 927,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 942,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 969,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 975,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 978,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "GC_KA",
    "Rank": 1016,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1038,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1041,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1043,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1146,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1195,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "GC_KA",
    "Rank": 1210,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 1215,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1217,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1236,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_KA",
    "Rank": 1249,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1318,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1360,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1394,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1403,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1526,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "Rank": 1537,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1642,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 1645,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 1786,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 2089,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 2253,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 2954,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "Rank": 3113,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_KA",
    "Rank": 3228,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "EWS_KA",
    "Rank": 3267,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "Rank": 3306,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 3315,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "PWD,W",
    "Rank": 3468,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "EWS",
    "SubCategory": "W,EWS_KA",
    "Rank": 3586,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 3663,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "PWD",
    "Rank": 3674,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "Rank": 4030,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 4052,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "W,SC_KA",
    "Rank": 4116,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "Rank": 4228,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "Rank": 4348,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 4405,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "W,OBC_KA",
    "Rank": 4583,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 4688,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 5028,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_KA",
    "Rank": 5565,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_KA",
    "Rank": 5821,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_KA",
    "Rank": 5865,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "SC",
    "SubCategory": "W,SC_KA",
    "Rank": 5944,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_KA",
    "Rank": 10974,
    "College Name": "National Law School of India University (NLSIU), Bengaluru"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 295,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 298,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 884,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 107,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 119,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 131,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 152,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 161,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 169,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 173,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 202,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 207,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 225,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 226,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 230,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 232,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 239,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 240,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 243,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 244,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 246,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 251,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 260,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 265,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 266,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 275,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 289,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 294,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 355,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 482,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "EWS",
    "SubCategory": "W",
    "Rank": 497,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 531,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 699,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 710,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 761,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 762,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 795,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "EWS",
    "SubCategory": "W",
    "Rank": 877,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "W",
    "Rank": 882,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 896,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 902,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 937,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 940,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "W",
    "Rank": 988,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 990,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1066,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "Rank": 1388,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1432,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "Rank": 1551,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1567,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "Rank": 1594,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "W",
    "Rank": 1683,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "Rank": 1711,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "GC_TL",
    "SubCategory": "Not Applicable",
    "Rank": 1907,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 1988,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2026,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 2167,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2247,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC_B_TL",
    "SubCategory": "Not Applicable",
    "Rank": 2834,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC_A_TL",
    "SubCategory": "W",
    "Rank": 3167,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "EWS_TL",
    "SubCategory": "W",
    "Rank": 3232,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC_E_TL",
    "SubCategory": "W",
    "Rank": 3235,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 3294,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "OBC_D_TL",
    "SubCategory": "Not Applicable",
    "Rank": 3438,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W,PWD",
    "Rank": 3538,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC_TL",
    "SubCategory": "W",
    "Rank": 3897,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 3928,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 4313,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 4710,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 4785,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "SC_TL",
    "SubCategory": "Not Applicable",
    "Rank": 5401,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "ST_TL",
    "SubCategory": "W",
    "Rank": 5649,
    "College Name": "NALSAR University of Law (NALSAR), Hyderabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 431,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 651,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1992,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5970,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 241,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 300,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 328,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 339,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 343,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 352,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 353,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 365,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 370,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 380,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 382,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 388,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 390,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 394,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 401,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 416,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 423,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 448,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 465,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 469,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 485,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 570,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 576,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 595,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 619,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 621,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 632,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 642,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "Rank": 1068,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "Rank": 1285,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1349,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP",
    "Rank": 1356,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "Rank": 1483,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1724,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1905,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "Rank": 2297,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "Rank": 2477,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP",
    "Rank": 2522,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 2596,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2749,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 2803,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP",
    "Rank": 3323,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 3336,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3721,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "Rank": 4301,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "OBC",
    "SubCategory": "W,PWD",
    "Rank": 4322,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP",
    "Rank": 4584,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 4726,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP",
    "Rank": 4741,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 5270,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 5495,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5559,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 5644,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5703,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 5723,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5813,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "Rank": 6501,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP",
    "Rank": 8349,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP",
    "Rank": 8480,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP",
    "Rank": 11364,
    "College Name": "The National Law College Name University (NLIU), Bhopal"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 587,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 589,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 611,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 620,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2732,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5555,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "Rank": 11255,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 158,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 238,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 252,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 253,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 295,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 301,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 312,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 317,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 319,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 320,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 325,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 338,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 351,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 356,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 362,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 363,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 372,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 377,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 383,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 391,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 395,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 396,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 398,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 399,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 405,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 414,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 425,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 437,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 438,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 452,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 458,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 459,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 470,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 473,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 492,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 495,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 502,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 505,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 511,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 513,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 514,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 516,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 518,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 532,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 536,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 540,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 546,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 564,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 577,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 579,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 586,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 752,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 790,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 805,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 807,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 830,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 854,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 915,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 921,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1354,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1449,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1474,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1515,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1541,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1592,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1610,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1661,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1899,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1911,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "GC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 1952,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2018,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "Rank": 2177,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2274,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2362,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2450,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2553,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2586,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2632,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 3263,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "Rank": 3353,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "Rank": 3836,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "Rank": 4346,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "OBC_A_WB",
    "SubCategory": "Not Applicable",
    "Rank": 4462,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 4702,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 4719,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 4824,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 4901,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5462,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 5882,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "OBC_B_WB",
    "SubCategory": "Not Applicable",
    "Rank": 5893,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 5899,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 6041,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 6599,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 6765,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 6801,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "SC_WB",
    "SubCategory": "Not Applicable",
    "Rank": 6812,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 8262,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 8687,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST_WB",
    "SubCategory": "Not Applicable",
    "Rank": 9926,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "ST_WB",
    "SubCategory": "Not Applicable",
    "Rank": 10724,
    "College Name": "The West Bengal National University of Juridical Sciences (WBNUJS), Kolkata"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 595,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 599,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 638,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1905,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1959,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 2039,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC_B_WB",
    "SubCategory": "SC_RJ",
    "Rank": 3952,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 290,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_RJ",
    "Rank": 298,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 344,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_RJ",
    "Rank": 348,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 361,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 384,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_RJ",
    "Rank": 392,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 397,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 406,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_RJ",
    "Rank": 431,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 455,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 460,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 481,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 490,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 500,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 510,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 515,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 519,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 533,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 568,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 585,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 589,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 590,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "Rank": 598,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_RJ",
    "Rank": 616,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "Rank": 737,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_RJ,PWD",
    "Rank": 992,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1468,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1508,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1666,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "Rank": 1728,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_RJ",
    "Rank": 1729,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1768,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1805,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1847,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "Rank": 2138,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2209,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_RJ",
    "Rank": 2516,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2710,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2732,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2993,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_RJ",
    "Rank": 3355,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3403,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3465,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 3880,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_RJ",
    "Rank": 4532,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 4595,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5562,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "MBC_RJ",
    "SubCategory": "Not Applicable",
    "Rank": 9939,
    "College Name": "National Law University (NLU), Jodhpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1051,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1059,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1070,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1074,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1080,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1093,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1094,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1096,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2987,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 3149,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3173,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 3412,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3428,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 3455,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 3608,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 4873,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5056,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 5065,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "W_ST",
    "Rank": 9058,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 599,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 600,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 620,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 627,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 643,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 647,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 650,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 651,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 653,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 668,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 709,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 715,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 721,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 724,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 731,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 732,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 755,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 775,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 785,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 789,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 815,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 818,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 826,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 831,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 837,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 838,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 846,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 853,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 866,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 874,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 891,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 901,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 917,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 949,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 964,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 977,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 979,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 994,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 995,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1009,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1014,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1017,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1040,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1939,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1953,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2113,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2130,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2154,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2234,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2245,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2354,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2394,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2465,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2501,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2508,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2536,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2549,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2630,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2690,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2757,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2829,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2852,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2865,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2923,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W_OBC",
    "Rank": 2967,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2975,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 3321,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3839,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 3927,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4046,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 4137,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 4144,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 4178,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4242,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4473,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 4510,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4573,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "W_SC",
    "Rank": 4652,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4821,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5839,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "W_ST",
    "Rank": 6642,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "W_ST",
    "Rank": 7300,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "W_ST",
    "Rank": 7370,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 7718,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "W_ST",
    "Rank": 7928,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "ST",
    "SubCategory": "W_ST",
    "Rank": 8357,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "OBC",
    "SubCategory": "PWD",
    "Rank": 8931,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 9293,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "SC",
    "SubCategory": "PWD",
    "Rank": 10184,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 11520,
    "College Name": "Hidayatullah National Law University (HNLU), Raipur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 627,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 2354,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2465,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 2501,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2757,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 4144,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6372,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 125,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 177,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 318,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 326,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 419,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 421,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 466,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 483,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 561,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 587,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 593,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 596,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 851,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 904,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "GC_GJ",
    "Rank": 991,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1083,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "Rank": 1111,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "GC_GJ",
    "Rank": 1118,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1122,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "Rank": 1147,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1177,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1205,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "GC_GJ",
    "Rank": 1245,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1293,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1309,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "GC_GJ",
    "Rank": 1456,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1528,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1576,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1720,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1798,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1854,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1949,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1959,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 1962,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1992,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 2039,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 2069,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "PWD,GC_GJ",
    "Rank": 2199,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "GC_GJ",
    "Rank": 2261,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "Rank": 2298,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_GJ",
    "Rank": 2320,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "GC_GJ",
    "Rank": 2496,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "GC_GJ",
    "Rank": 2507,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "PWD,W",
    "Rank": 2929,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 2976,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3018,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 3055,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 3458,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3495,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3504,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3534,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3779,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3815,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 4544,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "ST",
    "SubCategory": "W,GC_GJ",
    "Rank": 5146,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5555,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "PWD,W",
    "Rank": 5652,
    "College Name": "Gujarat National Law University (GNLU), Gandhinagar"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1211,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1216,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1267,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1297,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2237,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3626,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3703,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3805,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3858,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6459,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 6656,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 634,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 723,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 728,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 747,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 802,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 987,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1044,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1080,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1096,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1102,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1119,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1143,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1158,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1165,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1204,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1298,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1785,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1981,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 2460,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 2960,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3007,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 3051,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 3118,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3173,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3185,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3428,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 4081,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4341,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 5065,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 5118,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5134,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 5645,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_DDN",
    "Rank": 8932,
    "College Name": "Gujarat National Law University (GNLU), Silvassa"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 19,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 31,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 36,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 52,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 83,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 96,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 103,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 108,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 113,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 178,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 181,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "CDP_UP",
    "Rank": 209,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 245,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 259,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 261,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 267,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "CDP_UP",
    "Rank": 274,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 277,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 315,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 324,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 537,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "EWS_UP",
    "SubCategory": "Not Applicable",
    "Rank": 562,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "W",
    "Rank": 567,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 722,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 745,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "W",
    "Rank": 772,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 828,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "EWS_UP",
    "SubCategory": "W",
    "Rank": 967,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "W",
    "Rank": 1000,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "EWS_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1025,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1048,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1099,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1108,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1247,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "EWS_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1307,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "W",
    "Rank": 1323,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1325,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1400,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 1491,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "W",
    "Rank": 1579,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1597,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1628,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1648,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 1820,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "SC_UP",
    "SubCategory": "W",
    "Rank": 1863,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "OBC_UP",
    "SubCategory": "Not Applicable",
    "Rank": 2145,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "ST_UP",
    "SubCategory": "W",
    "Rank": 4280,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 4350,
    "College Name": "Dr. Ram Manohar Lohiya National Law University (RMLNLU), Lucknow"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 987,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5118,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5188,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5971,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6614,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6881,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 90,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 185,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 217,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 247,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 422,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 501,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 526,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 548,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 569,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 663,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 674,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 690,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 692,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 695,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 697,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 720,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 725,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 726,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 740,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 751,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 753,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 760,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 764,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 765,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 766,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 771,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 774,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 783,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 786,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 797,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 798,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 809,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 811,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 814,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 836,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 841,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 880,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 893,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 897,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 924,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 936,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 982,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 1883,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 2563,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3539,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3637,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 3952,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4251,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4253,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4265,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4494,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4743,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5417,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5725,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5970,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6103,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6372,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 10798,
    "College Name": "Rajiv Gandhi National University of Law (RGNUL), Punjab"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1560,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1573,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1578,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1580,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1581,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1586,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1590,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1617,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1626,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1707,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 2184,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 2270,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "JKM",
    "SubCategory": "Not Applicable",
    "Rank": 2628,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "JKM",
    "SubCategory": "Not Applicable",
    "Rank": 3905,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "Rank": 5622,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 486,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 588,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 611,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 782,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 835,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 903,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 997,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1019,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1042,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1059,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1070,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1186,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1211,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1224,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1234,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1248,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1264,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1267,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1272,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1297,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1304,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1333,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1409,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1418,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1437,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1450,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1467,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1475,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1490,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1530,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1544,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 1703,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 1765,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 1809,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 1824,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 1887,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 1924,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "JKM",
    "SubCategory": "Not Applicable",
    "Rank": 2098,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "Rank": 2384,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "Rank": 2505,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "Rank": 2581,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "JKM",
    "SubCategory": "Not Applicable",
    "Rank": 2595,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "Rank": 3044,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "Rank": 3210,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "GC_KL",
    "SubCategory": "PWD",
    "Rank": 3394,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "Rank": 3588,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "Rank": 3755,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "OBX_KL",
    "SubCategory": "Not Applicable",
    "Rank": 3868,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "Rank": 4493,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "LA_KL",
    "SubCategory": "Not Applicable",
    "Rank": 4651,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "LA_KL",
    "SubCategory": "Not Applicable",
    "Rank": 4777,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "Rank": 4815,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "OBH_KL",
    "SubCategory": "Not Applicable",
    "Rank": 4883,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "MU_KL",
    "SubCategory": "Not Applicable",
    "Rank": 4888,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EZ_KL",
    "SubCategory": "Not Applicable",
    "Rank": 5122,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "Rank": 5654,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 6171,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "OBH_KL",
    "SubCategory": "Not Applicable",
    "Rank": 6562,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 6825,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 7317,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "DV_KL",
    "SubCategory": "Not Applicable",
    "Rank": 7631,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 8179,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "VK_KL",
    "SubCategory": "Not Applicable",
    "Rank": 8194,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "SC_KL",
    "SubCategory": "Not Applicable",
    "Rank": 8279,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "EWS_KL",
    "SubCategory": "Not Applicable",
    "Rank": 9708,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "ST_KL",
    "SubCategory": "Not Applicable",
    "Rank": 9811,
    "College Name": "The National University of Advanced Legal Studies (NUALS), Kochi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1248,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1264,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1295,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1312,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1329,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1358,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 2156,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5215,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 8559,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 8575,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_OD",
    "Rank": 9467,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 484,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 494,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 685,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 819,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 839,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 862,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 900,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 922,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 938,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 989,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1021,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1051,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1090,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 1095,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1098,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1115,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 1116,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1120,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1130,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1132,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1141,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1157,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1159,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1174,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1198,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1212,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1244,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1246,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 1420,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 1518,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 1539,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_OD",
    "Rank": 1701,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4010,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4873,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_OD",
    "Rank": 6638,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6881,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 7155,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 7490,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 7514,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_OD",
    "Rank": 7897,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_OD",
    "Rank": 9058,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "DOM_ OD,PWD,PWD_OD",
    "Rank": 9768,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 10680,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_OD",
    "Rank": 11111,
    "College Name": "National Law University Odisha (NLUO)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1623,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1668,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1700,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 3179,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3234,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 3270,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 3587,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6869,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 8841,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 9101,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 827,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 869,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 916,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1022,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1075,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1173,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1182,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1295,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1300,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1302,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1312,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1329,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1343,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1358,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 1481,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 1547,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1618,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 1657,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 1702,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 1731,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2112,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 2127,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "Rank": 2168,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 2202,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 2286,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2382,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2462,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2464,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2471,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 2621,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 2772,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 2805,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2818,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "GC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 2927,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2987,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3062,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 3149,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "Rank": 3788,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EBC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 3810,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 4166,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 4582,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 4586,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4860,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 4952,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5056,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5083,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5241,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5338,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 5667,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 6053,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6097,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "BC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 6420,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "Rank": 7360,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "Rank": 7518,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "Rank": 8240,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EBC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 8576,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 8724,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "Rank": 8748,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "SC_JD",
    "SubCategory": "Not Applicable",
    "Rank": 9952,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "ST_JD",
    "SubCategory": "Not Applicable",
    "Rank": 10966,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "EWS_JD",
    "SubCategory": "Not Applicable",
    "Rank": 11154,
    "College Name": "National University of Study and Research in Law (NUSRL),Ranchi"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1827,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1874,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1961,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2119,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2140,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2146,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2155,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2183,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2187,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2195,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2958,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "Rank": 9703,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "GC_AS",
    "Rank": 934,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "GC_AS",
    "Rank": 1253,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1321,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1342,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1463,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1480,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1545,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1548,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1560,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1573,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1581,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1586,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1590,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1611,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1619,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1626,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1646,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1663,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1669,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1678,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1685,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1707,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1743,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "GC_AS",
    "Rank": 1751,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "GC_AS",
    "Rank": 1760,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1762,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1770,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1781,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1808,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "GC_AS",
    "Rank": 2835,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "Rank": 6383,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "Rank": 6459,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "STH_AS",
    "SubCategory": "Not Applicable",
    "Rank": 8483,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "Rank": 8928,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "Rank": 9362,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "SC_AS",
    "SubCategory": "Not Applicable",
    "Rank": 9407,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "STP_AS",
    "SubCategory": "Not Applicable",
    "Rank": 9702,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "Rank": 9734,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "Rank": 10644,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "OBC_AS",
    "SubCategory": "Not Applicable",
    "Rank": 11821,
    "College Name": "National Law University and Judicial Academy (NLUJA), Assam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1684,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1818,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1868,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1901,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1957,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2033,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2275,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2420,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3652,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3744,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3895,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3908,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3929,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 5289,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 5642,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 5812,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "BC_D_AP",
    "SubCategory": "Not Applicable",
    "Rank": 6850,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6890,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 9316,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 9326,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 10294,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "Rank": 10467,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 754,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "W_AP",
    "Rank": 923,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "W_AP",
    "Rank": 1225,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1316,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1371,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1387,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1447,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1555,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1580,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1617,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1627,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1654,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1700,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 1815,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2040,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 2097,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 2360,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 2404,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2771,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 2780,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 3092,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3412,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3449,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3455,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3558,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3608,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 3671,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 3742,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 4039,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 4040,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 4129,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 4135,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 4379,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 4674,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 4769,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "GC_AP",
    "SubCategory": "W_AP",
    "Rank": 4908,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5199,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5253,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5346,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5647,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "BC_D_AP",
    "SubCategory": "W_AP",
    "Rank": 5867,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },

  {
    "Category": "BC_D_AP",
    "SubCategory": "W_AP",
    "Rank": 6338,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "BC_A_AP",
    "SubCategory": "Not Applicable",
    "Rank": 6517,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "Not Applicable",
    "Rank": 6523,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "BC_A_AP",
    "SubCategory": "Not Applicable",
    "Rank": 6680,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "Rank": 6743,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "Rank": 6917,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "BC_B_AP",
    "SubCategory": "W_AP",
    "Rank": 7191,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "BC_B_AP",
    "SubCategory": "Not Applicable",
    "Rank": 7226,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "Rank": 7972,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 8559,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 8875,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "Rank": 9224,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "EWS_AP",
    "SubCategory": "W_AP",
    "Rank": 9479,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "Rank": 9994,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "SC_AP",
    "SubCategory": "W_AP",
    "Rank": 10165,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "ST_AP",
    "SubCategory": "W_AP",
    "Rank": 10403,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },
  {
    "Category": "BC_E_AP",
    "SubCategory": "Not Applicable",
    "Rank": 11467,
    "College Name": "Damodaram Sanjivayya National Law University (DSNLU), Visakhapatnam"
  },

  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3941,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3948,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3972,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 4028,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6238,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6332,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1251,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1478,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1578,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 1591,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1818,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2244,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 2713,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 3014,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3216,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3234,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3236,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 3957,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4162,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4827,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 5092,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5527,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 5798,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 5940,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6291,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 9849,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "ST_TN",
    "SubCategory": "Not Applicable",
    "Rank": 11187,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Corporate and Securities Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3236,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 5504,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6848,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 7788,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 8128,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 641,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1011,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 1956,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 2374,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2400,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2411,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2576,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3155,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 3526,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4295,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4621,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4733,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4753,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4907,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5858,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6586,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6913,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 7592,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 8552,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "SCA_TN",
    "SubCategory": "Not Applicable",
    "Rank": 10322,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 11422,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Intellectual Property Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2236,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2241,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2246,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 4073,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 4076,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 4099,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 4108,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6722,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6883,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 7045,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 7072,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 8214,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 9564,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1006,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1827,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1874,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 2550,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3262,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3473,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3556,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3622,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3626,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4594,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "GC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4696,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 4831,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 5296,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "BCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 5354,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6180,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6280,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "BCM_TN",
    "SubCategory": "Not Applicable",
    "Rank": 6679,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 8907,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 9418,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "MBC__DNC_TN",
    "SubCategory": "Not Applicable",
    "Rank": 9483,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "SCO_TN",
    "SubCategory": "Not Applicable",
    "Rank": 11408,
    "College Name": "Tamil Nadu National Law University (TNNLU), Tiruchirappalli _ LLM (Natural Resources Laws)"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 754,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 782,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 5967,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 10503,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 242,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 528,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 626,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 631,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 638,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 664,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 687,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 700,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 702,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 717,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 727,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W,XS_MH",
    "Rank": 1078,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "XS_MH",
    "Rank": 1335,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 1452,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W,XS_MH",
    "Rank": 1991,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 2000,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 2521,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "W",
    "Rank": 2605,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 2705,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "W",
    "Rank": 2874,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 3107,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 3330,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "Rank": 3410,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 3593,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 3683,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "W",
    "Rank": 3826,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "NTC_MH",
    "SubCategory": "W",
    "Rank": 4006,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 4044,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 4189,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 4227,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "W",
    "Rank": 4258,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 4398,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 4511,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5070,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5144,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5242,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 5309,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "W",
    "Rank": 5330,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5368,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "SBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5522,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W,PWD",
    "Rank": 5862,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "NTC_MH",
    "SubCategory": "W",
    "Rank": 8161,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W,PWD",
    "Rank": 9036,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9156,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9388,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "NTB_MH",
    "SubCategory": "W",
    "Rank": 9614,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "W,PWD",
    "Rank": 9869,
    "College Name": "Maharashtra National Law University (MNLU), Mumbai"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1674,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1694,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1721,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1774,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1801,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1808,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9083,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9403,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9766,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9822,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 375,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 667,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 791,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 813,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 856,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 999,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1039,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1062,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1063,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1091,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1252,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1273,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1289,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1320,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1336,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1364,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1382,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1393,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1416,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1460,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "Rank": 4114,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 4731,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5499,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5898,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5967,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6109,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6159,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6172,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6209,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6366,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6379,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6536,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6580,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6606,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7036,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7080,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7142,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7229,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7573,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7618,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7701,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7741,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "NTD_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7909,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7911,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8039,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "NTB_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8101,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8131,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8728,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8738,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8889,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8913,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8940,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9343,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9672,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 10409,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 10480,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 10503,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "NTC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 10749,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "Rank": 11262,
    "College Name": "Maharashtra National Law University (MNLU), Nagpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1933,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2088,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2102,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2129,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2142,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2160,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2196,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2205,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2210,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2240,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2242,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2243,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2250,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 9775,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9876,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W,XS_MH",
    "Rank": 10532,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1012,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1170,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1419,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1582,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1623,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1643,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1674,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1676,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1684,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1721,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1774,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1790,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1810,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1868,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1876,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1893,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1897,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1901,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1910,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1915,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W,XS_MH",
    "Rank": 2518,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 2833,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W,XS_MH",
    "Rank": 3209,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "XS_MH",
    "Rank": 3313,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 5052,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "W",
    "Rank": 5108,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5454,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "W",
    "Rank": 5628,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "PWD_MH",
    "Rank": 5635,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 5982,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6051,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "NTD_MH",
    "SubCategory": "W",
    "Rank": 6068,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6317,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 6576,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6662,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 6924,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "W",
    "Rank": 7025,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 7405,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "W",
    "Rank": 8225,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "W",
    "Rank": 8390,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8514,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 8667,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 9083,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "EWS_MH",
    "SubCategory": "W",
    "Rank": 9105,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9110,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 9207,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "Not Applicable",
    "Rank": 9280,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SC_MH",
    "SubCategory": "W",
    "Rank": 9340,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "OBC_MH",
    "SubCategory": "W",
    "Rank": 9679,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "ST_MH",
    "SubCategory": "Not Applicable",
    "Rank": 10127,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "SBC_MH",
    "SubCategory": "W",
    "Rank": 10666,
    "College Name": "Maharashtra National Law University (MNLU), Aurangabad"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1886,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1897,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1946,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2041,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2125,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2132,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2148,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 6578,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6593,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6767,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6929,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 6997,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "GC_HP",
    "Rank": 8286,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 8564,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 8620,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 8677,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 9461,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 9536,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 9612,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 623,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 686,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 696,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 787,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 817,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 920,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 948,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "GC_HP",
    "Rank": 984,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1036,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 1074,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1081,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1089,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1093,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1094,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 1100,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1150,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1255,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1261,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1274,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1279,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1314,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1324,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1339,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1348,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1362,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1377,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1381,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1383,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1390,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1484,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1487,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1496,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1517,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1566,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1650,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1691,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1692,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1694,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1706,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1710,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1801,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1841,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "GC_HP",
    "Rank": 2033,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 2140,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 2166,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 2446,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,WSRD_HP",
    "Rank": 2704,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,WSRD_HP",
    "Rank": 2931,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 3470,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "GC_HP",
    "Rank": 4021,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 4043,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4191,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4229,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 4606,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 4647,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4701,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4756,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 4900,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 5061,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5165,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 5188,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 5215,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "GC_HP",
    "Rank": 5329,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "Rank": 5582,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5876,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5936,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5971,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 6614,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 6811,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "SC",
    "SubCategory": "PWD",
    "Rank": 6852,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 7034,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "WSRD_HP",
    "Rank": 7523,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W,GC_HP",
    "Rank": 7711,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 7858,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "GC_HP",
    "Rank": 8029,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 8055,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "GC_HP",
    "Rank": 8274,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 8575,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 8904,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "PWD",
    "Rank": 8909,
    "College Name": "Himachal Pradesh National Law University (HPNLU), Shimla"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2143,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2188,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2226,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2238,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2248,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2260,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2263,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2278,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 2279,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2428,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2532,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "Rank": 2595,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 2814,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 2862,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2948,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 3028,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 3100,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 3205,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 3719,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "Rank": 6060,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 6349,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 6394,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "Rank": 6401,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "Rank": 9011,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 9817,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 9908,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 9933,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 850,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1216,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1313,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1668,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 1696,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1715,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 1726,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1846,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1880,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1886,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1904,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1909,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1918,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "W",
    "Rank": 1919,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 1993,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2101,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "EWS",
    "SubCategory": "Not Applicable",
    "Rank": 2106,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2283,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2321,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 2367,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "Rank": 2375,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2430,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "JKRES",
    "SubCategory": "Not Applicable",
    "Rank": 2444,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2449,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "W",
    "Rank": 2499,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP,W",
    "Rank": 2564,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2608,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2674,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 2741,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 2777,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "Not Applicable",
    "Rank": 3299,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "GC_MP",
    "Rank": 3340,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "Rank": 3732,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 4569,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "Rank": 4874,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "Rank": 4994,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP",
    "Rank": 5246,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "Not Applicable",
    "Rank": 5466,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 5698,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "EWS",
    "SubCategory": "EWS_MP",
    "Rank": 5717,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "Rank": 5956,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "W",
    "Rank": 6018,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "OBC",
    "SubCategory": "OBC_MP,W",
    "Rank": 6038,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP",
    "Rank": 6915,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 7207,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP",
    "Rank": 7328,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP,W",
    "Rank": 7767,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "SC",
    "SubCategory": "SC_MP",
    "Rank": 8359,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 8505,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "Rank": 8509,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP",
    "Rank": 8714,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 9094,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "W",
    "Rank": 9125,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "Not Applicable",
    "Rank": 9145,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP",
    "Rank": 10293,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP,W",
    "Rank": 10385,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "ST",
    "SubCategory": "ST_MP",
    "Rank": 11956,
    "College Name": "Dharmashastra National Law University (MPDNLU), Jabalpur"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2153,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2162,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2197,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2222,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2256,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2293,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2303,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2312,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2317,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2318,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2330,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2333,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2340,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2344,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2358,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2365,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2368,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2372,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2391,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2406,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2416,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2426,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2439,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2440,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2447,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2451,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2463,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2470,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2475,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2481,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2487,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2503,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2511,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2513,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2517,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2523,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2525,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2530,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 10293,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1793,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1933,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1941,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1946,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1957,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1961,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1963,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1972,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1974,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1977,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1984,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 1987,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2022,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2025,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2027,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2030,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2041,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2043,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2044,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2045,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2057,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2059,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2060,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2063,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2066,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2081,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2084,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2088,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2102,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2104,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2107,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2111,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2117,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2119,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2125,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2128,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "Not Applicable",
    "Rank": 2129,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 2408,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 3342,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 4966,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 5793,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 7962,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 8371,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 8467,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "General",
    "SubCategory": "GC_TR",
    "Rank": 9043,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "ST_TR",
    "SubCategory": "Not Applicable",
    "Rank": 9326,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "ST_TR",
    "SubCategory": "Not Applicable",
    "Rank": 9637,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "SC_TR",
    "SubCategory": "Not Applicable",
    "Rank": 9760,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "SC_TR",
    "SubCategory": "Not Applicable",
    "Rank": 11036,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  },
  {
    "Category": "SC_TR",
    "SubCategory": "Not Applicable",
    "Rank": 11928,
    "College Name": "National Law University Tripura (NLUT), Agartala"
  }
]