import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, ""); // Remove special characters
};

const CollegeRanking = () => {
  const [collegesData, setCollegesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch data from the backend API
    const fetchColleges = async () => {
      try {
        const response = await fetch('https://caderaedu.com/api/college/all');
        const data = await response.json();
        setCollegesData(data.colleges);
        // console.log(data.colleges); // Debugging: check fetched data
        setLoading(false);
      } catch (err) {
        setError('Failed to load college data.');
        setLoading(false);
      }
    };

    fetchColleges();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  const handleCollegeClick = (name) => {
    const slug = generateSlug(name);
    navigate(`/college/${slug}`);
  };

  return (
    <div className="container mx-auto my-8 px-4">
      {/* Heading */}
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8">Top Colleges</h2>

      {/* Colleges List */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                Rank
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                College
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
              Courses Offered
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                Fees 
              </th>
              <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                AIR
              </th>
              {/* <th className="py-4 px-6 sm:px-2 text-gray-700 font-semibold text-xs sm:text-sm">
                Fees
              </th> */}
            </tr>
          </thead>
          <tbody>
            {collegesData.map((college, index) => (
              <tr key={index} className={`border-b ${index % 2 !== 0 ? 'bg-blue-50' : ''}`}>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">
                  {college.rank || index + 1}
                </td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">
                  <div>
                    <button className="font-semibold text-left text-blue-400" onClick={()=>handleCollegeClick(college.name)}>{college.name}</button>
                    <div className="text-gray-500 text-xs sm:text-sm">{college.location || 'N/A'}</div>
                  </div>
                </td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">{college.courses[0].name || 'N/A'}</td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">{college.courses[0].fee || 'N/A'}</td>
                <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">{college.college_rank || 'N/A'}</td>
                {/* <td className="py-4 px-6 sm:px-2 text-xs sm:text-sm">{college.fees || 'N/A'}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CollegeRanking;
