import React, { useState } from "react";
import { Table, Select, Input, Button, Typography, Space, message } from "antd";
import data from "./GGSIPUMBAPredictor.json"; // Import JSON file
import CompulsoryTimesignup from "../CompulsoryTimesignup";
const { Option } = Select;
const { Title } = Typography;

const GGSIPUMBA = () => {
  const [course, setCourse] = useState("All");
  const [category, setCategory] = useState("All");
  const [rank, setRank] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track form submission
  const [showSignupModal, setShowSignupModal] = useState(false); // State to control modal visibility
  const [isPredictionAttempted, setIsPredictionAttempted] = useState(false); // Track prediction attempt

  // Full forms for category abbreviations
  const categoryFullForms = {
    NOAFAI: "Army",
    EWNOHS: "Delhi EWS",
    OPDFHS: "Delhi General Defence",
    OPNOHS: "Delhi General",
    SCNOHS: "Delhi Scheduled Caste",
    EWNOOS: "Outside Delhi EWS",
    OPDFOS: "Outside Delhi General Defence",
    OPNOOS: "Outside Delhi General",
    SCNOOS: "Outside Delhi Scheduled Caste",
};

  // Unique dropdown options
  const courseOptions = [...new Set(data.map((item) => item.Course))];
  const categoryOptions = [...new Set(data.map((item) => item.Category))];

  // Filter Logic
  const filteredInstitutes = data.filter((institute) => {
    const matchesCourse = course === "All" || institute.Course === course;
    const matchesCategory = category === "All" || institute.Category === category;
    const matchesRank = rank === "" || institute.Rank >= parseInt(rank, 10);
    return matchesCourse && matchesCategory && matchesRank;
  });

  // Ant Design Table Columns
  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => index + 1, // Placeholder for calculation
    },
    {
      title: "Institute",
      dataIndex: "Institute",
      key: "Institute",
    },
  ];

  const handlePredictClick = () => {
    // Validation: Ensure all fields are filled
    if (course === "All" || category === "All" || rank === "") {
      message.error("All fields are mandatory. Please fill them out to predict.");
      return; // Stop further execution if validation fails
    }

    setShowResults(true);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
      GGSIPU MBA Predictor
      </Title>
      <CompulsoryTimesignup
        onFormSubmit={() => {
          setIsFormSubmitted(true); // Set form submitted to true once form is completed
          setShowSignupModal(false); // Close modal after successful submission
        }}
        showSignupModal={showSignupModal} // Pass down the state for modal visibility
        onCloseModal={() => setShowSignupModal(false)} // Function to close the modal
      />
      {/* Filter Form */}
      <Space direction="vertical" style={{ width: "100%", marginBottom: "20px" }}>
        <div>
          <label>Course:</label>
          <Select
            value={course}
            onChange={(value) => setCourse(value)}
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Option value="All">Select Option</Option>
            {courseOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </div>

        <div>
          <label>Category:</label>
          <Select
            value={category}
            onChange={(value) => setCategory(value)}
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Option value="All">Select Option</Option>
            {categoryOptions.map((option) => (
              <Option key={option} value={option}>
                {option} ({categoryFullForms[option]})
              </Option>
            ))}
          </Select>
        </div>

        <div>
          <label>Rank:</label>
          <Input
            type="number"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            placeholder="Enter rank"
            style={{ width: "100%", marginTop: "5px" }}
          />
        </div>

        <Button
          type="primary"
          style={{ width: "100%", marginTop: "10px" }}
          onClick={handlePredictClick}
        >
         Predict Institute
        </Button>
      </Space>

      {/* Results Table */}
      {showResults && (
        <div style={{ marginTop: "20px" }}>
          {filteredInstitutes.length > 0 ? (
            <Table
              dataSource={filteredInstitutes.map((item, index) => ({
                ...item,
                key: index,
              }))}
              columns={[
                {
                  title: "S.No",
                  render: (_, __, index) => {
                    const currentPage = (document.querySelector(
                      ".ant-pagination-item-active"
                    )?.textContent || 1) - 1;
                    const pageSize = 1; // Update as needed
                    return index + 1 + currentPage * pageSize;
                  },
                },
                {
                  title: "Institute",
                  dataIndex: "Institute",
                  key: "Institute",
                },
              ]}
              pagination={{
                pageSize: 15,
              }}
              bordered
            />
          ) : (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
             OOPS!! YOU  DO NOT GET ANY INSTITUTE AT THIS RANK 
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default GGSIPUMBA;
