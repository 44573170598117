// import React from "react";
// const SeatIntakeTable = ({ courses, CollegeName }) => {
//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h2 className="text-xl font-semibold mb-6">
//         {CollegeName ? `${CollegeName} Seat Intake` : "Seat Intake"}
//       </h2>
//       <div className="overflow-x-auto">
//         <table className="min-w-full border-collapse bg-white">
//           <thead>
//             <tr className="bg-blue-100">
//               <th className="px-4 py-2 text-left">Course Name</th>
//               <th className="px-4 py-2 text-left">Quota</th>
//               <th className="px-4 py-2 text-left">Seat Intake</th>
//             </tr>
//           </thead>
//           <tbody>
//             {courses && courses.length > 0 ? (
//               courses.map((course, index) =>
//                 course.quotas.map((quota, quotaIndex) => (
//                   <tr key={`${index}-${quotaIndex}`} className="border-b">
//                     <td className="px-4 py-2">{course.name}</td>
//                     <td className="px-4 py-2">{quota.quotaType}</td>
//                     <td className="px-4 py-2">{quota.seats}</td>
//                   </tr>
//                 ))
//               )
//             ) : (
//               <tr>
//                 <td colSpan="3" className="px-4 py-2 text-center">
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };
// export default SeatIntakeTable;
import React from "react";

const SeatIntakeTable = ({ courses, CollegeName }) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl font-semibold mb-6">
        {CollegeName ? `${CollegeName} Seat Intake` : "Seat Intake"}
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse bg-white">
          <thead>
            <tr className="bg-blue-100">
              <th className="px-4 py-2 text-left">Course Name</th>
              <th className="px-4 py-2 text-left">Quota</th>
              <th className="px-4 py-2 text-left">Seat Intake</th>
            </tr>
          </thead>
          <tbody>
            {courses && courses.length > 0 ? (
              courses
                .filter((course) => course.name) // Filter out courses without a name
                .map((course, index) =>
                  course.quotas.map((quota, quotaIndex) => (
                    <tr key={`${index}-${quotaIndex}`} className="border-b">
                      <td className="px-4 py-2">{course.name}</td>
                      <td className="px-4 py-2">{quota.quotaType}</td>
                      <td className="px-4 py-2">{quota.seats}</td>
                    </tr>
                  ))
                )
            ) : (
              <tr>
                <td colSpan="3" className="px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SeatIntakeTable;
