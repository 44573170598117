[
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 137390,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 309252,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 281397,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 587565,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1154098,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD( ST Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 378379,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 157050,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 390670,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 360132,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 825639,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 1389185,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 124222,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 276212,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 264840,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 507738,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 860643,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 133986,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 342877,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 268475,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 549283,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1159291,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 104368,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 237603,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 196101,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 383254,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 681286,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD( ST Category Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 1077216,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 121765,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 272930,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 236321,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 496646,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 905661,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 116042,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 335252,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 210790,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 437535,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 849708,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 154884,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 387389,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 338172,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 681781,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 1233891,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 143594,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 355859,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 313947,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 613040,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1235567,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 149556,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 383119,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 313198,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 712288,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1300810,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 95524,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 972059,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 191394,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 177663,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "(OBC Category Person with disability Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 354467,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 1208572,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 569382,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 98662,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 176075,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 190092,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 302294,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 620544,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD( ST Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 971893,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 157638,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 388196,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 363032,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 792518,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 146482,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 347924,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 300541,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 687960,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 1236038,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 136230,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 321781,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 254157,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 519470,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 934304,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 137153,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 256841,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 275779,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 561060,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 1039559,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 40459,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 45707,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 57730,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 190767,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 378379,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 43357,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 49102,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 60877,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 210930,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Printing Technology",
    "Gender": "Male/Female",
    "Rank": 418467,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 39245,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 44421,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 57217,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 200064,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 381816,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 39348,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 44877,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 57933,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 204716,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 381423,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 35385,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 39748,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 57143,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 160673,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Male/Female",
    "Rank": 381035,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 36963,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 44062,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 56623,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 204286,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 36706,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 43485,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 56379,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Data Analytics",
    "Gender": "Male/Female",
    "Rank": 201326,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 43299,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 49982,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 59069,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 203319,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 343034,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 42034,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 47494,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 59562,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 207115,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 380841,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 42538,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 48731,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 60526,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 209727,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 400041,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 31128,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 42015,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 54547,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 163523,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 378337,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 34635,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 43340,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 55318,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 183837,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 268249,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 43423,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 49668,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 60631,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Tool Engineering",
    "Gender": "Male/Female",
    "Rank": 212028,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 41936,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 46593,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 59504,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 206543,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechatronics Engineering",
    "Gender": "Male/Female",
    "Rank": 387602,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 38753,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 46189,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 57756,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 209864,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 38516,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 45974,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 56589,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Network Engineering & Security",
    "Gender": "Male/Female",
    "Rank": 208421,
    "Institute": "DELHI SKILL AND ENTREPREURSHIP UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 45739,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 53980,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication - AI",
    "Gender": "Female",
    "Rank": 80292,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 82462,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 68272,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 69333,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 98257,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Data Management and Analysis",
    "Gender": "Female",
    "Rank": 105940,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 6954,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 82958,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 119580,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Electronics and Communication - AI",
    "Gender": "Female",
    "Rank": 194657,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 151427,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 147045,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 104561,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 202715,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Data Management and Analysis",
    "Gender": "Female",
    "Rank": 385431,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 10126,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 959513,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 640722,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 387848,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 311015,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication - AI",
    "Gender": "Female",
    "Rank": 743436,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 655685,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 511481,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 528188,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 1349056,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Data Management and Analysis",
    "Gender": "Female",
    "Rank": 1099401,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 54831,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 709472,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 899995,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 927894,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 176593,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 192010,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication - AI",
    "Gender": "Female",
    "Rank": 308601,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 319412,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 236462,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 259846,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 402391,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Data Management and Analysis",
    "Gender": "Female",
    "Rank": 461613,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 49419,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 356758,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 567533,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Electronics and Communication - AI",
    "Gender": "Female",
    "Rank": 471154,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 388633,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 770468,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 626081,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Data Management and Analysis",
    "Gender": "Female",
    "Rank": 632143,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 92955,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 110352,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication - AI",
    "Gender": "Female",
    "Rank": 156473,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 160216,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 130914,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 132471,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 217017,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Data Management and Analysis",
    "Gender": "Female",
    "Rank": 245318,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 29348,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWCWD(EWS Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 284244,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWCWD(EWS Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 181967,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 52461,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 56018,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Electronics and Communication - AI",
    "Gender": "Female",
    "Rank": 84931,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 90650,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 72946,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 73291,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 105724,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Data Management and Analysis",
    "Gender": "Female",
    "Rank": 114543,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "KM(Kashmiri Migrant)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 124747,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 28410,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 25613,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering-AI",
    "Gender": "Female",
    "Rank": 33187,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 32355,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 30706,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 31349,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 42614,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "DMAM",
    "Gender": "Female",
    "Rank": 39598,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 2490,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 965191,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 95937,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Electronics and Communication Engineering-AI",
    "Gender": "Female",
    "Rank": 202419,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 201819,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 350097,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 363291,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 159725,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 146930,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering-AI",
    "Gender": "Female",
    "Rank": 202505,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 198604,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 184959,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 171956,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 208106,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "DMAM",
    "Gender": "Female",
    "Rank": 217146,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 387863,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 269936,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering-AI",
    "Gender": "Female",
    "Rank": 401236,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 298635,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 317316,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 319091,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 366596,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 6588,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 42200,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 41586,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering-AI",
    "Gender": "Female",
    "Rank": 57864,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 54311,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 48060,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 49718,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 60444,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "DMAM",
    "Gender": "Female",
    "Rank": 60739,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Female",
    "Rank": 5695,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 48257,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 178178,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 71430,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Female",
    "Rank": 33195,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Female",
    "Rank": 27588,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering-AI",
    "Gender": "Female",
    "Rank": 43572,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Female",
    "Rank": 41440,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Female",
    "Rank": 34638,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "AIML",
    "Gender": "Female",
    "Rank": 38824,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechanical and Automation Engineering",
    "Gender": "Female",
    "Rank": 44649,
    "Institute": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 9717,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 12803,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 15264,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 19074,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 21874,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 24436,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 30182,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 31542,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 39515,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 49909,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 61154,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 79890,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 35335,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 39235,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 47145,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 75156,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 84121,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 88631,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Male/Female",
    "Rank": 6219,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 18327,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 25468,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 26286,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 30826,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 33072,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 38028,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 48352,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 53078,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 65267,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 74833,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 96473,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 149099,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 54376,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 58980,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 70752,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 148509,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 158779,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 173994,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Male/Female",
    "Rank": 21924,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 30361,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 49996,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 57736,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 76935,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 84388,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 90670,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 117158,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 124554,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 162609,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 199619,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 245326,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 425798,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 122106,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 148000,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 181233,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 429105,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 474810,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 570334,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Male/Female",
    "Rank": 37937,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 82671,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 113605,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 130553,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 161096,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 187910,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 217029,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 243281,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 268558,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 382617,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 592273,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 680587,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 921597,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 242397,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 320932,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 455021,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 901257,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 842323,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 1064756,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Male/Female",
    "Rank": 61765,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 254803,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 314867,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 384101,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 542855,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 568571,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 722781,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1195863,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 39639,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1089198,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 83030,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1097599,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 763740,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 586310,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 824815,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 1090974,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 722907,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 894734,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 1077485,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Male/Female",
    "Rank": 34909,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 7417,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 8168,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 8757,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 9422,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 10632,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 10680,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 12368,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 12959,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 16229,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 18602,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 22083,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 29585,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 11227,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 12659,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 13497,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 25061,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 30115,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 31389,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "B.Arch (Paper 2)",
    "Gender": "Male/Female",
    "Rank": 1449,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 18327,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 25468,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 26286,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 30826,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 33072,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 38028,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 48352,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 53078,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 65267,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 74833,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 96473,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 149099,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 54376,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 58980,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 70752,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 148509,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 158779,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 173994,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 30361,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 49996,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 57736,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 76935,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 84388,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 90670,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 117158,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 124554,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 162609,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 199619,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 245326,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 425798,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 122106,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 148000,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 181233,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 429105,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 474810,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 570334,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 82671,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 113605,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 130553,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 161096,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 187910,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 217029,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 243281,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 268558,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 382617,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 592273,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 680587,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 921597,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 242397,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 320932,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 455021,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 901257,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 842323,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 1064756,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 254803,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 314867,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 384101,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 542855,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 568571,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 722781,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1195863,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 222532,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1089198,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 383479,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 372164,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 384046,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 586310,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 824815,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 358129,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 372147,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 42085,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 16573,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 18509,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 20647,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 23859,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 25681,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 30048,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 37404,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 39639,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 70420,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 83030,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 88025,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 83821,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 55920,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 70486,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 82978,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 105529,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 107062,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGLO(General Not Given for Locality Option)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 116027,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 720686,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 63023,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 55989,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 124881,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 128362,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 35192,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 41050,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 65191,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 92422,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 127177,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 97373,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 153603,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 159473,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 380581,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 30713,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 413073,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 455021,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNCWO( General Category Defence Oustide Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 1234546,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 680283,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 64598,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 31275,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 124899,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 170392,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 274503,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 460877,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 613108,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 785123,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 931188,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1325377,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1582012,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 260103,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 310342,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 410501,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 560977,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 615678,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWCWO(EWS Category Defence Outside Delhi)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 780991,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 731377,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 54861,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 64203,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 131452,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 178849,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 192706,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 274397,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 376543,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 497680,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 532902,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 774583,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1210987,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 210592,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 321682,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 433795,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 650788,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 784723,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBCWO(OBC Category Defence Outside Delhi)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 1093847,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 620209,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 534877,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 687273,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 896545,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 1058499,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 1378280,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1425708,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 1456873,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1550679,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 1684799,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1782633,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1900284,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 312591,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 405680,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 510703,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 763221,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 896352,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCCWO(Self Certification for Caste/Other Verification Outstanding)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 1209834,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 874620,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 976308,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 1143589,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 1389093,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 1493878,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 1554891,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1645889,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 1783936,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1861575,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 2001010,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 2137851,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 2359081,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 356278,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 432895,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 563184,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 684197,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 801738,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STCWO(Scheduled Tribe Caste Verification Outstanding)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 1005732,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 451472,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 736514,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 842709,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 990945,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 1113923,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 1217480,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1302305,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 1423007,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1498700,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 1594871,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1635941,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1722093,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 247808,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 321767,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 410998,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 573195,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 623569,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNPDO( General Category Person With Disability Outside Delhi Region)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 763838,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 384712,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 536947,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 672763,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 813184,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 934272,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 1057880,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1211077,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 1312670,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1450985,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 1584427,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1705395,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1899087,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWPDO( EWS Category Person with disability Outside Delhi)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 258734,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 596210,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 453103,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 511220,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 694317,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 890678,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 1012362,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1127489,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 1234767,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1342531,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 1461234,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1580290,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1712159,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 212005,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 308911,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 400551,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 514219,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 606201,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBPDO(Other Backward Classes (OBC) Provisional Document Out)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 725135,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 518381,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 409305,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 486319,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 649261,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 821597,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 936057,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1045854,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 1163283,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1269650,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 1387561,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1503872,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1624348,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 203123,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 297652,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 387847,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 498032,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 586052,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCPDO(Scheduled Caste Provisional Document Out)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 708352,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 402489,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 314570,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Data Science)",
    "Gender": "Male/Female",
    "Rank": 375655,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 507893,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Information Technology (Network and Information Security)",
    "Gender": "Male/Female",
    "Rank": 639400,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 736241,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 823287,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Electronics Engineering (VLSI Design and Technology)",
    "Gender": "Male/Female",
    "Rank": 912328,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1023386,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Instrumentation and Control Engineering",
    "Gender": "Male/Female",
    "Rank": 1134895,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 1244821,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 1362762,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Big Data Analytics)",
    "Gender": "Male/Female",
    "Rank": 174902,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Computer Science and Engineering (Internet of Things)",
    "Gender": "Male/Female",
    "Rank": 263420,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Electronics and Communication Engineering (Artificial Intelligence and Machine Learning)",
    "Gender": "Male/Female",
    "Rank": 347621,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Mechanical Engineering (Electric Vehicles)",
    "Gender": "Male/Female",
    "Rank": 452936,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 523007,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STPDO(Scheduled Tribe Provisional Document Out)",
    "Branch": "Geoinformatics",
    "Gender": "Male/Female",
    "Rank": 635191,
    "Institute": "NETAJI SUBHAS UNIVERSITY OF TECHNOLOGY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 13567,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 25062,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 57096,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 122763,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 361124,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 15060,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 17916,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 27361,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 71245,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 140198,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 494782,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 20928,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 21614,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 30793,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 83824,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 175806,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 515808,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 24512,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 24053,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 35773,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 93741,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 213200,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 967013,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 26715,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 28173,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 41619,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 109062,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 222834,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 1176223,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 31492,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 37098,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 56169,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 149952,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 346626,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 1268457,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 54011,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 54356,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 79877,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 203069,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 459706,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 732816,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 100098,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 56014,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 86511,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 210234,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 479584,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 81079,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 53492,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 82675,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 200510,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 615180,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 96283,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 62712,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 103693,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 254518,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 618505,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 95849,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 71914,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 118803,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 294176,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 580756,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "STD(ST Category Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 468166,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 112350,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 65734,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 113617,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 317506,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 812707,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 120929,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 75960,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 137948,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 382735,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 924695,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 104831,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 76007,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 141758,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 320922,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 738541,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "SG(Single Girl Child)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 83845,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 6406,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 10421,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 12590,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 66040,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 88837,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 6871,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 12088,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 15893,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 73585,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Information Technology",
    "Gender": "Male/Female",
    "Rank": 125374,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 10046,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 14131,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 21624,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 86411,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Software Engineering",
    "Gender": "Male/Female",
    "Rank": 106627,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 10289,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 14223,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 24179,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 96377,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mathematics and Computing",
    "Gender": "Male/Female",
    "Rank": 129675,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 11787,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 15529,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 25615,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 99941,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 151748,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 15907,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 17614,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 33509,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 128524,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electrical Engineering",
    "Gender": "Male/Female",
    "Rank": 194690,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 20977,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 24501,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 44147,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 170442,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering",
    "Gender": "Male/Female",
    "Rank": 260436,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 16830,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 23791,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 45219,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 173048,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Mechanical Engineering with specialization in Automotive Engineering",
    "Gender": "Male/Female",
    "Rank": 368939,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 19657,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 24442,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 49002,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 187998,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Engineering Physics",
    "Gender": "Male/Female",
    "Rank": 375857,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 22845,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 25367,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 50025,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 180128,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Chemical Engineering",
    "Gender": "Male/Female",
    "Rank": 206487,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 28180,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 37215,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 53032,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 178296,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Civil Engineering",
    "Gender": "Male/Female",
    "Rank": 252579,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 25875,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 29899,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 54184,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 188464,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Production & Industrial Engineering",
    "Gender": "Male/Female",
    "Rank": 383834,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 27789,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 37686,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 55534,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 201699,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Environmental Engineering",
    "Gender": "Male/Female",
    "Rank": 387956,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 26859,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 37660,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 55770,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 197944,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Bio-Technology",
    "Gender": "Male/Female",
    "Rank": 282632,
    "Institute": "DELHI TECHNOLOGICAL UNIVERSITY"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 111264,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 77706,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 197359,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 142445,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 85498,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 177071,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 211433,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBCWD(OBC Category Defence Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 297700,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 91885,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 69014,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 118234,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 93853,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 77284,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 110610,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 129389,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "OBD(OBC Category Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 139416,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 21973,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 15175,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 23894,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 20788,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 16368,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 23573,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 23566,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "OBO(OBC Category Outside Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 25399,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWCWD(EWS Category Defence Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 44866,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWCWD(EWS Category Defence Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 78647,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 34188,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 25210,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 43881,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 37521,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 30034,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 41120,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 47856,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "EWD(EWS Category Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 51223,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 13240,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 7261,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 14726,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 13422,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 12045,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 15148,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 15280,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "EWO(EWS Category Outside Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 16603,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 12846,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 150678,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 46816,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 62447,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 121036,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 32789,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 20879,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNKM(Kashmiri Migrant)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 78495,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 19736,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 310232,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 48262,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 22262,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 199515,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 44693,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 30877,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNCWD(General Category Defence Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 48549,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 14246,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 7558,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 21559,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 16524,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 9984,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 20507,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 23258,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGND(General Category Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 26442,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 6078,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 4778,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 8645,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 6695,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 4201,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 9672,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 8872,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNGNO(General Category Outside Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 9911,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 408025,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 83476,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 492106,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 408056,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 304157,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 422761,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 785734,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "GNPDD(General Category Person with Disability Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 924573,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 382637,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 900774,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCD(SC Category, Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 256102,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 217116,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 154703,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 294345,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 232098,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 162855,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 281995,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 307690,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 389103,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 94128,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 66859,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 108531,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 70651,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 93441,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Computer Science and Social Sciences",
    "Gender": "Male/Female",
    "Rank": 108493,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 108660,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "SCO(SC Category Outside Delhi Region)",
    "Branch": "Engineering in Environmental Science",
    "Gender": "Male/Female",
    "Rank": 105367,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 822638,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Design",
    "Gender": "Male/Female",
    "Rank": 631551,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Delhi Region",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 824775,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Applied Mathematics",
    "Gender": "Male/Female",
    "Rank": 103550,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering - AI",
    "Gender": "Male/Female",
    "Rank": 133028,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Biosciences (CSB)",
    "Gender": "Male/Female",
    "Rank": 142256,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Computer Science and Engineering",
    "Gender": "Male/Female",
    "Rank": 86188,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  },
  {
    "Region": "Outside Delhi",
    "Category": "STO(ST Category Outside Delhi Region)",
    "Branch": "Electronics and Communication Engineering",
    "Gender": "Male/Female",
    "Rank": 145213,
    "Institute": "Indraprastha Institute of Information Technology Delhi"
  }
]